import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/integration';
import { toastify } from '../ducks/toast';

function* importDataFromCSV(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/importDataFromCSV', action.data);
    if (res.status === 'success') {
      yield put(actions.importDataFromCSVSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getIntegrationMST(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getIntegrationMST', action.data);

    if (res.status === 'success') {
      yield put(actions.getIntegrationMSTSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getUserIntegration(action) {
  try {
    yield put(load());
    let res;
    if (action.data.typ)
      res = yield call(api.GET, '/getUserIntegration?shp=' + action.data.shp + '&typ=' + action.data.typ);
    else
      res = yield call(api.GET, '/getUserIntegration?shp=' + action.data.shp);

    if (res.status === 'success') {
      yield put(actions.getUserIntegrationSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getFullUserIntegration() {
  try {
    yield put(load());
    let res = yield call(api.GET, '/getUserIntegration');

    if (res.status === 'success') {
      yield put(actions.getFullUserIntegrationSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addUpdIntegration(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addUpdateIntegration', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delIntegration(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteIntegration', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getWebhookField(action) {
  try {
    // yield put(load());
    const res = yield call(api.GET, '/webhook/getField?fuid=' + action.data.fuid);

    if (res.status === 'success') {
      yield put(actions.getWebhookFieldSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    // yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* startListening(action) {
  try {
    // yield put(load());
    const res = yield call(api.GET, '/webhook/startNewListening?fuid=' + action.data.fuid);

    if (res.status === 'success') {
      yield put(actions.getWebhookFieldSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    // yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCstmWbhookField(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/webhook/createField', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      //get updated user integration data
      yield put(actions.getUserIntegrationSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delWebhookField(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/webhook/delField', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      //get updated user integration data
      yield put(actions.getUserIntegrationSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* syncToken(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/syncToken', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.syncTokenSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delSyncToken(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/removeSyncToken', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
      yield put(actions.delSyncTokenSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createInterakt(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createInterakt', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.createInteraktSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateInterakt(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateInteraktKey', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.updateInteraktSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
      yield put(actions.updateInteraktErr(res.data));
    }
    yield put(loaded());
  } catch (error) {
    yield put(actions.updateInteraktErr(true));
  }
}

function* addTmptIdInterakt(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addTmptId', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.addTmptIdInteraktSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delTmptIdInterakt(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/removeTmplById', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.delTmptIdInteraktSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateTmplIdInteraktStatus(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateTmplIdStatus', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.updateTmplIdInteraktStatusSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateTmplMappingInterakt(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateTmplMapping', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.updateTmplMappingInteraktSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* sendWTAMessageInterakt(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/sendWTAMessage', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.sendWTAMessageInteraktSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addUpdateSyndication(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addUpdateSyndication', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createKlaviyo(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createKlaviyo', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delKlaviyo(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteKlaviyo', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createWati(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createWati', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
      yield put(actions.watiTampList());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}
function* createWatiTmp(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createWatiTemplate', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
      yield put(actions.watiTampList());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delWati(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteWati', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delWatiTmp(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/removeWatiTmpl', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delWatiField(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/delWatiField', action.data);

    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* watiTampList(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getWatiTemplate', action.data);
    if (res.status === 'success') {
      yield put(actions.watiTampListSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}
function* getWatiList(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getMappingField ', action.data);
    if (res.status === 'success') {
      yield put(actions.getWatiFieldSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateWatiTmp(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateTemplateData', action.data);
    if (res.status === 'success') {
      yield put(actions.updateWatiTemplateDataSuccess(res.data));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* sendWatiMsg(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/sendWatiMessage', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* sendTestDataWebhook(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/webhook/sendTestData', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getUserIntegration({ shp: localStorage.getItem('shp') }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateIntegrationStatus(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateIntegrationStatus', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* facebookIntegration(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/facebook/get', action.data);

    if (res.status === 'success') {
      yield put(actions.facebookIntegrationSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createFBPage(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/facebook/createPage', action.data);

    if (res.status === 'success') {
      yield put(actions.facebookIntegrationSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteFBList(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/facebook/deleteList?_id=' + action.data._id + '&id=' + action.data.id);
    if (res.status === 'success') {
      yield put(actions.facebookIntegrationSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteFB(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/facebook/delete?_id=' + action.data);
    if (res.status === 'success') {
      yield put(actions.facebookIntegrationSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


//GMB
function* getGMB() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/gmb/get');
    if (res.status === 'success') {
      yield put(actions.gmbSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createGMBPage(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/gmb/createPage', action.data);
    if (res.status === 'success') {
      yield put(actions.gmbSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deletePage(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/gmb/deletePage', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.gmbSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteGMB(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/gmb/delete?_id=' + action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.gmbSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* syncGooglePage(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/gmb/syncGooglePage?_id=' + action.data);
    if (res.status === 'success') {
      yield put(actions.gmbSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateGmbStatus(action) {
  try {
    //yield put(load());
    const res = yield call(api.POST, '/gmb/updIntIsActive', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}



//Zapier
function* getZap() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/zapier/getZap');
    if (res.status === 'success') {
      yield put(actions.getZapSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createZap(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/zapier/createZap', action.data);
    if (res.status === 'success') {
      yield put(actions.getZapSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateZapStatus(action) {
  try {
    //yield put(load());
    const res = yield call(api.POST, '/zapier/updIntIsActive', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteZap(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/zapier/delete?_id=' + action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getZapSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Google Place
function* getGglPlace() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/google/get');
    if (res.status === 'success') {
      yield put(actions.getGglPlaceSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createGglPlace(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/google/create', action.data);
    if (res.status === 'success') {
      yield put(actions.getGglPlaceSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateGglPlaceStatus(action) {
  try {
    //yield put(load());
    const res = yield call(api.POST, '/google/updIntIsActive', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteGglPlace(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/google/delete?id=' + action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getGglPlaceSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Custom Webhook
function* getCustomWebHook() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/customwebhook/get');
    if (res.status === 'success') {
      yield put(actions.customWebhookSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCustomWebHook(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/customwebhook/create', action.data);
    if (res.status === 'success') {
      yield put(actions.customWebhookSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteCustomWebhook(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/customwebhook/delete?_id=' + action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.customWebhookSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


function* getCustomWebhookField(action) {
  try {
    // yield put(load());
    const res = yield call(api.GET, '/customwebhook/getField?fuid=' + action.data.usind);
    if (res.status === 'success') {
      yield put(actions.successCustomWebhookField(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    // yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updCustHookStatus(action) {
  try {
    //yield put(load());
    const res = yield call(api.POST, '/customwebhook/updCustHookStatus', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updCustField(action) {
  try {
    //yield put(load());
    const res = yield call(api.POST, '/customwebhook/updCustField', action.data);
    if (res.status === 'success') {
      yield put(actions.successCustomWebhookField(res.data));
      // yield put(actions.intSuccess(res.uint));
      //yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delCustomWebhookFieldUint(action) {
  try {
    //yield put(load());
    const res = yield call(api.POST, '/customwebhook/delField', action.data);
    if (res.status === 'success') {
      yield put(actions.customWebhookSuccess(res.data));
      yield put(toastify({ type: 'success', msg: 'Integration updated successfully' }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCustomWebhookFieldUint(action) {
  try {
    //yield put(load());
    const res = yield call(api.POST, '/customwebhook/createField', action.data);
    if (res.status === 'success') {
      yield put(actions.customWebhookSuccess(res.data));
      yield put(toastify({ type: 'success', msg: 'Integration Add successfully' }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchImportDataFromCSV() {
  yield takeLatest("IMPORT_DATA_FROM_CSV", importDataFromCSV);
}
export function* watchGetIntegrationMST() {
  yield takeLatest("GET_INTEGRATION_MST", getIntegrationMST);
}
export function* watchGetUserIntegration() {
  yield takeLatest("GET_USER_INTEGRATION", getUserIntegration);
}
export function* watchGetFullUserIntegration() {
  yield takeLatest("GET_FULL_USER_INTEGRATION", getFullUserIntegration);
}
export function* watchAddUpdIntegration() {
  yield takeLatest("ADD_UPD_INTEGRATION", addUpdIntegration);
}
export function* watchDelIntegration() {
  yield takeLatest("DEL_INTEGRATION", delIntegration);
}
export function* watchGetWebhookField() {
  yield takeLatest("GET_WEBHOOK_FIELD", getWebhookField);
}
export function* watchCreateCstmWbhookField() {
  yield takeLatest("CREATE_WEBHOOK_FIELD", createCstmWbhookField);
}
export function* watchDelWebhookField() {
  yield takeLatest("DEL_WEBHOOK_FIELD", delWebhookField);
}
export function* watchStartListening() {
  yield takeLatest("START_LISTENING", startListening);
}
export function* watchSyncToken() {
  yield takeLatest("SYNC_TOKEN", syncToken);
}
export function* watchDelSyncToken() {
  yield takeLatest("DEL_SYNC_TOKEN", delSyncToken);
}
export function* watchCreateInterakt() {
  yield takeLatest("CREATE_INTERAKT", createInterakt);
}
export function* watchUpdateInterakt() {
  yield takeLatest("UPDATE_INTERAKT", updateInterakt);
}
export function* watchAddTmptIdInterakt() {
  yield takeLatest("ADD_TMPT_ID_INTERAKT", addTmptIdInterakt);
}
export function* watchDelTmptIdInterakt() {
  yield takeLatest("DEL_TMPT_ID_INTERAKT", delTmptIdInterakt);
}
export function* watchUpdateTmplIdStatus() {
  yield takeLatest("UPDATE_TMPT_ID_INTERAKT_STATUS", updateTmplIdInteraktStatus);
}
export function* watchUpdateTmplMappingInterakt() {
  yield takeLatest("UPDATE_TMPL_MAPPING_INTERAKT", updateTmplMappingInterakt);
}
export function* watchSendWTAMessageInterakt() {
  yield takeLatest("SEND_WTA_MESSAGE_INTERAKT", sendWTAMessageInterakt);
}
export function* watchAddUpdateSyndication() {
  yield takeLatest("ADD_UPD_SYNDICATION", addUpdateSyndication);
}
export function* watchCreateKlaviyo() {
  yield takeLatest("CREATE_KLAVIYO", createKlaviyo);
}
export function* watchDelKlaviyo() {
  yield takeLatest("DEL_KLAVIYO", delKlaviyo);
}
export function* watchCreateWati() {
  yield takeLatest("CREATE_WATI", createWati);
}
export function* watchDelWati() {
  yield takeLatest("DEL_WATI", delWati);
}
export function* watchWatiTampList() {
  yield takeLatest("WATI_TAMP_LIST", watiTampList);
}
export function* watchGetWatiList() {
  yield takeLatest("GET_WATI_LIST", getWatiList);
}
export function* watchUpdateWatiTmp() {
  yield takeLatest("UPDATE_WATI_TMP", updateWatiTmp)
}
export function* watchcreateWatiTmp() {
  yield takeLatest("CREATE_WATI_TMP", createWatiTmp)
}
export function* watchDelWatiTmp() {
  yield takeLatest("DEL_WATI_TMP", delWatiTmp)
}
export function* watchDelWatiField() {
  yield takeLatest("DEL_WATI_FIELD", delWatiField)
}
export function* watchSendWatiMsg() {
  yield takeLatest("SEND_WATI_MSG", sendWatiMsg)
}
export function* watchSendTestDataWebhook() {
  yield takeLatest("SEND_TEST_DATA_WEBHOOK", sendTestDataWebhook)
}
export function* watchupdateIntegrationStatus() {
  yield takeLatest("UPDATE_INTEGRATION_STATUS", updateIntegrationStatus)
}
export function* watchGetFacebook() {
  yield takeLatest("FACEBOOK_INTEGRATION", facebookIntegration);
}
export function* watchCreateFBPageData() {
  yield takeLatest("CREATE_FB_PAGE_DATA", createFBPage);
}
export function* watchDelFBList() {
  yield takeLatest("DELETE_FB_PAGE_DATA", deleteFBList);
}
export function* watchDelFBData() {
  yield takeLatest("DELETE_FB_DATA", deleteFB);
}

//GMB
export function* watchGetGMB() {
  yield takeLatest("GET_GMB", getGMB);
}
export function* watchCreateGMBPage() {
  yield takeLatest("CREATE_GMB_PAGE", createGMBPage);
}
export function* watchDeletePage() {
  yield takeLatest("DELETE_PAGE", deletePage);
}
export function* watchDeleteGMB() {
  yield takeLatest("DELETE_GMB", deleteGMB);
}
export function* watchSyncGooglePage() {
  yield takeLatest("SYNC_GOOGLE_PAGE", syncGooglePage);
}
export function* watchUpdateGmbStatus() {
  yield takeLatest("UPDATE_GMB_ISACTIVE", updateGmbStatus);
}

//Custom Webhook
export function* watchGetCustomWebHook() {
  yield takeLatest("GET_CUSTOM_WEBHOOK", getCustomWebHook);
}
export function* watchCreateCustomWebHook() {
  yield takeLatest("CREATE_CUSTOM_WEBHOOK", createCustomWebHook);
}
export function* watchDeleteCustomWebHook() {
  yield takeLatest("DELETE_CUSTOM_WEBHOOK", deleteCustomWebhook);
}
export function* watchGetCustomWebhookField() {
  yield takeLatest("GET_CUSTOM_WEBHOOK_FIELD", getCustomWebhookField);
}
export function* watchUpdateIntIsActive() {
  yield takeLatest("UPDATE_INT_ISACTIVE", updCustHookStatus);
}
export function* watchUpdCustField() {
  yield takeLatest("UPDATE_CUST_FIELD", updCustField);
}
export function* watchDelCustomWebhookFieldUint() {
  yield takeLatest("DEL_CUST_WEBHOOK_FIELD", delCustomWebhookFieldUint);
}
export function* watchCreateCustomWebhookFieldUint() {
  yield takeLatest("CREATE_CUST_WEBHOOK_FIELD", createCustomWebhookFieldUint);
}

//Zapier
export function* watchGetZap() {
  yield takeLatest("GET_ZAP", getZap);
}
export function* watchCreateZap() {
  yield takeLatest("CREATE_ZAP", createZap);
}
export function* watchDelZap() {
  yield takeLatest("DELETE_ZAP", deleteZap);
}
export function* watchUpdateZapStatus() {
  yield takeLatest("UPDATE_ZAP_ISACTIVE", updateZapStatus);
}

//Google place
export function* watchGetGGLPLACE() {
  yield takeLatest("GET_GGLPLACE", getGglPlace);
}
export function* watchCreateGGLPLACE() {
  yield takeLatest("CREATE_GGLPLACE", createGglPlace);
}
export function* watchDelGGLPLACE() {
  yield takeLatest("DELETE_GGLPLACE", deleteGglPlace);
}
export function* watchUpdateGGLPLACEStatus() {
  yield takeLatest("UPDATE_GGLPLACE_ISACTIVE", updateGglPlaceStatus);
}

export default function* rootSaga() {
  yield fork(watchImportDataFromCSV);
  yield fork(watchGetIntegrationMST);
  yield fork(watchGetUserIntegration);
  yield fork(watchGetFullUserIntegration);
  yield fork(watchAddUpdIntegration);
  yield fork(watchDelIntegration);
  yield fork(watchGetWebhookField);
  yield fork(watchDelWebhookField);
  yield fork(watchCreateCstmWbhookField);
  yield fork(watchStartListening);
  yield fork(watchSyncToken);
  yield fork(watchDelSyncToken);
  yield fork(watchCreateInterakt);
  yield fork(watchUpdateInterakt);
  yield fork(watchAddTmptIdInterakt);
  yield fork(watchDelTmptIdInterakt);
  yield fork(watchUpdateTmplIdStatus);
  yield fork(watchUpdateTmplMappingInterakt);
  yield fork(watchSendWTAMessageInterakt);
  yield fork(watchAddUpdateSyndication);
  yield fork(watchCreateKlaviyo);
  yield fork(watchDelKlaviyo);
  yield fork(watchCreateWati);
  yield fork(watchDelWati);
  yield fork(watchWatiTampList);
  yield fork(watchGetWatiList);
  yield fork(watchUpdateWatiTmp);
  yield fork(watchcreateWatiTmp);
  yield fork(watchDelWatiTmp);
  yield fork(watchDelWatiField);
  yield fork(watchSendWatiMsg);
  yield fork(watchSendTestDataWebhook);
  yield fork(watchupdateIntegrationStatus);
  yield fork(watchGetFacebook);
  yield fork(watchCreateFBPageData);
  yield fork(watchDelFBList);
  yield fork(watchDelFBData);
  yield fork(watchGetGMB);
  yield fork(watchCreateGMBPage);
  yield fork(watchDeletePage);
  yield fork(watchDeleteGMB);
  yield fork(watchSyncGooglePage);
  yield fork(watchUpdateGmbStatus);
  yield fork(watchGetCustomWebHook);
  yield fork(watchCreateCustomWebHook);
  yield fork(watchDeleteCustomWebHook);
  yield fork(watchGetCustomWebhookField);
  yield fork(watchUpdateIntIsActive);
  yield fork(watchUpdCustField);
  yield fork(watchDelCustomWebhookFieldUint);
  yield fork(watchCreateCustomWebhookFieldUint);
  yield fork(watchGetZap);
  yield fork(watchCreateZap);
  yield fork(watchDelZap);
  yield fork(watchUpdateZapStatus);
  yield fork(watchGetGGLPLACE);
  yield fork(watchCreateGGLPLACE);
  yield fork(watchDelGGLPLACE);
  yield fork(watchUpdateGGLPLACEStatus);
}
