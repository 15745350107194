import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  Text,
  Badge,
  Page,
  Modal,
  PageActions,
  InlineStack,
  Button,
  RadioButton,
  Label,
  Box,
} from '@shopify/polaris';
import { DeleteIcon, PlusCircleIcon, PlusIcon } from '@shopify/polaris-icons';


function TagListCard(props) {
  const { state, changeNameValue } = props
  const { mode, setMode } = useSetIndexFiltersMode();

  const resourceName = {
    singular: 'Item',
    plural: 'Items',
  };

  const promotedBulkActions = [
    { content: 'Publish', onAction: () => props.actionListClick(true, 'update') },
    { content: 'Unpublish', onAction: () => props.actionListClick(false, 'update') },
    { content: 'Delete', onAction: () => changeNameValue({ tagListDelete: true, tagListDeleteOpen: true }) },
  ];

  return (
    <Page
      title={"TagList"}
      backAction={(props.isShwBack === true) ?
        { onAction: () => props.handleBackToMain() } : false}
      primaryAction={
        <Button icon={PlusCircleIcon} onClick={(e) => { changeNameValue({ tagListAdd: true, tagName: '', tagStatus: true, errMessage: {} }); }} variant="primary">
          Create Tag
        </Button>
      }
    >

      <LegacyCard>
        <IndexFilters
          queryValue={state.searchText}
          queryPlaceholder="Searching in all"
          onQueryChange={(value) => changeNameValue({ searchText: value })}
          onQueryClear={() => changeNameValue({ searchText: '' })}

          cancelAction={{
            onAction: () => changeNameValue({ searchText: '' }),
          }}
          tabs={props.tabs}
          selected={state.selected}
          onSelect={(index) => changeNameValue({ selected: index })}
          canCreateNewView={false}

          filters={[]}
          mode={mode}
          setMode={setMode}
        />
        <IndexTable
          resourceName={resourceName}
          itemCount={state.getTagList?.length > 0 ? state.getTagList.length : 0}
          selectedItemsCount={props.selectedResources.length}
          onSelectionChange={props.handleSelectionChange}
          promotedBulkActions={promotedBulkActions}
          headings={[
            { title: 'Tag Name' },
            { title: 'Status' },
            { title: <div className='active-txt'>Actions</div> },
          ]}
          pagination={{
            // label: props.paggiLabel,
            hasPrevious: state.paggiActive > 1 ? true : false,
            hasNext: (state.paggiActive < props.pageNumber) ? true : false,
            onPrevious: () => props.onPaggiCall('-1'),
            onNext: () => props.onPaggiCall('+1')
          }}>

          {state.getTagList && state.getTagList.length > 0 && state.getTagList.map(
            (elm, index) => <IndexTable.Row
              id={elm._id}
              key={index}
              selected={props.selectedResources.includes(elm._id)}
              position={index}
            // onClick={() => changeNameValue({})}  //for whole row click none
            >
              <IndexTable.Cell>
                <Text variant="bodyMd" fontWeight="bold" as="span">
                  {elm.tg}
                </Text>
              </IndexTable.Cell>
              <IndexTable.Cell>
                {elm.ia === true ?
                  <Badge status="success">Publish</Badge> :
                  <Badge status="critical">Unpublish</Badge>
                }
              </IndexTable.Cell>
              <div className='action-Cell-Width'>
                <IndexTable.Cell>
                  {/* <div style={{ width: '800px' }}> */}
                  <InlineStack spacing="tight" gap={200}>
                    <Button plain onClick={() => changeNameValue({ tagListUpdate: true, selectedTagId: elm._id, tagName: elm.tg, tagStatus: elm.ia, errMessage: {} })}>Edit</Button>
                    <Button plain icon={DeleteIcon} tone="critical" onClick={() => changeNameValue({ 'tagListDelete': true, 'selectedTagId': elm._id })}></Button>
                  </InlineStack>
                  {/* </div> */}
                </IndexTable.Cell>
              </div>
            </IndexTable.Row>
          )
          }
        </IndexTable>
      </LegacyCard>

      {/* Add/Create Taglist Modal */}
      <Modal
        open={state.tagListAdd}
        title={<Text variant='headingMd' fontWeight='bold'>Create Tag</Text>}
        onClose={(e) => props.openCloseModal('tagListAdd', state.tagListAdd, e)}>
        <Modal.Section>
          <TextField
            label="Tag Name"
            value={state.tagName}
            onChange={(value) => changeNameValue({ tagName: value })}
            autoComplete="off"
            error={state.errMessage.tagName ? "The Tag name field is required." : false}
          />
          {props.validator.message('tagName', state.tagName, 'required')}

          <Box paddingBlock={300}>
            <Label variant="headingXs" as="p"> Tag status </Label>
            <InlineStack gap={300}>
              <RadioButton
                label="Publish"
                checked={state.tagStatus === true}
                id="disabled"
                name="accounts"
                onChange={() => changeNameValue({ tagStatus: true })} />
              <RadioButton
                label="Unpublish"
                id="optional"
                name="accounts"
                checked={state.tagStatus === false}
                onChange={() => changeNameValue({ tagStatus: false })} />
            </InlineStack>
          </Box>

          <div className='pageAction_paDDing'>
            <PageActions
              primaryAction={{
                icon: PlusIcon,
                content: 'Add Tag',
                tone: 'success',
                onAction: () => props.handleAddTagBtn()
              }}
              secondaryActions={[{
                content: 'Cancel',
                onAction: () => props.openCloseModal('tagListAdd', state.tagListAdd, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>

      {/* Update Taglist Modal */}
      <Modal
        open={state.tagListUpdate}
        title={<Text variant='headingMd' fontWeight='bold'>Update</Text>}
        onClose={(e) => props.openCloseModal('tagListUpdate', state.tagListUpdate, e)}>
        <Modal.Section>
          <TextField
            label="Tag Name"
            value={state.tagName}
            onChange={(value) => changeNameValue({ tagName: value })}
            autoComplete="off"
            error={state.errMessage.tagName ? "The Tag name field is required." : false}
          />
          {props.validator.message('tagName', state.tagName, 'required')}

          <Box paddingBlock={300}>
            <Label variant="headingXs" as="p"> Tag status </Label>
            <InlineStack gap={300}>
              <RadioButton
                label="Publish"
                checked={state.tagStatus === true}
                id="disabled"
                name="accounts"
                onChange={() => changeNameValue({ tagStatus: true })} />
              <RadioButton
                label="Unpublish"
                id="optional"
                name="accounts"
                checked={state.tagStatus === false}
                onChange={() => changeNameValue({ tagStatus: false })} />
            </InlineStack>
          </Box>

          <div className='pageAction_paDDing'>
            <PageActions
              primaryAction={{
                content: 'Update',
                tone: 'success',
                onAction: () => props.handleUpdateBtn(state.selectedTagId)
              }}
              secondaryActions={[{
                content: 'Cancel',
                onAction: () => props.openCloseModal('tagListUpdate', state.tagListUpdate, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>

      {/* Taglist Delete Modal */}
      <Modal
        open={state.tagListDelete}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => props.openCloseModal('tagListDelete', state.tagListDelete, e)}>
        <Modal.Section>
          <Text fontWeight='semibold' variant='headingMd' as='h3'>Are you sure you want to delete this Taglist?</Text>
          <div className='pageAction_paDDing'>
            <PageActions
              primaryAction={{
                icon: DeleteIcon,
                content: 'Delete',
                tone: 'critical',
                onAction: () => props.handleDeleteBtn(state.selectedTagId, state.tagListDeleteOpen)
              }}
              secondaryActions={[{
                content: 'No',
                onAction: () => props.openCloseModal('tagListDelete', state.tagListDelete, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>
    </Page>
  );

}

export default TagListCard;
