import { Page, Box } from '@shopify/polaris';
import AllCards from './AllCards';
import AddAutoScreen from './AddAutoScreen';
import { useState, useCallback, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as emailDucks from '../../ducks/email';
import * as authDucks from '../../ducks/auth';
import * as integrationDucks from '../../ducks/integration';
import * as masterDucks from '../../ducks/master';
import * as settingDucks from '../../ducks/setting';
import { toastify } from '../../ducks/toast';
import { useNavigate } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { changePathForShopifyAppEmbed } from "../../utils/utils";
const moment = require('moment');

const templatesArr = ['on_followup', 'on_order_fulfill', 'media_request'];


function List(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validator = new SimpleReactValidator({ locale: 'en' });

  const [state, setState] = useState({
    ddValue: 1,
    CampgnCount: 0,
    minStarRate: '',
    emailTyp: '',
    sbtyp: '',
    selSeqName: '',  //title of innerpage
    subTtl: '',  //subtitle of innerpage
    htmltmpl: '',  //html
    emlsqmstid: '',
    emailTest: '',
    htmlcrd: '',
    temp_id: '',
    dataTxt: '',   // condition data
    ctyp: '',  // condition typ
    cnd: '',   // condition
    emlTypName: '',
    mnrtng: '',    //media rating
    mainTtl: 'Set up automate review requests',
    tabTyp: '',
    campTyp: '',
    isNewEditPanel: false,
    iarrcnd: false,
    isLaunchCmp: false,
    isConfiguration: false, //is show configuration screen
    isMobile: false,
    resetOpen: false,
    testEmailOpen: false,
    emailErr: false,  //send test email required error
    isEdit: false,  //is show "save"
    isSelectTypeOn: false,
    isSelectCondtOn: false,
    isAutoConfiguration: false,
    errMessage: {},
    configStateData: [],
    ProfileObj: {},
    emailObj: {},  //obj same as cnf (lgurl,sbjct,msg,rtngtxt,irttl,rttltxt,irdsc,rdsctxt,rbtntxt,clstmnt)
    emailMSTArr: [],  //latest new master data
    newSeqData: [],
    arrcnd: [],
    stateIntegration: [],
    UserIntegration: [],
    inteMstList: [],
    currAtmId: ''
  });

  // main common function for update state & value
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  useEffect(() => {
    dispatch(emailDucks.getSeqData());
    dispatch(emailDucks.getConfigByType());
    dispatch(integrationDucks.getFullUserIntegration());
    dispatch(integrationDucks.getUserIntegration({ shp: localStorage.getItem('shp'), typ: 'automation' }));
  }, []);

  // seq api data
  const masterState = useSelector(state => state.master.MasterData);
  const seqState = useSelector(state => state.email);
  const UserIntegration = useSelector(state => state.integration.UserIntegration);
  const fullUserIntegration = useSelector(state => state.integration.fullUserIntegration);
  const historyState = useSelector(state => state.email.HistoryList);
  const ProfileData = useSelector(state => state.auth.profile);
  const CmpgnCnt = useSelector(state => state.email.CampgnCount);

  // get not created template from mst arr
  useEffect(() => {
    let createMstArr = state?.emailMSTArr?.length > 0 && state.emailMSTArr.filter((data) => {
      return data.ia === true && (!state.newSeqData.some((val) => val.typ === data.typ && val.sbtyp === data.sbtyp));
    });
    changeNameValue({ createMstArr });
  }, [state.emailMSTArr, state.newSeqData]);

  useEffect(() => {
    let obj = {
      isCmpgnLoad: CmpgnCnt !== undefined ? false : true,
      CampgnCount: CmpgnCnt ? CmpgnCnt : 0
    }
    changeNameValue(obj);
  }, [CmpgnCnt]);

  useEffect(() => { //get objshp from userData
    if (ProfileData !== null && ProfileData !== undefined)
      changeNameValue({ ProfileObj: ProfileData });
    else dispatch(authDucks.getProfile());
  }, [ProfileData]);

  useEffect(() => {
    if (!masterState || masterState === undefined) {
      let masterDT = setTimeout(() => dispatch(masterDucks.getMasterData()), 700);
      return () => clearTimeout(masterDT);
    }
  }, [masterState]);

  useEffect(() => {
    if (fullUserIntegration) {
      changeNameValue({ userInteFullList: fullUserIntegration });
    }
    if (seqState?.SeqList?.data?.length > 0) {
      changeNameValue({ newSeqData: seqState?.SeqList?.data });
    }
    if (historyState?.data?.length > 0) {
      changeNameValue({ HistoryData: historyState.data });
    }
  }, [seqState, fullUserIntegration, historyState]);

  useEffect(() => {  // store emlMaster values in arr
    let _arrEml = [], obj = {};
    const _pvd = localStorage.getItem('pvd');

    if (masterState?.emailSequenceData?.length > 0) {
      masterState.emailSequenceData.filter(y => {
        if (templatesArr.includes(y.typ) && y.arrpvd && (_pvd === 'local' && y.arrpvd.includes('custom') || y.arrpvd.includes(_pvd))) {
          _arrEml.push(y);
          if (y.typ === state.emailTyp) {
            if (y.sbtyp === state.sbtyp) {
              obj['htmltmpl'] = y.htmltmpl;
              if (y.htmlcrd)
                obj['htmlcrd'] = y.htmlcrd;
            }
          }
        }
      });
      obj['emailMSTArr'] = _arrEml;
      changeNameValue(obj);
    }
  }, [masterState, state.emailTyp, state.emlsqmstid, state.sbtyp]);

  //get multiple automation list
  useEffect(() => {
    if (seqState?.configData?.length > 0) {
      let filteredArray = seqState?.configData.filter(record => record.typ !== 'one_time_campaign');  //get automation arr

      let AutoListArr = [];   //get unique arr by atmid and store data(fullfill,followup,media)
      let seenAtmids = new Set();
      for (let record of filteredArray) {
        if (!seenAtmids.has(record.atmid)) {
          AutoListArr.push(record);
          seenAtmids.add(record.atmid);
        }
      }
      changeNameValue({ AutoListArr });
    }
  }, [seqState.configData]);

  const getValbyTyp = (typ) => {
    return state.configStateData?.length > 0 && state.configStateData.find(data => data.typ === typ);
  }

  const handleCreateAutotion = () => {
    dispatch(emailDucks.createAutoMation({ dn: state.creDnField }));
    openCloseModal('createOpen', state.createOpen, 'close');
    changeNameValue({ creDnField: '' });
  }

  const handleDelAution = (atmid) => {
    dispatch(emailDucks.delAutoMation({ atmid: atmid }));
    openCloseModal('deleteOpen', state.deleteOpen, 'close');
  }

  //get seqdata arr
  useEffect(() => {
    if (seqState.configData?.length > 0) {
      if (state.currAtmId) {
        let matchedConfig = seqState.configData.filter(record => record.atmid === state.currAtmId);
        changeNameValue({ configStateData: matchedConfig });
      }
      else changeNameValue({ configStateData: seqState.configData });  // pvd shopify get all data
    }
  }, [seqState.configData, state.currAtmId]);

  const handleAutoCustomize = (data) => {
    changeNameValue({ isAutoConfiguration: true, mainTtl: data.dn, currAtmId: data.atmid });
  }

  // useEffect(() => {
  //   if (state.configStateData) {
  //     let { configStateData } = state;

  //     let obj = {
  //       CurrConfigId: configStateData._id,
  //       iarrcnd: configStateData.iarrcnd ? configStateData.iarrcnd : false,
  //       ia: configStateData.ia ? configStateData.ia : false,
  //       ddValue: configStateData.dd ? configStateData.dd : 1,
  //       arrcnd: configStateData.arrcnd,
  //       mnrtng: configStateData.mnrtng ? configStateData.mnrtng : '',
  //       arrfuidArr: configStateData.arrfuid || [],
  //     };
  //     changeNameValue(obj);
  //   }
  // }, [state.configStateData]);

  useEffect(() => {
    const { integrationData } = masterState || {};

    if (integrationData?.length > 0)
      changeNameValue({ inteMstList: integrationData });

    if (UserIntegration?.length > 0 && integrationData?.length > 0) {
      const updatedData = UserIntegration.map(dataItem => {
        const matchingIntegration = integrationData.find(integration => integration.in === dataItem.inm);
        if (matchingIntegration) {
          return {
            ...dataItem,
            img: matchingIntegration.img,
            dn: matchingIntegration.dn,
            dsc: matchingIntegration.dsc,
            ctg: matchingIntegration.ctg
          };
        }
        return dataItem;
      });

      if (UserIntegration != state.UserIntegration) {
        changeNameValue({ stateIntegration: updatedData });
      }
    }
  }, [masterState?.integrationData, UserIntegration, state.UserIntegration]);

  // only for template edit field
  const handleTextChange = (obj, isEditable) => {
    let currentState = { ...state.emailObj, ...obj };

    changeNameValue({
      emailObj: currentState,
      isEdit: isEditable ? true : false
    });
  }

  const handleStatusUpdate = (_id, ia) => {
    if (_id) {
      let obj = { _id: _id, ia: ia };
      dispatch(emailDucks.updateSequence(obj));
    }
  }

  const updateTmpStatus = (mainId, dataId, key, ia) => {
    let obj = {
      id: mainId,
      tid: dataId,
      [key]: ia
    };
    dispatch(emailDucks.updTmpTypeStatus(obj));
  }

  const updDaysField = (dd, typ) => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    }
    else {
      changeNameValue({ errMessage: {}, isShowMessage: false });
      let obj = {
        frm: 'config',
        _id: getValbyTyp(typ)._id,
        typ: typ,
        dd: dd || state.ddValue || 0
      }
      dispatch(emailDucks.updConfig(obj));
    }
  }

  const openCloseModal = useCallback((name, value, type) => { //use of type is don't close modal on click of outside
    if (type !== undefined) changeNameValue({ [name]: !value });
  }, [changeNameValue]);

  const editTemplateClk = (data) => {
    let obj = {
      isConfiguration: true,
      emailTyp: data.typ,
      emlTypName: data.nm,
      emlsqmstid: data.emlsqmstid,
      sbtyp: data.sbtyp,
      tabTyp: ''
    }
    changeNameValue(obj);

    let stateObj = {};
    if (data.lgurl) stateObj['lgurl'] = data.lgurl;
    if (data.sbjct) stateObj['sbjct'] = data.sbjct;
    if (data.msg) stateObj['msg'] = data.msg;
    if (data.rtngtxt) stateObj['rtngtxt'] = data.rtngtxt;
    if (data.irttl) stateObj['irttl'] = data.irttl;
    if (data.rttltxt) stateObj['rttltxt'] = data.rttltxt;
    if (data.irdsc) stateObj['irdsc'] = data.irdsc;
    if (data.rdsctxt) stateObj['rdsctxt'] = data.rdsctxt;
    if (data.rbtntxt) stateObj['rbtntxt'] = data.rbtntxt;
    if (data.clstmnt) stateObj['clstmnt'] = data.clstmnt;
    handleTextChange(stateObj);
  }

  const createSeqTemplate = (data) => {
    if (data) {
      let obj = {
        selSeqName: data.dspttl,
        subTtl: data.dspdsc,
        emailTyp: data.typ,
        sbtyp: data.sbtyp,
        emlTypName: data.nm
      };
      changeNameValue(obj);

      let stateObj = {};
      if (data.lgurl) stateObj['lgurl'] = data.lgurl;
      if (data.sbjct) stateObj['sbjct'] = data.sbjct;
      if (data.msg) stateObj['msg'] = data.msg;
      if (data.rtngtxt) stateObj['rtngtxt'] = data.rtngtxt;
      if (data.irttl) stateObj['irttl'] = data.irttl;
      if (data.rttltxt) stateObj['rttltxt'] = data.rttltxt;
      if (data.irdsc) stateObj['irdsc'] = data.irdsc;
      if (data.rdsctxt) stateObj['rdsctxt'] = data.rdsctxt;
      if (data.rbtntxt) stateObj['rbtntxt'] = data.rbtntxt;
      if (data.clstmnt) stateObj['clstmnt'] = data.clstmnt;
      handleTextChange(stateObj);

      setTimeout(() => {
        let obj = {
          isNewEditPanel: true,
          isConfiguration: true
        }
        changeNameValue(obj);
      }, 100);

      dispatch(settingDucks.createTemplate({ id: data._id }));
    }
  }

  const handleDelCondition = (data) => {
    let obj = {
      _id: data._id,
      typ: state.emailTyp
    }
    dispatch(emailDucks.removeCondition(obj));
  }

  const updConfigData = (val, typ) => {
    let obj = {
      frm: 'main',
      _id: getValbyTyp(typ)._id,
      typ: typ,
      ia: val
    }
    dispatch(emailDucks.updConfig(obj));
  }

  const saveMediaConfig = (e, typ) => {
    let obj = {
      frm: 'config',
      _id: getValbyTyp(typ)._id,
      typ: typ,
      mnrtng: e
    }
    dispatch(emailDucks.updConfig(obj));
  }

  const handleConditionAdd = (stateObj, typ) => {
    if (typ === 'radio') {
      let payLoadObj = {
        frm: 'condition',
        _id: getValbyTyp('on_order_fulfill')._id,
        typ: 'on_order_fulfill',
        ...stateObj
      }
      dispatch(emailDucks.updConfig(payLoadObj));
      changeNameValue(stateObj);
    }
    else {
      if (state.ctyp && state.cnd && state.dataTxt) {
        let payLoadObj = {
          frm: 'condition',
          _id: getValbyTyp('on_order_fulfill')._id,
          typ: 'on_order_fulfill',
          ctyp: state.ctyp,
          cnd: state.cnd,
          data: state.dataTxt
        }
        dispatch(emailDucks.updConfig(payLoadObj));
        let stateObj = {
          ctyp: '',
          cnd: '',
          dataTxt: ''
        }
        changeNameValue(stateObj);
      }
      else dispatch(toastify({ type: 'error', msg: 'Please fill out all the fields' }));
    }
  }

  const backTempList = () => {
    changeNameValue({ isNewEditPanel: false, emailObj: {} });
  }

  const handleBackToMain = () => {
    let rdctPage = '/sequence', shopPage = '/shopifyAuth'; //default back to main page
    if (props.isShwBack === true) //comes from other page
      rdctPage = props.frm; shopPage = props.frm;

    if (window.location.search !== '') {
      if (window.location.search && window.location.search.split('_')[1]) {
        let typ = window.location.search.split('_')[1];
        if (typ === 'ds') shopPage = 'shopifyAuth'; //from dashboard
        else if (typ === 'mn') shopPage = 'reviewmoderate';  //from manageReview 
      }

      if (localStorage.getItem('is_shopify_embed'))
        changePathForShopifyAppEmbed(shopPage);
      else navigate(-1);
    }
    else {
      if (localStorage.getItem('is_shopify_embed'))
        changePathForShopifyAppEmbed(shopPage);
      else navigate(rdctPage);
    }

    changeNameValue({ isAutoConfiguration: false });   //back to main page
  }


  const callSaveDataSrc = (arr, typ) => {
    if (arr) {
      let obj = {
        frm: 'config',
        _id: getValbyTyp(typ)._id,
        arrfuid: arr,
        typ: typ
      }
      dispatch(emailDucks.updConfig(obj));
    }
    else dispatch(toastify({ type: 'error', msg: 'Please select one!' }));
  }

  //show back button if coming from other pages
  let isShwBackBtn = window.location.search !== '' && window.location.href != '/sequence';

  return (
    <div className='Seq_page'>

      {state.isAutoConfiguration !== true && localStorage.getItem('pvd') === 'local' ?
        isShwBackBtn ?
          <Page
            title={'Configure Automated Review Campaign'}
            subtitle={'Gather reviews seamlessly through email, SMS, and WhatsApp by setting up automated follow-ups and templates'}
            backAction={{ onAction: () => handleBackToMain() }}>
            <Box paddingInlineStart={200}>
              <AddAutoScreen
                state={state}
                changeNameValue={changeNameValue}
                openCloseModal={openCloseModal}
                handleCreateAutotion={handleCreateAutotion}
                handleDelAution={handleDelAution}
                handleAutoCustomize={handleAutoCustomize}
              />
            </Box>
          </Page>
          :
          <Page
            title={'Configure Automated Review Campaign'}
            subtitle={'Gather reviews seamlessly through email, SMS, and WhatsApp by setting up automated follow-ups and templates'}>
            <AddAutoScreen
              state={state}
              changeNameValue={changeNameValue}
              openCloseModal={openCloseModal}
              handleCreateAutotion={handleCreateAutotion}
              handleDelAution={handleDelAution}
              handleAutoCustomize={handleAutoCustomize}
            />
          </Page>
        :
        <AllCards
          state={state}
          updConfigData={updConfigData}
          saveMediaConfig={saveMediaConfig}
          validator={validator}
          changeNameValue={changeNameValue}
          handleStatusUpdate={handleStatusUpdate}
          updateTmpStatus={updateTmpStatus}
          handleTextChange={handleTextChange}
          editTemplateClk={editTemplateClk}
          updDaysField={updDaysField}
          handleConditionAdd={handleConditionAdd}
          handleDelCondition={handleDelCondition}
          openCloseModal={openCloseModal}
          backTempList={backTempList}
          handleBackToMain={handleBackToMain}
          createSeqTemplate={createSeqTemplate}
          callSaveDataSrc={callSaveDataSrc}
          getValbyTyp={getValbyTyp} />
      }
    </div>
  );
}

export default memo(List);