import { Button, Popover, Divider, Icon, ButtonGroup, SkeletonBodyText, Card, Tooltip, Modal, IndexTable, Select, DropZone, InlineStack, Checkbox, OptionList, Scrollable, Box, DatePicker, InlineGrid, Text, TextField, Badge } from '@shopify/polaris';
import { useState, useEffect } from 'react';
import { useBreakpoints } from '@shopify/polaris';
import { CalendarIcon, ArrowRightIcon } from '@shopify/polaris-icons';

const LaunchCampCard = ({ props }) => {
  let { state, changeNameValue } = props;

  const { mdDown, lgUp } = useBreakpoints();
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const yesterday = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0, 0));

  const ranges = [
    {
      title: "Today",
      alias: "today",
      period: {
        since: yesterday,
        until: today,
      },
    },
    {
      title: "Last 7 days",
      alias: "last7days",
      period: {
        since: new Date(new Date(new Date().setDate(today.getDate() - 7)).setHours(0, 0, 0, 0)),
        until: yesterday,
      },
    },
    {
      title: "Last 30 days",
      alias: "last30days",
      period: {
        since: new Date(new Date(new Date().setDate(today.getDate() - 30)).setHours(0, 0, 0, 0)),
        until: yesterday,
      },
    }
  ];

  const [activeDateRange, setActiveDateRange] = useState(ranges[0]);
  const [{ month, year }, setDate] = useState({
    month: activeDateRange.period.since.getMonth(),
    year: activeDateRange.period.since.getFullYear()
  });

  const activeRangeChange = (state, isStoreNewDate) => {
    setActiveDateRange(state);

    if (!isStoreNewDate) {
      let obj = {
        frmdt: state.period.since.toDateString(),
        tdt: state.period.until.toDateString()
      }
      changeNameValue(obj);
    }
  }

  function handleCalendarChange({ start, end }) {
    const newDateRange = {
      alias: "custom",
      title: "Custom",
      period: { since: start, until: end }
    };
    activeRangeChange(newDateRange);
    let obj = {
      frmdt: newDateRange.period.since.toDateString(),
      tdt: newDateRange.period.until.toDateString()
    }
    changeNameValue(obj, 'isShowMessage');
  }

  useEffect(() => {
    const newDateRange = {
      alias: "custom",
      title: "Custom",
      period: {
        since: new Date(state.frmdt || yesterday),
        until: new Date(state.tdt || today)
      }
    };
    activeRangeChange(newDateRange, 'isStoreNewDate');
  }, [state.frmdt, state.tdt]);

  const isValidDate = (date) => !isNaN(new Date(date).getTime());
  const buttonValue = isValidDate(state.frmdt) && isValidDate(state.tdt)
    ? `${state.frmdt} - ${state.tdt}`
    : "Pick a start and end date";

  const fieldsMapping = [
    { name: 'First Name', val: 'fn' },
    { name: 'Last Name', val: 'ln' },
    { name: 'Phone', val: 'phn' },
    { name: 'Email', val: 'e' },
    { name: 'Product Title', val: 'pn' },
    { name: 'Product URL', val: 'pu' },
    { name: 'Product Image URL', val: 'piu' },
    { name: 'Product Id', val: 'pid' },
    { name: 'Reviewer image url', val: 'rvwrimg' }
  ];

  useEffect(() => {
    let isCnfmLnchCmp = true;

    if (state?.ProfileObj?.ulq >= state?.CampgnCount)
      isCnfmLnchCmp = false; //enable cta if count user.ulq is greater than count
    else isCnfmLnchCmp = true;

    changeNameValue({ isCnfmLnchCmp });
  }, [state.ProfileObj, state.CampgnCount]);

  const getDate = (dt) => {
    return dt.substr(dt.indexOf(' ') + 1);
  }

  return (
    <Box paddingBlockStart={600}>

      {localStorage.getItem('pvd') === 'local' ?  // import csv file
        <Card padding={'0'}>
          <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
            <Text variant='bodyLg' fontWeight='medium' as='h1'>Import customer list(only csv file)</Text>
          </Box>
          <Divider borderColor='border-tertiary' />

          <Box padding={400}>
            <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} alignItems='end' gap={200}>
              <TextField
                label="Enter campaign name"
                value={state.sourceSelect}
                error={state.sourceSelectErr}
                onChange={(val) => props.changeNameValue({ sourceSelect: val })} />

              <InlineStack gap={200} blockAlign='end'>
                <DropZone id='csvFile' error={state.fileErr} accept='.csv' type='file' variableHeight={true} outline={false} onDrop={props.handleDropZONE}>
                  <Button size='large'>
                    {props.state?.files?.length > 0 ? state.files.map((file, i) => {
                      return (
                        <div key={i}>
                          <div style={{ width: "100px", overflow: "hidden" }}>
                            <Text variant="bodySm" fontWeight='medium' as="h6">{file.name}</Text>
                          </div>
                          <Text variant="bodySm" as="p"> {file.size} bytes</Text>
                        </div>
                      )
                    }) : 'Select file..'}
                  </Button>
                </DropZone>
                <Button variant='primary' onClick={() => props.handleUpldFile()}>Upload & continue</Button>
              </InlineStack>
            </InlineGrid>
          </Box>
        </Card>
        :  //date selection for shopify
        <Card padding={'0'}>
          <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
            <Text variant='bodyLg' fontWeight='medium' as='h1'>Specify the campaign's duration</Text>
          </Box>
          <Divider borderColor='border-tertiary' />

          <Box padding={400}>
            <Text variant='bodyMd' fontWeight='medium' as='h6'>WiserReviews will request reviews from customers who made purchases within that timeframe, considering only fulfilled orders.</Text>

            <Box paddingBlockStart={300}>
              <Popover
                fluidContent
                fullHeight
                active={state.popoverActive}
                preferredAlignment="left"
                preferredPosition="below"
                activator={<Button onClick={() => changeNameValue({ popoverActive: !state.popoverActive })}
                  icon={CalendarIcon}>{buttonValue}</Button>}
                onClose={() => changeNameValue({ popoverActive: false })}>

                <Popover.Pane fixed>
                  <InlineGrid gap={0}
                    columns={{ xs: "1fr", mdDown: "1fr", md: "max-content max-content" }}>

                    <Box  // today/week/month selection
                      maxWidth={mdDown ? "516px" : "160px"}
                      width={mdDown ? "100%" : "160px"} >
                      <Scrollable>
                        <OptionList
                          options={ranges.map((x) => ({
                            value: x.alias,
                            label: x.title
                          }))}
                          selected={activeDateRange.alias}
                          onChange={(value) => {
                            const result = ranges.find((range) => range.alias === value[0]);
                            activeRangeChange(result);
                          }} />
                      </Scrollable>
                    </Box>

                    <Box padding={400} maxWidth={mdDown ? "320px" : "516px"}>

                      <InlineStack gap="400">
                        <InlineStack gap="200">
                          <div style={{ flexGrow: 1 }}>
                            <TextField
                              prefix={<Icon source={CalendarIcon} />} readOnly
                              value={new Date(state.frmdt).toLocaleDateString('en-CA') || ''} />
                          </div>
                          <Icon source={ArrowRightIcon} />
                          <div style={{ flexGrow: 1 }}>
                            <TextField
                              prefix={<Icon source={CalendarIcon} />} readOnly
                              value={new Date(state.tdt).toLocaleDateString('en-CA') || ''} />
                          </div>
                        </InlineStack>
                        <div>
                          <DatePicker
                            month={month} year={year}
                            selected={{
                              start: activeDateRange.period.since,
                              end: activeDateRange.period.until
                            }}
                            onMonthChange={(month, year) => setDate({ month, year })}
                            onChange={handleCalendarChange}
                            multiMonth={lgUp} allowRange />
                        </div>
                      </InlineStack>
                    </Box>
                  </InlineGrid>
                </Popover.Pane>

                <Box padding={200}>
                  <InlineStack align="end">
                    <Button variant='tertiary' onClick={() => changeNameValue({ popoverActive: false })}>Close</Button>
                  </InlineStack>
                </Box>
              </Popover>

              <Box paddingBlockStart={200} paddingBlockEnd={400}>
                <Checkbox
                  checked={state.iexclitem}
                  onChange={(e) => changeNameValue({ iexclitem: e }, 'isShowMessage')}
                  label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Exclude already reviewed items</Text>} />
              </Box>

              <InlineGrid>
                {localStorage.getItem('csub') === 'free' ?
                  <InlineStack gap={200} blockAlign='center'>
                    <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                      <Button disabled id='primary_btn'>Save</Button>
                    </Tooltip>
                    <Box><Badge tone='warning'>Exclusive</Badge></Box>
                  </InlineStack>
                  :
                  <InlineStack>
                    <Button id='primary_btn' onClick={() => props.updDaysField('one_time_campaign')}>Save</Button>
                  </InlineStack>
                }
              </InlineGrid>
            </Box>

          </Box>
        </Card>
      }

      <Box paddingBlockStart={200}>
        {localStorage.getItem('csub') === 'free' ? //disable button if user is on free plan
          <InlineStack gap={200} blockAlign='center'>
            <Box><Badge tone='warning'>Exclusive</Badge></Box>
            <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
              <Button disabled id='primary_btn'>Launch campaign</Button>
            </Tooltip>
          </InlineStack>
          :
          <Button id='primary_btn' onClick={() => props.handleLaunchCampClk()}
            disabled={localStorage.getItem('pvd') === 'shopify' && !state.configStateData.frmdt && !state.configStateData.tdt ? true
              : localStorage.getItem('pvd') === 'local' && Object.keys(state.mappingFields).length === 0 ? true
                : false}>Launch campaign</Button>
        }
      </Box>



      <Modal //send test email model
        open={state.launchOpen}
        onClose={(e) => props.openCloseModal('launchOpen', state.launchOpen, e)}
        title={<Text variant='headingMd' fontWeight='bold'>Are you sure?</Text>}>
        <Modal.Section>
          <Box paddingBlockEnd={600}>

            {localStorage.getItem('pvd') === 'shopify' ?
              state.isCmpgnLoad === false ?
                state.ProfileObj && (state.CampgnCount > state.ProfileObj.ulq) ?
                  <Text>You received {state.CampgnCount} orders within the selected duration, and you only have {state.ProfileObj && state.ProfileObj.ulq}quotas left. To launch the campaign, kindly upgrade your plan or adjust the duration.<br /><br />   Selected date : {getDate(state.frmdt)} to {getDate(state.tdt)}.</Text>
                  :
                  state.CampgnCount > 0 ?
                    <Text> {state.CampgnCount} quota will be deducted from your remaining {state.ProfileObj && state.ProfileObj.ulq} quota. <br /><br />   Selected date : {getDate(state.frmdt)} to {getDate(state.tdt)}.</Text>
                    :
                    <Text>No orders is available for the selected date: {getDate(state.frmdt)} to {getDate(state.tdt)}.</Text>
                :
                <SkeletonBodyText lines={2} />
              :
              localStorage.getItem('pvd') === 'local' &&
              <div>
                <Text> {(state.csvData.length - 1)} quota will be deducted from your remaining {state.ProfileObj && state.ProfileObj.ulq} quota.</Text>
              </div>
            }

          </Box>
          <InlineStack align='end'>
            <ButtonGroup>
              <Button onClick={() => props.openCloseModal('launchOpen', state.launchOpen, 'close')}>Cancel</Button>
              <Button id='primary_btn' disabled={localStorage.getItem('pvd') === 'shopify' ? state.isCnfmLnchCmp : false} onClick={() => props.launchCampaign()}>
                Yes, launch campaign</Button>
            </ButtonGroup>
          </InlineStack>
        </Modal.Section>
      </Modal>

      <Modal
        size='large'
        open={state.uploadOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Field mapping</Text>}
        onClose={props.opnCloseCsvSheet}>
        <div className='fieldMapp_modal'>
          <Modal.Section>
            <Text variant="headingSm" fontWeight="regular" as="h6">Select columns from your CSV file to map against review fields, or to ignore during import. Note: Custom Forms columns will not be displayed below, but will also be imported automatically</Text>

            <Box paddingBlockStart={400} paddingBlockEnd={400}>
              <Card>
                <IndexTable
                  selectable={false}
                  itemCount={state.csvData.length || 0}
                  headings={[
                    { title: <Text variant="bodyMd" fontWeight="medium" as="span">Review field</Text>, id: 'revField' },
                    { title: <Text variant="bodyMd" fontWeight="medium" as="span">CSV column name</Text>, id: 'csvName' },
                  ]}>

                  {fieldsMapping?.length > 0 && fieldsMapping.map((field, index) => (
                    <IndexTable.Row key={index}>
                      <IndexTable.Cell>
                        <Text variant="bodyMd" fontWeight="medium" as="h6">{field.name}</Text>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <Select
                          options={props.state?.csvData[0]}
                          onChange={(e) => props.handleCsvSelect(e, field.val)}
                          value={props.imprtData[field.val]}
                        />
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  ))}

                </IndexTable>
              </Card>
            </Box>

            <InlineStack align="end">
              <ButtonGroup>
                <Button onClick={() => props.opnCloseCsvSheet('close')}>Cancel</Button>
                <Button id='primary_btn' onClick={() => props.handleNextMapping()}>Next</Button>
              </ButtonGroup>
            </InlineStack>
          </Modal.Section>
        </div>
      </Modal>

    </Box>
  );
}

export default LaunchCampCard;