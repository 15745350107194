import {
  IndexTable, IndexFilters, Text, Card, Page, Button, Box, Modal, InlineStack, ButtonGroup, Thumbnail, Divider, BlockStack, DropZone, LegacyStack, Link, PageActions, TextField, ResourceList, ResourceItem, Filters, Popover, Badge, Tooltip, RadioButton, Label,
  Grid
} from '@shopify/polaris';
import { ImportIcon, NoteIcon, PlusCircleIcon, ViewIcon } from '@shopify/polaris-icons';
import { useState, useCallback } from 'react';
import Papa from 'papaparse';

const opt = { day: 'numeric', month: 'short', year: 'numeric' };

const sortOptions = [
  { label: 'Date', value: 'quedt asc', directionLabel: 'Ascending' },
  { label: 'Date', value: 'quedt desc', directionLabel: 'Descending' },
];

const QueAns = (props) => {

  const { state, changeNameValue, openCloseModal, QuestionData } = props;

  const [files, setFiles] = useState([]);
  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles, _rejectedFiles) => {
    setFiles(() => [...acceptedFiles]);
    const file = _dropFiles[0];
    Papa.parse(file, {
      header: true, // Treat the first row as headers
      skipEmptyLines: true,
      complete: function (results) {
        const data = results.data;
        for (let i = 0; i < data[0].length; i++) {
          const values = data[0];
          if (i === 0) {
            values.unshift('Select');
          }
        }
        changeNameValue({ csvData: data })
      },
      error: function (error) {
        changeNameValue({ fileErr: true });
      }
    });
  }, [files]);

  const validImageTypes = ['image/gif', 'image/jpeg', 'image/csv'];

  const fileUpload = !files.length && <DropZone.FileUpload />;
  const uploadedFiles = files.length > 0 && (
    <div style={{ padding: '0' }}>
      <LegacyStack vertical>
        {files.map((file, index) => (
          <LegacyStack alignment="center" key={index}>
            <Thumbnail
              size="small"
              alt={file.name}
              source={
                validImageTypes.includes(file.type)
                  ? window.URL.createObjectURL(file)
                  : NoteIcon
              }
            />
            <div>
              {file.name}{' '}
              <Text variant="bodySm" as="p">
                {file.size} bytes
              </Text>
            </div>
          </LegacyStack>
        ))}
      </LegacyStack>
    </div>
  );

  const filterControl = (
    <Filters
      focused
      queryValue={state.groupNewSearch}
      queryPlaceholder='Search product'
      onQueryChange={(e) => changeNameValue({ groupNewSearch: e })}
      filters={[]}
      appliedFilters={[]}
      onQueryClear={() => props.changeNameValue({ groupNewSearch: '' })}
    />
  );

  const SelectionActions = [{ content: 'Save', onAction: () => changeNameValue({ groupViewProOpen: false }) }]

  const promotedBulkActions = [
    { content: 'Publish', onAction: () => props.actionListClick(true) },
    { content: 'Unpublish', onAction: () => props.actionListClick(false) },
    { content: 'Delete', onAction: () => openCloseModal('queAnsDeleteOpen', state.queAnsDeleteOpen, 'open') },
  ];

  const getSourceImg = (src) => {
    let img = '';
    if (src === 'csv')
      img = 'rvw_src_csv';
    else
      img = 'rvw_src_shopify';
    let srcImg = <img src={`https://storage.googleapis.com/shopify_review_app/dash/${img}.svg`} alt="Source" />
    return srcImg;
  }

  return (
    <div className='header-title-managereviews'>

      <Page
        title={"Questions"}
        subtitle={"Manage and automate the approval of question."}
        secondaryActions={
          <div className='btn-group btn-manage-reviews-head'>
            <ButtonGroup>
              <Button icon={PlusCircleIcon} onClick={(e) => {
                props.handleTextChange({
                  que: '',
                  ans: '',
                  ia: true,
                  arr: []
                });
                changeNameValue({ createProSelectedItem: '' }); openCloseModal('addQueAnsOpen', state.addQueAnsOpen, e);
              }} variant="primary">Add questions</Button>
              <Button icon={ImportIcon} onClick={() => { setFiles([]); openCloseModal('importOpen', state.importOpen, 'open'); }} variant="primary">Import questions</Button>
            </ButtonGroup>
          </div>
        }>

        <Box paddingBlockEnd={400}>
          <Card padding={0}>

            <IndexFilters
              sortOptions={sortOptions}
              sortSelected={state.sortSelected}
              onSort={(e) => changeNameValue({ sortSelected: e })}
              queryValue={state.queryValue}
              queryPlaceholder="Search..."
              onQueryChange={(e) => changeNameValue({ queryValue: e })}
              onQueryClear={() => changeNameValue({ queryValue: '' })}
              cancelAction={{
                onAction: () => changeNameValue({ queryValue: '' })
              }}
              tabs={props.tabs}
              canCreateNewView={false}
              selected={state.selected}
              onSelect={(e) => changeNameValue({ selected: e })}
              filters={props.filters}
              appliedFilters={props.appliedFilters}
              onClearAll={props.handleFiltersClearAll}
              mode={props.mode}
              setMode={props.setMode}
            />

            <IndexTable
              itemCount={QuestionData ? QuestionData.length : 0}
              selectedItemsCount={props.selectedResources.length}
              onSelectionChange={props.handleSelectionChange}
              promotedBulkActions={promotedBulkActions}
              selectable={true}
              headings={[
                { title: 'Name & email' },
                { title: 'Question & answer' },
                { title: "Source" },
                { title: "Status" },
                { title: "Action" },
              ]}
              pagination={{
                // label: props.paggiLabel,
                hasPrevious: state.paggiActive > 1 ? true : false,
                hasNext: (state.paggiActive < props.pageNumber) ? true : false,
                onPrevious: () => props.onPaggiCall('-1'),
                onNext: () => props.onPaggiCall('+1')
              }}>

              {QuestionData?.length > 0 && QuestionData.map((data, index) => {
                return (
                  <IndexTable.Row
                    id={data._id}
                    key={index}
                    selected={props.selectedResources.includes(data._id)}
                    position={index}
                    onClick={() => changeNameValue({})}  //for whole row click none
                  >
                    <IndexTable.Cell>
                      <Text variant="headingXs" fontWeight="regular" as="p">{data.cstnm || '-'}</Text>
                      <Box paddingBlockStart={200} paddingBlockEnd={100}>
                        <Text variant="headingXs" fontWeight="regular" as="p" id='txt_limit1'>
                          <Tooltip width='wide' padding="400" dismissOnMouseOut content={<Text variant="headingXs" fontWeight="semibold" as="span">{data.csteml}</Text>}>
                            <Text variant="headingXs" fontWeight="regular" as="span" >{data.csteml || '-'}</Text>
                          </Tooltip>
                        </Text>
                      </Box>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <div onClick={(e) => {
                        props.handleUptQueAns(data);
                        openCloseModal('viewOpen', state.viewOpen, e)
                      }}>
                        <Text variant='headingXs' fontWeight='regular' as='p' id='txt_limit'>
                          <Tooltip preferredAlignment={'left'} width='wide' padding="400" dismissOnMouseOut
                            content={<Text variant='headingXs' fontWeight='medium' as='span'>{data.que}</Text>}>
                            <Text variant="headingXs" fontWeight="regular" as="span">{data.que}</Text>
                          </Tooltip>
                        </Text>

                        <Text variant='headingXs' fontWeight='regular' as='p' id='txt_limit' tone='subdued'>
                          <Tooltip preferredAlignment={'left'} width='wide' padding="400" dismissOnMouseOut
                            content={<Text variant='headingXs' fontWeight='regular' as='span' tone='subdued'>{data.ans}</Text>}>
                            <Text variant="headingXs" fontWeight="regular" as="span">{data.ans}</Text>
                          </Tooltip>
                        </Text>
                      </div>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      {data.src !== 'admin' ? getSourceImg(data.src) : '-'}
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <Badge size='medium' tone={data.ia && data.ia === true ? 'success' : 'attention'}>
                        {data?.ia === true ? <Text variant='headingXs' fontWeight='medium' as='span'>Publish</Text>
                          : <Text variant='headingXs' fontWeight='medium' as='span'>Unpublish</Text>
                        }
                      </Badge>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                      <ButtonGroup>
                        <Button variant='monochromePlain' icon={ViewIcon}
                          onClick={(e) => {
                            props.handleUptQueAns(data);
                            openCloseModal('viewOpen', state.viewOpen, e)
                          }} />
                      </ButtonGroup>
                    </IndexTable.Cell>

                  </IndexTable.Row>
                );
              })}
            </IndexTable>
          </Card>
        </Box>

        {/* import modal */}
        <Modal
          title={<Text variant='headingMd' fontWeight='semibold' as='h5'>Import questions by CSV</Text>}
          size=''
          open={state.importOpen}
          onClose={(e) => openCloseModal('importOpen', state.importOpen, e)}>
          <Modal.Section>
            <BlockStack gap="500">
              <DropZone onDrop={handleDropZoneDrop} allowMultiple={false}>
                {uploadedFiles}
                {fileUpload}
              </DropZone>
              <Divider />
              <InlineStack wrap={false} align='space-between'>
                <Link url="https://docs.google.com/spreadsheets/d/1TFzVHoumAKzcO4YGY7OIna4uCrBxkAgPlDxlUDfR_0E/edit#gid=0" target="_blank">Download sample CSV</Link>
                <ButtonGroup>
                  <Button onClick={(e) => { openCloseModal('importOpen', state.importOpen, e); setFiles([]); }}>Cancel</Button>
                  <Button onClick={() => props.handleImportData()}>Import</Button>
                </ButtonGroup>
              </InlineStack>
            </BlockStack>
          </Modal.Section>
        </Modal>



        {/* Add data modal */}
        <Modal
          open={state.addQueAnsOpen}
          title={<Text variant='headingMd' fontWeight='bold'>Add question</Text>}
          onClose={(e) => props.openCloseModal('addQueAnsOpen', state.addQueAnsOpen, e)}>
          <Modal.Section>
            <TextField
              label="Question"
              value={state.addQueAnsobj.que}
              onChange={(e) => { props.handleTextChange({ que: e }) }}
              error={state.errMessage.que ? "The question field is required." : false}
              autoComplete="off" />
            {props.validator.message('que', state.addQueAnsobj.que, 'required')}
            <Box paddingBlockStart={300}>
              <TextField
                label="Answer"
                multiline={4}
                value={state.addQueAnsobj.ans}
                onChange={(e) => { props.handleTextChange({ ans: e }) }}
                autoComplete="off"
                error={state.errMessage.ans ? "The answer field is required." : false} />
              {props.validator.message('ans', state.addQueAnsobj.ans, 'required')}
            </Box>
            <Box paddingBlock={300}>
              <Label variant="headingXs" as="p"> Question status </Label>
              <InlineStack gap={300}>
                <RadioButton
                  label="Publish"
                  checked={state.addQueAnsobj.ia === true}
                  id="disabled"
                  name="accounts"
                  onChange={() => props.handleTextChange({ ia: true })} />
                <RadioButton
                  label="Unpublish"
                  id="optional"
                  name="accounts"
                  checked={state.addQueAnsobj.ia === false}
                  onChange={() => props.handleTextChange({ ia: false })} />
              </InlineStack>
            </Box>
            <Box>
              <Popover
                fullWidth
                preferredPosition='mostSpace'
                active={state.groupPopActive}
                onClose={() => changeNameValue({ groupPopActive: !state.groupPopActive })}
                activator={
                  <TextField
                    label={"Select multiple products"}
                    autoComplete='off'
                    placeholder='Type product name'
                    verticalContent={state.verticalTags}
                    onChange={(e) => {
                      props.openGroupProModal('groupNewSearch', e);
                      props.handleSearchGroupProduct(e);
                    }}
                    error={state.errMessage.product ? "The Product field is required." : false}
                    connectedRight={<Box paddingInlineStart={200}><Button onClick={() => {
                      changeNameValue({ groupNewSearch: '' });
                      props.openCloseModal('groupViewProOpen', state.groupViewProOpen, 'open');
                      changeNameValue({ productValue: '' });
                    }}>Search</Button></Box>} />
                }>
              </Popover>
              {props.validator.message('product', state.verticalTags, 'required')}
            </Box>
            <div className='pageAction_paDDing'>
              <PageActions
                primaryAction={{
                  icon: PlusCircleIcon,
                  content: 'Add',
                  tone: 'success',
                  onAction: () => props.handleAddQueAns()
                }}
                secondaryActions={[{
                  content: 'Cancel',
                  onAction: () => props.openCloseModal('addQueAnsOpen', state.addQueAnsOpen, 'close')
                }]} />
            </div>
          </Modal.Section>
        </Modal>




        {/* view modal */}
        <Modal
          size='large'
          open={state.viewOpen}
          onClose={(e) => (state.queAnsDeleteOpen || state.groupViewProOpen) ? props.changeNameValue()
            : props.openCloseModal('viewOpen', state.viewOpen, e)
          }>
          <div className={(state.queAnsDeleteOpen || state.groupViewProOpen) ?
            'moderate_nonPnt' : "moderate_rev_modal"}>
            <Modal.Section>

              <InlineStack align="space-between" gap={500} blockAlign='center'> {/* modal header */}
                <InlineStack align='center' blockAlign='center'>
                  <Text variant="headingMd" as="h2">Question detail</Text>

                  <Box paddingInlineStart={200} paddingInlineEnd={400}>
                    <Badge size='medium' tone={state.addQueAnsobj.ia && state.addQueAnsobj.ia === true ? 'success' : 'attention'}>
                      {state.addQueAnsobj.ia && state.addQueAnsobj.ia === true ? <Text variant='headingXs' fontWeight='medium' as='span'>Publish</Text>
                        : <Text variant='headingXs' fontWeight='medium' as='span'>Unpublish</Text>
                      }
                    </Badge>
                  </Box>
                  <Text variant="bodySm" as="p" fontWeight='medium'>{(new Date(state.curUserDate)).toLocaleDateString("en-US", opt)}</Text>
                </InlineStack>

                <Box className='reviewdetail-popuphd-right Polaris-Box' paddingInlineEnd={{ xs: 0, sm: 400, md: 400, lg: 800, xl: 800 }}>
                  <InlineStack align='center' gap={200}>
                    <Button variant={'primary'} tone='critical'
                      onClick={(e) => { openCloseModal('queAnsDeleteOpen', state.queAnsDeleteOpen, e); changeNameValue({ queAnsDeleteId: state.udtQueAnsDataId }) }}>
                      Delete
                    </Button>
                  </InlineStack>
                </Box>

              </InlineStack>

              <Box paddingBlockStart={500}>
                <Grid gap={400}>
                  <Grid.Cell columnSpan={{ xl: 7, lg: 7, md: 6, sm: 6, xs: 6 }}>
                    <BlockStack gap={400}>

                      <Card>
                        <TextField
                          label="Question"
                          value={state.addQueAnsobj.que}
                          onChange={(e) => { props.handleTextChange({ que: e }) }}
                          error={state.errMessage.que ? "The question field is required." : false}
                          autoComplete="off"
                        />
                        {props.validator.message('que', state.addQueAnsobj.que, 'required')}
                      </Card>

                      <Card title={`Response to ${state.addQueAnsobj.ans} ${state.addQueAnsobj.que ? `(${state.addQueAnsobj.que})` : ''}`} sectioned>

                        <TextField
                          label="Answer"
                          value={state.addQueAnsobj.ans}
                          onChange={(e) => { props.handleTextChange({ ans: e }) }}
                          autoComplete="off"
                          multiline={4}
                          error={state.errMessage.ans ? "The answer field is required." : false}
                        />
                        {props.validator.message('ans', state.addQueAnsobj.ans, 'required')}
                      </Card>

                      <Card>
                        <Box>
                          <Popover
                            fullWidth
                            preferredPosition='below'
                            active={state.groupPopActive}
                            onClose={() => changeNameValue({ groupPopActive: !state.groupPopActive })}
                            activator={
                              <TextField
                                label={"Select multiple products"}
                                autoComplete='off'
                                placeholder='Type product name'
                                verticalContent={state.verticalTags}
                                error={state.errMessage.product ? "The Product field is required." : false}
                                onChange={(e) => {
                                  props.openGroupProModal('groupNewSearch', e)
                                  props.handleSearchGroupProduct(e);
                                }}
                                connectedRight={<Button onClick={() => {
                                  changeNameValue({ groupNewSearch: '' });
                                  props.openCloseModal('groupViewProOpen', state.groupViewProOpen, 'open'); changeNameValue({ productValue: '' });
                                }}>Search</Button>} />
                            }>
                          </Popover>
                          {props.validator.message('product', state.verticalTags, 'required')}
                        </Box>
                      </Card>
                      <InlineStack align="end">
                        <Button id='primary_btn' onClick={() => props.handleUptQueAnsSuccess()}>
                          Update
                        </Button>
                      </InlineStack>
                    </BlockStack>
                  </Grid.Cell>

                  <Grid.Cell columnSpan={{ xl: 5, lg: 5, md: 6, sm: 6, xs: 6 }}>
                    <BlockStack gap={400}>
                      <Card> {/* Review status */}
                        <Text variant="headingMd" fontWeight='bold' as="h3">Review status</Text>

                        <Box paddingBlockStart={400}>
                          {state.addQueAnsobj.ia === true ?
                            <Button
                              onClick={() => {
                                props.updQueAnsStatus(false);
                              }}
                            >Unpublish</Button>
                            :
                            <Button id='primary_btn'
                              onClick={() => {
                                props.updQueAnsStatus(true)
                              }}
                            >Publish</Button>
                          }
                        </Box>
                      </Card>

                      <Card>
                        <Box>
                          <Text variant="headingMd" fontWeight='bold' as="h3">Customer contact detail</Text>
                          <Box paddingBlockStart={300}>
                            <InlineStack direction={'column'} gap={200}>
                              <Text variant="headingMd" fontWeight='semibold' as="h5">Name</Text>
                              <Text variant="headingMd" fontWeight='regular' as="h5" tone='subdued'>{state.curUserName ? state.curUserName : '-'}</Text>
                            </InlineStack>
                          </Box>
                        </Box>
                        {state.addQueAnsobj.que &&
                          <Box paddingBlockStart={400}>
                            <InlineStack direction={'column'} gap={200}>
                              <Text variant="headingMd" fontWeight='semibold' as="h5">Email</Text>
                              <Text variant="headingMd" fontWeight='regular' as="h5" tone='subdued'>{state.curUserEmail ? state.curUserEmail : '-'}</Text>
                            </InlineStack>
                          </Box>
                        }
                      </Card>

                    </BlockStack>
                  </Grid.Cell>
                </Grid>
              </Box>
            </Modal.Section>
          </div>
          {/* );
          })
          } */}
        </Modal>

        {/* model for select product */}
        <Modal
          open={state.groupViewProOpen}
          limitHeight={true}
          // noScroll={true}
          onClose={(e) => props.openCloseModal('groupViewProOpen', state.groupViewProOpen, e)}
          title={"Product list"}>

          <Modal.Section>
            <div className='groupProList'>
              <ResourceList
                selectable
                // showHeader={false}
                headerContent={`Showing ${state.groupProdOptions.length} Products`}
                totalItemsCount={state.createProSelectedItem.length}
                selectedItems={state.createProSelectedItem}
                onSelectionChange={(e) => props.selectProducts('createProSelectedItem', e)}
                items={state.groupProdOptions}
                filterControl={filterControl}
                flushFilters
                pagination={{
                  hasPrevious: (state.hasPreviousPage === true) ? true : false,
                  hasNext: (state.hasNextPage === true) ? true : false,
                  onNext: () => props.groupPagiCall('+1'),
                  onPrevious: () => props.groupPagiCall('-1')
                }}
                promotedBulkActions={SelectionActions}
                renderItem={(item) => {
                  return (
                    <ResourceItem
                      id={item.id}
                      media={item.media}
                      verticalAlignment='center'
                      onClick={() => {
                        props.selectProducts(
                          'createProSelectedItem',
                          [...state.createProSelectedItem, item.id]
                        );
                      }}>
                      <Text variant="bodyMd" fontWeight="bold" as="h3">{item.label}</Text>
                    </ResourceItem>
                  );
                }} />
            </div>
          </Modal.Section>
        </Modal>

        {/* confirmation delete modal */}
        <Modal
          size='small'
          open={state.queAnsDeleteOpen}
          title={<Text variant='headingMd' as='h3' fontWeight='bold'>Confirmation</Text>}
          onClose={(e) => openCloseModal('queAnsDeleteOpen', state.queAnsDeleteOpen, e)}>
          <Modal.Section>
            <Text fontWeight='semibold' variant='headingMd'>Are you sure to delete this?</Text>
            <div className='pageAction_paDDing'>
              <PageActions
                primaryAction={
                  <Button variant='primary' tone='critical' onClick={() => props.actionListClick('delete')}>Yes</Button>
                }
                secondaryActions={[{
                  content: 'Cancel',
                  onAction: () => props.openCloseModal('queAnsDeleteOpen', state.queAnsDeleteOpen, 'close')
                }]} />
            </div>
          </Modal.Section>
        </Modal>
      </Page>
    </div >
  );
}

export default QueAns;