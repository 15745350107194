import { Text, Button, ButtonGroup, InlineStack, InlineGrid, Box, BlockStack, RadioButton, List, Modal, TextField, Divider } from '@shopify/polaris';
import { ArrowLeftIcon } from '@shopify/polaris-icons';
import { onboard_step_5, onboard_step_6 } from '../../img';
import Import from '../../containers/Import/Import';

const BoardingStep5 = ({ props }) => {
  let { state, changeNameValue } = props;
  let _pvd = localStorage.getItem('pvd') || '';    //steps for shopify and wix

  return (
    _pvd === 'shopify' ?
      <Box>
        <Box paddingBlockEnd={800}>
          <InlineGrid gap="800" columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
            <div className='border-right'>
              {/* <div id='cstm_scrollbar' className='onboard-image'> */}
              <div className='onboard-image'>
                <Box paddingBlockEnd={200}>
                  <Text variant='headingMd' as='p' fontWeight='medium' alignment='start'>Preview</Text>
                </Box>
                <div className='image-preview'>
                  <div>
                    <img src={onboard_step_5} width='100%' height='100%' alt="onboard" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <BlockStack gap={{ xl: 1000, lg: 600, md: 600, sm: 800, xs: 800 }}>
                <Text as="h6" variant='bodyLg' fontWeight='semibold'>Step 5 / 6</Text>
                <Box paddingBlockEnd={500} maxWidth='85%'>
                  <Text variant="headingLg" as="h5" fontWeight='medium'>Activate the review request feature to automatically gather reviews</Text>
                </Box>
              </BlockStack>
              <Box paddingBlockStart={0} paddingBlockEnd={500} maxWidth='90%'>
                <Text variant="bodyLg" as="h6" fontWeight='regular' tone='subdued'>Choose when to send requests after an order is fulfilled.
                </Text>
              </Box>
              <InlineGrid columns={2}>
                <TextField
                  label='Select days'
                  type='number'
                  suffix='days'
                  value={props.state.ddValue}
                  onChange={(e) => props.changeNameValue({ ddValue: parseInt(e) })}
                  error={props.state.errMessage.daysField ? props.state.errMessage.daysField : false} />
                {props.validator.message('daysField', props.state.ddValue, 'required|numeric|min:1,num|max:60,num')}
              </InlineGrid>
            </div>
          </InlineGrid>
        </Box>
        <Divider />
        <Box paddingBlockStart={500}>
          <InlineStack align="space-between">
            <Button id='plain_clr' variant={'plain'} icon={ArrowLeftIcon} onClick={() => props.handleNextStep('step4', 'Back_Step5')}>Back</Button>
            <ButtonGroup>
              <Button variant={'plain'} onClick={() => props.handleNextStep('step6', 'Skip_Step5')}>Skip</Button>
              <Button id='primry_clr' variant='primary' onClick={() => props.updateConfig('step6', 'Step5')}>Save & Continue</Button>
            </ButtonGroup>
          </InlineStack>
        </Box>
      </Box>
      :
      _pvd === 'wix' &&
      <Box>
        <Box paddingBlockEnd={800}>
          <InlineGrid gap="800" columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
            <div className='border-right'>
              <div className='onboard-image'>
                <div>
                  <img src={onboard_step_6} width='100%' height='100%' alt="onboard" />
                </div>
              </div>
            </div>
            <div>
              <BlockStack gap={{ xl: 1000, lg: 600, md: 600, sm: 800, xs: 800 }}>
                <Text as="h6" variant='bodyLg' fontWeight='semibold'>Step 5 / 5</Text>
                <Box paddingBlockEnd={400}>
                  <Text variant="headingLg" as="h5" fontWeight='medium'>Enable Collecting reviews from previous customers</Text>
                </Box>
              </BlockStack>

              <BlockStack gap={400}>
                <div className='ob_import_bg'>
                  <RadioButton
                    label="Collecting my first reviews"
                    checked={state.radio === 'disabled'}
                    id="disabled"
                    name="accounts"
                    onChange={() => changeNameValue({ radio: 'disabled' })} />
                </div>

                <div className='ob_import_bg'>
                  <BlockStack gap={400}>

                    <RadioButton
                      id="optional"
                      name="accounts"
                      checked={state.radio ? (state.radio === 'optional') : true}
                      label="I already have reviews on a different platform."
                      onChange={() => changeNameValue({ radio: 'optional' })} />
                    <List type="bullet">
                      <List.Item>Good news! You can move these reviews over by importing a CSV file.</List.Item>
                    </List>
                    <Box>
                      <Button id='primary_btn' variant="primary" onClick={() => props.openCloseModal('importCsvOpen', props.state.importCsvOpen, 'open')}>Import CSV</Button>
                    </Box>
                  </BlockStack>
                </div>
              </BlockStack>

            </div>
          </InlineGrid>
        </Box>
        <Divider />
        <Box paddingBlockStart={500}>
          <InlineStack align="space-between">
            <Button id='plain_clr' variant={'plain'} icon={ArrowLeftIcon} onClick={() => props.handleNextStep('step4', 'Back_Step5')}>Back</Button>
            <ButtonGroup>
              <Button variant={'plain'} onClick={() => props.handleNextStep('dashboard', 'Skip_Step5')}>Skip</Button>
              <Button id='primry_clr' variant='primary' onClick={() => props.handleNextStep('dashboard', 'Step5')}>Save & Continue</Button>
            </ButtonGroup>
          </InlineStack>
        </Box>

        {/* import csv modal */}
        <Modal
          open={props.state.importCsvOpen}
          onClose={(e) => props.openCloseModal('importCsvOpen', props.state.importCsvOpen, e)}
          title={<Text variant='headingMd' fontWeight='bold'>Import Review</Text>}>
          <Modal.Section>
            <Import />
          </Modal.Section>
        </Modal>
      </Box>
  );
}

export default BoardingStep5;