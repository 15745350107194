import { Button, Divider, Card, Checkbox, Badge, Sheet, Grid, Scrollable, InlineStack, Box, Text } from '@shopify/polaris';
import { ExternalIcon, EditIcon, XIcon } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import EditFields from './EditFields';
import EmlTempSidebar from './EmlTempSidebar';
import * as brandingDucks from '../../ducks/branding';

const TemplateCard = ({ props }) => {
  let { state, changeNameValue, openCloseModal, handleTextChange } = props;

  const dispatch = useDispatch();
  const brandList = useSelector(state => state.branding.brandList);

  useEffect(() => {
    dispatch(brandingDucks.getBranding());
    changeNameValue({ emailTest: localStorage.getItem('e') });
  }, []);

  useEffect(() => {
    if (brandList?.nm) changeNameValue({ brandName: brandList.nm });
  }, [brandList]);

  const callResetApi = () => {
    changeNameValue({ isEdit: true });

    if (state.emailMSTArr && state.emailMSTArr.length > 0) {
      state.emailMSTArr && state.emailMSTArr.length > 0 &&
        state.emailMSTArr.filter(data => {
          if (data.typ === 'one_time_campaign') {
            if (data.sbtyp === state.sbtyp) {

              let config = {
                dd: data.dd,
                sbjct: data.sbjct,
                msg: data.msg,
                ...(state?.emailObj?.lgurl && { lgurl: state.emailObj.lgurl }), // for not reseting logo field
                ...(data.irttl && { irttl: data.irttl }),
                ...(data.rtngtxt && { rtngtxt: data.rtngtxt }),
                ...(data.rttltxt && { rttltxt: data.rttltxt }),
                ...(data.irdsc && { irdsc: data.irdsc }),
                ...(data.rdsctxt && { rdsctxt: data.rdsctxt }),
                ...(data.rbtntxt && { rbtntxt: data.rbtntxt }),
                ...(data.clstmnt && { clstmnt: data.clstmnt }),
              }

              let stateObj = {
                emailObj: config,
                dn: data.dn,
                ...(data.htmltmpl && { htmltmpl: data.htmltmpl }),
                ...(data.htmlcrd && { htmlcrd: data.htmlcrd })
              };
              changeNameValue(stateObj);
            }
          }
        });
    }
    openCloseModal('resetOpen', state.resetOpen, 'close');
  }

  const handleDiscrdChange = () => {
    state?.emailSeqArr?.length > 0 && state.emailSeqArr.filter((data) => {
      if (data.typ === 'one_time_campaign') {
        if (data.sbtyp === state.sbtyp) {
          let obj = {
            dd: data.dd,
            sbjct: data.sbjct,
            msg: data.msg,
            lgurl: data.lgurl,
            irttl: data.irttl,
            rtngtxt: data.rtngtxt,
            rttltxt: data.rttltxt,
            irdsc: data.irdsc,
            rdsctxt: data.rdsctxt,
            rbtntxt: data.rbtntxt,
            clstmnt: data.clstmnt
          }
          changeNameValue({ emailObj: obj });
        }
      }
    });
    changeNameValue({ isEdit: false });
  }

  const saveSequnceTab = () => {
    changeNameValue({ isEdit: false, tempSidebarOpen: false, isSaveMnualTemp: true });
  }

  //show only those templates which arrpvd includes the pvd (for local-custom)
  let createMstArr = state?.emailMSTArr.length > 0 && state.emailMSTArr.filter((data) => {
    const pvd = localStorage.getItem('pvd');
    return (
      !state.emlNewSeqData.some((val) => val.typ === data.typ && val.sbtyp === data.sbtyp) &&
      (pvd === 'local' ? data.arrpvd.includes('custom') : data.arrpvd.includes(pvd))
    );
  });

  const getTitle = (typ) => {
    let ttl = 'Single product review template';
    if (typ === 'multi')
      ttl = 'Multiple product review template';
    return ttl;
  }

  const handleClkTempEdit = (data) => {
    changeNameValue({ tempSidebarOpen: true });  //open template edit section on sidebar
    props.editTemplateClk(data);
  }

  return (
    <Card padding={0}>
      <Box background='bg-surface-secondary' paddingInline={400} paddingBlock={300}>
        <Text variant='bodyLg' fontWeight='medium' as='h1'>Start collecting reviews via Email</Text>
      </Box>
      <Divider borderColor='border-tertiary' />

      <Box padding={400} paddingInlineStart={600}>
        {/* <Text variant='headingSm' fontWeight='medium' as='h6'>
          Choose templates for requesting reviews via email, SMS, and whatsApp.</Text> */}

        {/* {localStorage.getItem('pvd') !== 'local' &&
          <Box paddingBlockStart={400}>
            <div className='info_banner' style={{ width: '100%' }}>
              <InlineStack gap={200} wrap={false}>
                <img src={error} alt='error' />
                <Text variant='bodyMd' fontWeight='medium' as='p'>If you select multiple templates, then we will randomly pick a template.</Text>
              </InlineStack>
            </div>
          </Box>
        } */}

        {/* <InlineStack gap={200} blockAlign='start'>
            <Box paddingBlockStart={'050'}>
              <img src={wisernotify} alt="wisernotify" />
            </Box>
            <InlineStack direction={'column'}>
              <Text variant='headingSm' fontWeight='medium' as='h6'>Default templates</Text>
              <Box paddingBlockStart={100}>
                <Text variant='bodyMd' fontWeight='regular' tone='subdued'>Choose email template to collect reviews</Text>
              </Box>
            </InlineStack>
          </InlineStack> */}

        <Box paddingBlockEnd={100}>

          {/* create template section */}
          {createMstArr?.length > 0 && createMstArr.map((data, i) => {
            if (data.typ === 'one_time_campaign')
              return (
                <Box key={i} paddingBlockStart={100}>
                  <InlineStack align="space-between" blockAlign='center' gap={200}>
                    {localStorage.getItem('pvd') === 'local' ?
                      <InlineStack gap={200}>
                        <Text id='clrsecondary' as='span' fontWeight='regular' variant='bodySm' >
                          Email Template Customization</Text>
                        <Badge tone="attention"><Text fontWeight='medium'>Email</Text></Badge>
                      </InlineStack>
                      :
                      <Checkbox disabled label={<InlineStack gap={200}>
                        <Text id='clrsecondary' as='span' fontWeight='regular' variant='bodySm' >
                          Email Template Customization</Text>
                        <Badge tone="attention"><Text fontWeight='medium'>Email</Text></Badge>
                      </InlineStack>}
                      />
                    }
                    <Button icon={EditIcon} variant='primary' onClick={() => props.createSeqTemplate(data)}>Create Template</Button>
                  </InlineStack>
                </Box>
              )
          })}

          {/* edit template section */}
          {state?.emailSeqArr?.length > 0 && state.emailSeqArr.map((data, i) => {
            if (data.typ === 'one_time_campaign')
              return (
                <Box key={i} paddingBlockStart={100}>
                  <InlineStack align="space-between" blockAlign='center' gap={'50'}>
                    {localStorage.getItem('pvd') === 'local' ?
                      <InlineStack gap={200}>
                        <Text id='clrsecondary' as='span' fontWeight='regular' variant='bodyMd'>
                          Email Template Customization &nbsp;
                          <Badge tone="attention" fontWeight='medium'>Email</Badge>
                        </Text>
                      </InlineStack>
                      :
                      <Checkbox checked={data.ia} onChange={(e) => props.handleStatusUpdate(data._id, e)}
                        label={<InlineStack gap={200}>
                          <Text id='clrsecondary' as='span' fontWeight='regular' variant='bodyMd'>
                            Email Template Customization &nbsp;
                            <Badge tone="attention" fontWeight='medium'>Email</Badge>
                          </Text>
                        </InlineStack>
                        } />
                    }
                    <Button icon={EditIcon} onClick={() => handleClkTempEdit(data)}>Edit</Button>
                  </InlineStack>
                </Box>
              );
          })}

        </Box>

        {/* integration parts */}
        {/* {localStorage.getItem('pvd') !== 'local' &&  // hid cuase of csv section, no integration show
          state?.stateIntegration?.length > 0 && state.stateIntegration.map((mainData, j) => {
            return (
              <Box key={j}>
                <Box paddingBlock={400}><Divider /></Box>

                <InlineStack align='space-between' gap={300}>
                  <InlineStack gap={200} blockAlign='start'>
                    <Box paddingBlockStart={'050'}>
                      <img src={mainData?.img || wisernotify} alt="Integration" />
                    </Box>
                    <InlineStack direction={'column'}>
                      <Text variant='headingSm' fontWeight='medium' as='h6'>{mainData.dn}</Text>
                      <Box paddingBlockStart={100}>
                        <Text variant='bodySm' as='span' tone='subdued'>Ideal for requesting reviews for individual product</Text>
                      </Box>
                    </InlineStack>
                  </InlineStack>
                  <Link to={`/integration?name=${mainData?.inm}`}><Button icon={ExternalIcon} variant='plain'>Integration</Button></Link>
                </InlineStack>

                <Box paddingBlockStart={400} paddingInlineStart={800}>
                  {mainData?.d?.arr?.length > 0 && mainData.d.arr.map((data, i) => {
                    if (data.ia === true) {
                      return (
                        <Box key={i} paddingBlockEnd={i !== mainData.d.arr.length - 1 ? 400 : 0}>
                          <InlineStack align="space-between" blockAlign='center'>
                            <InlineStack align='center'>

                              <Checkbox checked={data.imnlcmpgn}
                                onChange={(e) => props.updateTmpStatus(mainData._id, data._id, 'imnlcmpgn', e)} />

                              <div id='cursorPoint'
                                onClick={() => props.updateTmpStatus(mainData._id, data._id, 'imnlcmpgn', !data.imnlcmpgn)}>

                                <InlineStack direction={'column'} align='start' blockAlign='start' gap={100}>
                                  <InlineStack gap={200} align='start'>
                                    <Text id='clrsecondary' as='p' fontWeight='regular' variant='bodymd'>
                                      {data.rmk ? data.rmk : '-'}</Text>
                                    {mainData.ctg &&
                                      <Badge tone={mainData.ctg === 'Whatsapp' ? 'success' : 'info'}>
                                        <Text fontWeight='medium'>{mainData.ctg}</Text>
                                      </Badge>
                                    }
                                  </InlineStack>
                                </InlineStack>

                              </div>

                            </InlineStack>
                          </InlineStack>
                        </Box>
                      );
                    }
                  })}
                </Box>

              </Box>
            );
          })} */}

      </Box>


      {/* edit email template open on sidebar */}
      {state.tempSidebarOpen === true &&
        <EmlTempSidebar
          state={state}
          changeNameValue={changeNameValue}
          openCloseModal={openCloseModal}
          handleTextChange={handleTextChange}
        />
      }
    </Card>
  );
}

export default TemplateCard;