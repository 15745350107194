import { fork } from 'redux-saga/effects';
import auth from './auth';
import product from './product';
import customer from './customer';
import groupProduct from './groupProduct';
import integration from './integration';
import incentive from './incentives';
import reviewWidget from './reviewWidget';
import master from './master';
import moderate from './moderate';
import importPage from './import';
import email from './email';
import analytics from './analytics';
import branding from './branding';
import plan from './plan';
import setting from './setting';
import ugc from './ugc';
import customQuestion from './customQuestion';
import upsell from './upsell';
import queAns from './queAns';
import currentQueue from './currentQueue';
import login from './login';
import tagList from './tagList';
import myprofile from './myprofile';

export default function* rootSaga() {
  yield fork(auth);
  yield fork(product);
  yield fork(customer);
  yield fork(groupProduct);
  yield fork(integration);
  yield fork(incentive);
  yield fork(reviewWidget);
  yield fork(master);
  yield fork(moderate);
  yield fork(importPage);
  yield fork(email);
  yield fork(analytics);
  yield fork(branding);
  yield fork(plan);
  yield fork(setting);
  yield fork(ugc);
  yield fork(customQuestion);
  yield fork(upsell);
  yield fork(queAns);
  yield fork(currentQueue);
  yield fork(login);
  yield fork(tagList);
  yield fork(myprofile);
}
