import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/reviewWidget';
import { toastify } from '../ducks/toast';

function* getWidgetList() {
  try {
    yield put(load());
    let res = yield call(api.GET, '/getWidgetList');

    if (res.status === 'success') {
      yield put(actions.getWidgetListSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }))
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createWidget(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createNewWidget', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      // yield put(actions.createWidgetSuccess(true));
      yield put(actions.getWidgetList());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (e) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateWidget(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updWidget', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getWidgetListSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (e) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteWidget(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/delWidget?_id=' + action.payload.id);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getWidgetList());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (e) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updWdgtStatus(action) {
  try {
    const res = yield call(api.POST, '/updWdgtStatus', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getWidgetList());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getIntgList(action) {
  try {
    const res = yield call(api.POST, '/getIntList', action.payload);
    if (res.status === 'success') {
      yield put(actions.getIntgListSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

// save data source onchange api
function* addDataSrc(action) {
  try {
    const res = yield call(api.POST, '/addDataSrc', action.payload);
    if (res.status === 'success') {
      yield put(actions.getWidgetListSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (e) {
    console.error(e, '==>addDataSrc');
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delDataSrc(action) {
  try {
    const res = yield call(api.DELETE, '/delDataSrc?_id=' + action.payload._id + '&uid=' + action.payload.uid + '&pid=' + action.payload.pid + '&wdtyp=' + action.payload.wdtyp);

    if (res.status === 'success') {
      yield put(actions.getWidgetListSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (e) {
    console.error(e, '==>delDataSrc');
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetWidgetList() {
  yield takeLatest(actions.getWidgetList.type, getWidgetList);
}
export function* watchCreateWidget() {
  yield takeLatest(actions.createWidget.type, createWidget);
}
export function* watchUpdWidget() {
  yield takeLatest(actions.updateWidget.type, updateWidget);
}
export function* watchDeleteWidget() {
  yield takeLatest(actions.deleteWidget.type, deleteWidget);
}
export function* watchUpdWdgtStatus() {
  yield takeLatest(actions.updWdgtStatus.type, updWdgtStatus);
}
export function* watchGetIntgList() {
  yield takeLatest(actions.getIntgList.type, getIntgList);
}
export function* watchAddDataSrc() {
  yield takeLatest(actions.addDataSrc.type, addDataSrc);
}
export function* watchDelDataSrc() {
  yield takeLatest(actions.delDataSrc.type, delDataSrc);
}

export default function* rootSaga() {
  yield fork(watchGetWidgetList);
  yield fork(watchCreateWidget);
  yield fork(watchUpdWidget);
  yield fork(watchDeleteWidget);
  yield fork(watchUpdWdgtStatus);
  yield fork(watchGetIntgList);
  yield fork(watchAddDataSrc);
  yield fork(watchDelDataSrc);
} 