import GoogleReviewCollector from './GoogleReviewCollector';
import GoogleShoping from './GoogleShoping';
import WebhookDataImport from './WebhookDataImport';
import MultiStoreSyndication from './MultiStoreSyndication';
import WebhookDataExport from './WebhookDataExport';
import WhatsappInterakt from './WhatsappInterakt';
import MetaShopping from './MetaShopping';
import { InlineStack } from '@shopify/polaris';
import Klaviyo from './Klaviyo';
import WebhookReviewExport from './WebhookReviewExport';
import Wati from './Wati';
import Wisernotify from './Wisernotify';
import Facebook from './Facebook';
import GoogleMyBusiness from './GoogleMyBusiness';
import WebhookAutoTrigger from './WebhookAutomationTrigger';
import Zapier from './Zapier';
import GooglePlace from './GooglePlace';

const IntegrationDetail = (props) => {
  return (
    <div className='inter_wrapper'>

      {props.state.currTyp === 'google_shopping' ?
        <GoogleShoping
          props={props}
        /> :
        props.state.currTyp === 'google_review_collector' ?
          <GoogleReviewCollector
            props={props}
          /> :
          props.state.currTyp === 'webhook_data_import' ?
            <WebhookDataImport
              props={props}
            /> :
            props.state.currTyp === 'multi_store_syndication' ?
              <MultiStoreSyndication
                props={props}
              /> :
              props.state.currTyp === 'webhook_data_export' ?
                <WebhookDataExport
                  props={props}
                /> :
                props.state.currTyp === 'wp_interakt' ?
                  <WhatsappInterakt
                    props={props}
                  /> :
                  props.state.currTyp === 'meta_shopping' ?
                    <MetaShopping
                      props={props}
                    /> :
                    props.state.currTyp === 'klaviyo' ?
                      <Klaviyo
                        props={props}
                      /> :
                      props.state.currTyp === 'webhook_review_request_export' ?
                        <WebhookReviewExport
                          props={props}
                        /> :
                        props.state.currTyp === 'wati' ?
                          <Wati
                            props={props}
                          /> :
                          props.state.currTyp === 'wisernotify' ?
                            <Wisernotify
                              props={props}
                            /> :
                            props.state.currTyp === 'facebook' ?
                              <Facebook
                                props={props}
                              /> :
                              props.state.currTyp === 'googlemybusiness' ?
                                <GoogleMyBusiness
                                  props={props}
                                /> :
                                props.state.currTyp === 'webhook_automation_trigger' ?
                                  <WebhookAutoTrigger
                                    props={props}
                                  />
                                  :
                                  props.state.currTyp === 'zapier' ?
                                    <Zapier
                                      props={props}
                                    />
                                    :
                                    props.state.currTyp === 'google' ?
                                      <GooglePlace
                                        props={props}
                                      />
                                      :
                                      <InlineStack align='center' blockAlign='center'>
                                        <div style={{ fontSize: '18px', position: 'absolute', top: '0', inset: '0' }}>
                                          <h3>no data available</h3>
                                        </div>
                                      </InlineStack>

      }
    </div>
  );
}

export default IntegrationDetail;