import { ImportCard } from "../../components";
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as importDucks from '../../ducks/import';
import { toastify } from '../../ducks/toast';
import * as integrationDucks from '../../ducks/integration';
import Papa from 'papaparse';
import './Import.css';

const Import = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    queryValue: '',
    starRate: [],
    paggiActive: 1,
    sourceSelect: '',
    files: [],
    uploadOpen: false,
    fileErr: false,
    csvCol: '',
    csvData: []
  });

  const [headerData, setHeaderData] = useState({
    rttl: '',
    rtxt: '',
    rtng: '',
    cdt: '',
    un: '',
    e: '',
    pid: '',
    pn: '',
    pu: '',
    piu: '',
    imgurl: '',
    vdourl: '',
    ct: '',
    st: '',
    cn: '',
    i: '',
    rplytxt: '',
    rplydt: '',
    cst1: '',
    cst2: '',
    cst3: ''
  });

  useEffect(() => {
    dispatch(importDucks.getImportData());
  }, []);

  // get api data
  const loadingState = useSelector(state => state.loading.isLoading);
  const profile = useSelector(state => state.auth.profile);
  const importState = useSelector(state => state.importPage);

  let ImportData = [];

  if (importState.ImportList)
    ImportData = importState.ImportList;

  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  const handleSelectCsvCol = (e, typ) => {
    changeNameValue({ csvCol: e });
    setHeaderData({ ...headerData, [typ]: e });
  }

  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles, _rejectedFiles) => {
    changeNameValue({ files: _dropFiles });

    const file = _dropFiles[0];

    Papa.parse(file, {
      header: false, // Treat the first row as headers
      skipEmptyLines: true,
      complete: function (results) {
        const data = results.data;
        for (let i = 0; i < data[0].length; i++) {
          const values = data[0];
          if (i === 0) {
            values.unshift('Select');
          }
        }
        changeNameValue({ csvData: data });
      },
      error: function (error) {
        changeNameValue({ fileErr: false });
      }
    });

    // const reader = new FileReader();
    // reader.onload = (event) => {
    //   const contents = event.target.result;
    //   const lines = contents.split('\n');

    //   const data = [];
    //   for (let i = 0; i < lines.length; i++) {
    //     const values = lines[i].split(','); 
    //     if (i === 0) {
    //       values.unshift('Select');
    //     }
    //     data.push(values);
    //   } 
    //   changeNameValue('csvData', data);
    // };
    // reader.readAsText(_dropFiles[0]);

    if (acceptedFiles.length > 0)
      changeNameValue({ fileErr: false });
    else
      changeNameValue({ fileErr: true });

  }, [state.files]);

  const openCloseUpload = useCallback((typ) => {
    let obj = {
      sourceSelect: '',
      files: []
    }
    changeNameValue(obj);

    if (typ !== undefined) changeNameValue({ uploadOpen: !state.uploadOpen });
  }, [state.uploadOpen]);

  const handleFileUpload = () => {
    if (state.sourceSelect && state.files.length > 0) {
      let obj = {
        sourceSelectErr: false,
        fileErr: false,
        uploadOpen: true,
      }
      changeNameValue(obj);
    }
    else {
      if (!state.sourceSelect) {
        changeNameValue({ sourceSelectErr: true });
      }
      else
        changeNameValue({ fileErr: true });
    }
  }

  const handleMappNext = () => {
    let dataAray = [];
    for (let i = 0; i < state.csvData.length; i++) {
      if (i !== 0) {
        const csv = state.csvData[i];
        if (csv.length === 1 && csv.includes('') === true) {
          break;
        }
        // get position
        let reviewDataObj = { ti: profile.ti, shp: profile.objshp.d };
        if (headerData.rttl !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.rttl][0]);
          reviewDataObj.rttl = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : ''
        }
        if (headerData.rtxt !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.rtxt][0]);
          reviewDataObj.rtxt = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        }
        if (headerData.rtng !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.rtng][0]);
          //check this value is number or not if not than breach loop in give error
          let _rating = 0; 
          if (csv[_rttlIdx - 1]) {
            _rating = csv[_rttlIdx - 1].replace(/"/gi, '')
            if (!isNaN(_rating) !== true) {
              dispatch(toastify({ type: 'error', msg: 'Some rating data is not valid in csv' }));
              dataAray = [];
              return;
            }
          }
          reviewDataObj.rtng = (_rating >= 5) ? 5 : _rating;
        }
        else {
          dispatch(toastify({ type: 'error', msg: 'Rating is required!' }));
          dataAray = [];
          return;
        }
        if (headerData.rplytxt !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.rplytxt][0]);
          reviewDataObj.rplytxt = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        }
        if (headerData.rplydt !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.rplydt][0]);
          //check this value is date or not if not than breach loop in give error
          let _date = '';
          if (csv[_rttlIdx - 1]) {
            _date = csv[_rttlIdx - 1].replace(/"/gi, '');
            _date = new Date(_date);
            if (!isNaN(_date) === true) {
              reviewDataObj.rplydt = _date;
            }
          }
          // else {
          //   dispatch(toastify({ type: 'error', msg: 'Some review reply date value is not valid in csv' }));
          //   dataAray = [];
          //   return;
          // }
          // reviewDataObj.rplydt = _date;
        }
        if (headerData.cdt !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.cdt][0]);
          //check this value is date or not if not than breach loop in give error
          let _date = '';
          if (csv[_rttlIdx - 1]) {
            _date = csv[_rttlIdx - 1].replace(/"/gi, '');
            _date = new Date(_date);
            if (!isNaN(_date) !== true) {
              dispatch(toastify({ type: 'error', msg: 'Some review date value is not valid in csv' }));
              dataAray = [];
              return;
            }
          }
          else {
            dispatch(toastify({ type: 'error', msg: 'Some date value is not valid in csv' }));
            dataAray = [];
            return;
          }
          reviewDataObj.cdt = _date;
        }
        if (headerData.un !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.un][0]);
          reviewDataObj.un = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        }
        else {
          dispatch(toastify({ type: 'error', msg: 'Reviwer name is required!' }));
          dataAray = [];
          return;
        }
        if (headerData.e !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.e][0]);
          //check this value is email or not if not than breach loop in give error
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          let _email = '';
          if (csv[_rttlIdx - 1]) {
            _email = csv[_rttlIdx - 1].replace(/"/gi, '');
            if (emailRegex.test(_email) !== true) {
              dispatch(toastify({ type: 'error', msg: 'Some email value is not valid in csv' }));
              dataAray = [];
              return;
            }
          }
          else {
            dispatch(toastify({ type: 'error', msg: 'Some email value is not valid in csv' }));
            dataAray = [];
            return;
          }
          reviewDataObj.e = _email;
        }
        if (headerData.pid !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.pid][0]);
          reviewDataObj.pid = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        }
        if (headerData.pn !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.pn][0]);
          reviewDataObj.pn = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        }
        if (headerData.pu !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.pu][0]);
          reviewDataObj.pu = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        }
        if (headerData.piu !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.piu][0]);
          reviewDataObj.piu = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        } 
        if (headerData.imgurl !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.imgurl][0]);
          reviewDataObj.imgurl = (csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '');
        }
        if (headerData.vdourl !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.vdourl][0]);
          reviewDataObj.vdourl = (csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '');
        }
        if (headerData.ct !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.ct][0]);
          reviewDataObj.ct = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        }
        if (headerData.st !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.st][0]);
          reviewDataObj.st = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        }
        if (headerData.cn !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.cn][0]);
          reviewDataObj.cn = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        }
        if (headerData.i !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.i][0]);
          reviewDataObj.i = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        }
        if (headerData.cst1 !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.cst1][0]);
          reviewDataObj.cst1 = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        }
        if (headerData.cst2 !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.cst2][0]);
          reviewDataObj.cst2 = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        }
        if (headerData.cst3 !== '') {
          let _rttlIdx = state.csvData[0].findIndex(x => x === [headerData.cst3][0]);
          reviewDataObj.cst3 = csv[_rttlIdx - 1] ? csv[_rttlIdx - 1].replace(/"/gi, '') : '';
        }
        if (state.sourceSelect) {
          reviewDataObj.fa = state.sourceSelect;
        }
        reviewDataObj.rvwst = 1;
        dataAray.push(reviewDataObj);
      }
    }

    let dataObj = {
      dataAray: dataAray
    }
    if (state.sourceSelect) {
      dataObj.src = state.sourceSelect;
    }
    dispatch(integrationDucks.importDataFromCSV(dataObj));
    openCloseUpload('close');

    let obj = {
      sourceSelect: '',
      files: []
    }
    changeNameValue(obj);
    setTimeout(() => dispatch(importDucks.getImportData()), 300);
  }

  return (
    <ImportCard
      state={state}
      loadingState={loadingState}
      handleDropZoneDrop={handleDropZoneDrop}
      handleFileUpload={handleFileUpload}
      ImportData={ImportData}
      openCloseUpload={openCloseUpload}
      handleSelectCsvCol={handleSelectCsvCol}
      headerData={headerData}
      handleMappNext={handleMappNext}
      changeNameValue={changeNameValue} />
  );
}

export default Import;