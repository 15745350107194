import { Grid, Popover, RadioButton, InlineGrid, InlineStack, OptionList, Box, IndexTable, Label, Tag, Listbox, EmptySearchResult, Combobox, AutoSelection, Button, Divider, Card, Text, TextField } from '@shopify/polaris';
import { useState, useEffect } from 'react';
import { useCallback, useMemo } from 'react';
import { PlusIcon, DeleteIcon } from '@shopify/polaris-icons';

const AdvanceFilter = ({ props }) => {
  let { state, changeNameValue, getValbyTyp } = props;

  const [selectedTags, setSelectedTags] = useState([]);
  const [valueChange, setChangeValue] = useState('');
  const [suggestion, setSuggestion] = useState('');

  let fullFill = getValbyTyp('on_order_fulfill') || {};

  //get list of data source
  useEffect(() => {
    if (state.userInteFullList?.length > 0 && state.inteMstList?.length > 0) {
      const lst = state.inteMstList.filter((data) => (data.typ === 'automation_trigger') &&
        state.userInteFullList.some(item2 => item2.inm === data.in)
      );
      const lst2 = state.userInteFullList.filter((data) => (data.inm === 'webhook_automation_trigger') &&
        state.inteMstList.some(item2 => item2.in === data.inm)
      );
      const matchedNames = lst.map(({ dn }) => dn) || [];
      changeNameValue({ dataNmArr: matchedNames, dataListArr: lst, lst2: lst2 });
    }
  }, [state.userInteFullList]);

  useEffect(() => {
    if (fullFill.arrfuid?.length > 0) {

      const lst = state.inteMstList?.length > 0 && state.inteMstList.filter((data) => (data.typ === 'automation_trigger') &&
        state.userInteFullList?.length > 0 && state.userInteFullList.some(item2 => item2.inm === data.in)
      );

      const newArr = fullFill.arrfuid?.length > 0 && fullFill.arrfuid.map(id =>
        state.userInteFullList?.length > 0 && state.userInteFullList.find(obj => obj._id === id)?.inm
      ).filter(Boolean).map(inm => lst?.length > 0 && lst.find(obj => obj.in === inm)?.dn
      ).filter(Boolean);

      setSelectedTags(newArr || []);
    }
  }, [fullFill.arrfuid, state.userInteFullList, state.inteMstList]);

  const handleActiveOptionChange = useCallback((activeOption) => {
    const activeOptionIsAction = activeOption === valueChange;

    if (!activeOptionIsAction && !selectedTags.includes(activeOption))
      setSuggestion(activeOption);
    else setSuggestion('');

  }, [valueChange, selectedTags]);

  const updateSelection = useCallback((selected) => {
    const nextSelectedTags = new Set([...selectedTags]);

    if (nextSelectedTags.has(selected)) {
      nextSelectedTags.delete(selected);
    } else {
      nextSelectedTags.add(selected);
    }
    setSelectedTags([...nextSelectedTags]);
    setChangeValue('');
    setSuggestion('');

    const selectedObjects = state.dataListArr?.length > 0 &&
      state.dataListArr.filter(item => [...nextSelectedTags].includes(item.dn));

    const resultIds = selectedObjects?.length > 0 &&
      selectedObjects.map((mst) => state.lst2?.length > 0 &&
        state.lst2.find(userItem => userItem.inm === mst.in)?._id).filter(Boolean) || [];

    props.callSaveDataSrc(resultIds, 'on_order_fulfill');
  }, [selectedTags, state.dataListArr, state.lst2]);

  const removeTag = useCallback((tag) => () => {
    updateSelection(tag);
  }, [updateSelection]);

  const getAllTags = useCallback(() => {
    let savedTags = state.dataNmArr || [];
    return [...new Set([...savedTags, ...selectedTags])];
  }, [selectedTags, state.dataNmArr]);

  const options = useMemo(() => {
    let list;
    const allTags = getAllTags();
    const filterRegex = new RegExp(valueChange.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');

    if (valueChange) { list = allTags.filter((tag) => tag.match(filterRegex)); }
    else list = allTags;

    return [...list];
  }, [valueChange, getAllTags]);

  const verticalContentMarkup =
    selectedTags.length > 0 ? (
      <InlineStack gap={100}>
        {selectedTags.map((tag) => (
          <Tag key={`option-${tag}`} onRemove={removeTag(tag)}> {tag} </Tag>
        ))}
      </InlineStack>
    ) : null;

  const optionMarkup = options.length > 0 ? options.map((option) => {
    return (
      <Listbox.Option
        key={option}
        value={option}
        accessibilityLabel={option}
        selected={selectedTags.includes(option)}>
        <Listbox.TextOption selected={selectedTags.includes(option)}>
          {option}
        </Listbox.TextOption>
      </Listbox.Option>
    );
  })
    : <EmptySearchResult description={`No integration found`} />;

  const actionMarkup = valueChange && !getAllTags().includes(valueChange) ? (
    <Listbox.Action value={valueChange}>{`Add "${valueChange}"`}</Listbox.Action>
  ) : null;

  // const handleStrDataSrc = useCallback(() => {
  //   const selectedObjects = state.dataListArr?.length > 0 &&
  //     state.dataListArr.filter(item => selectedTags.includes(item.dn));

  //   const resultIds = selectedObjects?.length > 0 &&
  //     selectedObjects.map((mst) => state.lst2?.length > 0 &&
  //       state.lst2.find(userItem => userItem.inm === mst.in)?._id).filter(Boolean) || [];

  //   props.callSaveDataSrc(resultIds);
  // }, [state.dataListArr, selectedTags, state.lst2]);













  const popOpt1 = [
    { label: 'Product name', value: 'product' },
    { label: 'Customer', value: 'customer' }
  ];

  const popOpt2 = [
    { label: 'Contains', value: 'contains' },
    { label: 'Equals', value: 'equals' },
    { label: 'Does not contain', value: 'notcontain' },
    { label: 'Does not equal', value: 'notequal' }
  ];

  const getLabel = (val) => {
    let _lbl = <Text fontWeight='medium' tone='subdued'>Select</Text>;
    if (val) {
      popOpt1.map((x) => { if (x.value === val) _lbl = x.label; });
      popOpt2.map((x) => { if (x.value === val) _lbl = x.label; });
    }
    return _lbl;
  }

  return (
    <div>

      {/* select dataSrc */}
      {localStorage.getItem('pvd') === 'local' ?
        <Card padding={'0'}>
          <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
            <Text variant='bodyLg' fontWeight='medium' as='h1'>Select data-source, product, customers form</Text>
          </Box>
          <Divider borderColor='border-tertiary' />
          <Box padding={400} paddingBlockEnd={200}>
            <Box paddingBlockEnd={200}>
              <Text variant='headingSm' fontWeight='regular' as='h6'> Select data-source </Text>
            </Box>
            <InlineStack gap='200' align='start' blockAlign='center' direction={'row'}>
              <Text variant='headingSm' fontWeight='regular' as='h6'> When new order is received in </Text>
              <div className='dd-dataapp'>
                <Combobox
                  allowMultiple
                  activator={
                    <Combobox.TextField
                      autoComplete="off"
                      value={valueChange}
                      suggestion={suggestion}
                      placeholder="Search tags"
                      verticalContent={verticalContentMarkup}
                      onChange={setChangeValue} />
                  }>
                  {optionMarkup || actionMarkup ?
                    <Listbox
                      autoSelection={AutoSelection.None}
                      onSelect={updateSelection}
                      onActiveOptionChange={handleActiveOptionChange} >
                      {actionMarkup}
                      {optionMarkup}
                    </Listbox>
                    : <EmptySearchResult description={`No integration found`} />
                  }
                </Combobox>
              </div>
            </InlineStack>

            <InlineStack align='end'>
              <Button icon={PlusIcon} variant='plain' onClick={() => changeNameValue({ isAdvnceFilterOpn: !state.isAdvnceFilterOpn })}>Advance filter</Button>
            </InlineStack>
          </Box>

          {state.isAdvnceFilterOpn &&
            <Box padding={400} paddingBlockStart={'000'}>
              <Box paddingBlockEnd={200}>
                <Text tone='base' fontWeight='medium' as='span'>Select customers and products to request reviews from</Text>
              </Box>
              <InlineStack direction={'column'} gap={'050'}>
                <RadioButton
                  label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Collect review for all customers and products</Text>}
                  checked={fullFill.iarrcnd === false}
                  name="collectReview"
                  onChange={() => props.handleConditionAdd({ iarrcnd: false }, 'radio')}
                />
                <RadioButton
                  label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Collect review for all certain customers or specific products</Text>}
                  name="collectReview"
                  checked={fullFill.iarrcnd === true}
                  onChange={() => props.handleConditionAdd({ iarrcnd: true }, 'radio')}
                />
              </InlineStack>

              {fullFill.iarrcnd === true &&
                <Box paddingBlockStart={300}>
                  <Box id='custom_infoBG' padding={400} paddingBlock={300}>
                    <Text variant='bodyLg' fontWeight='regular' as='h1'>Add conditions</Text>
                    <Box paddingBlockStart={400} id='condition_btn'>
                      <Grid gap={300}>
                        <Grid.Cell columnSpan={{ xs: 6, sm: 12, md: 3, lg: 3, xl: 3 }}>
                          <Label><Text fontWeight='regular'>Select type</Text>
                            <div className='mt-2'>
                              <Popover
                                fullWidth
                                active={state.isSelectTypeOn}
                                onClose={() => changeNameValue({ isSelectTypeOn: !state.isSelectTypeOn })}
                                activator={<Button textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isSelectTypeOn: !state.isSelectTypeOn })}>
                                  <Text tone='subdued'> {getLabel(state.ctyp)} </Text>
                                </Button>}>
                                <OptionList
                                  options={popOpt1}
                                  selected={`[${state.ctyp}]`}
                                  onChange={(e) => changeNameValue({ ctyp: e.toString(), isSelectTypeOn: !state.isSelectTypeOn }, 'isShowMessage')} />
                              </Popover>
                            </div>
                          </Label>
                        </Grid.Cell>
                        <Grid.Cell columnSpan={{ xs: 6, sm: 12, md: 3, lg: 4, xl: 4 }}>
                          <Label><Text fontWeight='regular'>Select condition</Text>
                            <div className='mt-2'>
                              <Popover
                                fullWidth
                                active={state.isSelectCondtOn}
                                onClose={() => changeNameValue({ isSelectCondtOn: !state.isSelectCondtOn })}
                                activator={<Button textAlign="left" fullWidth disclosure="select"
                                  onClick={() => changeNameValue({ isSelectCondtOn: !state.isSelectCondtOn })}>
                                  <Text tone='subdued'> {getLabel(state.cnd)} </Text>
                                </Button>}>
                                <OptionList
                                  options={popOpt2}
                                  selected={`[${state.cnd}]`}
                                  onChange={(e) => changeNameValue({ cnd: e.toString(), isSelectCondtOn: !state.isSelectCondtOn }, 'isShowMessage')} />
                              </Popover>
                            </div>
                          </Label>
                        </Grid.Cell>
                        <Grid.Cell columnSpan={{ xs: 6, sm: 12, md: 3, lg: 4, xl: 4 }}>
                          <TextField
                            autoComplete='off'
                            label={<Text fontWeight='regular'>Enter data</Text>}
                            placeholder='Enter data'
                            value={state.dataTxt}
                            onChange={(e) => changeNameValue({ dataTxt: e }, 'isShowMessage')} />
                        </Grid.Cell>
                        <Grid.Cell columnSpan={{ xs: 6, sm: 12, md: 1, lg: 1, xl: 1 }}>
                          <Button id='primary_btn' size='large' onClick={() => props.handleConditionAdd()}>Add</Button>
                        </Grid.Cell>
                      </Grid>
                      <Box paddingBlockStart={400}>
                        <Card padding={0}>

                          <IndexTable
                            itemCount={fullFill.arrcnd ? fullFill.arrcnd.length : 0}
                            selectable={false}
                            headings={[
                              { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Type</Text> },
                              { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Condition</Text> },
                              { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Data</Text> },
                              { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Action</Text> }
                            ]}>
                            {fullFill?.arrcnd?.length > 0 && fullFill.arrcnd.map((val, i) => {
                              return (
                                <IndexTable.Row
                                  id={i}
                                  key={i}
                                  position={i}>
                                  <IndexTable.Cell>
                                    <Text variant="bodyMd" fontWeight="regular" as="span">
                                      {val.ctyp ? getLabel(val.ctyp) : '-'}</Text>
                                  </IndexTable.Cell>
                                  <IndexTable.Cell>
                                    <Text variant="bodyMd" fontWeight="regular" as="span">
                                      {val.cnd ? getLabel(val.cnd) : '-'}</Text>
                                  </IndexTable.Cell>
                                  <IndexTable.Cell>
                                    <Text variant="bodyMd" fontWeight="regular" as="span">{val.data ? val.data : '-'}</Text>
                                  </IndexTable.Cell>
                                  <IndexTable.Cell>
                                    <Button icon={DeleteIcon} variant='monochromePlain' onClick={() => props.handleDelCondition(val)}></Button>
                                  </IndexTable.Cell>
                                </IndexTable.Row>
                              )
                            })}
                          </IndexTable>

                        </Card>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              }
            </Box>
          }

        </Card>
        :
        <Card padding={'0'}>
          <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
            <InlineGrid columns="1fr auto">
              <Text variant='bodyLg' fontWeight='medium' as='h1'> Select customers and products to request reviews from</Text>
            </InlineGrid>
          </Box>
          <Divider borderColor='border-tertiary'></Divider>
          <Box padding={400}>
            <InlineStack direction={'column'} gap={100}>
              <RadioButton
                label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Collect review for all customers and products</Text>}
                checked={fullFill.iarrcnd === false || true}
                name="collectReview"
                onChange={() => props.handleConditionAdd({ iarrcnd: false }, 'radio')}
              />
              <RadioButton
                label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Collect review for all certain customers or specific products</Text>}
                name="collectReview"
                checked={fullFill.iarrcnd === true}
                onChange={() => props.handleConditionAdd({ iarrcnd: true }, 'radio')}
              />
            </InlineStack>

            {fullFill.iarrcnd === true &&
              <Box paddingBlockStart={300}>
                <Box id='custom_infoBG' padding={400} paddingBlock={300}>
                  <Text variant='bodyLg' fontWeight='regular' as='h1'>Add conditions</Text>
                  <Box paddingBlockStart={400} id='condition_btn'>
                    <Grid gap={300}>
                      <Grid.Cell columnSpan={{ xs: 6, sm: 12, md: 3, lg: 3, xl: 3 }}>
                        <Label><Text fontWeight='regular'>Select type</Text>
                          <div className='mt-2'>
                            <Popover
                              fullWidth
                              active={state.isSelectTypeOn}
                              onClose={() => changeNameValue({ isSelectTypeOn: !state.isSelectTypeOn })}
                              activator={<Button textAlign="left" fullWidth disclosure="select"
                                onClick={() => changeNameValue({ isSelectTypeOn: !state.isSelectTypeOn })}>
                                <Text tone='subdued'>
                                  {getLabel(state.ctyp)}
                                </Text>
                              </Button>}>
                              <OptionList
                                options={popOpt1}
                                selected={`[${state.ctyp}]`}
                                onChange={(e) => changeNameValue({ ctyp: e.toString(), isSelectTypeOn: !state.isSelectTypeOn }, 'isShowMessage')} />
                            </Popover>
                          </div>
                        </Label>
                      </Grid.Cell>
                      <Grid.Cell columnSpan={{ xs: 6, sm: 12, md: 3, lg: 4, xl: 4 }}>
                        <Label><Text fontWeight='regular'>Select condition</Text>
                          <div className='mt-2'>
                            <Popover
                              fullWidth
                              active={state.isSelectCondtOn}
                              onClose={() => changeNameValue({ isSelectCondtOn: !state.isSelectCondtOn })}
                              activator={<Button textAlign="left" fullWidth disclosure="select"
                                onClick={() => changeNameValue({ isSelectCondtOn: !state.isSelectCondtOn })}>
                                <Text tone='subdued'>
                                  {getLabel(state.cnd)}
                                </Text>
                              </Button>}>
                              <OptionList
                                options={popOpt2}
                                selected={`[${state.cnd}]`}
                                onChange={(e) => changeNameValue({ cnd: e.toString(), isSelectCondtOn: !state.isSelectCondtOn }, 'isShowMessage')} />
                            </Popover>
                          </div>
                        </Label>
                      </Grid.Cell>
                      <Grid.Cell columnSpan={{ xs: 6, sm: 12, md: 3, lg: 4, xl: 4 }}>
                        <TextField
                          autoComplete='off'
                          label={<Text fontWeight='regular'>Enter data</Text>}
                          placeholder='Enter data'
                          value={state.dataTxt}
                          onChange={(e) => changeNameValue({ dataTxt: e }, 'isShowMessage')} />
                      </Grid.Cell>
                      <Grid.Cell columnSpan={{ xs: 6, sm: 12, md: 1, lg: 1, xl: 1 }}>
                        <Button id='primary_btn' size='large' onClick={() => props.handleConditionAdd()}>Add</Button>
                      </Grid.Cell>
                    </Grid>
                    <Box paddingBlockStart={400}>
                      <Card padding={0}>

                        <IndexTable
                          itemCount={fullFill.arrcnd ? fullFill.arrcnd.length : 0}
                          selectable={false}
                          headings={[
                            { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Type</Text> },
                            { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Condition</Text> },
                            { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Data</Text> },
                            { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Action</Text> }
                          ]}>
                          {fullFill?.arrcnd?.length > 0 && fullFill.arrcnd.map((val, i) => {
                            return (
                              <IndexTable.Row
                                id={i}
                                key={i}
                                position={i}>
                                <IndexTable.Cell>
                                  <Text variant="bodyMd" fontWeight="regular" as="span">
                                    {val.ctyp ? getLabel(val.ctyp) : '-'}</Text>
                                </IndexTable.Cell>
                                <IndexTable.Cell>
                                  <Text variant="bodyMd" fontWeight="regular" as="span">
                                    {val.cnd ? getLabel(val.cnd) : '-'}</Text>
                                </IndexTable.Cell>
                                <IndexTable.Cell>
                                  <Text variant="bodyMd" fontWeight="regular" as="span">{val.data ? val.data : '-'}</Text>
                                </IndexTable.Cell>
                                <IndexTable.Cell>
                                  <Button icon={DeleteIcon} variant='monochromePlain' onClick={() => props.handleDelCondition(val)}></Button>
                                </IndexTable.Cell>
                              </IndexTable.Row>
                            )
                          })}
                        </IndexTable>

                      </Card>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
          </Box>
        </Card>
      }

      {/* <Card padding={'0'}>
        <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
          <Text variant='bodyLg' fontWeight='medium' as='h1'>Schedule requests</Text>
        </Box>
        <Divider borderColor='border-tertiary' />
        <Box padding={400}>
          <InlineGrid columns="1fr auto" alignItems='start'>
            <InlineStack gap='200' align='start' blockAlign='start' direction={'row'}>
              <Box paddingBlockStart={100}>
                <Text variant='headingSm' fontWeight='regular' as='h6'>
                  {state.emailTyp === 'on_order_fulfill' ?
                    `Send the review request after  ${state.ddValue || ''} days.`
                    : state.emailTyp === 'on_followup' ?
                      `Follow-up requests and reminders for reviews are sent after ${state.ddValue || ''} days.`
                      :
                      `Follow-up requests and reminders for media upload are sent after ${state.ddValue || ''} days.`
                  }
                </Text>
              </Box>
              <TextField
                id='afterdayinput'
                type='number'
                suffix='days'
                value={state.ddValue}
                onChange={(e) => props.changeNameValue({ ddValue: parseInt(e) }, 'isShowMessage')}
                error={state.errMessage.daysField ? state.errMessage.daysField : false} />
              {props.validator.message('daysField', state.ddValue, 'required|numeric|min:1,num|max:60,num')}
            </InlineStack>
            <Button id='primary_btn' onClick={() => props.updDaysField()}>Save</Button>

            {state.emailTyp === 'media_request' && state?.configStateData?.dd && state?.configStateData?.dd !== state?.ddValue && <Text as='p' id='space_nowRap' tone='critical'>Save your changes !</Text>
            }
          </InlineGrid>

        </Box>
      </Card> */}

    </div >
  );
}

export default AdvanceFilter;