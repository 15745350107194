import { Text, Box, ButtonGroup, Button, Divider, PageActions, InlineStack, Spinner, Modal } from '@shopify/polaris';
import { DesktopIcon, MobileIcon, ReplayIcon } from '@shopify/polaris-icons';
import Parser from 'html-react-parser';
import Constant from '../../services/constant.json';

const PreviewCard = ({
  state,
  changeNameValue,
  openCloseModal,
  callResetApi
}) => {
  let tempHTML = '';
  if (state.htmltmpl) {
    tempHTML = state.htmltmpl;

    let logoImg = (localStorage.getItem('lg') && localStorage.getItem('lg') !== null) ?
      'https://storage.googleapis.com/shopify_review_app/user_media/' + localStorage.getItem('lg') + '?q=' + new Date().getTime()
      : Constant.LOGO_IMG;

    if (tempHTML) {
      tempHTML = tempHTML.replace(/{{IS_REQUIRED}}/gi, 'required');
      tempHTML = tempHTML.replace(/{{MESSAGE}}/gi, state.emailObj.msg);
      tempHTML = tempHTML.replace(/{{PRODUCT_IMG}}/gi, Constant.P_IMG);
      tempHTML = tempHTML.replace(/{{RATING_TEXT}}/gi, state.emailObj.rtngtxt);
      tempHTML = tempHTML.replace(/{{REVIEW_REPLY}}/gi, Constant.REVIEW_REPLY);
      tempHTML = tempHTML.replace(/{{DSPLY_REVIEW_TTL}}/gi, state.emailObj.irttl === true ? 'revert' : 'none');
      tempHTML = tempHTML.replace(/{{REVIEW_TTL_TEXT}}/gi, state.emailObj.rttltxt);
      tempHTML = tempHTML.replace(/{{DSPLY_REVIEW_DESC}}/gi, state.emailObj.irdsc === true ? 'revert' : 'none');
      tempHTML = tempHTML.replace(/{{REVIEW_DESC_TEXT}}/gi, state.emailObj.rdsctxt);
      tempHTML = tempHTML.replace(/{{SUBMIT_BTN_TXT}}/gi, state.emailObj.rbtntxt);
      tempHTML = tempHTML.replace(/{{FOOTER_TEXT_LINE}}/gi, state.emailObj.clstmnt);
      tempHTML = tempHTML.replace(/{{PRODUCT_NAME}}/gi, `<a href='#' target='_blank'>${Constant.P_NAME}</a>`);
      tempHTML = tempHTML.replace(/{{CUSTOMER_NAME}}/gi, Constant.C_NAME);
      tempHTML = tempHTML.replace(/{{STORE_NAME}}/gi, localStorage.getItem('bn') || 'Store');
      tempHTML = tempHTML.replace(/{{PRODUCT_LIST}}/gi, state.htmlcrd ? state.htmlcrd : '');
      tempHTML = tempHTML.replace(/{{PRODUCT_URL}}/gi, '#');
      tempHTML = tempHTML.replace(/{{PRODUCT_NM}}/gi, Constant.P_NAME);
      tempHTML = tempHTML.replace(/{{PRODUCT_IMG_URL}}/gi, Constant.P_IMG);
      tempHTML = tempHTML.replace(/{{REVIEW_TITLE}}/gi, Constant.R_TITLE);
      tempHTML = tempHTML.replace(/{{REVIEW_DESCRIPTION}}/gi, Constant.R_DESC);
      tempHTML = tempHTML.replace(/{{REVIEW_DESC}}/gi, Constant.REVIEW_DESC);
      tempHTML = tempHTML.replace(/{{REPLAY}}/gi, Constant.REPLAY);
      tempHTML = tempHTML.replace(/{{WISERNOTIFY_URL}}/gi, 'wisernotify.com');
      tempHTML = tempHTML.replace(/{{DISCOUNT_CODE}}/gi, 'THANKYOU10');
      tempHTML = tempHTML.replace(/{{DISCOUNT_VALUE}}/gi, '10%');
      tempHTML = tempHTML.replace(/{{REVIEW_RATING}}/gi, '★ ★ ★ ★ ★');
      tempHTML = tempHTML.replace(/{{EMAIL_LOGO}}/gi, logoImg);
    }
  }

  return (
    <div className='cstm_card' style={{ background: '#f7f3ff', boxShadow: 'none' }}>
      <div style={{ background: '#fff' }}>

        <Box padding={400} paddingBlockEnd={300}>
          <InlineStack align="space-between" gap={300}>
            <Box>
              <Text variant='headingMd' as='h3' fontWeight='medium'>Preview</Text>
              <Box paddingBlockStart={200}>
                <Text>It shows example email data</Text>
              </Box>
            </Box>

            <ButtonGroup>
              <Button id='reset_btn' icon={ReplayIcon}
                onClick={() => openCloseModal('resetOpen', state.resetOpen, 'open')}>Reset</Button>
              <ButtonGroup variant="segmented">
                <Button variant={state.isMobile ? 'secondary' : ''}
                  icon={DesktopIcon} id={state.isMobile ? '' : 'primry_clr'}
                  onClick={() => changeNameValue({ isMobile: false })}>Web</Button>
                <Button variant={state.isMobile ? '' : 'secondary'}
                  icon={MobileIcon} id={state.isMobile ? 'primry_clr' : ''}
                  onClick={() => changeNameValue({ isMobile: true })}>Mobile</Button>
              </ButtonGroup>
            </ButtonGroup>
          </InlineStack>
        </Box><Divider borderColor='border-tertiary' />
      </div>

      <div className={state.isMobile ? 'preview-mail scroll_card mobi_card' : 'preview-mail scroll_card'} >
        <Box align='center'>
          {tempHTML ?
            Parser(tempHTML)
            :
            <Spinner accessibilityLabel="Small spinner example" size="small" />
          }
        </Box>
      </div>

      <Modal //reset design model   
        size='small'
        open={state.resetOpen}
        onClose={(e) => openCloseModal('resetOpen', state.resetOpen, e)}
        title={<Text variant='headingMd' as='h3' fontWeight='bold'>Reset design</Text>}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='semibold' variant='headingMd' as='h5'>Are you sure to reset design?</Text>
            <PageActions
              primaryAction={<Button id='primary_btn' onClick={() => callResetApi()}>Yes</Button>}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('resetOpen', state.resetOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>
    </div >
  );
}
export default PreviewCard;