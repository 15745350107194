import {
  IndexTable, Text, Card, Page, Button, Modal, Box, Select, DropZone, TextField, ButtonGroup, InlineStack, Link, InlineGrid
} from '@shopify/polaris';
import React, { memo } from 'react';
const opt = { day: 'numeric', month: 'long', year: 'numeric' };

function List(props) {

  let importSection =
    <div>
      <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }} alignItems='end' gap={200}>
        <TextField
          label="Enter import task name"
          value={props.state.sourceSelect}
          error={props.state.sourceSelectErr}
          onChange={(val) => props.changeNameValue({ sourceSelect: val })} />

        <InlineStack gap={200} blockAlign='end' id='sdasd'>
          <DropZone id='csvFile' error={props.state.fileErr} accept='.csv' type='file' variableHeight={true} outline={false} onDrop={props.handleDropZoneDrop}>
            <Button size='large'>
              {props.state.files.length > 0 ? props.state.files.map((file, i) => {
                return (
                  <div key={i}>
                    <div style={{ width: "100px", overflow: "hidden" }}>
                      <Text id='upload-file-name' variant="bodySm" fontWeight='medium' as="h6">{file.name}</Text>
                    </div>
                    {/* <Text variant="bodySm" as="p"> {file.size} bytes</Text> */}
                  </div>
                )
              }) : 'Select file..'}
            </Button>
          </DropZone>
          <Button id='primary_btn' onClick={() => props.handleFileUpload()}>Upload & continue</Button>
        </InlineStack>
      </InlineGrid>

      <Box paddingBlockStart={100} paddingBlockEnd={600}>
        <Text variant="headingSm" as="p" fontWeight='regular' tone='subdued'>You can download example file <Link target='_blank' url="https://docs.google.com/spreadsheets/d/1_bf47-2o6Mb5hFc484IBQNn6-ZMC7hnndSSIwQlrwrY/edit#gid=0">here</Link></Text>
      </Box>

      <IndexTable
        itemCount={props.ImportData ? props.ImportData.length : 0}
        selectable={false}
        // loading={props.loadingState}
        headings={[
          { title: <Text as='p' fontWeight='medium' tone='subdued'>Source name</Text> },
          { title: <Text as='p' fontWeight='medium' tone='subdued'>Date</Text> }
        ]}>

        {props.ImportData && props.ImportData.length > 0 && props.ImportData.map((data, index) => (
          <IndexTable.Row
            id={data._id}
            key={index}
            position={index}>
            <IndexTable.Cell>
              <Text variant="bodyMd" fontWeight="medium" as="span">{data.src ? data.src : '-'}</Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text variant="bodyMd" fontWeight="medium" as="span">{(new Date(data.cdt)).toLocaleDateString("en-US", opt)}</Text>
            </IndexTable.Cell>
          </IndexTable.Row>
        ))}
      </IndexTable>
    </div>;

  return (
    <div>
      {window.location.pathname === '/import' ?
        <Page
          title={"Import"}>
          <Card>
            {importSection}
          </Card>
        </Page>
        :
        importSection
      }

      <Modal
        size='large'
        open={props.state.uploadOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Field mapping</Text>}
        onClose={props.openCloseUpload}>
        <div className='fieldMapp_modal'>
          <Modal.Section>
            <Text variant="headingSm" fontWeight="regular" as="h6">Select columns from your CSV file to map against review fields, or to ignore during import. Note: Custom Forms columns will not be displayed below, but will also be imported automatically</Text>

            <Box paddingBlockStart={400} paddingBlockEnd={400}>
              <Card>
                <IndexTable
                  itemCount={props.state.csvData ? props.state.csvData.length : 0}
                  headings={[
                    { title: <Text variant="bodyMd" fontWeight="medium" as="span">Review field</Text>, id: 'revField' },
                    { title: <Text variant="bodyMd" fontWeight="medium" as="span">CSV column name</Text>, id: 'csvName' },
                  ]}
                  selectable={false}>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Title</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'rttl')}
                        value={props.headerData.rttl}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Review Text</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'rtxt')}
                        value={props.headerData.rtxt}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Rating<span className='srv-validation-message'>*</span>  </Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'rtng')}
                        value={props.headerData.rtng}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Review Date </Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'cdt')}
                        value={props.headerData.cdt}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Reviwer name<span className='srv-validation-message'>*</span></Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'un')}
                        value={props.headerData.un}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Reviwer email </Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'e')}
                        value={props.headerData.e}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Product id </Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'pid')}
                        value={props.headerData.pid}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Product name</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'pn')}
                        value={props.headerData.pn}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Product url</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'pu')}
                        value={props.headerData.pu}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Product image url</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'piu')}
                        value={props.headerData.piu}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Review image url</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'imgurl')}
                        value={props.headerData.imgurl}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Review video url</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'vdourl')}
                        value={props.headerData.vdourl}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">City </Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'ct')}
                        value={props.headerData.ct}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">State</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'st')}
                        value={props.headerData.st}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Country</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'cn')}
                        value={props.headerData.cn}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">IP </Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'i')}
                        value={props.headerData.i}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Reply </Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'rplytxt')}
                        value={props.headerData.rplytxt}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>


                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Reply date </Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'rplydt')}
                        value={props.headerData.rplydt}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Custom field 1</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'cst1')}
                        value={props.headerData.cst1}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Custom field 2</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'cst2')}
                        value={props.headerData.cst2}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                  <IndexTable.Row>
                    <IndexTable.Cell>
                      <Text variant="bodyMd" fontWeight="medium" as="h6">Custom field 3</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Select
                        options={props.state.csvData[0]}
                        onChange={(e) => props.handleSelectCsvCol(e, 'cst3')}
                        value={props.headerData.cst3}
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                </IndexTable>
              </Card>
            </Box>

            <InlineStack align="end">
              <ButtonGroup>
                <Button onClick={() => props.openCloseUpload('close')}>Cancel</Button>
                <Button id='primary_btn' onClick={() => props.handleMappNext()}>Next</Button>
              </ButtonGroup>
            </InlineStack>
          </Modal.Section>
        </div>
      </Modal>

    </div>
  );
}

export default memo(List);