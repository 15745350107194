import { Badge, Page, Button, Box, Tooltip, InlineStack, Modal, PageActions, IndexTable, TextField, Text, Checkbox, Card, Divider, Icon, Collapsible, List, BlockStack, Banner, Select, Spinner, } from '@shopify/polaris';
import { DeleteIcon, ClipboardIcon, ChannelsIcon } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';
import { useCallback, useState, useEffect } from 'react';
import { toastify } from '../../ducks/toast';
import copy from 'copy-to-clipboard';
import moment from 'moment';

let _ia = false;

const WebhookAutoTrigger = ({ props }) => {
    const [value, setValue] = useState({
        errMessage: {},
        CustomWebhookCard: [],
        rmk: '',//Name your connection
        dataId: '',//delete id
        ia: false,//for toggle on/off
        deleteDataModal: false,//open delete modal
        modalC: false,//condition for delete modal
        notifListData: "",//show data in modal

        viewDataModal: false,//open view modal
        mapId: '',//map id
        isloading: false,
        wfkey: '',// keyword
        cfkey: '',//key&value
        optionKeyword: [],//keyword-list
        optionrvcKeyVal: [],//key-value list
        CWFInterval: '',//interval
        rmkName: '',//rmk name
        Endpoint: '',//endpoint
    });
    const { state, validator, handleTextChange, handleBackToMain } = props;
    const dispatch = useDispatch();

    const getCustomWebhookData = useSelector(state => state.integration.cutmWebhookData);

    const custWBFieldData = useSelector(state => state.integration?.custWBField);

    let resp = custWBFieldData?.resp;

    let findAmPData = value?.CustomWebhookCard && value?.CustomWebhookCard.length > 0 && value?.CustomWebhookCard?.find((elm) => elm._id === value?.mapId);


    useEffect(() => {
        const arrayOp = [{ label: '-- Select Key/Value --', value: '' }];
        if (resp?.length > 0) {
            clearInterval(value.CWFInterval);
            resp?.length > 0 && resp?.map((elm) => { arrayOp.push({ label: elm, value: elm }) });
            changeNameCValue({ optionrvcKeyVal: arrayOp, isloading: false });
        }
        else if (value.viewDataModal === true) {
            changeNameCValue({ isloading: true })
            getCWFInt();
        }
        changeNameCValue({ CustomWebhookCard: getCustomWebhookData, optionrvcKeyVal: arrayOp });
    }, [getCustomWebhookData, custWBFieldData])

    // if (state.currUserInt && state.currUserInt.ia !== undefined) { //for isActive
    //     _ia = state.currUserInt.ia;
    // }
    // if (state.newDObj && Object.keys(state.newDObj).length <= 0) //set active on create
    //     _ia = true;

    const changeNameCValue = useCallback((obj) => {
        setValue((prevState) => ({ ...prevState, ...obj }));
    }, []);

    const openCloseModal = useCallback((name, value, typ) => {
        if (typ !== undefined) changeNameCValue({ [name]: !value });
    }, [changeNameCValue]);

    useEffect(() => {
        dispatch(integrationDucks.getCustomWebHook());
    }, [])

    // create btn
    const handleCreateBtn = () => {
        if (validator.errorMessages.rmk) {
            validator.showMessages();
            validator.helpers.forceUpdateIfNeeded();
            changeNameCValue({ 'errMessage': validator.errorMessages });
        }
        else {
            changeNameCValue({ errMessage: {} });
            const obj = {
                rmk: value.rmk
            };
            dispatch(integrationDucks.createCustomWebHook(obj));
            changeNameCValue({ rmk: '', errMessage: {} });
        }
    }

    //copy endpoint
    const copyEndpoint = (url) => {
        copy(url, { debug: true });
        dispatch(toastify({ type: 'success', msg: 'Endpoint copied' }));
        return;
    }

    //update toggle button
    const handleIsActive = (item) => {
        let obj = {
            id: item._id,
            ia: !item.ia
        };
        dispatch(integrationDucks.updCustHookStatus(obj));
        const updatedList = value.CustomWebhookCard?.map((elm) =>
            elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
        );
        changeNameCValue({ CustomWebhookCard: updatedList })
    };

    //open delete modal
    const handledeleteModal = (id) => {
        let data = state.notiflist?.filter(x => x._id === id);
        if (data && data.length > 0) {
            changeNameCValue({ modalC: true, notifListData: data[0].data.toString() })
        }
        else {
            changeNameCValue({ modalC: false })
        }
        openCloseModal('deleteDataModal', value.deleteDataModal, 'open');
        changeNameCValue({ dataId: id, })
    }

    //delete api call 
    const handleDeleteCall = (id) => {
        dispatch(integrationDucks.deleteCustomWebhook(id));
        openCloseModal('deleteDataModal', value.deleteDataModal, 'close');
    }

    //Object For WiserNotify Key
    let objk = {
        e: 'Email',
        phn: 'Phone',
        pn: 'Product Title',
        pu: 'Product URL',
        piu: 'Product Image URL',
        fn: 'First Name',
        ln: 'Last Name',
        pid: 'Product Id',
        rvwrimg: 'Review Image',
        rvwrimgurl: 'Review Image URL'
    };

    //open view modal
    const handleModal = (id, rmk, wbURL) => {
        keyWordFilter(id);
        changeNameCValue({ mapId: id, cfkey: "", wfkey: "", rmkName: rmk, Endpoint: wbURL });
        openCloseModal('viewDataModal', value.viewDataModal, 'open');
        dispatch(integrationDucks.getCustomWebhookField({ usind: id }));
    }

    // mapping listed keyword remove 
    const keyWordFilter = (id) => {
        let filterKeyWord = value?.CustomWebhookCard?.find((elm) => elm._id === id);

        filterKeyWord?.amp?.map((value) => {
            const keys = Object.keys(value)[0];
            for (const key1 in objk) {
                if (objk[key1] === keys) {
                    delete objk[key1];
                }
            }
        });
        let arrayOp = [];

        for (const key in objk) {
            if (objk.hasOwnProperty(key)) {
                arrayOp.push({ [key]: objk[key] });
            }
        }
        let optionKeyword = [];
        optionKeyword = arrayOp?.map((elm) => { return { label: elm[Object.keys(elm)[0]], value: elm[Object.keys(elm)[0]] } })
        optionKeyword.unshift({ label: "-- Select Keyword --", value: '' })
        changeNameCValue({ optionKeyword })
    }

    // view modal delete field
    const handleDeleteMapField = (val) => {
        const obj = {
            fuid: value.mapId,
            key: val
        };
        dispatch(integrationDucks.delCustomWebhookFieldUint(obj));
        const oldOption = value.optionKeyword;
        oldOption.push({ label: val, value: val });
        changeNameCValue({ optionKeyword: oldOption });
    }


    //Interval function for get custom webhook field
    const getCWFInt = () => {
        clearInterval(value.CWFInterval);
        let CWFInterval = setInterval(function () {
            dispatch(integrationDucks.getCustomWebhookField({ usind: value.mapId }));
        }, 5000);
        changeNameCValue({ CWFInterval })
    }

    //start listening btn
    const handleStartListening = () => {
        getCWFInt();
        dispatch(integrationDucks.updCustField({ fuid: value.mapId }));
    }

    //Add button 
    const handleAddField = () => {
        if (value.wfkey !== '' && value.cfkey !== '') {
            const obj = {
                "fuid": value.mapId,
                "wfkey": value.wfkey,
                "cfkey": value.cfkey
            }
            dispatch(integrationDucks.createCustomWebhookFieldUint(obj));
            let optionfilter = value.optionKeyword.filter((elm) => elm.label !== value.wfkey)
            changeNameCValue({ wfkey: '', cfkey: '', optionKeyword: optionfilter });
        }
    }

    return (
        <Page
            title={(state.currMasterObj && state.currMasterObj.dn) && state.currMasterObj.dn}
            subtitle={
                <Text tone='base' >
                    {state.currMasterObj.dsc ? state.currMasterObj.dsc : '-'}
                </Text>}
            backAction={{ onAction: () => handleBackToMain() }}>

            <Box paddingInline={800}>
                <BlockStack gap={400}>
                    <Card>
                        <Box paddingBlockEnd={200}>
                            <Text variant='headingMd' as='p' fontWeight='semibold'>Webhook setup</Text>
                        </Box>
                        <BlockStack gap={400}>
                            <Text variant='headingSm' as='p' fontWeight='semibold'>Connect your app:</Text>
                            <Box>
                                <List type="bullet">
                                    <List.Item>Create a connection</List.Item>
                                    <List.Item>After creating the connection, you will start receiving the new reviews.</List.Item>
                                </List>
                            </Box>

                            <Box>
                                <BlockStack gap={200}>
                                    <div className='form-width'>
                                        <InlineStack blockAlign="center" >
                                            <div className='textfeild-lable'>
                                                <Tooltip preferredPosition='below' content='Name your connection' hasUnderline width='wide' padding="400" dismissOnMouseOut >
                                                    <Text variant="headingSm" as="h6">Name your connection</Text>
                                                </Tooltip>
                                            </div>
                                            <div className='textfeild-box'>
                                                <TextField
                                                    value={value.rmk}
                                                    onChange={(e) => { handleTextChange('rmk', e); changeNameCValue({ 'rmk': e }) }}
                                                    placeholder='Name your connection'
                                                    autoComplete="off"
                                                    error={state.errMessage.rmk ? state.errMessage.rmk : false} />
                                                {validator.message('rmk', value.rmk, 'required|required')}
                                            </div>
                                        </InlineStack>
                                    </div>
                                </BlockStack>

                                <Box align='center' padding={300}>
                                    <Button variant="primary" tone='success' onClick={() => handleCreateBtn()}>Create</Button>
                                </Box>
                            </Box>
                            <Box padding={0}>
                                <InlineStack align='space-between'>
                                    <Box paddingBlockEnd={200} paddingBlockStart={100}>
                                        <Text variant="headingMd" fontWeight='bold' as="h6">{"Account details"}</Text>
                                    </Box>
                                </InlineStack>
                                <Card padding={0}>
                                    <IndexTable
                                        itemCount={value.CustomWebhookCard?.length || 0}
                                        headings={[
                                            { title: '#' },
                                            { title: 'Name' },
                                            { title: 'Endpoint' },
                                            { title: 'Created date' },
                                            { title: 'Active' },
                                            { title: 'Action' },
                                        ]}
                                        selectable={false}
                                    >
                                        {value?.CustomWebhookCard && value?.CustomWebhookCard?.length > 0 && (
                                            value?.CustomWebhookCard.map((i, index) => {
                                                let wbURL = i.d?.web_url;
                                                if (props.isWhite === true) {
                                                    wbURL = wbURL.replace('https://is.wisernotify.com', window.location.origin);
                                                }
                                                return (
                                                    <IndexTable.Row key={index}>
                                                        <IndexTable.Cell>
                                                            <Text variant="bodyMd" fontWeight="bold" as="span">
                                                                {index + 1}
                                                            </Text>
                                                        </IndexTable.Cell>
                                                        <IndexTable.Cell>{i.rmk}</IndexTable.Cell>
                                                        <IndexTable.Cell>
                                                            <span className='text-break amazon-url'>
                                                                {wbURL ? wbURL.slice(0, 30) + '...' : ''}
                                                            </span>
                                                        </IndexTable.Cell>
                                                        <IndexTable.Cell>
                                                            {moment(new Date(i.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}
                                                        </IndexTable.Cell>
                                                        <IndexTable.Cell>
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={i.ia}
                                                                    onChange={() => handleIsActive(i)}
                                                                />
                                                                <span className="switch-label"></span>
                                                                <span className="switch-handle"></span>
                                                            </label>
                                                        </IndexTable.Cell>
                                                        <IndexTable.Cell>
                                                            <InlineStack>
                                                                <Box paddingInline={100}>
                                                                    <Tooltip preferredPosition='below' content="Copy endpoint" hasUnderline width="wide" padding="400" dismissOnMouseOut>
                                                                        <div id="delete" onClick={() => copyEndpoint(wbURL)}>
                                                                            <Banner hideIcon tone='value'>
                                                                                <Icon source={ClipboardIcon} tone="value" />
                                                                            </Banner>
                                                                        </div>
                                                                    </Tooltip>
                                                                </Box>
                                                                <Box paddingInline={100}>
                                                                    <div id="delete" onClick={() => handleModal(i._id, i.rmk, wbURL)}>
                                                                        <Banner hideIcon tone='success'>
                                                                            <Icon source={ChannelsIcon} tone="success" />
                                                                        </Banner>
                                                                    </div>
                                                                </Box>
                                                                <Box paddingInline={100}>
                                                                    <div id="delete" onClick={() => handledeleteModal(i._id)}>
                                                                        <Banner hideIcon tone='critical'>
                                                                            <Icon source={DeleteIcon} tone="critical" />
                                                                        </Banner>
                                                                    </div>
                                                                </Box>
                                                            </InlineStack>
                                                        </IndexTable.Cell>
                                                    </IndexTable.Row>
                                                );
                                            })
                                        )}
                                    </IndexTable>
                                </Card>
                            </Box>
                        </BlockStack>
                    </Card>
                </BlockStack>

                {/* delete data modal  */}
                <Modal
                    open={value.deleteDataModal}
                    title={<Text variant='headingMd' fontWeight='bold'>{"Are you sure want to delete this record?"}</Text>}
                    onClose={(e) => { openCloseModal('deleteDataModal', value.deleteDataModal, e); changeNameCValue({ apiD: false }); }}>
                    <Modal.Section>
                        <Box paddingBlockEnd={400}>
                            <Text variant="bodyMd" as="p">{"By clicking yes, you will lose the current record."}</Text>
                        </Box>
                        <Divider />
                        <PageActions
                            primaryAction={{
                                icon: DeleteIcon, content: "Yes, delete record", tone: 'critical',
                                onAction: () => { handleDeleteCall(value.dataId) }
                            }}
                            secondaryActions={[
                                {
                                    content: "Cancel",
                                    onAction: () => { openCloseModal('deleteDataModal', value.deleteDataModal, 'close') }
                                }
                            ]}
                        />
                    </Modal.Section>
                </Modal>

                {/* view modal  */}
                <Modal
                    open={value.viewDataModal}
                    title={<Text variant='headingMd' fontWeight='bold'>{value.rmkName} - Field mapping setup</Text>}
                    onClose={(e) => { openCloseModal('viewDataModal', value.viewDataModal, e); clearInterval(value.CWFInterval); }}>
                    <Modal.Section>
                        <Box paddingBlockEnd={400} id="modal-in-modal">
                            <Box paddingBlockEnd={200}>
                                <TextField
                                    value={value.Endpoint ? value.Endpoint.slice(0, 70) : value.Endpoint}
                                    label={<Text variant="headingSm" fontWeight="bold">Click to copy webhook URL</Text>}
                                    suffix={
                                        <Box padding={100}>
                                            <Tooltip preferredPosition='below' content="Copy endpoint" hasUnderline width="wide" padding="400" dismissOnMouseOut>
                                                <Button
                                                    variant='primary'
                                                    tone='success'
                                                    icon={ClipboardIcon}
                                                    onClick={() => copyEndpoint(value.Endpoint)}
                                                >
                                                    Copy
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    }
                                />
                            </Box>
                            <Text variant="headingSm" fontWeight="bold">Action required:</Text>
                            <Box paddingBlockStart={100}>
                                <List type="bullet">
                                    <List.Item>Send sample data from your third-party platform to the webhook URL provided.</List.Item>
                                    <List.Item>Map the received fields to WiserNotify keywords. Once mapped, all future data will be automatically saved and ready for notifications.</List.Item>
                                </List>
                            </Box>
                            <Box paddingBlockStart={0}>
                                <Text variant="headingSm" fontWeight="bold">Status:</Text>
                                <Box paddingBlockStart={100}>
                                    <InlineStack gap={200} blockAlign='center'>
                                        <Button variant="primary" tone="success" onClick={() => handleStartListening()} disabled={value.isloading === true ? true : false} >Start listening</Button>
                                        {value.isloading && <Spinner accessibilityLabel="Small spinner example" size="small" />}
                                    </InlineStack>
                                    {!value.isloading && (
                                        <Box paddingBlockStart={100}>
                                            <Text>Click on start listening & post a data on webhook URL from your third party platform. </Text>
                                        </Box>
                                    )}
                                    {value.isloading && (
                                        <Box paddingBlockStart={100}>
                                            <Text>Please post sample data on the webhook URL from your third party platform. </Text>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Box paddingBlockStart={100}>
                                <Text variant="headingSm" fontWeight="bold">Mapping setup</Text>
                                <InlineStack gap={400} blockAlign={'Stretch'} wrap={false}>
                                    <Box width='43%'>
                                        <Select
                                            label="Select default keyword"
                                            options={value.optionKeyword}
                                            onChange={(e) => changeNameCValue({ wfkey: e })}
                                            value={value.wfkey}
                                        />
                                    </Box>
                                    <Box width='43%'>
                                        <Select
                                            label="Select received key-value"
                                            options={value.optionrvcKeyVal}
                                            onChange={(e) => changeNameCValue({ cfkey: e })}
                                            value={value.cfkey}
                                        />
                                        <Box paddingBlockStart={200}>
                                            <Text>{!value.isloading && <Badge tone="success">Received data successfully!</Badge>}</Text>
                                        </Box>
                                    </Box>
                                    <Box paddingBlockStart={600}>
                                        <Button variant="primary" size="large" onClick={() => handleAddField()}>Add</Button>
                                    </Box>
                                </InlineStack>
                            </Box>
                        </Box>
                        <Card padding={0}>
                            <IndexTable
                                itemCount={findAmPData?.amp?.length || 0}
                                headings={[
                                    {
                                        title: <Text variant='headingSm' fontWeight='bold'>No</Text>
                                    },
                                    {
                                        title: <Text variant='headingSm' fontWeight='bold'>Key</Text>
                                    },
                                    {
                                        title: <Text variant='headingSm' fontWeight='bold'>Value</Text>
                                    },
                                    {
                                        title: <Text variant='headingSm' fontWeight='bold'>Action</Text>
                                    }
                                ]}
                                selectable={false}
                            >
                                {findAmPData && findAmPData?.amp?.length > 0 && findAmPData?.amp?.map((data, i) => (
                                    <>
                                        <IndexTable.Row key={i}>
                                            <IndexTable.Cell>
                                                <Text variant="bodyMd" fontWeight="bold" as="span">
                                                    {i + 1}
                                                </Text>
                                            </IndexTable.Cell>
                                            <IndexTable.Cell>{Object.keys(data)[0]}</IndexTable.Cell>
                                            <IndexTable.Cell>{data[Object.keys(data)[0]]}</IndexTable.Cell>
                                            <IndexTable.Cell>
                                                <div id="delete" onClick={() => handleDeleteMapField(Object.keys(data)[0])}>
                                                    <Banner hideIcon tone='critical'>
                                                        <Icon
                                                            source={DeleteIcon}
                                                            tone="critical"
                                                        />
                                                    </Banner>
                                                </div>
                                            </IndexTable.Cell>
                                        </IndexTable.Row>
                                    </>
                                ))}
                            </IndexTable>
                        </Card>
                    </Modal.Section>
                </Modal>
            </Box>
        </Page >
    );
}

export default WebhookAutoTrigger;