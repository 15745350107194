import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/myprofile';
import { toastify } from '../ducks/toast';
import * as actionProfile from '../ducks/auth';

function* updProfile(action) {
    try {
        const res = yield call(api.POST, '/updProfile', action.payload);
        if (res.status === 'success') {
            yield put(toastify({ type: 'success', msg: res.m }));
            yield put(actionProfile.getProfile())
        }
        else {
            yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please refresh page.' }));
        }
    } catch (error) {
        console.log(error, '====error');
    }
}

function* updProfileImg(action) {
    console.log(action.payload, '====action.payload');
    try {
        yield put(load());
        const res = yield call(api.POST, '/updProfileImg', action.payload);

        if (res.status === 'success') {
            yield put(toastify({ type: 'success', msg: res.m }));
            yield put(actionProfile.getProfile())
        }
        else {
            yield put(toastify({ type: 'error', msg: res.m }));
        }
        yield put(loaded());
    } catch (error) {
        yield put(loaded());
        yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
    }
}

function* cPwd(action) {
    console.log(action, '====action');
    try {
        yield put(load());
        const res = yield call(api.POST, '/cPwd', action.payload);

        if (res.status === 'success') {
            yield put(toastify({ type: 'success', msg: res.m }));
            yield put(actionProfile.getProfile())
        }
        else {
            yield put(toastify({ type: 'error', msg: res.m }));
        }
        yield put(loaded());
    } catch (error) {
        console.log(error, '==>error')
        yield put(loaded());
        yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
    }
}


export function* watchUpdProfile() {
    yield takeLatest(actions.updateProfile.type, updProfile);
}

export function* watchUpdProfileImg() {
    yield takeLatest(actions.updProfileImg.type, updProfileImg);
}

export function* watchCPwd() {
    yield takeLatest(actions.cPwd.type, cPwd);
}

export default function* rootSaga() {
    yield fork(watchUpdProfile);
    yield fork(watchUpdProfileImg);
    yield fork(watchCPwd);
}