import { Text, Modal, Divider, Box, ButtonGroup, PageActions, Button, InlineStack, Card } from '@shopify/polaris';
import { DesktopIcon, MobileIcon, ReplayIcon } from '@shopify/polaris-icons';
import { sidebarBg, sidebarMobiBg } from '../../img';

const PreviewCard = ({
  state,
  changeNameValue,
  openCloseModal,
  callResetApi
}) => {
  let widgetData = localStorage.getItem('WS_PRVW_WIDGET');
  let _typ = localStorage.getItem('WS_PRVW_TYP');

  if (state.currAllData && widgetData !== 'undefined') {
    let previewWidget = JSON.parse(widgetData);

    if (previewWidget && previewWidget !== undefined) {
      if (state.widgetTyp === 'que_ans') {
        if (state.currAllData && state.currAllData.currQueLayoutId)
          previewWidget['htn'] = state.currAllData.currQueLayoutId;
        if (state.currAllData && state.currAllData.currQueCardId)
          previewWidget['htncrd'] = state.currAllData.currQueCardId;
        if (state.currAllData && state.currAllData.currQueWriteId)
          previewWidget['htnwrtfrm'] = state.currAllData.currQueWriteId;
        if (state.currAllData && state.currAllData.currQuePopId)
          previewWidget['htnpopup'] = state.currAllData.currQuePopId;
      }
      else {
        if (state.currAllData && state.currAllData.currLayoutId)
          previewWidget['htn'] = state.currAllData.currLayoutId;
        if (state.currAllData && state.currAllData.currCardId)
          previewWidget['htncrd'] = state.currAllData.currCardId;
        if (state.currAllData && state.currAllData.currWriteId)
          previewWidget['htnwrtfrm'] = state.currAllData.currWriteId;
        if (state.currAllData && state.currAllData.currPopupId)
          previewWidget['htnpopup'] = state.currAllData.currPopupId;
      }

      if (state.currAllData && state.currAllData.currLayData && state.currAllData.currLayData.html)
        previewWidget['htmlLayout'] = state.currAllData.currLayData.html;
      if (state.currAllData && state.currAllData.currCardData && state.currAllData.currCardData.html)
        previewWidget['htmlCard'] = state.currAllData.currCardData.html;
      if (state.currAllData && state.currAllData.currFormData && state.currAllData.currFormData.html)
        previewWidget['htmlWriteForm'] = state.currAllData.currFormData.html;
      if (state.currAllData && state.currAllData.currPopData && state.currAllData.currPopData.html)
        previewWidget['htmlPopup'] = state.currAllData.currPopData.html;

      if (_typ === 'main') {
        if (state.currAllData && state.currAllData.currQueLayData && state.currAllData.currQueLayData.html)
          previewWidget['htmlLayoutQueAns'] = state.currAllData.currQueLayData.html;
        if (state.currAllData && state.currAllData.currQueCardData && state.currAllData.currQueCardData.html)
          previewWidget['htmlCardQueAns'] = state.currAllData.currQueCardData.html;
        if (state.currAllData && state.currAllData.currQueWriteData && state.currAllData.currQueWriteData.html)
          previewWidget['htmlWriteFormQueAns'] = state.currAllData.currQueWriteData.html;
        if (state.currAllData && state.currAllData.currQuePopData && state.currAllData.currQuePopData.html)
          previewWidget['htmlPopupQueAns'] = state.currAllData.currQuePopData.html;
      }

      localStorage.setItem('WS_PRVW_WIDGET', JSON.stringify(previewWidget));
    }
  }

  let noPddWdArr = ['main', 'que_ans', 'sidebar_cta', 'popup_widget', 'floating_badge'];

  return (
    <div className='sticky-preview'>
      <Card padding={0}>
        <Box paddingBlock={400} paddingInline={400}>
          <InlineStack align="space-between" blockAlign='center'>
            <Text variant='headingMd' as='h3' fontWeight='medium'>Preview</Text>

            <ButtonGroup>
              <Button id='reset_btn' icon={ReplayIcon}
                onClick={() => openCloseModal('resetOpen', state.resetOpen, 'open')}>Reset</Button>

              <ButtonGroup variant="segmented">
                <Button variant={state.isMobile === true ? 'secondary' : 'primary'} icon={DesktopIcon}
                  onClick={() => {
                    openCloseModal('webPreviewOpen', state.webPreviewOpen, 'open');
                    changeNameValue({ isMobile: false });
                  }}>Web</Button>

                <Button variant={state.isMobile === true ? 'primary' : 'secondary'} icon={MobileIcon}
                  onClick={() => changeNameValue({ isMobile: true })}>Mobile</Button>
              </ButtonGroup>
            </ButtonGroup>

          </InlineStack>
        </Box>
        <Divider />

        <div className="preview_hght">
          <Box padding={!noPddWdArr.includes(state.widgetTyp) ? '300' : '0'}>
            <div className='sidebar_wrapp'>
              <div className={state.wln === 'sidebar' ? 'sidebar_Mobiframe' : ''}>
                <iframe style={{ height: state.wln === 'Popup widget' ? '72vh' : state.wln === 'sidebar' ? '64vh' : '71vh' }} title="iframe" width="100%" height="610px" src='/preview.html' id="WSRSHPFRVWAPP_IFRAME" frameBorder="0"></iframe>
              </div>
              {state.wln === 'sidebar' &&
                <div className='sidebar_bg'>
                  <img src={sidebarMobiBg} />
                </div>
              }
            </div>
          </Box>
        </div>

      </Card >

      <Modal
        size='fullScreen'
        open={state.webPreviewOpen}
        onClose={(e) => openCloseModal('webPreviewOpen', state.webPreviewOpen, e)}
        title={<Text variant='headingMd' fontWeight='bold'>Desktop view</Text>}>
        <div className='sidebar_wrapp'>
          <div className={state.wln === 'sidebar' ? 'sidebar_frame' : ''}>
            <iframe style={{ height: '80vh' }} title="iframe" width="100%" height="610px" src='/preview.html' id="WSRSHPFRVWAPP_IFRAME" frameBorder="0"></iframe>
          </div>

          {state.wln === 'sidebar' &&
            <div className='sidebar_bg'>
              <img src={sidebarBg} />
            </div>
          }
        </div>
      </Modal >

      <Modal
        size='small'
        open={state.resetOpen}
        onClose={(e) => openCloseModal('resetOpen', state.resetOpen, e)}
        title={<Text variant='headingMd' as='h3' fontWeight='bold'>Reset design</Text>}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='regular' tone='subdued' variant='headingMd' as='h5'>Are you sure to reset design?</Text>
            <PageActions
              primaryAction={<Button id='primary_btn' onClick={() => callResetApi()}>Yes</Button>}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('resetOpen', state.resetOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>

    </div >
  );
}
export default PreviewCard;