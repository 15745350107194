import { Text, Checkbox, InlineGrid, Tooltip, Box, InlineStack, TextField, Badge } from '@shopify/polaris';
import { ThemeTemplateIcon, EyeCheckMarkIcon, ImageWithTextOverlayIcon, CodeIcon } from '@shopify/polaris-icons';
import ColorPick from '../ColorPick/ColorPick';
import { useEffect } from 'react';

const TextUGC = ({ props }) => {
  let { state, handleTextChange, changeNameValue } = props;
  let cnf = state.cnf;

  let verticalTabsArr = [
    { title: 'Theme layouts', img: ThemeTemplateIcon },
    { title: 'Reviewer cards', img: ImageWithTextOverlayIcon },
    { title: 'Color combination', img: EyeCheckMarkIcon },
    { title: 'Add custom css', img: CodeIcon }
  ];

  useEffect(() => {
    changeNameValue({ verticalTabsArr: verticalTabsArr });
  }, [state.newActiveTab]);

  return (
    <Box padding={200}>

      {state.sidebarActiveIndex === 0 ?
        <Box>

          <InlineGrid columns={2} gap={300}>
            {state.mainLayoutArr && state.mainLayoutArr.length > 0 && state.mainLayoutArr.map((val, i) => {
              return (
                <div key={i} onClick={() => changeNameValue({ currLayoutId: val._id }, 'isEditable')}
                  className={val._id === state.currLayoutId ? 'select_block active' : 'select_block'}>
                  <InlineStack align='center' blockAlign='center'>
                    <Checkbox tone={val._id === state.currLayoutId ? 'magic' : ''}
                      checked={val._id === state.currLayoutId} />
                    <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                      {val.dn}</Text>
                  </InlineStack>
                  <img className='border_NoneImg' src={val.iu} alt="Skeleton" />
                </div>
              );
            })}
          </InlineGrid>

          {cnf && cnf.ibrnd !== undefined &&
            <Box paddingBlockStart={300}>
              <InlineStack gap={200} blockAlign='center'>
                <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                  <Checkbox
                    disabled={state.isFreePln === true}
                    label={<Text fontWeight='medium' as='span'>Branding</Text>}
                    checked={cnf.ibrnd}
                    onChange={(e) => handleTextChange({ ibrnd: e })} />
                </Tooltip>
                <Box>
                  <Badge tone='warning'>Exclusive</Badge>
                </Box>
              </InlineStack>
            </Box>
          }
        </Box>
        :
        state.sidebarActiveIndex === 1 ?
          <Box>
            {cnf && cnf.ugcittl !== undefined &&
              <Box>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show title text</Text>}
                  checked={cnf.ugcittl}
                  onChange={(e) => handleTextChange({ ugcittl: e })}
                />
                <Box paddingBlockStart={100} paddingInlineStart={600}>
                  <TextField
                    disabled={!cnf.ugcittl}
                    value={cnf.ugcttltxt}
                    onChange={(e) => handleTextChange({ ugcttltxt: e })}
                    autoComplete="off" />
                </Box>
              </Box>
            }
            {cnf && cnf.ugcilkcnt !== undefined &&
              <Box paddingBlock={200}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show like count</Text>}
                  checked={cnf.ugcilkcnt}
                  onChange={(e) => handleTextChange({ ugcilkcnt: e })} />
              </Box>
            }

            {cnf && cnf.ugcicmntcnt !== undefined &&
              <Box paddingBlock={200}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show comment count</Text>}
                  checked={cnf.ugcicmntcnt}
                  onChange={(e) => handleTextChange({ ugcicmntcnt: e })} />
              </Box>
            }

            {cnf && cnf.ugcipsttxt !== undefined &&
              <Box paddingBlock={200}>
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show post text</Text>}
                  checked={cnf.ugcipsttxt}
                  onChange={(e) => handleTextChange({ ugcipsttxt: e })} />
              </Box>
            }
          </Box>
          :

          state.sidebarActiveIndex === 2 ?
            <Box>
              <Text variant="bodyMd" fontWeight='bold'>Reviewer card</Text>
              {cnf &&
                <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={2400}>
                  <InlineStack direction={'column'} gap={500}>
                    {cnf.ugcpsttxtclr !== undefined &&
                      <ColorPick
                        labelName='Card text color'
                        colorKey="ugcpsttxtclr"
                        colorValue={cnf.ugcpsttxtclr}
                        changeNameValue={handleTextChange} />
                    }
                    {cnf.crdttlclr !== undefined &&
                      <ColorPick
                        labelName='Page name color'
                        colorKey="crdttlclr"
                        colorValue={cnf.crdttlclr}
                        changeNameValue={handleTextChange} />
                    }
                    {cnf.crddtclr !== undefined &&
                      <ColorPick
                        labelName='Date color'
                        colorKey="crddtclr"
                        colorValue={cnf.crddtclr}
                        changeNameValue={handleTextChange} />
                    }
                    {cnf.crdtxtclr !== undefined &&
                      <ColorPick
                        labelName='Description color'
                        colorKey="crdtxtclr"
                        colorValue={cnf.crdtxtclr}
                        changeNameValue={handleTextChange} />
                    }
                    {cnf.crdircmnd !== undefined &&
                      <ColorPick
                        labelName='Tagged product color'
                        colorKey="crdircmnd"
                        colorValue={cnf.crdircmnd}
                        changeNameValue={handleTextChange} />
                    }
                  </InlineStack>
                </Box>
              }
            </Box>
            :
            state.sidebarActiveIndex === 3 &&
            <Box>

              {cnf && cnf.icstcss !== undefined &&
                <Box>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Is custom css enabled</Text>}
                    checked={cnf.icstcss}
                    onChange={(e) => handleTextChange({ icstcss: e })}
                  />
                  <Box paddingBlockStart={200} paddingInlineStart={600}>
                    <TextField
                      label={<Text tone='subdued' as='span'>Custom css block</Text>}
                      disabled={!cnf.icstcss}
                      multiline={4}
                      value={cnf.cstcss}
                      onChange={(e) => handleTextChange({ cstcss: e })}
                      autoComplete="off" />
                  </Box>
                </Box>
              }

            </Box>
      }

    </Box >
  );
}

export default TextUGC;