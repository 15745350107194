import { Text, Button, ButtonGroup, InlineStack, InlineGrid, Box, BlockStack, Divider } from '@shopify/polaris';
import { ArrowLeftIcon, ExternalIcon } from '@shopify/polaris-icons';
import { onboard_step_1, onboard_step_1_1, onboard_step_Wx1, error } from '../../img';

const BoardingStep1 = ({ props }) => {
  let _pvd = localStorage.getItem('pvd') || '';   //steps for shopify and wix

  return (
    _pvd === 'shopify' ?
      <Box>
        <Box paddingBlockEnd={{ xs: 6, sm: 3, md: 3, lg: 400, xl: 800 }}>
          <InlineGrid gap="800" columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
            <div className='border-right'>
              <div className='onboard-image onboard-image-border'>
                <img style={{ objectFit: 'cover' }} src={onboard_step_1_1} width='100%' height='100%' alt="onboard" />
              </div>
            </div>

            <div>
              <BlockStack gap={{ xl: 1000, lg: 600, md: 600, sm: 800, xs: 800 }}>
                <Text as="h6" variant='bodyLg' fontWeight='semibold'>Step 1 / 6</Text>
                <Text variant="headingLg" as="h5" fontWeight='medium'>
                  Enable this app to your Shopify theme</Text>
              </BlockStack>

              <Box paddingBlockStart={600}>
                <Box paddingBlockEnd={400} maxWidth='90%'>
                  <Text as="h3" variant='headingMd' fontWeight='regular'>1. Click 'Enable app' to integrate with your Shopify theme.</Text>
                  <Text as="h3" variant='headingMd' fontWeight='regular'>2. Save changes in the new window.</Text>
                  <Text as="h3" variant='headingMd' fontWeight='regular'>3. Return here to continue.</Text>
                </Box>
                <Box paddingBlockEnd={600}>
                  <Button id='primary_btn' icon={ExternalIcon} onClick={() => props.redrctNewTab('https://{SHOPNAME}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/pixel&target=newAppsSection')}>Enable app</Button>
                </Box>
                <div className='info_banner'>
                  <InlineStack gap={200} wrap={false}>
                    <img src={error} alt='error' />
                    <Text variant='bodyMd' fontWeight='medium' as='p'>Having issues with installation? Talk with us or visit <Button variant='plain' onClick={() => props.handleDeskuShow()}>support doc link</Button></Text>
                  </InlineStack>
                </div>
              </Box>

            </div>
          </InlineGrid>
        </Box>
        <Divider />
        <Box paddingBlockStart={400}>
          <InlineStack align="space-between">
            <Button variant={'monochromePlain'} icon={ArrowLeftIcon} onClick={() => props.handleNextStep('stepGuide', 'Back_Step1')}><Text fontWeight='medium'>Back</Text></Button>
            <ButtonGroup>
              <Button variant={'plain'} onClick={() => props.handleNextStep('step2', 'Skip_Step1')}>Skip</Button>
              <Button id='primry_clr' variant='primary' onClick={() => props.handleNextStep('step2', 'Step1')}>Continue</Button>
            </ButtonGroup>
          </InlineStack>
        </Box>
      </Box>
      :
      _pvd === 'wix' &&
      <Box>
        <Box paddingBlockEnd={{ xs: 6, sm: 3, md: 3, lg: 400, xl: 800 }}>
          <InlineGrid gap="800" columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
            <div className='border-right'>
              <div className='onboard-image onboard-image-border' style={{ display: 'flex', alignItems: 'center' }} >
                <img style={{ objectFit: 'cover' }} src={onboard_step_Wx1} width='429px' height='297px' alt="onboard" />
              </div>
            </div>

            <div>
              <Box paddingBlockEnd={600}>
                <BlockStack gap={{ xl: 1000, lg: 600, md: 600, sm: 800, xs: 800 }}>
                  <Text as="h6" variant='bodyLg' fontWeight='semibold'>Step 1 / 5</Text>
                  <Text variant="headingLg" as="h5" fontWeight='medium'>
                    Embed product-specific star rating badge </Text>
                </BlockStack>
              </Box>

              <BlockStack gap={600}>
                <div className='ob_import_bg'>
                  <Text as="h3" variant='headingMd' fontWeight='regular'>Here’s how: </Text>
                  <Box paddingBlockStart={200} maxWidth='90%'>
                    <BlockStack gap={200}>
                      <Text as="h5" variant='headingMd' fontWeight='regular'>1. Open product page in theme editor</Text>
                      <Text as="h5" variant='headingMd' fontWeight='regular'>2. Find and Add plugin: “Product Star Rating” by WisetNotify Product Reviews</Text>
                      <Text as="h5" variant='headingMd' fontWeight='regular'>3. When you're done, click Save & Publish.</Text>
                    </BlockStack>
                  </Box>
                </div>

                <div className='info_banner'>
                  <InlineStack gap={200} wrap={false}>
                    <img src={error} alt='error' />
                    <Text variant='bodyMd' fontWeight='medium' as='p'>If you need any assistance, our support team is here to  <Button variant='plain' onClick={() => props.handleDeskuShow()}>help!</Button></Text>
                  </InlineStack>
                </div>
              </BlockStack>

            </div>
          </InlineGrid>
        </Box>
        <Divider />
        <Box paddingBlockStart={400}>
          <InlineStack align="space-between">
            <Button variant={'monochromePlain'} icon={ArrowLeftIcon} onClick={() => props.handleNextStep('stepGuide', 'Back_Step1')}><Text fontWeight='medium'>Back</Text></Button>
            <ButtonGroup>
              <Button variant={'plain'} onClick={() => props.handleNextStep('step2', 'Skip_Step1')}>Skip</Button>
              <Button id='primry_clr' variant='primary' onClick={() => props.handleNextStep('step2', 'Step1')}>Continue</Button>
            </ButtonGroup>
          </InlineStack>
        </Box>
      </Box>
  );
}

export default BoardingStep1;