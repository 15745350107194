import { Badge, Page, Button, Popover, OptionList, Box, Tooltip, InlineStack, InlineGrid, Modal, PageActions, IndexTable, TextField, Text, Card, Checkbox, Divider, Icon, Collapsible, List, BlockStack, } from '@shopify/polaris';
import { ConnectIcon, DeleteIcon, ClipboardIcon, ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';


const opt = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
let _ia = false;

let keyObj = {
  un: '{{USERNAME}}',
  e: '{{EMAIL}}',
  phn: '{{PHONE}}',
  ct: '{{CITY}}',
  st: '{{STATE}}',
  cn: '{{COUNTRY}}',
  i: '{{IP}}',
  lt: '{{LATITUDE}}',
  lg: '{{LONGITUDE}}',
  pn: '{{PRODUCT_NAME}}',
  pid: '{{PRODUCT_ID}}',
  pu: '{{PRODUCT_URL}}',
  piu: '{{PRODUCT_IMAGE}}',
  rttl: '{{REVIEW_TITLE}}',
  rtxt: '{{REVIEW_TEXT}}',
  rtng: '{{REVIEW_RATING}}',
  cdt: '{{CAPTURE_DATE}}',
  fn: '{{F_NAME}}',
  ln: '{{L_NAME}}',
  // cst1: '{{CUSTOM_1}}',
  // cst2: '{{CUSTOM_2}}',
  // cst3: '{{CUSTOM_3}}'
};

const WebhookDataImport = ({ props }) => {
  const [cState, setCState] = useState({
    WbhkField: {},
    rmk: '',
    webUrl: '',
    wfkey: '',
    cfkey: '', //Custom webhook Field value
    keywordsOpt: [],
    keyValOpt: [],
    deleteOpen: false,
    mappOpen: false,
    isListenStart: false,
    isMapErr: true,
  });
  const { state, changeNameValue, validator, handleSave, handleTextChange, handleCopyCode, handleBackToMain } = props;
  const dispatch = useDispatch();

  if (state.currUserInt && state.currUserInt.ia !== undefined) //for isActive
    _ia = state.currUserInt.ia;
  if (state.newDObj && Object.keys(state.newDObj).length <= 0) //set active on create
    _ia = true;

  const changeNameCValue = useCallback((obj) => {
    setCState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  const openCloseModal = useCallback((name, value, typ) => {
    if (typ !== undefined) changeNameCValue({ [name]: !value });
    clearInterval(fieldsIntervalRef.current);
  }, [changeNameCValue]);

  //api data 
  const WbhkField = useSelector(state => state.integration.webhookField);

  const fieldsIntervalRef = useRef(null);

  //temp: added by hardik
  useEffect(() => {
    if (WbhkField) {
      let obj = {
        isListenStart: false,
        WbhkField: WbhkField
      }
      changeNameCValue(obj);
      clearInterval(fieldsIntervalRef.current);
    }
    //If there is no data in the mapping, upon opening the popup, we need to start auto-listening
    else if (!WbhkField || !WbhkField.resp || WbhkField.resp.length === 0) {
      let obj = {
        isListenStart: true,
      }
      changeNameCValue(obj);
      callGetwbhookFieldApi();
    }
  }, [WbhkField])

  useEffect(() => {  //clearInterval for get fields api in webhook
    if (!WbhkField && !cState.WbhkField) {
      //clearInterval(fieldsIntervalRef.current);

      if (cState.mappOpen === true) callGetwbhookFieldApi();
      //else clearInterval(fieldsIntervalRef.current);
    }

    if (WbhkField !== cState.WbhkField) {
      if (WbhkField && WbhkField.resp && WbhkField.resp.length > 0) {
        //clearInterval(fieldsIntervalRef.current);
        changeNameValue({ 'isLisDtSuc': true });
        let queryTimer = setTimeout(() => { changeNameValue({ 'isLisDtSuc': false }); }, 2000);
        return () => clearTimeout(queryTimer);
      }
    }
  }, [WbhkField, cState.mappOpen, cState.WbhkField]);

  const callGetwbhookFieldApi = () => {
    clearInterval(fieldsIntervalRef.current);
    fieldsIntervalRef.current = setInterval(() => {
      dispatch(integrationDucks.getWebhookField({ fuid: state.currIntId }));
    }, 5000);
  }

  const handleStartListening = () => {
    let obj = {
      fuid: state.currIntId
    };
    dispatch(integrationDucks.startListening(obj));

    //clear old data
    let intObj = {
      WbhkField: {},
      isListenStart: true
    }
    changeNameCValue(intObj);
    callGetwbhookFieldApi();
  }

  const delCustomField = (key) => {
    let obj = {
      key,
      fuid: state.currIntId
    };

    if (key === '{{USERNAME}}' || key === '{{REVIEW_RATING}}') {
      changeNameCValue({ 'isMapErr': true });
    }
    dispatch(integrationDucks.delWebhookField(obj));
  }

  useEffect(() => { //set webPoint
    if (state.currUserInt) {
      let api_url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://reviewsbe.azurewebsites.net/api';
      let _webUrl = `${api_url}/webhook/import?ti=${state.currUserInt.ti}&fuid=${state.currUserInt._id}`;

      changeNameCValue({ 'webUrl': _webUrl ? _webUrl : '' });
    }
  }, [state.currUserInt]);

  useEffect(() => { //set 'select keyword' options
    let keywordsOpt = [];
    Object.keys(keyObj).map((key) => {
      let obj = {};
      obj['value'] = keyObj[key];
      obj['label'] = keyObj[key];
      keywordsOpt.push(obj);
    });
    changeNameCValue({ 'keywordsOpt': keywordsOpt });
  }, [keyObj, state.ampArray]);

  useEffect(() => { //set 'select key/value' options
    let keyValOpt = [];
    if (cState.WbhkField && cState.WbhkField.resp && cState.WbhkField.resp.length > 0) {
      keyValOpt = [];
      cState.WbhkField.resp.map(((x) => {
        let obj = {};
        obj['value'] = x;
        obj['label'] = x;
        keyValOpt.push(obj);
      }))
    }
    changeNameCValue({ 'keyValOpt': keyValOpt });
  }, [cState.WbhkField]);

  useEffect(() => { //remove used keyword from 'keywordsOpt' opt.
    state.ampArray && state.ampArray.length > 0 && state.ampArray.map((x) => {
      cState.keywordsOpt && cState.keywordsOpt.length > 0 && cState.keywordsOpt.map((y, j) => {
        if (Object.keys(x)[0] === y.value) delete cState.keywordsOpt[j];
      });
    });
  }, [cState.keywordsOpt, state.ampArray]);

  const clearState = () => {
    let obj = {
      currIntId: '',
      isLisDtSuc: false,
    }
    changeNameValue(obj);

    let intObj = {
      rmk: '',
      webUrl: '',
      wfkey: '',
      cfkey: ''
    }
    changeNameCValue(intObj);
  }

  const openFieldMappModal = () => {
    openCloseModal('mappOpen', cState.mappOpen, 'open');
    dispatch(integrationDucks.getWebhookField({ fuid: state.currIntId }));
    if (cState.isListenStart) {
      callGetwbhookFieldApi();
    }
  }

  const addMappingField = () => {
    if (cState.wfkey && cState.cfkey) {
      let obj = {
        wfkey: cState.wfkey,
        cfkey: cState.cfkey,
        fuid: state.currIntId
      };
      dispatch(integrationDucks.createCstmWbhookField(obj));
      clearState();
    }
  }

  const handleDelIntegration = () => {
    let obj = {
      shp: localStorage.getItem('shp'),
      id: state.currIntId
    }
    dispatch(integrationDucks.delIntegration(obj));

    let intObj = {
      WbhkField: {},
      ampArray: [],
      currUserInt: {},
      dObj: {},
      newDObj: {},
      currIntId: '',
      isLisDtSuc: false,
      isDelWbHook: true
    }
    changeNameValue(intObj);

    let intCObj = {
      rmk: '',
      webUrl: '',
      wfkey: '',
      cfkey: ''
    }
    changeNameCValue(intCObj);
    setTimeout(() => openCloseModal('deleteOpen', cState.deleteOpen, 'close'), 600);
  }

  useEffect(() => {
    if (state.ampArray && state.ampArray.length > 0) {
      const keys = state.ampArray.map(data => Object.keys(data)[0]);
      if (keys.includes('{{USERNAME}}') && keys.includes('{{REVIEW_RATING}}')) {
        changeNameCValue({ 'isMapErr': false });
      }
    }
  }, [state.ampArray, changeNameCValue]);

  return (
    <Page
      title={(state.currMasterObj && state.currMasterObj.dn) && state.currMasterObj.dn}
      titleMetadata={_ia === true ? <Badge tone='success'><Text variant="bodySm" fontWeight="medium" as="span">Active</Text></Badge>
        : <Badge><Text variant="bodySm" fontWeight="medium" as="span">Disabled</Text></Badge>}
      subtitle={
        <Text tone='base' >
          {state.currMasterObj.dsc ? state.currMasterObj.dsc : '-'}
        </Text>}
      backAction={{ onAction: () => handleBackToMain() }}>

      <Box paddingInline={800}>
        <BlockStack gap={400}>
          <Card>
            <Box paddingBlockEnd={200}>
              <Text variant='headingMd' as='p' fontWeight='semibold'>Webhook setup</Text>
            </Box>
            {/* <Text>Integrate the following URL with your app and map the fields according to it.</Text> */}
            <BlockStack gap={400}>
              <Text variant='headingSm' as='p' fontWeight='semibold'>Connect your app:</Text>
              <Box>
                <List type="bullet">
                  <List.Item>Create a connection to obtain your unique webhook endpoint URL where data can be posted.</List.Item>
                  <List.Item>After creating the connection, use the URL to integrate the webhook with your application.</List.Item>
                  <List.Item>Ensure the correct review data mapping by aligning the required fields in your application.</List.Item>
                </List>
              </Box>

              {Object.keys(state.newDObj).length <= 0 ?
                <Box>
                  <TextField
                    label="Name your connection"
                    placeholder='Enter remark'
                    value={cState.rmk}
                    onChange={(e) => { handleTextChange('rmk', e); changeNameCValue({ 'rmk': e }) }}
                    autoComplete="off"
                    error={state.errMessage.remark ? state.errMessage.remark : false} />
                  {validator.message('remark', cState.rmk, 'required')}

                  <Box paddingBlockStart={400}>
                    <InlineStack align='center'>
                      <Button id='primary_btn' onClick={() => handleSave(_ia)}>Create</Button>
                    </InlineStack>
                  </Box>
                </Box>
                :
                <Box>
                  <Box paddingBlockEnd={200}>
                    <Text variant='headingSm' as='h2' fontWeight='semibold'>Account Details</Text>
                  </Box>
                  <Card padding={0}>
                    <div id='sd_tbl'>
                      <IndexTable
                        itemCount={1}
                        selectable={false}
                        headings={[
                          { title: 'Name' },
                          { title: 'Endpoint' },
                          { title: 'Create date' },
                          { title: 'Active' },
                          { title: 'Action' }
                        ]} >

                        {state.currUserInt &&
                          <IndexTable.Row
                            id={0}
                            key={0}
                            position={0}>
                            <IndexTable.Cell>{(state.currUserInt.d && state.currUserInt.d.rmk) ? state.currUserInt.d.rmk : '-'}</IndexTable.Cell>
                            <IndexTable.Cell><div id='txt_limit1'>{cState.webUrl}</div></IndexTable.Cell>
                            <IndexTable.Cell>
                              {state.currUserInt.cdt ? (new Date(state.currUserInt.cdt)).toLocaleDateString("en-US", opt) : '-'}
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              {/* <label className="switch">
                          <input type="checkbox" checked={state.currUserInt.ia} onChange={(e) => handleSave(e.target.checked)} />
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label> */}
                              <Checkbox
                                checked={state.currUserInt.ia}
                                onChange={() => handleSave(!state.currUserInt.ia)}
                              />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <InlineStack gap={300} align='center' wrap={false}>
                                <Tooltip width='wide' padding="400" dismissOnMouseOut content="Copy URL">
                                  <Button id='primary_btn' icon={ClipboardIcon} onClick={() => handleCopyCode(cState.webUrl, 'Endpoint')} />
                                </Tooltip>
                                <Tooltip width='wide' padding="400" dismissOnMouseOut content="Field Mapping">
                                  <Button variant='secondary' icon={ConnectIcon} onClick={() => openFieldMappModal()}></Button></Tooltip>
                                <Tooltip width='wide' padding="400" dismissOnMouseOut content="Delete">
                                  <Button variant="primary" tone="critical" icon={DeleteIcon} onClick={() => openCloseModal('deleteOpen', cState.deleteOpen, 'open')}></Button>
                                </Tooltip>
                              </InlineStack>
                            </IndexTable.Cell>
                          </IndexTable.Row>
                        }
                      </IndexTable>
                    </div>
                  </Card>
                </Box>
              }
            </BlockStack>
          </Card>

          <Card padding={0}>
            <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
              <Text variant='headingMd' fontWeight='semibold' as='h1'>General questions</Text>
            </Box>
            <Divider borderColor='border-tertiary' />
            <Box paddingInline={400} paddingBlockEnd={300}>
              <div onClick={() => changeNameValue({ tabEvent: 'firstTabCollapse' })} id='cursorPoint'>
                <Box paddingBlockStart={300} paddingBlockEnd={300}>
                  <InlineStack wrap={false} blockAlign='center' align='space-between'>
                    <Box>
                      <Text variant='headingSm' as='p' fontWeight='medium'>What is a webhook for review import?</Text>
                    </Box>
                    <Box>
                      <Icon source={state.tabEvent === 'firstTabCollapse' ? ChevronUpIcon : ChevronDownIcon}></Icon>
                    </Box>
                  </InlineStack>
                  <Collapsible
                    open={state.tabEvent === 'firstTabCollapse'}
                    id="db_collaspe"
                    transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                    <Box paddingBlockStart={100}>
                      <Text variant='headingSm' as='p' fontWeight='regular' tone="subdued">A webhook for review import is a way to automate the process of importing product reviews into your system.</Text>
                    </Box>
                  </Collapsible>
                </Box>
                <Divider />
              </div>

              <div onClick={() => changeNameValue({ tabEvent: 'secondTabCollapse' })} id='cursorPoint'>
                <Box paddingBlockStart={300} paddingBlockEnd={300}>
                  <InlineStack wrap={false} blockAlign='center' align='space-between'>
                    <Box>
                      <Text variant='headingSm' as='p' fontWeight='medium'>How do I integrate the webhook URL with my app?</Text>
                    </Box>
                    <Box>
                      <Icon source={state.tabEvent === 'secondTabCollapse' ? ChevronUpIcon : ChevronDownIcon}></Icon>
                    </Box>
                  </InlineStack>
                  <Collapsible
                    open={state.tabEvent === 'secondTabCollapse'}
                    id="db_collaspe"
                    transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                    <Box paddingBlockStart={100}>
                      <Text variant='headingSm' as='p' fontWeight='regular' tone="subdued">Copy the provided endpoint URL and paste it into your app's webhook settings. Map the fields accordingly and post the review data in JSON format.</Text>
                    </Box>
                  </Collapsible>
                </Box>
                <Divider />
              </div>

              <div onClick={() => changeNameValue({ tabEvent: 'thirdTabCollapse' })} id='cursorPoint'>
                <Box paddingBlockStart={300}>
                  <InlineStack wrap={false} blockAlign='center' align='space-between'>
                    <Box>
                      <Text variant='headingSm' as='p' fontWeight='medium'>Can I deactivate the webhook if needed?</Text>
                    </Box>
                    <Box>
                      <Icon source={state.tabEvent === 'thirdTabCollapse' ? ChevronUpIcon : ChevronDownIcon}></Icon>
                    </Box>
                  </InlineStack>
                  <Collapsible
                    open={state.tabEvent === 'thirdTabCollapse'}
                    id="db_collaspe"
                    transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                    <Box paddingBlockStart={100}>
                      <Text variant='headingSm' as='p' fontWeight='regular' tone="subdued">Yes, To deactivate the webhook, uncheck the option. Then, it stops saving the new data.</Text>
                    </Box>
                  </Collapsible>
                </Box>
              </div>

            </Box>
          </Card>
        </BlockStack>
      </Box>

      <Modal
        size='large'
        open={cState.mappOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Field Mapping</Text>}
        onClose={(e) => openCloseModal('mappOpen', cState.mappOpen, e)}>
        <Modal.Section>
          <div className='inTe_wrapper'>
            {/* <Text fontWeight='medium' variant='headingMd' as='h6'>
              {cState.isListenStart ?
                'Please post sample data on the webhook URL from your third party platform.'
                :
                'Click on start listening & post a data on webhook URL from your third party platform.'
              }
            </Text> */}
            <BlockStack gap={400}>
              <Text variant='headingSm' as='p' fontWeight='semibold'>Action Required:</Text>
              <Box>
                <List type="bullet">
                  <List.Item>Click on Start listening & wait for new data.</List.Item>
                  <List.Item>Send a test JSON payload from 3rd party app to this webhook end point to confirm that the data is correctly received.</List.Item>
                  <List.Item>Then, define mappings between received payload keys and the WiserReview app fields.</List.Item>
                </List>
              </Box>
              <Box>
                <Text variant='headingSm' as='p' fontWeight='semibold'>Status:</Text>
                <Box paddingBlockStart={300}>
                  <InlineStack>
                    <Button variant='primary' onClick={() => handleStartListening()}
                    //disabled={(cState.WbhkField && cState.WbhkField.resp && cState.WbhkField.resp.length === 0) || !cState.WbhkField}
                    >
                      {/* {(cState.WbhkField && cState.WbhkField.resp && cState.WbhkField.resp.length === 0) || !cState.WbhkField ? */}
                      {cState.isListenStart ?
                        'Waiting for data'
                        :
                        'Start listening'
                      }
                    </Button>

                    {/* {(cState.WbhkField && cState.WbhkField && cState.WbhkField.resp && cState.WbhkField.resp.length === 0) || !cState.WbhkField ? */}
                    {cState.isListenStart ?
                      <div><div className="spinner-pixel"></div></div> //loader
                      :
                      (cState.WbhkField && cState.WbhkField.resp && cState.WbhkField.resp.length === 0) || !cState.WbhkField ? '' : state.isLisDtSuc === true &&
                        <p id="sucess-data-rec-p">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Data received successfully! Please map Wisernotify Review App keywords with received Key/Values.</p>
                    }
                  </InlineStack>
                </Box>
              </Box>
              <Box>
                <Text fontWeight='medium' variant='bodyLg' as='h3'>Do mapping between Wisernotify Review App & Webhook data:</Text>

                <Box paddingBlockStart={200} paddingBlockEnd={300}>
                  <InlineGrid columns={4} gap={300} alignItems='start'>
                    <Popover
                      fullWidth
                      preferredPosition='above'
                      active={cState.isWfKey}
                      onClose={() => changeNameCValue({ 'isWfKey': !cState.isWfKey })}
                      activator={
                        <Button onClick={() => changeNameCValue({ 'isWfKey': !cState.isWfKey })} textAlign="left" fullWidth disclosure>{cState.wfkey ? cState.wfkey : '--Select Keyword--'}</Button>}>
                      <OptionList
                        options={cState.keywordsOpt}
                        selected={cState.wfkey}
                        onChange={(e) => changeNameCValue({ 'wfkey': e.toString(), 'isWfKey': !cState.isWfKey })} />
                      {/* onChange={() => changeNameCValue({ 'isWfKey': !cState.isWfKey })}
                      onFocusOption={(e) => changeNameCValue({ 'wfkey': e })} /> */}
                    </Popover>
                    <Box>
                      <Popover
                        fullWidth
                        preferredPosition='above'
                        active={cState.isCfkey}
                        onClose={() => changeNameCValue({ 'isCfkey': !cState.isCfkey })}
                        activator={
                          <Button onClick={() => changeNameCValue({ 'isCfkey': !cState.isCfkey })} textAlign="left" fullWidth disclosure>{cState.cfkey?.length > 20 ? cState.cfkey.slice(0, 30) : '--Select Key/Value--'}</Button>}>
                        <OptionList
                          options={cState.keyValOpt}
                          selected={cState.cfkey}
                          onChange={(e) => changeNameCValue({ 'cfkey': e.toString(), 'isCfkey': !cState.isCfkey })} />
                          {/* onChange={() => changeNameCValue({ 'isCfkey': !cState.isCfkey })}
                          onFocusOption={(e) => changeNameCValue({ 'cfkey': e })} /> */}
                      </Popover>
                      {cState.keyValOpt && cState.keyValOpt.length > 0 && <p id="sucess-data-rec-p">Data received</p>}
                    </Box>
                    <Button id='primary_btn' size='large' disabled={!cState.cfkey || !cState.wfkey}
                      onClick={() => addMappingField()}>Add</Button>
                  </InlineGrid>
                  <Box paddingBlockStart={200}>
                    {cState.isMapErr &&
                      <p id="error-data-rec-p">The "USERNAME" and "REVIEW_RATING" fields are mandatory; data is not saved unless both fields are mapped.</p>
                    }
                  </Box>
                </Box>
              </Box>
            </BlockStack>

            <IndexTable
              itemCount={1}
              selectable={false}
              headings={[
                { title: 'No.' },
                { title: 'Key' },
                { title: 'Value' },
                { title: 'Create date' },
                { title: 'Action' }
              ]}>
              {state.ampArray && state.ampArray.length > 0 ?
                state.ampArray.map((data, index) => (
                  <IndexTable.Row
                    id={data._id}
                    key={index}
                    position={index}
                  >
                    <IndexTable.Cell>{index + 1}</IndexTable.Cell>
                    <IndexTable.Cell>{Object.keys(data)[0]}</IndexTable.Cell>
                    <IndexTable.Cell><div style={{ width: "160px", overflow: 'hidden' }}>{data[Object.keys(data)[0]]}</div></IndexTable.Cell>
                    <IndexTable.Cell>
                      {data[Object.keys(data)[1]] ?
                        (new Date(data[Object.keys(data)[1]])).toLocaleDateString("en-US", opt)
                        : '-'
                      }
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Tooltip width='wide' padding="400" dismissOnMouseOut content="Delete mapping field">
                        <Button variant="primary" tone="critical" icon={DeleteIcon}
                          onClick={() => delCustomField(Object.keys(data)[0])} />
                      </Tooltip>
                    </IndexTable.Cell>
                  </IndexTable.Row>
                ))
                :
                <IndexTable.Row>
                  <IndexTable.Cell colSpan='4' className="text-center">
                    <Text fontWeight='medium' as='h6'>No record found</Text>
                  </IndexTable.Cell>
                </IndexTable.Row>
              }
            </IndexTable>
          </div>
        </Modal.Section>
      </Modal>

      <Modal
        size='small'
        open={cState.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('deleteOpen', cState.deleteOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this?</Text>
            <PageActions
              primaryAction={{
                id: 'primary_btn',
                content: 'Yes',
                onAction: () => handleDelIntegration()
              }}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('deleteOpen', cState.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>
    </Page >
  );
}

export default WebhookDataImport;