import { Box, Button, Page, Text, InlineStack, Badge, Divider, SkeletonBodyText, Modal, TextField, PageActions, IndexTable, Tooltip, BlockStack, ButtonGroup, Card, Icon } from '@shopify/polaris';
import { PlusIcon, DeleteIcon, } from '@shopify/polaris-icons';
import moment from 'moment';

const AddAutoScreen = (props) => {
  let { state, changeNameValue, handleCreateAutotion, handleAutoCustomize, handleDelAution, openCloseModal } = props;

  return (
    <div>

      <InlineStack align='end'>
        <Button icon={PlusIcon} variant='primary' onClick={() => openCloseModal('createOpen', state.createOpen, 'open')}>Create automation</Button>
      </InlineStack>

      <Box paddingBlockStart={400}>
        <Card padding={0}>
          <IndexTable
            itemCount={state?.AutoListArr?.length || 0}
            selectable={false}
            headings={[
              { title: 'Name' },
              // { title: 'Status' },
              { title: 'Change' },
              { title: 'Actions' }
            ]}>

            {state?.AutoListArr?.length > 0 && state.AutoListArr.map((data, index) => {
              return (
                <IndexTable.Row
                  id={data._id}
                  key={index}
                  position={index}
                >
                  <IndexTable.Cell>
                    <Box paddingBlockStart={200} paddingBlockEnd={200}>
                      <BlockStack gap="200" align="start">
                        <Text variant="headingMd" as="h6" fontWeight="medium"> {data.dn} </Text>
                        <div style={{ padding: '0 120px 0 0' }}>
                          <Tooltip dismissOnMouseOut content={<BlockStack><Text>CreatedOn: {moment(new Date(data.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</Text><Text>LastModified: {moment(data.udt ? new Date(data.udt) : new Date()).format('MMM-DD-YYYY hh:mm:ss a')}</Text></BlockStack>} width='wide'>
                            <Text variant="bodyXs" tone='subdued' fontWeight="medium" as="p">
                              {moment(data.udt ? new Date(data.udt) : new Date()).format('MMM-DD-YYYY')}
                            </Text>
                          </Tooltip>
                        </div>
                      </BlockStack>
                    </Box>
                  </IndexTable.Cell>

                  <IndexTable.Cell>
                    <Button variant='primary' tone='success' onClick={() => handleAutoCustomize(data)}>Customize</Button>
                  </IndexTable.Cell>

                  <IndexTable.Cell>
                    <InlineStack gap={200}>
                      <Button variant='primary' tone='critical' icon={DeleteIcon}
                        onClick={() => {
                          openCloseModal('deleteOpen', state.deleteOpen, 'open');
                          changeNameValue({ delAtmId: data._id, delAutoNm: data.dn });
                        }} />
                    </InlineStack>
                  </IndexTable.Cell>
                </IndexTable.Row>
              );
            })}
          </IndexTable>
        </Card>
      </Box>

      <Modal // create widget
        size='small'
        open={state.createOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Add automation</Text>}
        onClose={(e) => openCloseModal('createOpen', state.createOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <TextField
              label={<Text>Automation Name</Text>}
              onChange={(e) => changeNameValue({ creDnField: e })}
              value={state?.creDnField} />

            <PageActions
              primaryAction={<Button variant='primary' tone='success' disabled={!state.creDnField}
                onClick={() => handleCreateAutotion()}> Create </Button>}
              secondaryActions={[{
                content: 'Not now',
                onAction: () => openCloseModal('createOpen', state.createOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>

      <Modal // delete widget
        size='small'
        open={state.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('deleteOpen', state.deleteOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this "{state.delAutoNm}"?</Text>
            <PageActions
              primaryAction={<Button variant='primary' tone='critical'
                onClick={() => handleDelAution(state.delAtmId)}> Yes</Button>}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('deleteOpen', state.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>

    </div>
  );
}

export default AddAutoScreen;