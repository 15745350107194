import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/email';
import { toastify } from '../ducks/toast';
import * as  integrationActions from '../ducks/integration';

function* getSeqData(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getEmailSequence', action.payload);

    if (res.status === 'success') {
      yield put(actions.getSeqDataSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateSequence(action) {
  try {
    const res = yield call(api.POST, '/updateEmailSequence', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getSeqData());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
      yield put(actions.getSeqData());
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* testEmail(action) {
  try {
    const res = yield call(api.POST, '/testEmail', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updConfig(action) {
  try {
    const res = yield call(api.POST, '/updConfig', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));

      if (action.payload.spcfcTyp)
        yield put(actions.getConfigByType({ typ: action.payload.spcfcTyp }));
      else yield put(actions.getConfigByType());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));

      if (action.payload.spcfcTyp)
        yield put(actions.getConfigByType({ typ: action.payload.spcfcTyp }));
      else yield put(actions.getConfigByType());
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getConfigByType(action) {
  try {
    yield put(load());
    // const res = yield call(api.GET, '/getConfigByType?typ=' + action.payload.typ);
    const res = yield call(api.POST, '/getConfigByType', action.payload);
    if (res.status === 'success') {
      yield put(actions.getConfigByTypeSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* removeCondition(action) {
  try {
    const res = yield call(api.GET, '/removeCondition?_id=' + action.payload._id + '&typ=' + action.payload.typ);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));

      if (action.payload.spcfcTyp)
        yield put(actions.getConfigByType({ typ: action.payload.spcfcTyp }));
      else yield put(actions.getConfigByType());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));

      if (action.payload.spcfcTyp)
        yield put(actions.getConfigByType({ typ: action.payload.spcfcTyp }));
      else yield put(actions.getConfigByType());
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getAnalyticsCount(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getAnalyticsCount', action.payload);

    if (res.status === 'success') {
      yield put(actions.getAnalyticsCountSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCampaign(action) {
  try {
    const res = yield call(api.POST, '/createCampaign', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getCampaignHistory(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getCampaignHistory?shp=' + action.payload.shp);

    if (res.status === 'success') {
      yield put(actions.getCampaignHistorySuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updTmpTypeStatus(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updTmpTypeStatus', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(integrationActions.getUserIntegration({ shp: localStorage.getItem('shp'), typ: 'automation' }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getCampaignCnt(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getCampaignCount', action.payload);
    if (res.status === 'success') {
      yield put(actions.getCampaignCntSuccess(res.dataCount));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAutoMation(action) {
  try {
    const res = yield call(api.POST, '/createUserAutoMation', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getConfigByType());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    console.log(error, 'createAutoMation==>');
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delAutoMation(action) {
  try {
    const res = yield call(api.POST, '/deleteUserAutoMation', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getConfigByType());
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    console.log(error, 'delAutoMation==>');
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetSeqData() {
  yield takeLatest(actions.getSeqData.type, getSeqData);
}
export function* watchUpdateSequence() {
  yield takeLatest(actions.updateSequence.type, updateSequence);
}
export function* watchTestEmail() {
  yield takeLatest(actions.testEmail.type, testEmail);
}
export function* watchUpdConfig() {
  yield takeLatest(actions.updConfig.type, updConfig);
}
export function* watchGetConfigByType() {
  yield takeLatest(actions.getConfigByType.type, getConfigByType);
}
export function* watchRemoveCondition() {
  yield takeLatest(actions.removeCondition.type, removeCondition);
}
export function* watchGetAnalyticsCount() {
  yield takeLatest(actions.getAnalyticsCount.type, getAnalyticsCount);
}
export function* watchCreateCampaign() {
  yield takeLatest(actions.createCampaign.type, createCampaign);
}
export function* watchGetCampaignHistory() {
  yield takeLatest(actions.getCampaignHistory.type, getCampaignHistory);
}
export function* watchUpdTmpTypeStatus() {
  yield takeLatest(actions.updTmpTypeStatus.type, updTmpTypeStatus);
}
export function* watchGetCampaignCnt() {
  yield takeLatest(actions.getCampaignCnt.type, getCampaignCnt);
}
export function* watchCreateAutoMation() {
  yield takeLatest(actions.createAutoMation.type, createAutoMation);
}
export function* watchDelAutoMation() {
  yield takeLatest(actions.delAutoMation.type, delAutoMation);
}

export default function* rootSaga() {
  yield fork(watchGetSeqData);
  yield fork(watchUpdateSequence);
  yield fork(watchTestEmail);
  yield fork(watchUpdConfig);
  yield fork(watchGetConfigByType);
  yield fork(watchRemoveCondition);
  yield fork(watchGetAnalyticsCount);
  yield fork(watchCreateCampaign);
  yield fork(watchGetCampaignHistory);
  yield fork(watchUpdTmpTypeStatus);
  yield fork(watchGetCampaignCnt);
  yield fork(watchCreateAutoMation);
  yield fork(watchDelAutoMation);
}