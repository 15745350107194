import { Text, Button, ButtonGroup, InlineStack, InlineGrid, Box, BlockStack, TextField, Divider } from '@shopify/polaris';
import { ArrowLeftIcon } from '@shopify/polaris-icons';
import { onboard_step_5 } from '../../img';
import { useEffect } from 'react';
import Branding from './Branding';

const BoardingStep4 = ({ props }) => {

  useEffect(() => {
    if (props.state.shwDcrdSave === true)
      props.changeNameValue({ isDiabledCta: false });
  }, [props.state.shwDcrdSave]);

  let _pvd = localStorage.getItem('pvd') || ''; //steps for shopify and wix

  return (
    _pvd === 'shopify' ?
      <Branding
        state={props.state}
        changeNameValue={props.changeNameValue}
        handleDropZone={props.handleDropZone}
        handleNextStep={props.handleNextStep}
        handleSaveContinue={props.handleSaveContinue} />
      :
      _pvd === 'wix' &&
      <Box>
        <Box paddingBlockEnd={800}>
          <InlineGrid gap="800" columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
            <div className='border-right'>
              <div className='onboard-image'>
                <Box paddingBlockEnd={200}>
                  <Text variant='headingMd' as='p' fontWeight='medium' alignment='start'>Preview</Text>
                </Box>
                <div className='image-preview'>
                  <div><img src={onboard_step_5} width='100%' height='100%' alt="onboard" /></div>
                </div>
              </div>
            </div>
            <div>
              <BlockStack gap={{ xl: 1000, lg: 600, md: 600, sm: 800, xs: 800 }}>
                <Text as="h6" variant='bodyLg' fontWeight='semibold'>Step 4 / 5</Text>
                <Box paddingBlockEnd={500} maxWidth='85%'>
                  <Text variant="headingLg" as="h5" fontWeight='medium'>Start collecting reviews </Text>
                </Box>
              </BlockStack>
              <Box paddingBlockStart={0} paddingBlockEnd={500} maxWidth='90%'>
                <Text variant="bodyLg" as="h6" fontWeight='regular' tone='subdued'>Let's take a look at your review request process and activate it to start collecting reviews. Here is a preview of how an email review request looks in email.</Text>
                <br />
                <Text variant="bodyLg" as="h6" fontWeight='regular' tone='subdued'>Set the delay for sending review requests after order fulfillment</Text>
              </Box>
              <InlineGrid columns={2}>
                <TextField
                  label='Select days'
                  type='number'
                  suffix='days'
                  value={props.state.ddValue}
                  onChange={(e) => props.changeNameValue({ ddValue: parseInt(e) })}
                  error={props.state.errMessage.daysField ? props.state.errMessage.daysField : false} />
                {props.validator.message('daysField', props.state.ddValue, 'required|numeric|min:1,num|max:60,num')}
              </InlineGrid>
            </div>
          </InlineGrid>
        </Box>
        <Divider />
        <Box paddingBlockStart={500}>
          <InlineStack align="space-between">
            <Button id='plain_clr' variant={'plain'} icon={ArrowLeftIcon} onClick={() => props.handleNextStep('step3', 'Back_Step4')}>Back</Button>
            <ButtonGroup>
              <Button variant={'plain'} onClick={() => props.handleNextStep('step5', 'Skip_Step4')}>Skip</Button>
              <Button id='primry_clr' variant='primary' onClick={() => props.updateConfig('step5', 'Step4')}>Save & Continue</Button>
            </ButtonGroup>
          </InlineStack>
        </Box>
      </Box>
  );
}

export default BoardingStep4;