import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/tagList';
import { toastify } from '../ducks/toast';

function* getTagList(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getTagList', action.payload);

    if (res.status === 'success') {
      yield put(actions.getTagListSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createTagList(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/createTagList', action.payload);
    if (res.status === 'success') {
      yield put(actions.getTagList(global.modeObj));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateTagList(action) {
  try {
    const res = yield call(api.POST, '/updateTagList', action.payload);
    if (res.status === 'success') {
      yield put(actions.getTagList(global.modeObj));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


export function* watchGetTagList() {
  yield takeLatest(actions.getTagList.type, getTagList);
}

export function* watchCreateTagList() {
  yield takeLatest(actions.createTagList.type, createTagList);
}

export function* watchUpdateTagList() {
  yield takeLatest(actions.updateTagList.type, updateTagList);
}

export default function* rootSaga() {
  yield fork(watchGetTagList);
  yield fork(watchCreateTagList);
  yield fork(watchUpdateTagList);
}
