import React, { useEffect } from 'react'
import { ThemeTemplateIcon, FilterIcon, ImageWithTextOverlayIcon, CodeIcon, LanguageIcon, EyeCheckMarkIcon } from '@shopify/polaris-icons';
import { Text, Button, Checkbox, Tag, ChoiceList, Tooltip, Popover, Box, InlineStack, Thumbnail, TextField, RadioButton, BlockStack, InlineGrid, Select, Card, Badge } from '@shopify/polaris';
import ColorPick from '../ColorPick/ColorPick';

const TextAvatar = ({ props }) => {
    let { state, handleTextChange, changeNameValue, handleMainTextChange, handleKeyDown, handleRemoveKeyword, handleSelectDataSrc } = props;
    let { cnf, widgetArr } = state;

    let verticalTabsArr = [
        { title: 'Filter list', img: FilterIcon },
        { title: 'Theme layouts', img: ThemeTemplateIcon },
        { title: 'Reviewer cards', img: ImageWithTextOverlayIcon },
        { title: 'Color combination', img: EyeCheckMarkIcon },
        { title: 'Add custom css', img: CodeIcon },
        { title: 'Language', img: LanguageIcon }
    ];

    useEffect(() => {
        changeNameValue({ verticalTabsArr: verticalTabsArr });
    }, [state.newActiveTab]);

    let dsiSelect = widgetArr?.dsi?.length > 0 && widgetArr.dsi.map(vl => vl.uid + '_' + (vl.pid ? vl.pid : '')) || [];

    return (
        <Box padding={200}>
            {state.sidebarActiveIndex === 0 ?
                <Box>

                    <Box paddingBlock={100}>

                        <Text variant="bodyMd" fontWeight='bold'>Set how reviews show in the carousel</Text>
                        <Box paddingBlockStart={200}>

                            <RadioButton
                                label={<Text fontWeight='medium' as='span'>Choose reviews manually</Text>}
                                id="chooseReviews"
                                checked={!cnf.iautotstmnl}
                                onChange={() => handleTextChange({ iautotstmnl: false })}
                                helpText="Use the heart button on the reviews dashboard to select the reviews you want to show. You need to pick at least 1 review for the carousel to appear."
                            />
                            <Box paddingBlockStart={200}>
                                <RadioButton
                                    label={<Text fontWeight='medium' as='span'>Feature reviews automatically</Text>}
                                    id="featureReviews"
                                    checked={cnf.iautotstmnl}
                                    onChange={() => handleTextChange({ iautotstmnl: true })}
                                // helpText="Up to 15 (5-star) reviews will be shown."
                                />

                                {cnf.iautotstmnl === true &&
                                    <Box paddingBlockStart={200} paddingInlineStart={600}>

                                        <Box paddingBlockEnd={300}>
                                            <BlockStack gap={100}>
                                                <Text as='span'>Data source</Text>
                                                <Popover
                                                    fullWidth
                                                    active={state.isSelInte}
                                                    onClose={() => changeNameValue({ isSelInte: !state.isSelInte })}
                                                    activator={<Button textAlign="left" fullWidth disclosure
                                                        id='sel_dtsrc' onClick={() => changeNameValue({ isSelInte: !state.isSelInte })}>
                                                        <Text variant='bodyMd' tone='subdued' id='txt_lmtPop'>

                                                            {state.InteListArr?.length > 0 ? widgetArr?.dsi?.length > 0 ? `${widgetArr.dsi.filter(vl =>
                                                                state.InteListArr.some(({ value }) => value === vl.uid + '_' + (vl.pid ? vl.pid : ''))
                                                            ).length} selected` : '0 selected' : 'No integrations available'}

                                                        </Text>
                                                    </Button>}>
                                                    <Box padding={300}>
                                                        <ChoiceList
                                                            allowMultiple
                                                            choices={state.InteListArr || []}
                                                            selected={dsiSelect}
                                                            onChange={(e) => handleSelectDataSrc(e)} />
                                                    </Box>
                                                </Popover>
                                            </BlockStack>
                                        </Box>

                                        <Checkbox
                                            checked={widgetArr?.ihwc}
                                            onChange={(e) => handleMainTextChange({ ihwc: e })}
                                            label={<Text as='span'>Hide post without content</Text>}
                                        />
                                        <Box paddingBlockStart={100} paddingBlockEnd={200}>
                                            <Checkbox
                                                checked={widgetArr?.ihwi}
                                                onChange={(e) => handleMainTextChange({ ihwi: e })}
                                                label={<Text as='span'>Hide post without image</Text>}
                                            />
                                        </Box>

                                        <Box paddingInlineStart={200} paddingBlockStart={150}>
                                            <BlockStack gap={300}>
                                                <TextField
                                                    type='Number'
                                                    placeholder='Enter count'
                                                    label='Minumum Rating display'
                                                    onChange={(e) => handleMainTextChange({ mnrt: Number(e) })}
                                                    value={widgetArr?.mnrt || ''} />

                                                <BlockStack gap={100}>   {/* include keyword */}
                                                    <div onKeyDown={(e) => handleKeyDown(e, 'inkw')}>
                                                        <TextField
                                                            label="Include Keywords"
                                                            value={state.inkInput}
                                                            onChange={(value) => changeNameValue({ inkInput: value })}
                                                            placeholder="Type a keyword and press Enter"
                                                            autoComplete="off" />
                                                    </div>

                                                    <InlineStack gap={200}>
                                                        {widgetArr?.inkw?.length > 0 && widgetArr.inkw.map((keyword, index) =>
                                                            <Tag key={index} onRemove={() => handleRemoveKeyword(keyword)}>{keyword}</Tag>
                                                        )}
                                                    </InlineStack>
                                                </BlockStack>

                                                <BlockStack gap={100}> {/* exclude keyword */}
                                                    <div onKeyDown={(e) => handleKeyDown(e, 'exkw')}>
                                                        <TextField
                                                            label="Exclude Keywords"
                                                            value={state.exkInput}
                                                            onChange={(value) => changeNameValue({ exkInput: value })}
                                                            placeholder="Type a keyword and press Enter"
                                                            autoComplete="off" />
                                                    </div>
                                                    <InlineStack gap={200}>
                                                        {widgetArr?.exkw?.length > 0 && widgetArr.exkw.map((keyword, index) =>
                                                            <Tag key={index} onRemove={() => handleRemoveKeyword(keyword, 'exkw')}>{keyword}</Tag>
                                                        )}
                                                    </InlineStack>
                                                </BlockStack>

                                                <BlockStack gap={100}>
                                                    <Text as='span'>Select tags</Text>
                                                    <Popover
                                                        fullWidth
                                                        active={state.isSelTag}
                                                        onClose={() => changeNameValue({ isSelTag: !state.isSelTag })}
                                                        activator={<Button textAlign="left" fullWidth disclosure
                                                            onClick={() => changeNameValue({ isSelTag: !state.isSelTag })}>
                                                            <Text variant='bodyMd' tone='subdued'>
                                                                {widgetArr?.arrtgid?.length > 0 && widgetArr.arrtgid.map(tgid => state.TagListArr?.length > 0 && state.TagListArr.find(({ value }) => value === tgid)?.label).filter(Boolean).join(', ') || 'Select'}
                                                            </Text>
                                                        </Button>}>
                                                        <Box padding={300}>
                                                            <ChoiceList
                                                                allowMultiple
                                                                choices={state.TagListArr || []}
                                                                selected={widgetArr?.arrtgid || []}
                                                                onChange={(e) => handleMainTextChange({ arrtgid: e })} />
                                                        </Box>
                                                    </Popover>
                                                </BlockStack>

                                            </BlockStack>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Box>

                </Box>
                :
                state.sidebarActiveIndex === 1 ?
                    <Box>

                        <InlineGrid columns={2} gap={300}>
                            {state.mainLayoutArr && state.mainLayoutArr.length > 0 &&
                                state.mainLayoutArr.map((val, i) => {
                                    return (
                                        <div key={i} className={val._id === state.currLayoutId ? 'select_block active' : 'select_block'}
                                            onClick={() => changeNameValue({ currLayoutId: val._id }, 'isEditable')}>
                                            <InlineStack align='center' blockAlign='center'>
                                                <Checkbox tone={val._id === state.currLayoutId ? 'magic' : ''}
                                                    checked={val._id === state.currLayoutId} />
                                                <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                                                    {val.dn}</Text>
                                            </InlineStack>
                                            <img className='border_NoneImg' src={val.iu} alt="Skeleton" />
                                        </div>
                                    );
                                })}
                        </InlineGrid>

                        {cnf && cnf.ibrnd !== undefined &&
                            <Box paddingBlock={400}>
                                <InlineStack gap={200} blockAlign='center'>
                                    <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                                        <Checkbox
                                            disabled={state.isFreePln === true}
                                            label={<Text fontWeight='medium' as='span'>Branding</Text>}
                                            checked={cnf.ibrnd}
                                            onChange={(e) => handleTextChange({ ibrnd: e })} />
                                    </Tooltip>
                                    <Box>
                                        <Badge tone='warning'>Exclusive</Badge>
                                    </Box>
                                </InlineStack>
                            </Box>
                        }

                        {/* set page size */}
                        <TextField
                            type='Number'
                            placeholder='Enter count'
                            label='Maximum record to display'
                            onChange={(e) => handleMainTextChange({ pgsz: Number(e) })}
                            value={widgetArr?.pgsz || ''}
                        />

                    </Box>
                    :
                    state.sidebarActiveIndex === 2 ?
                        <Box>
                            <InlineGrid columns={2} gap={300}>
                                {state.cardArr && state.cardArr.length > 0 && state.cardArr.map((val, i) => {
                                    return (
                                        <div key={i} className={val._id === state.currCardId ? 'select_block active' : 'select_block'}
                                            onClick={() => changeNameValue({ currCardId: val._id }, 'isEditable')}>
                                            <InlineStack align='center' blockAlign='center'>
                                                <Checkbox tone={val._id === state.currCardId ? 'magic' : ''}
                                                    checked={val._id === state.currCardId} />
                                                <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                                                    {val.dn}</Text>
                                            </InlineStack>
                                            <Thumbnail source={val.iu} size="large" alt="Layout" />
                                        </div>
                                    );
                                })}
                            </InlineGrid>
                        </Box>
                        :
                        state.sidebarActiveIndex === 4 ?
                            <Box>

                                {cnf && cnf.icstcss !== undefined &&
                                    <Box>
                                        <Checkbox
                                            label={<Text fontWeight='medium' as='span'>Is custom css enabled</Text>}
                                            checked={cnf.icstcss}
                                            onChange={(e) => handleTextChange({ icstcss: e })}
                                        />
                                        <Box paddingBlockStart={200} paddingInlineStart={600}>
                                            <TextField
                                                label={<Text tone='subdued' as='span'>Custom css block</Text>}
                                                disabled={!cnf.icstcss}
                                                multiline={4}
                                                value={cnf.cstcss}
                                                onChange={(e) => handleTextChange({ cstcss: e })}
                                                autoComplete="off" />
                                        </Box>
                                    </Box>
                                }
                            </Box>
                            :
                            state.sidebarActiveIndex === 3 ?
                                <Box>
                                    <Text variant="bodyMd" fontWeight='bold'>Star rating summary</Text>
                                    {cnf &&
                                        <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={800}>

                                            <InlineStack direction={'column'} gap={500}>
                                                {cnf && cnf.strclr !== undefined &&
                                                    <ColorPick
                                                        labelName='Star color'
                                                        colorKey="strclr"
                                                        colorValue={cnf.strclr}
                                                        changeNameValue={handleTextChange} />
                                                }
                                            </InlineStack>
                                        </Box>
                                    }

                                </Box>
                                :
                                state.sidebarActiveIndex === 5 &&
                                <Box>
                                    {cnf && cnf.lng !== undefined &&
                                        <Card>
                                            <Select
                                                label="Select language"
                                                options={state.lngOptions}
                                                onChange={props.handleLanguageChange}
                                                value={cnf.lng}
                                            />
                                        </Card>
                                    }
                                </Box>
            }
        </Box>

    )
}

export default TextAvatar