import React from 'react';
import { Box } from '@shopify/polaris';
import TextStarRating from './TextStarRating';
import TextCardCarousel from './TextCardCarousel';
import TextWall from './TextWall';
import TextVerifBadge from './TextVerifBadge';
import TextProductCarousel from './TextProductCarousel';
import TextMainWidget from './TextMainWidget';
import TextUGC from './TextUGC';
import TextSidebarCta from './TextSidebarCta';
import TextQueAns from './TextQueAns';
import WriteReviewTab from './WriteReviewTab';
import WriteQueAns from './WriteQueAns';
import TextChkOutRev from './TextChkOutRev';
import TextPopupWdgt from './TextPopupWdgt';
import TextAvatar from './TextAvatar';
import TextFloatBadge from './TextFloatBadge';

const AllReviewTab = ({ props }) => {
  let { state } = props;

  return (
    //  <div className='subdued_radius'>
    <Box padding={400} background='bg-surface-secondary' id='tabsContent'>

      {state.newActiveTab === 'writeReview' ? //writeReview Tabs Content
        <div>
          {state.widgetTyp === 'que_ans' ?
            <WriteQueAns
              props={props} />
            :
            <WriteReviewTab
              props={props} />
          }
        </div>
        :
        <div>
          {state.widgetTyp === 'main' ?
            <TextMainWidget
              props={props} />
            :
            state.widgetTyp === 'star_rating' ?
              <TextStarRating
                props={props} />
              :
              state.widgetTyp === 'carousel' ?
                <TextCardCarousel
                  props={props} />
                :
                state.widgetTyp === 'wall' ?
                  <TextWall
                    props={props} />
                  :
                  state.widgetTyp === 'verified_badge' ?
                    <TextVerifBadge
                      props={props} />
                    :
                    state.widgetTyp === 'carousel_product' ?
                      <TextProductCarousel
                        props={props} />
                      :
                      state.widgetTyp === 'ugc' ?
                        <TextUGC
                          props={props} />
                        :
                        state.widgetTyp === 'sidebar_cta' ?
                          <TextSidebarCta
                            props={props} />
                          :
                          state.widgetTyp === 'que_ans' ?
                            <TextQueAns
                              props={props} />
                            :
                            state.widgetTyp === 'checkout_review' ?
                              <TextChkOutRev
                                props={props} />
                              :
                              state.widgetTyp === 'avatar' ?
                                <TextAvatar
                                  props={props} />
                                :
                                state.widgetTyp === 'floating_badge' ?
                                  <TextFloatBadge
                                    props={props} />
                                  :
                                  state.widgetTyp === 'popup_widget' &&
                                  <TextPopupWdgt
                                    props={props} />
          }
        </div>
      }

    </Box>
    // </div>
  );
}

export default AllReviewTab;