import React, { useState, useEffect, useCallback } from 'react';
import { BlockStack, Box, Button, Card, IndexTable, InlineStack, Page, Text, TextField, Tooltip, Banner, Icon, Modal, Divider, PageActions, Select } from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
// import IntegrationHeader from './IntegrationHeader';
// import { useLanguage } from '../../LanguageContext';

function GooglePlace({ props }) {
  const dispatch = useDispatch();
  const { state, handleBackToMain } = props;
  const navigate = useNavigate();
  // const { translate } = useLanguage();

  const [value, setValue] = useState({
    intDataList: [],
    pi: '',
    rmk: '',
    errMessage: {},
    deleteModal: false,
    dataId: '',
    lng: ''
  });

  const changeValue = ((obj) => {
    setValue((prevValue) => ({ ...prevValue, ...obj }))
  })

  let intDataList = useSelector(state => state.integration?.gglPlaceData);
  let isLoading = useSelector(state => state?.loading?.isLoading);//loading(skleton)

  useEffect(() => {
    changeValue({ intDataList: intDataList })
  }, [intDataList])

  useEffect(() => {
    dispatch(integrationDucks.getGglPlace());
  }, [])

  const handleupdActive = (_id, active) => {
    const obj = {
      id: _id,
      ia: active
    }
    dispatch(integrationDucks.updateGglPlaceStatus(obj))
    const filterData = value.intDataList?.map((elm) => {
      if (elm._id === _id) {
        return { ...elm, ia: active };
      }
      return elm;
    });
    changeValue({ intDataList: filterData })
  }

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeValue({ [name]: !value });
    }
  }, []);

  const handleDeleteData = (id) => {
    openCloseModal('deleteModal', value.deleteModal, 'open');
    changeValue({ dataId: id });
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteGglPlace(id));
    openCloseModal('deleteModal', value.deleteModal, 'close');
  }

  const handleCreateBtn = () => {
    if (props.validator?.errorMessages.pi || props.validator?.errorMessages.rmk || props.validator?.errorMessages.lng) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeValue({ errMessage: props.validator.errorMessages });
    }
    else {
      const obj = {
        pi: value.pi,
        rmk: value.rmk,
        lng: value.lng
      };
      dispatch(integrationDucks.createGglPlace(obj));
      changeValue({ pi: '', rmk: '', lng: '', errMessage: {} });
    }
  }

  const optionsLG = [
    { value: '', label: 'Select' },
    { value: 'af', label: 'Afrikaans' },
    { value: 'sq', label: 'Albanian' },
    { value: 'am', label: 'Amharic' },
    { value: 'ar', label: 'Arabic' },
    { value: 'hy', label: 'Armenian' },
    { value: 'az', label: 'Azerbaijani' },
    { value: 'eu', label: 'Basque' },
    { value: 'be', label: 'Belarusian' },
    { value: 'bn', label: 'Bengali' },
    { value: 'bs', label: 'Bosnian' },
    { value: 'bg', label: 'Bulgarian' },
    { value: 'my', label: 'Burmese' },
    { value: 'ca', label: 'Catalan' },
    { value: 'zh', label: 'Chinese' },
    { value: 'zh-HK', label: 'Chinese (Hong Kong)' },
    { value: 'zh-CN', label: 'Chinese (Simplified)' },
    { value: 'zh-TW', label: 'Chinese (Traditional)' },
    { value: 'hr', label: 'Croatian' },
    { value: 'cs', label: 'Czech' },
    { value: 'da', label: 'Danish' },
    { value: 'nl', label: 'Dutch' },
    { value: 'en', label: 'English' },
    { value: 'en-AU', label: 'English (Australian)' },
    { value: 'en-GB', label: 'English (Great Britain)' },
    { value: 'et', label: 'Estonian' },
    { value: 'fa', label: 'Farsi' },
    { value: 'fil', label: 'Filipino' },
    { value: 'fi', label: 'Finnish' },
    { value: 'fr', label: 'French' },
    { value: 'fr-CA', label: 'French (Canada)' },
    { value: 'gl', label: 'Galician' },
    { value: 'ka', label: 'Georgian' },
    { value: 'de', label: 'German' },
    { value: 'el', label: 'Greek' },
    { value: 'gu', label: 'Gujarati' },
    { value: 'iw', label: 'Hebrew' },
    { value: 'hi', label: 'Hindi' },
    { value: 'hu', label: 'Hungarian' },
    { value: 'is', label: 'Icelandic' },
    { value: 'id', label: 'Indonesian' },
    { value: 'it', label: 'Italian' },
    { value: 'ja', label: 'Japanese' },
    { value: 'kn', label: 'Kannada' },
    { value: 'kk', label: 'Kazakh' },
    { value: 'km', label: 'Khmer' },
    { value: 'ko', label: 'Korean' },
    { value: 'ky', label: 'Kyrgyz' },
    { value: 'lo', label: 'Lao' },
    { value: 'lv', label: 'Latvian' },
    { value: 'lt', label: 'Lithuanian' },
    { value: 'mk', label: 'Macedonian' },
    { value: 'ms', label: 'Malay' },
    { value: 'ml', label: 'Malayalam' },
    { value: 'mr', label: 'Marathi' },
    { value: 'mn', label: 'Mongolian' },
    { value: 'ne', label: 'Nepali' },
    { value: 'no', label: 'Norwegian' },
    { value: 'pl', label: 'Polish' },
    { value: 'pt', label: 'Portuguese' },
    { value: 'pt-BR', label: 'Portuguese (Brazil)' },
    { value: 'pt-PT', label: 'Portuguese (Portugal)' },
    { value: 'pa', label: 'Punjabi' },
    { value: 'ro', label: 'Romanian' },
    { value: 'ru', label: 'Russian' },
    { value: 'sr', label: 'Serbian' },
    { value: 'si', label: 'Sinhalese' },
    { value: 'sk', label: 'Slovak' },
    { value: 'sl', label: 'Slovenian' },
    { value: 'es', label: 'Spanish' },
    { value: 'es-419', label: 'Spanish (Latin America)' },
    { value: 'sw', label: 'Swahili' },
    { value: 'sv', label: 'Swedish' },
    { value: 'ta', label: 'Tamil' },
    { value: 'te', label: 'Telugu' },
    { value: 'th', label: 'Thai' },
    { value: 'tr', label: 'Turkish' },
    { value: 'uk', label: 'Ukrainian' },
    { value: 'ur', label: 'Urdu' },
    { value: 'uz', label: 'Uzbek' },
    { value: 'vi', label: 'Vietnamese' },
    { value: 'zu', label: 'Zulu' }
  ];

  const rowMarkup = value.intDataList && value.intDataList.length > 0 && value.intDataList?.map(
    (
      usInt,
      index,
    ) => (
      <IndexTable.Row
        id={usInt._id}
        key={index}
        position={index}
      >
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {index + 1}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{usInt.d && usInt.d.place_id ? usInt.d.place_id : "Not Found"}</IndexTable.Cell>
        <IndexTable.Cell>{usInt.d && usInt.d.pageName ? usInt.d.pageName : "Not Found"}</IndexTable.Cell>
        <IndexTable.Cell>{usInt.d && usInt.d.avg_rati ? usInt.d.avg_rati : "Not Found"}</IndexTable.Cell>
        <IndexTable.Cell>{usInt.d && usInt.d.rati_count ? usInt.d.rati_count : "Not Found"}</IndexTable.Cell>
        <IndexTable.Cell>{usInt.rmk}</IndexTable.Cell>
        <IndexTable.Cell>{moment(new Date(usInt.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
        <IndexTable.Cell> <label className="switch">
          <input type="checkbox" checked={usInt.ia}
            onChange={() => handleupdActive(usInt._id, !usInt.ia)} />
          <span className="switch-label"></span>
          <span className="switch-handle"></span>
        </label></IndexTable.Cell>
        <IndexTable.Cell>

          <div id="delete" onClick={() => handleDeleteData(usInt._id)}>
            <Banner hideIcon tone='critical'>
              <Icon
                source={DeleteIcon}
                tone="critical"
              />
            </Banner>
          </div>

        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Page title={
      <div className='app-inner-box'>
        {/* <img src={state.img} alt='' className='app-inner-img' /> */}
        <span>Google</span>
      </div>
    }
      backAction={{ onAction: () => handleBackToMain() }}
    >
      <BlockStack gap={200}>
        <Card>

          <>
            <BlockStack gap={200}>
              <div className='form-width'>
                <InlineStack blockAlign="center" >
                  <div className='textfeild-lable'>
                    <Tooltip content={state.tl?.pi} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                      <Text variant="headingSm" as="h6">Place id</Text>
                    </Tooltip>
                  </div>
                  <div className='textfeild-box'>
                    <TextField
                      value={value.pi}
                      onChange={(e) => changeValue({ pi: e })}
                      placeholder="Enter place id"
                      autoComplete="off"
                      error={value.errMessage.pi ? "The Place Id field is required." : false}
                    />
                    {props.validator.message('pi', value.pi, 'required')}
                  </div>
                </InlineStack>
              </div>
              <div className='form-width'>
                <InlineStack blockAlign="center" >
                  <div className='textfeild-lable'>
                    <Tooltip content={state.tl?.rmk} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                      <Text variant="headingSm" as="h6">Name your connection</Text>
                    </Tooltip>
                  </div>
                  <div className='textfeild-box'>
                    <TextField
                      value={value.rmk}
                      onChange={(e) => changeValue({ rmk: e })}
                      placeholder="Enter Name your connection"
                      autoComplete="off"
                      error={value.errMessage.rmk ? "The name your connection field is required." : false}
                    />
                    {props.validator.message('rmk', value.rmk, 'required')}
                  </div>
                </InlineStack>
              </div>
              <div className='form-width'>
                <InlineStack blockAlign="center" >
                  <div className='textfeild-lable'>
                    <Tooltip content={state.tl?.lng} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                      <Text variant="headingSm" as="h6">Select language</Text>
                    </Tooltip>
                  </div>
                  <div className='textfeild-box'>
                    <Select
                      // label="Date range"
                      options={optionsLG}
                      onChange={(e) => changeValue({ lng: e })}
                      value={value.lng}
                      error={value.errMessage.lng ? "The Select language field is required." : false}

                    />
                    {props.validator.message('lng', value.lng, 'required')}
                  </div>
                </InlineStack>
              </div>
            </BlockStack>
            <Box align='center' padding={300}>
              <Button variant="primary" onClick={() => handleCreateBtn()}>Create</Button>
            </Box>
          </>

          <Box padding={400}>
            <Box>
              <Text variant="headingSm" as="h6">Account Details</Text>
            </Box>
            <Card padding={0}>
              <IndexTable
                itemCount={value.intDataList && value.intDataList?.length || 0}
                selectable={false}
                headings={[
                  { title: "Table No" },
                  { title: "Place Id" },
                  { title: "Place Name" },
                  { title: "Average Rating" },
                  { title: "Rating Count" },
                  { title: "Name" },
                  { title: "Created Date" },
                  { title: "Active" },
                  { title: "Action" }
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {/* delete data modal */}
      <Modal
        open={value.deleteModal}
        title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
        onClose={(e) => { openCloseModal('deleteModal', value.deleteModal, e); }}>
        <Modal.Section>
          <Box paddingBlockEnd={400}>
            <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
          </Box>
          <Divider />
          <PageActions
            primaryAction={{
              icon: DeleteIcon, content: "Yes, delete record", tone: 'critical',
              onAction: () => {
                handleDeleteCall(value.dataId);
              }
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: () => { openCloseModal('deleteModal', value.deleteModal, 'close'); }
              }
            ]}
          />
        </Modal.Section>
      </Modal>

    </Page>
  )
}

export default GooglePlace;