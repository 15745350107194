import { Box, TextField, Button, ButtonGroup, Checkbox, Text, InlineStack } from '@shopify/polaris';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { DeleteIcon } from '@shopify/polaris-icons';

let dispatch;

const customHandler = {
  'color': function (value) {
    if (value === 'custom-color') value = window.prompt('Enter Hex Color Code');
    this.quill.format('color', value, 'user');
  },
  'background': function (value) {
    if (value === 'custom-background') value = window.prompt('Enter Hex Color Code');
    this.quill.format('background', value, 'user');
  },
  'custTagDropDown': function (value) {
    // if (this.quill.getText().includes(value) !== true)
    this.quill.insertText(this.quill.getSelection().index, '{{' + value + '}}', 'user');
    // else
    // dispatch(toastify({ type: 'error', msg: '{{' + value + '}} already available' }));
  }
};

const toolbarModule = {
  container: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'custTagDropDown': [] }],
    ['bold', 'italic', 'underline'],
    [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] },
    { 'background': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-background'] },
      'link', 'clean'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
  ],
  handlers: customHandler
};

const toolbarWithoutDynamic = {
  container: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline'],
    [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] },
    { 'background': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-background'] },
      'link', 'clean'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
  ],
  handlers: customHandler
};

const moduleQuill = {
  toolbar: toolbarModule,
  // 'emoji-toolbar': true,
  // 'emoji-textarea': true,
  // 'emoji-shortname': true,
};

const moduleQuillWithoutDynamic = {
  toolbar: toolbarWithoutDynamic
};

const EditFields = ({
  state,
  saveSequnceTab,
  handleTextChange,
  handleDiscrdChange
}) => {
  dispatch = useDispatch();

  let arrChips = [];
  if (state.emailTyp === 'on_review_reject' || state.emailTyp === 'on_review_approve') {
    arrChips = ['CUSTOMER_NAME', 'STORE_NAME', 'REVIEW_TITLE', 'REVIEW_DESCRIPTION'];
  }
  else if (state.emailTyp === 'on_review_submit') {
    arrChips = ['CUSTOMER_NAME', 'STORE_NAME', 'REVIEW_RATING', 'REVIEW_TITLE', 'REVIEW_DESCRIPTION'];
  }
  else if (state.emailTyp === 'incentive') {
    arrChips = ['CUSTOMER_NAME', 'STORE_NAME', 'DISCOUNT_CODE', 'DISCOUNT_VALUE'];
  }
  else if (state.emailTyp === 'on_order_fulfill' && state.sbtyp === 'single') {
    arrChips = ['PRODUCT_NAME', 'CUSTOMER_NAME', 'STORE_NAME'];
  }
  else {
    arrChips = ['PRODUCT_NAME', 'PRODUCT_URL', 'CUSTOMER_NAME', 'STORE_NAME', 'REVIEW_TITLE', 'REVIEW_DESCRIPTION'];
  }

  var style = document.createElement('style');
  document.head.appendChild(style);

  toolbarModule.container[1][0].custTagDropDown = arrChips;
  for (let i = 0; i < arrChips.length; i++) {
    const e = arrChips[i];
    style.sheet.insertRule('.ql-picker-options span[data-value=' + e + ']::before {content: "{{' + e + '}}" !important;}');
    style.sheet.insertRule('.ql-picker-label[data-value=' + e + ']::before {content: "Dynamic Value" !important;}');
  }

  const editorFocus = (typ) => {
    if (document.getElementsByClassName('showEditor').length) {
      const arrShow = document.getElementsByClassName('showEditor');
      for (let i = 0; i < arrShow.length; i++) {
        const oShow = arrShow[i];
        oShow.classList.remove('showEditor');
      }
    }
    if (document.getElementsByClassName(typ).length > 0)
      document.getElementsByClassName(typ)[0].classList.add('showEditor');
  }

  return (
    <div className='header-editor scroll_card'>
      <Box paddingBlockStart={500}>

        <InlineStack gap={"600"} direction={'column'}>

          {state.emailObj && state.emailObj.sbjct !== undefined &&
            <TextField
              label={<label>Subject</label>}
              placeholder='We’d love your feedback'
              value={state.emailObj.sbjct}
              onChange={(e) => handleTextChange({ sbjct: e }, 'isEditable')}
              autoComplete="off" />
          }

          {state.emailObj && state.emailObj.msg !== undefined &&
            <Box>
              <label>Message</label>
              <ReactQuill value={state.emailObj.msg} className='preview-mail hideEditor msg' modules={moduleQuill}
                onChange={(newValue, delta, source) => {
                  if (source === 'user') { handleTextChange({ msg: newValue }, 'isEditable') }
                }}
                onFocus={() => editorFocus('msg')} />
            </Box>
          }

          {state.emailTyp !== 'on_review_reply' &&
            <InlineStack gap={"600"} direction={'column'}>

              {state.emailObj && state.emailObj.rtngtxt !== undefined &&
                <TextField
                  label={<label>Rating title text</label>}
                  placeholder='rating title text'
                  value={state.emailObj.rtngtxt}
                  onChange={(e) => handleTextChange({ rtngtxt: e }, 'isEditable')}
                  autoComplete="off" />
              }

              {state.emailObj && state.emailObj.irttl !== undefined &&
                <Box>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show review title</Text>}
                    checked={state.emailObj.irttl}
                    onChange={(e) => handleTextChange({ irttl: e }, 'isEditable')}
                  />
                  <Box paddingBlockStart={100}>
                    <TextField
                      disabled={!state.emailObj.irttl}
                      placeholder='review title text'
                      value={state.emailObj.rttltxt}
                      onChange={(e) => handleTextChange({ rttltxt: e }, 'isEditable')}
                      autoComplete="off" />
                  </Box>
                </Box>
              }

              {state.emailObj && state.emailObj.irdsc !== undefined &&
                <Box>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show review description</Text>}
                    checked={state.emailObj.irdsc ? state.emailObj.irdsc : false}
                    onChange={(e) => handleTextChange({ irdsc: e }, 'isEditable')}
                  />
                  <Box paddingBlockStart={100}>
                    <TextField
                      disabled={!state.emailObj.irdsc}
                      placeholder='review description text'
                      value={state.emailObj.rdsctxt}
                      onChange={(e) => handleTextChange({ rdsctxt: e }, 'isEditable')}
                      autoComplete="off" />
                  </Box>
                </Box>
              }

              {state.emailObj && state.emailObj.rbtntxt !== undefined &&
                <TextField
                  label={<label>Review submit button text</label>}
                  placeholder='review submit button text'
                  value={state.emailObj.rbtntxt}
                  onChange={(e) => handleTextChange({ rbtntxt: e }, 'isEditable')}
                  autoComplete="off" />
              }

              {state.emailObj && state.emailObj.clstmnt !== undefined &&
                <Box paddingBlock={100}>
                  <label>Closing statement</label>
                  <ReactQuill value={state.emailObj.clstmnt} className='hideEditor preview-mail clstmnt' modules={moduleQuill}
                    onChange={(newValue, delta, source) => {
                      if (source === 'user') { handleTextChange({ clstmnt: newValue }, 'isEditable') }
                    }}
                    onFocus={() => editorFocus('clstmnt')} />
                </Box>
              }

            </InlineStack>
          }

        </InlineStack>

        <div className='save-btn-sibebar save-btn-mailbox'>
          {state.isEdit === true &&
            <Box paddingBlockStart={200}>
              <ButtonGroup>
                <Button variant='secondary' icon={DeleteIcon} onClick={() => handleDiscrdChange()}>Discard</Button>
                <Button id='primary_btn' onClick={() => saveSequnceTab()}>Save</Button>
              </ButtonGroup>
            </Box>
          }
        </div>

      </Box>
    </div>
  );
}

export default EditFields;