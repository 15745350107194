import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const myprofile = createSlice({
    name: 'myprofile',
    initialState,
    reducers: {
        updateProfile: (state, action) => { },
        updProfileImg: (state, action) => { },
        cPwd: (state, action) => { },
    }
});

export const {
    updateProfile,
    updProfileImg,
    cPwd,
} = myprofile.actions;

export default myprofile.reducer;