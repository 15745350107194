import { Card, Page, Text, InlineStack, Box } from '@shopify/polaris';
import LaunchCampCard from './LaunchCampCard';
import TemplateCard from './TemplateCard';
import HistoryCard from './HistoryCard';

const AllCards = (props) => {
  let { state, changeNameValue, handleBackToMain } = props;

  //show back button if coming from other pages
  let isShwBackBtn = window.location.search !== '' && window.location.href != '/manual';

  const showAllCards = () => {
    return <Box paddingInlineStart={isShwBackBtn ? 200 : '0'} paddingBlockEnd={600}>
      <Card padding={600}>
        <Box paddingBlockEnd={600}>

          {/*  onetimecampagin's 'launchCampaign' section */}
          <InlineStack align='space-between' blockAlign='start' gap={300}>
            <InlineStack gap={100} direction={'column'}>
              <Text variant='bodyLg' fontWeight='medium' as='h4'>{state.selSeqName}</Text>
              <Text variant='bodySm' fontWeight='regular' as='p' tone='subdued'>{state.subTtl}</Text>
            </InlineStack>

          </InlineStack>
        </Box>

        {/* <FilterCard //secondBlock (add condition)
          props={props} />
        <span className='brderLink'></span> */}

        <TemplateCard //showing emailTemplates & integration
          props={props} />
        <span className='brderLink'></span>

        <LaunchCampCard //firstBlock (Launch campaign)
          props={props} />

        <HistoryCard //history only for one_time_campaign
          props={props} />

      </Card>
    </Box>;
  }

  return (
    <div>
      {isShwBackBtn ?
        <Page
          title={state.mainTtl}
          backAction={{ onAction: () => handleBackToMain() }}>
          {showAllCards()}
        </Page>
        :
        <Page
          title={state.mainTtl}>
          {showAllCards()}
        </Page>
      }

    </div>
  );
}

export default AllCards;