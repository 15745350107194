import React from 'react'
import { Page, Box, BlockStack, InlineGrid, Card, Text, InlineStack, Icon, Badge, Button, Banner, IndexTable, Divider, Collapsible, Modal, TextContainer } from '@shopify/polaris';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, XIcon } from '@shopify/polaris-icons';
import { wisernotify } from '../../img';


const WixPlan = (props) => {

  const { state, changeNameValue } = props;

  const collectReviewsList = [
    {
      _id: 1,
      label: 'Photo reviews',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 2,
      label: 'Video reviews',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 3,
      label: 'Discount code',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 4,
      label: 'Past orders',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 5,
      label: 'Reminder email',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 6,
      label: 'Customize email',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 7,
      label: 'Review form',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
  ]

  const displayReviewsList = [
    {
      _id: 1,
      label: 'Product reviews',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 2,
      label: 'Rating widget',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 3,
      label: 'Trust badge',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 4,
      label: 'Carousel',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 5,
      label: 'Wall of love',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 6,
      label: 'Review popups',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 7,
      label: 'Branding removal',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 8,
      label: 'Product grouping',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 9,
      label: 'Reply publicly to reviews',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 10,
      label: 'Question answer widget',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
  ]

  const integrationsList = [
    {
      _id: 1,
      label: 'Google shopping reviews (PLAs)',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 2,
      label: 'Google review snippets',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 3,
      label: 'Integrations(Klaviyo)',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 4,
      label: 'Multi store sync',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
  ]

  const supportList = [
    {
      _id: 1,
      label: 'Live chat',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 2,
      label: 'Implementation',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 3,
      label: 'Custom HTML/CSS by our team',
      freeVal: XIcon,
      essentialsVal: XIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 4,
      label: 'Building integration based on request',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
  ]

  const exclusivePlanList = [
    {
      id: 1,
      label: 'All from free plan',
    },
    {
      id: 2,
      label: 'Video reviews',
    },
    {
      id: 3,
      label: 'Past orders',
    },
    {
      id: 4,
      label: 'Branding removal',
    },
    {
      id: 5,
      label: 'Google shopping reviews',
    },
    {
      id: 6,
      label: 'Multi store sync',
    },
    {
      id: 7,
      label: 'Building integration based on request',
    },
    {
      id: 8,
      label: 'UGC Grid',
    },
    {
      id: 9,
      label: 'Questions & answers',
    },
  ]

  const freePlanList = [
    {
      id: 1,
      label: '50 orders a month',
    },
    {
      id: 2,
      label: 'Photo reviews',
    },
    {
      id: 3,
      label: 'Reminder email',
    },
    {
      id: 4,
      label: 'Review form',
    },
    {
      id: 5,
      label: 'Google review snippets',
    },
    {
      id: 6,
      label: 'Live chat',
    }
  ]

  let queArr = [
    {
      field: 'firstTabCollapse',
      question: 'Can I request additional integrations?',
      answer: 'Yes, we build new integrations based on user demand and prioritize the most requested ones.'
    }, {
      field: 'secondTabCollapse',
      question: 'Is it possible to change my plan later?',
      answer: 'Absolutely, you can upgrade or downgrade your plan at any time based on your needs.'
    }, {
      field: 'thirdTabCollapse',
      question: "What happens if I exceed my plan's order limit?",
      answer: "We'll send you an email alert as you near your limit, so you can upgrade and avoid any disruptions."
    }, {
      field: 'fourthTabCollapse',
      question: 'Can I be billed annually instead of monthly?',
      answer: 'At the moment, we only offer monthly billing.'
    }, {
      field: 'fifthTabCollapse',
      question: 'Can I transfer my existing reviews to your platform?',
      answer: 'You can easily import your reviews from other platforms with just a few clicks or by reaching out to the live chat support team.'
    }
  ];

  return (
    <Page
      title={"Billing settings"}
    >
      <Box padding={{ xs: 400, sm: 400, md: 0 }} paddingBlockEnd={600}>
        <BlockStack gap={600}>
          <Box paddingBlockStart={300}>
            <InlineGrid gap={500} columns={{ lg: 2, md: 2, sm: 1, xs: 1 }}>
              <Card>
                <BlockStack gap={600}>
                  <Text variant='headingMd' as='p' fontWeight='semibold'>Forever free</Text>
                  <Box>
                    <Text variant='headingXl' as='p' fontWeight='semibold'>$ 0</Text>
                    <Text variant='headingSm' as='p' fontWeight='regular'>/month</Text>
                  </Box>
                  <Box>
                    {freePlanList && freePlanList.length > 0 && freePlanList.map((data, i) => (
                      <Box key={i} paddingBlock={100}>
                        <InlineStack gap={200} wrap={false} blockAlign='center'>
                          <Box>
                            <Icon source={CheckIcon} />
                          </Box>
                          <Text variant='headingSm' fontWeight='regular' as='span'>{data.label}</Text>
                        </InlineStack>
                      </Box>
                    ))}
                  </Box>
                </BlockStack>
              </Card>
              <Card>
                <BlockStack gap={600}>
                  <InlineStack gap={200} wrap={false} blockAlign='center'>
                    <Text variant='headingMd' as='p' fontWeight='semibold'>Exclusive</Text>
                    <Box>
                      <Badge tone='info'>Recommended</Badge>
                    </Box>
                  </InlineStack>
                  <Box>
                    <Text variant='headingXl' as='p' fontWeight='semibold'>$ {state.exclusivePrc ? state.exclusivePrc : '-'}</Text>
                    <Text variant='headingSm' as='p' fontWeight='regular'>/month</Text>
                  </Box>
                  <Box>
                    {exclusivePlanList && exclusivePlanList.length > 0 && exclusivePlanList.map((data, i) => (
                      <Box key={i} paddingBlock={100}>
                        <InlineStack gap={200} wrap={false} blockAlign='center'>
                          <Box>
                            <Icon source={CheckIcon} />
                          </Box>
                          <Text variant='headingSm' fontWeight='regular' as='span'>{data.label}</Text>
                        </InlineStack>
                      </Box>
                    ))}
                  </Box>
                  <InlineStack align='center'>
                    {/* <Box>
                      <Button id='primary_btn' variant='primary'>Upgrade now</Button>
                    </Box> */}
                    {(state.exclusivePrc < state.billingPrc) ?
                      <Button id='primary_btn' variant='primary' onClick={() => props.handlePlanUpgrade('exu')} fullWidth>
                        Downgrade
                      </Button>
                      :
                      (state.exclusivePrc === state.billingPrc) ?
                        <Button fullWidth disabled>
                          Selected
                        </Button>
                        :
                        <Button id='primary_btn' variant='primary' onClick={() => props.handlePlanUpgrade('exu')} fullWidth>
                          Upgrade now
                        </Button>
                    }
                  </InlineStack>
                </BlockStack>
              </Card>
            </InlineGrid>
          </Box>

          <Box>
            <Card>
              <BlockStack gap={400}>
                <Box>
                  <InlineStack gap={200}>
                    <Text variant='headingMd' as='p' fontWeight='semibold'>Current plan</Text>
                    <Badge tone="attention"><Text variant='headingXs' fontWeight='medium' as='span'>
                      {state.pn}</Text></Badge>
                  </InlineStack>
                </Box>
                <Box>
                  <InlineStack gap={300}>
                    <img src={wisernotify} alt='wisernotify' width="24px" />
                    <Text variant='headingSm' as='p' fontWeight='regular'>${state.billingPrc} USD billed {state.pt}. Renews on Renews on {state.billingDate}</Text>
                  </InlineStack>
                </Box>
                <Box>
                  <Banner>
                    <Text variant='headingSm' fontWeight='medium' as='span'>
                      You have used {state.uuq} of your {state.uaq} order monthly usage limit. It will reset at the end of your billing cycle.
                    </Text>
                  </Banner>
                </Box>
              </BlockStack>
            </Card>
          </Box>

          <Box>
            <Card padding={0}>
              <div id='cursorPoint' onClick={() => changeNameValue({ 'planOpen': !state.planOpen })}>
                <Box background="bg-fill-active" padding={400}>
                  <InlineStack align='space-between'>
                    <Text variant='bodyLg' fontWeight='semibold' as='h1'>Plan comparison</Text>
                    <Box>
                      {state.planOpen ?
                        <Icon source={ChevronUpIcon} tone="base"></Icon> :
                        <Icon source={ChevronDownIcon} tone="base"></Icon>}
                    </Box>
                  </InlineStack>
                </Box>
              </div>
              <Divider />
              <Box paddingInline={400}>
                <Collapsible
                  open={state.planOpen}
                  id="db_collaspe"
                  transition={{ duration: '300ms', timingFunction: 'ease-in' }}>

                  <Box paddingBlockStart={400} paddingBlockEnd={400}>
                    <div id='plan_tbl'>
                      <IndexTable
                        itemCount={collectReviewsList ? collectReviewsList.length : 0}
                        selectable={false}
                        headings={[
                          { title: '' },
                          { title: <Text variant='headingXs' fontWeight='semibold' as='span'>Free</Text> },
                          { title: <Text variant='headingXs' fontWeight='semibold' as='span'>Exclusive</Text> },
                        ]}
                      >
                        <Box paddingBlockStart={600} paddingBlockEnd={200}>
                          <Text variant='headingSm' as='h2' fontWeight='bold'>Collect reviews</Text>
                        </Box>

                        {collectReviewsList && collectReviewsList.length > 0 && collectReviewsList.map((data, index) => (
                          <IndexTable.Row
                            id={data._id + 'clctRev'}
                            selected={false}
                            key={'clctRev' + index}
                            position={'clctRev' + index}>
                            <IndexTable.Cell>
                              <Text variant="bodyMd" fontWeight="medium" as="span">
                                {data.label}
                              </Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.freeVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.exclusiveVal} />
                            </IndexTable.Cell>
                          </IndexTable.Row>
                        ))}

                        <Box paddingBlockStart={600} paddingBlockEnd={200}>
                          <Text variant='headingSm' as='h2' fontWeight='bold'>Display reviews</Text>
                        </Box>

                        {displayReviewsList && displayReviewsList.length > 0 && displayReviewsList.map((data, index) => (
                          <IndexTable.Row
                            id={data._id + 'dsplyRev'}
                            selected={false}
                            key={'dsplyRev' + index}
                            position={'dsplyRev' + index}>
                            <IndexTable.Cell>
                              <Text variant="bodyMd" fontWeight="medium" as="span">
                                {data.label}
                              </Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.freeVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.exclusiveVal} />
                            </IndexTable.Cell>
                          </IndexTable.Row>
                        ))}

                        <Box paddingBlockStart={600} paddingBlockEnd={200}>
                          <Text variant='headingSm' as='h2' fontWeight='bold'>Integrations</Text>
                        </Box>

                        {integrationsList && integrationsList.length > 0 && integrationsList.map((data, index) => (
                          <IndexTable.Row
                            id={data._id + 'integrate'}
                            selected={false}
                            key={'integrate' + index}
                            position={'integrate' + index}>
                            <IndexTable.Cell>
                              <Text variant="bodyMd" fontWeight="medium" as="span">
                                {data.label}
                              </Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.freeVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.exclusiveVal} />
                            </IndexTable.Cell>
                          </IndexTable.Row>
                        ))}

                        <Box paddingBlockStart={600} paddingBlockEnd={200}>
                          <Text variant='headingSm' as='h2' fontWeight='bold'>Support</Text>
                        </Box>

                        {supportList && supportList.length > 0 && supportList.map((data, index) => (
                          <IndexTable.Row
                            id={data._id + 'support'}
                            selected={false}
                            key={'support' + index}
                            position={'support' + index}>
                            <IndexTable.Cell>
                              <Text variant="bodyMd" fontWeight="medium" as="span">
                                {data.label}
                              </Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.freeVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.exclusiveVal} />
                            </IndexTable.Cell>
                          </IndexTable.Row>
                        ))}
                      </IndexTable>
                    </div>
                  </Box>

                </Collapsible>
              </Box>
            </Card>
          </Box>

          <Box>
            <Card padding={0}>
              <Box paddingBlock={300} paddingInline={400} background="bg-fill-active">
                <Text variant='headingMd' fontWeight='semibold' as='h1'>General questions</Text>
              </Box>
              <Divider borderColor='border-tertiary' />

              <Box padding={400}>

                {queArr && queArr.map((data, i) => {
                  return (
                    <div key={i} id='cursorPoint' onClick={() => changeNameValue({ tabEvent: data.field })}>
                      <Box paddingBlock={300}>
                        <InlineStack wrap={false} blockAlign='center' align='space-between'>
                          <Text variant='headingSm' as='p' fontWeight='medium'>{data.question}</Text>
                          <Box><Icon source={data.field === state.tabEvent ? ChevronUpIcon : ChevronDownIcon}></Icon></Box>
                        </InlineStack>
                        <Collapsible open={data.field === state.tabEvent} id="db_collaspe"
                          transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                          <Box paddingBlockStart={100}>
                            <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>{data.answer}</Text>
                          </Box>
                        </Collapsible>
                      </Box>
                      {i !== queArr.length - 1 && <Divider />}
                    </div>
                  )
                })}

              </Box>
            </Card>
          </Box>
        </BlockStack>

        <Modal
          open={state.wixOpen}
          onClose={() => props.handleCloseModal()}
          title=""
          primaryAction={{
            content: props.loading ? 'Wait a moment' : 'Reload',
            onAction: () => window.location.reload(),
            disabled: props.loading,
          }}
        >
          <Modal.Section>
            <TextContainer>
              <p>Please subscribe to your desired plan at the checkout page opened in a new tab.</p>
              <p>After you've subscribed, hit the reload button.</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </Box>
    </Page>
  )
}

export default WixPlan