import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './Store'; // Updated import for the store
import Spinner from './components/Spinner/Spinner';
import { EntryCard } from './components';
import { AppProvider } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import './App.css';
import en from "@shopify/polaris/locales/en.json";
import { BrowserRouter } from 'react-router-dom';
import { Toastify } from './components';
import * as Sentry from '@sentry/react';
import { onCLS, onFID, onINP, onLCP } from 'web-vitals';

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: 'https://3b482866fcf5412ba6935fb3528e1c26@o424749.ingest.us.sentry.io/5356985',
    tracesSampleRate: 1.0,
    integrations: [
      Sentry.replayIntegration(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

let authToken = localStorage.getItem('authToken');
let ti = localStorage.getItem('ti');

const App = (props) => {
  //#region start code for shopify app bridge
  //Extracting parameters from the URL query string
  const paramsShop = new URLSearchParams(window.location.search);
  const shop = paramsShop.get('shop');//"hdkmobilestore.myshopify.com";
  const host = paramsShop.get('host');//"YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvaGRrbW9iaWxlc3RvcmU";
  const scopes = paramsShop.get('scopes');
  const is_shopify_embed = paramsShop.get('is_embeded');
  const is_complete_onboarding = paramsShop.get('icmpltonbrdng');
  const apiKey = process.env.REACT_APP_SHOPIFY_KEY;

  // chrome.runtime.sendMessage("coklccgmnkkdkgdlioikabffpeocbfmf", { auth_token: authToken, ti: ti });

  // Redirecting to Shopify OAuth authorization if scopes are provided
  if (scopes) {
    window.parent.location.href = 'https://' + shop + '/admin/oauth/authorize?client_id=' + process.env.REACT_APP_SHOPIFY_KEY + '&scope=' + scopes + '&redirect_uri=' + process.env.REACT_APP_API_URL + '/auth/shopifyCallback&&host=' + host + '&shop=' + shop;
  }
  else if (apiKey && shop) {
    const config = {
      apiKey: apiKey,
      shopOrigin: shop,
      host: host,
      forceRedirect: true,
    }
    //Store Shopify store authentication information in localStorage. We need to use this information to start listening for menu clicks in the sidebar page because we can't use the navigate function in the app.js file.
    sessionStorage.setItem('shop_name', shop);
    localStorage.setItem('store_config', JSON.stringify(config))
  }

  // Checking if the app is rendered inside the Shopify admin
  if (is_shopify_embed) {
    localStorage.setItem('is_shopify_embed', true);
  }
  //we pass onboarding status from backend url, so we can check if user completed onboarding or not.
  if (is_complete_onboarding) {
    localStorage.setItem('icmpltonbrdng', is_complete_onboarding);
  }
  //#endregion 

  const params = new URLSearchParams(window.location.search);
  //set user token to localstorage 
  let param_tkn = params.get('tkn');
  let param_ispaid = params.get('ispaid');
  if (param_tkn) {
    localStorage.setItem('authToken', param_tkn);
    //update authtoken value
    authToken = localStorage.getItem('authToken');

    //check user paid or not 
    if (param_ispaid) {
      localStorage.setItem('ispaid', true);
    }
    //let url = window.location.hostname === 'localhost' ? 'http://localhost:3000' : process.env.REACT_APP_FRONT_END_DOMAIN;
    //window.open(url, '_self');
  }

  useEffect(() => {
    // Log web vitals metrics to the console
    onLCP(console.log); // Largest Contentful Paint
    onFID(console.log); // First Input Delay
    onCLS(console.log); // Cumulative Layout Shift
    onINP(console.log); // Interaction to Next Paint
  }, []);

  useEffect((props) => {
    (function (d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    window.fbAsyncInit = function () {
      window.FB?.init({
        appId: process.env.REACT_APP_FB_IG_APP_ID,
        xfbml: true,
        version: 'v19.0'
      });
    };

  }, []);

  return (
    <Provider store={store}>
      <AppProvider i18n={en}>
        <Spinner />
        <Toastify />
        <BrowserRouter>
          {
            authToken ?
              <EntryCard component={<props.Component />} />
              :
              <props.Component />
          }
        </BrowserRouter>
      </AppProvider>
    </Provider>
  );
};

export default App;
