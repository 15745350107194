import { Box, Button, Page, Text, InlineStack, Badge, Card } from '@shopify/polaris';
import MainEditPanel from './MainEditPanel';
import AdvanceFilter from './AdvanceFilter';
import FullfillCard from './FullfillCard';
import ReminderCard from './ReminderCard';
import MediaCard from './MediaCard';

const AllCards = (props) => {
  let { state, getValbyTyp, changeNameValue, handleTextChange, openCloseModal, backTempList, handleBackToMain } = props;

  let fullFill = getValbyTyp('on_order_fulfill') || {};

  //show back button if coming from other pages
  let isShwBackBtn = localStorage.getItem('pvd') === 'local' ? true :
    window.location.search !== '' && window.location.href != '/sequence';

  const showAllCards = () => {
    return <Box paddingInlineStart={isShwBackBtn ? 200 : '0'} paddingBlockEnd={600}>
      <Card padding={600}>
        <Box paddingBlockEnd={600}>

          {/* header section (automation on/off active) */}
          <InlineStack align='space-between' blockAlign='start' gap={400}>
            <InlineStack gap={100} direction={'column'}>
              <InlineStack gap={200}>
                <Text variant='bodyLg' fontWeight='medium' as='h4'>Ask for reviews</Text>
                {fullFill.ia === true ?
                  <Badge tone='success'><Text variant="bodySm" fontWeight="medium" as="span">On</Text></Badge>
                  : <Badge tone='enabled'><Text variant="bodySm" fontWeight="medium" as="span">Off</Text></Badge>}

              </InlineStack>
              <Text variant='bodySm' fontWeight='regular' as='p' tone='subdued'>Optimize your review strategy with automation settings</Text>

            </InlineStack>
            <Button id={fullFill.ia === true ? '' : 'primary_btn'}
              variant='primary'
              onClick={() => props.updConfigData(!fullFill.ia, 'on_order_fulfill')}>
              {fullFill.ia === true ? 'Turn off automation' : 'Turn on automation'}</Button>
          </InlineStack>
        </Box>

        <AdvanceFilter
          props={props} />
        <span className='brderLink'></span>

        <FullfillCard
          props={props} />
        <span className='brderLink'></span>

        <ReminderCard
          props={props} />
        <span className='brderLink'></span>

        <MediaCard
          props={props} />

      </Card>
    </Box>
  }

  return (
    <div>
      {state.isNewEditPanel !== true ?
        isShwBackBtn ?
          <Page
            title={state.mainTtl}
            backAction={{ onAction: () => handleBackToMain() }}>
            {showAllCards()}
          </Page>
          :
          <Page
            title={state.mainTtl}>
            {showAllCards()}
          </Page>
        :
        <MainEditPanel   //email template edit panel | preview
          state={state}
          changeNameValue={changeNameValue}
          handleTextChange={handleTextChange}
          openCloseModal={openCloseModal}
          backTempList={backTempList}
        />
      }

    </div>
  );
}

export default AllCards;