import { Button, Divider, Badge, Card, Checkbox, InlineStack, Box, Text } from '@shopify/polaris';
import { ExternalIcon, EditIcon, PlusIcon } from '@shopify/polaris-icons';
import { wisernotify } from '../../img';
import { Link } from 'react-router-dom';

const IncentiveTemplateCard = (props) => {
  let { state, createTemplate } = props;

  let createMstArr = state.emailMSTArr && state.emailMSTArr.length > 0 &&
    state.emailMSTArr.filter((data) => !state.emailSeqArr?.some(val => val.typ === data.typ));

  const getTitle = (typ) => {
    let ttl = 'Single product review template';
    if (typ === 'multi')
      ttl = 'Multiple product review template';
    return ttl;
  }

  return (
    <Card padding={0}>
      <Box background='bg-surface-secondary' paddingInline={400} paddingBlock={300}>
        <Text variant='bodyLg' fontWeight='medium' as='h1'>Templates</Text>
      </Box>
      <Divider borderColor='border-tertiary' />

      <Box padding={400}>
        <Text variant='headingSm' as='p' fontWeight='medium'>Choose templates for requesting reviews via email, SMS, and whatsApp.</Text>

        <Box paddingBlockStart={600}>
          <InlineStack gap={200} blockAlign='start'>
            <Box paddingBlockStart={'050'}>
              <img src={wisernotify} alt="wisernotify" />
            </Box>
            <InlineStack direction={'column'}>
              <Text variant='headingSm' fontWeight='medium' as='h6'>Default templates</Text>
              <Box paddingBlockStart={100}>
                <Text variant='bodyMd' fontWeight='regular' tone='subdued'>Choose email template to collect reviews</Text>
              </Box>
            </InlineStack>
          </InlineStack>

          <Box paddingBlockEnd={100} paddingInlineStart={800}>

            {/* create templates */}
            {createMstArr && createMstArr.length > 0 && createMstArr.map((data, i) => {
              return (
                <Box key={i} paddingBlockStart={400}>
                  <InlineStack align="space-between" blockAlign='center'>
                    <InlineStack>
                      <Checkbox disabled label={<InlineStack gap={200}>
                        <Text as='span' fontWeight='regular' variant='bodyMd' tone='subdued'>
                          {getTitle(data.sbtyp)}  <Badge tone="attention" fontWeight='medium'>Email</Badge></Text>
                        
                      </InlineStack>}
                      />
                    </InlineStack>
                    <Button icon={PlusIcon} variant='' onClick={() => createTemplate(data)}>Create template</Button>
                  </InlineStack>
                </Box>
              );
            })
            }

            {/* edit templates */}
            {state.emailSeqArr && state.emailSeqArr.length > 0 &&
              state.emailSeqArr.map((data, i) => {
                return (
                  <Box key={i} paddingBlockStart={400}>
                    <InlineStack align="space-between" blockAlign='center'>
                      <InlineStack>
                        <Checkbox checked={data.ia} onChange={(e) => props.handleStatusUpdate(data._id, e)}
                          label={<InlineStack gap={200}>
                            <Text id='clrsecondary' as='span' fontWeight='regular' variant='bodySm'>
                              {getTitle(data.sbtyp)}</Text>
                            <Badge tone="attention"><Text fontWeight='medium'>Email</Text></Badge>
                          </InlineStack>}
                        />
                      </InlineStack>
                      <Button icon={EditIcon} onClick={() => props.editTemplateClk(data)}>Edit</Button>
                    </InlineStack>
                  </Box>
                );
              })}

          </Box>
        </Box>

        {/* integration parts */}
        {state.userIntegration && state.userIntegration.length > 0 &&
          state.userIntegration.map((mainData, j) => {
            return (
              <Box key={j}>
                <Box paddingBlock={400}><Divider /></Box>

                <InlineStack align='space-between'>
                  <InlineStack gap={200} blockAlign='start'>
                    <Box paddingBlockStart={'050'}>
                      <img src={mainData.img ? mainData.img : wisernotify} alt="Integration" />
                    </Box>
                    <InlineStack direction={'column'}>
                      <Text variant='headingSm' fontWeight='medium' as='h6'>{mainData.dn}</Text>
                      <Box paddingBlockStart={100}>
                        <Text variant='bodySm' as='span' tone='subdued'>Ideal for requesting reviews for individual product</Text>
                      </Box>
                    </InlineStack>
                  </InlineStack>
                  <Link to={`/integration?name=${mainData.inm}`}><Button icon={ExternalIcon} variant='plain'>Integration</Button></Link>
                </InlineStack>

                <Box paddingBlockStart={400} paddingInlineStart={800}>
                  {mainData && mainData.d && mainData.d.arr && mainData.d.arr.length > 0 &&
                    mainData.d.arr.map((data, i) => {
                      if (data.ia === true) {
                        return (
                          <Box key={i} paddingBlockEnd={i !== mainData.d.arr.length - 1 ? 400 : 0}>
                            <InlineStack align="space-between" blockAlign='center'>
                              <InlineStack align='center'>

                                {state.emailTyp === 'incentive' &&
                                  <Checkbox checked={data.iincntv}
                                    onChange={(e) => props.updateTmpStatus(mainData, data, e)} />
                                }

                                <div id='cursorPoint'
                                  onClick={() =>
                                    state.emailTyp === 'incentive' &&
                                    props.updateTmpStatus(mainData, data, !data.iincntv)
                                  } >

                                  <InlineStack direction={'column'} align='start' blockAlign='start' gap={100}>
                                    <InlineStack gap={200} align='start'>
                                      <Text id='clrsecondary' as='p' fontWeight='regular' variant='bodymd'>
                                        {data.rmk ? data.rmk : '-'}</Text>
                                      {mainData.ctg &&
                                        <Badge tone={mainData.ctg === 'Whatsapp' ? 'success' : 'info'}>
                                          <Text fontWeight='medium'>{mainData.ctg}</Text>
                                        </Badge>
                                      }
                                    </InlineStack>
                                  </InlineStack>

                                </div>

                              </InlineStack>
                            </InlineStack>
                          </Box>
                        );
                      }
                    })
                  }
                </Box>

              </Box>
            );
          })
        }

      </Box>
    </Card>
  );
}

export default IncentiveTemplateCard;