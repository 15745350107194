import React, { useState, useEffect, useCallback } from 'react';
import { BlockStack, Box, Button, Card, IndexTable, InlineStack, Page, Text, TextField, Tooltip, Banner, Icon, Modal, Divider, PageActions } from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, ClipboardIcon } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { toastify } from '../../ducks/toast';
import copy from 'copy-to-clipboard';
// import { useLanguage } from '../../LanguageContext';

function Zapier({ props }) {
  const dispatch = useDispatch();
  const { state, handleBackToMain } = props;
  const navigate = useNavigate();
  // const { translate } = useLanguage();

  const [value, setValue] = useState({
    intDataList: [],
    rmk: '',
    errMessage: {},
    deleteModal: false,
    dataId: '',
    notifListData: []
  });

  const changeValue = ((obj) => {
    setValue((prevValue) => ({ ...prevValue, ...obj }))
  })



  let intDataList = useSelector(state => state.integration?.zapData);
  let isLoading = useSelector(state => state?.loading?.isLoading);//loading(skleton)

  useEffect(() => {
    changeValue({ intDataList: intDataList })
  }, [intDataList])

  useEffect(() => {
    dispatch(integrationDucks.getZap());
  }, [])

  const handleupdActive = (_id, active) => {
    const obj = {
      id: _id,
      ia: active
    }
    dispatch(integrationDucks.updateZapStatus(obj))
    const filterData = value.intDataList && value.intDataList?.map((elm) => {
      if (elm._id === _id) {
        return { ...elm, ia: active };
      }
      return elm;
    });
    changeValue({ intDataList: filterData })
  }

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeValue({ [name]: !value });
    }
  }, []);

  const handleDeleteData = (id) => {
    openCloseModal('deleteModal', value.deleteModal, 'open');
    changeValue({ dataId: id });
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    dispatch(integrationDucks.deleteZap(id));
    openCloseModal('deleteModal', value.deleteModal, 'close');
    changeValue({ errMessage: {} })
  }

  const handleHowToConn = () => {
    if (props.isWhite === false) {
      state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    }
  }

  const handleCreateBtn = () => {
    if (props.validator.errorMessages.rmk) {
      props.validator.helpers.forceUpdateIfNeeded();
      changeValue({ errMessage: props.validator.errorMessages });
    }
    else {
      const obj = {
        rmk: value.rmk
      };
      dispatch(integrationDucks.createZap(obj));
      changeValue({ rmk: '', errMessage: {} });
    }
  }

  //copy endpoint
  const copyEndpoint = (url) => {
    copy(url, { debug: true });
    dispatch(toastify({ type: 'success', msg: 'API key copied!' }));
    return;
  }

  const rowMarkup = value.intDataList && value.intDataList.length > 0 && value.intDataList?.map(
    (
      usInt,
      index,
    ) => (
      <IndexTable.Row
        id={usInt._id}
        key={index}
        position={index}
      >
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {index + 1}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{usInt._id}</IndexTable.Cell>
        <IndexTable.Cell>{usInt.rmk}</IndexTable.Cell>
        <IndexTable.Cell>{moment(new Date(usInt.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
        <IndexTable.Cell> <label className="switch">
          <input type="checkbox" checked={usInt.ia}
            onChange={() => handleupdActive(usInt._id, !usInt.ia)} />
          <span className="switch-label"></span>
          <span className="switch-handle"></span>
        </label></IndexTable.Cell>
        <IndexTable.Cell>
          <div id="delete" onClick={() => handleDeleteData(usInt._id)}>
            <Banner hideIcon tone='critical'>
              <Icon
                source={DeleteIcon}
                tone="critical"
              />
            </Banner>
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  const fnRedirect = (url) => {
    console.log("Connection..")
    // window.open(url, '_blank');
  };

  return (
    <Page
      title={
        <div className='app-inner-box'>
          {/* <img src={state.img} alt='' className='app-inner-img' /> */}
          <span>Zapier</span>
        </div>
      }
      backAction={{ onAction: () => handleBackToMain() }}
      primaryAction={props.isWhite === false ?
        <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to connect</Button> : <></>}>
      <BlockStack gap={200}>
        <Card>
          <>
            <BlockStack gap={200}>
              <div className='form-width'>
                <InlineStack blockAlign="center" >
                  {
                    intDataList && intDataList.length > 0 &&
                    <div className='form-login-btn'>
                      <Box align='end' paddingBlockEnd={400}>
                        <Button id='login-btn-bg' variant='primary' onClick={() => { fnRedirect(process.env.REACT_APP_ZAPIER_URL); }}>Connect to zapier</Button></Box>
                    </div>
                  }
                  <div className='textfeild-lable'>
                    <Tooltip content={state.tl} hasUnderline width='wide' padding="400" dismissOnMouseOut >
                      <Text variant="headingSm" as="h6">Integration name</Text>
                    </Tooltip>
                  </div>
                  <div className='textfeild-box'>
                    <TextField
                      value={value.rmk}
                      onChange={(e) => changeValue({ rmk: e })}
                      placeholder="Integration name"
                      autoComplete="off"
                      error={value.errMessage.rmk ? "The integration name field is required." : false}
                    />
                    {props.validator.message('rmk', value.rmk, 'required')}
                  </div>
                </InlineStack>
              </div>
              <div className='form-width'>
                <InlineStack blockAlign="center" >
                  <div className='textfeild-lable'></div>
                  <div className='textfeild_shp'>
                    <TextField
                      value={props.profile?.ak}
                      disabled
                      connectedRight={<Button icon={ClipboardIcon} variant='primary' onClick={() => copyEndpoint(props.profile.ak)}>Copy API Key</Button>}
                    />
                  </div>
                </InlineStack>
              </div>
            </BlockStack>
            <Box align='center' padding={300}>
              <Button variant="primary" onClick={() => handleCreateBtn()}>Create</Button>
            </Box>
          </>
          {/* } */}
          <Box padding={400}>
            <Box>
              <Text variant="headingSm" as="h6">Account details</Text>
            </Box>
            <Card padding={0}>
              <IndexTable
                itemCount={value.intDataList?.length || 0}
                selectable={false}
                headings={[
                  { title: "Table No" },
                  { title: "Id" },
                  { title: "Name" },
                  { title: "Created Date" },
                  { title: "Active" },
                  { title: "Action" }
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {/* delete data modal */}
      <Modal
        open={value.deleteModal}
        title={<Text variant='headingMd' fontWeight='bold'>Are you sure want to delete this record?</Text>}
        onClose={(e) => { openCloseModal('deleteModal', value.deleteModal, e); }}>
        <Modal.Section>
          <Box paddingBlockEnd={400}>
            <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
          </Box>
          <Divider />
          <PageActions
            primaryAction={{
              icon: DeleteIcon, content: 'Yes, delete record', tone: 'critical',
              onAction: () => {
                handleDeleteCall(value.dataId);
              }
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: () => { openCloseModal('deleteModal', value.deleteModal, 'close'); }
              }
            ]}
          />
        </Modal.Section>
      </Modal>
    </Page>
  )
}

export default Zapier;