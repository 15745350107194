
import React from 'react';
import { Text, OptionList, Button, Checkbox, Tag, Label, ChoiceList, Tooltip, Popover, Box, InlineStack, Thumbnail, TextField, RadioButton, BlockStack, InlineGrid, Select, Card, Badge } from '@shopify/polaris';
import { ThemeTemplateIcon, FilterIcon, StarIcon, EyeCheckMarkIcon, ImageWithTextOverlayIcon, CodeIcon, LanguageIcon } from '@shopify/polaris-icons';
import ColorPick from '../ColorPick/ColorPick';
import { useEffect } from 'react';

const dateOpt = [
  { label: 'mm-dd-yyyy', value: 'mm-dd-yyyy' },
  { label: 'dd-mm-yyyy', value: 'dd-mm-yyyy' }
];

const nameOpt = [
  { label: 'Jenny Karl', value: 0 },
  { label: 'J. karl', value: 1 },
  { label: 'Jenny K.', value: 2 },
  { label: 'J. K.', value: 3 }
];

const TextCardCarousel = ({ props }) => {
  let { state, handleTextChange, changeNameValue, handleMainTextChange, handleKeyDown, handleRemoveKeyword, handleSelectDataSrc } = props;
  let { cnf, widgetArr } = state;

  let verticalTabsArr = [
    { title: 'Filter list', img: FilterIcon },
    { title: 'Theme layouts', img: ThemeTemplateIcon },
    { title: 'Reviewer cards', img: ImageWithTextOverlayIcon },
    { title: 'Star rating summary', img: StarIcon },
    { title: 'Color combination', img: EyeCheckMarkIcon },
    { title: 'Add custom css', img: CodeIcon },
    { title: 'Language', img: LanguageIcon }
  ];

  useEffect(() => {
    changeNameValue({ verticalTabsArr: verticalTabsArr });
  }, [state.newActiveTab]);

  let dsiSelect = widgetArr?.dsi?.length > 0 && widgetArr.dsi.map(vl => vl.uid + '_' + (vl.pid ? vl.pid : '')) || [];

  return (
    <Box padding={200}>

      {state.sidebarActiveIndex === 0 ?
        <Box>

          <Box paddingBlock={100}>

            <Text variant="bodyMd" fontWeight='bold'>Set how reviews show in the carousel</Text>
            <Box paddingBlockStart={200}>

              <RadioButton
                label={<Text fontWeight='medium' as='span'>Choose reviews manually</Text>}
                id="chooseReviews"
                checked={!cnf.iautotstmnl}
                onChange={() => handleTextChange({ iautotstmnl: false })}
                helpText="Use the heart button on the reviews dashboard to select the reviews you want to show. You need to pick at least 1 review for the carousel to appear."
              />
              <Box paddingBlockStart={200}>
                <RadioButton
                  label={<Text fontWeight='medium' as='span'>Feature reviews automatically</Text>}
                  id="featureReviews"
                  checked={cnf.iautotstmnl}
                  onChange={() => handleTextChange({ iautotstmnl: true })}
                // helpText="Up to 15 (5-star) reviews will be shown."
                />

                {cnf.iautotstmnl === true &&
                  <Box paddingBlockStart={200} paddingInlineStart={600}>

                    <Box paddingBlockEnd={300}>
                      <BlockStack gap={100}>
                        <Text as='span'>Data source</Text>
                        <Popover
                          fullWidth
                          active={state.isSelInte}
                          onClose={() => changeNameValue({ isSelInte: !state.isSelInte })}
                          activator={<Button textAlign="left" fullWidth disclosure
                            id='sel_dtsrc' onClick={() => changeNameValue({ isSelInte: !state.isSelInte })}>
                            <Text variant='bodyMd' tone='subdued' id='txt_lmtPop'>

                              {state.InteListArr?.length > 0 ? widgetArr?.dsi?.length > 0 ? `${widgetArr.dsi.filter(vl =>
                                state.InteListArr.some(({ value }) => value === vl.uid + '_' + (vl.pid ? vl.pid : ''))
                              ).length} selected` : '0 selected' : 'No integrations available'}

                            </Text>
                          </Button>}>
                          <Box padding={300}>
                            {state.isLoadDtSrcSel ? <Text>Loading...</Text>
                              :
                              <ChoiceList
                                allowMultiple
                                choices={state.InteListArr || []}
                                selected={dsiSelect}
                                onChange={(e) => handleSelectDataSrc(e)} />
                            }
                          </Box>
                        </Popover>
                      </BlockStack>
                    </Box>

                    <Checkbox
                      checked={widgetArr?.ihwc}
                      onChange={(e) => handleMainTextChange({ ihwc: e })}
                      label={<Text as='span'>Hide post without content</Text>}
                    />
                    <Box paddingBlockStart={100} paddingBlockEnd={200}>
                      <Checkbox
                        checked={widgetArr?.ihwi}
                        onChange={(e) => handleMainTextChange({ ihwi: e })}
                        label={<Text as='span'>Hide post without image</Text>}
                      />
                    </Box>

                    <Box paddingInlineStart={200} paddingBlockStart={150}>
                      <BlockStack gap={300}>
                        <TextField
                          type='Number'
                          placeholder='Enter count'
                          label='Minumum Rating display'
                          onChange={(e) => handleMainTextChange({ mnrt: Number(e) })}
                          value={widgetArr?.mnrt || ''} />

                        <BlockStack gap={100}>   {/* include keyword */}
                          <div onKeyDown={(e) => handleKeyDown(e, 'inkw')}>
                            <TextField
                              label="Include Keywords"
                              value={state.inkInput}
                              onChange={(value) => changeNameValue({ inkInput: value })}
                              placeholder="Type a keyword and press Enter"
                              autoComplete="off" />
                          </div>

                          <InlineStack gap={200}>
                            {widgetArr?.inkw?.length > 0 && widgetArr.inkw.map((keyword, index) =>
                              <Tag key={index} onRemove={() => handleRemoveKeyword(keyword)}>{keyword}</Tag>
                            )}
                          </InlineStack>
                        </BlockStack>

                        <BlockStack gap={100}> {/* exclude keyword */}
                          <div onKeyDown={(e) => handleKeyDown(e, 'exkw')}>
                            <TextField
                              label="Exclude Keywords"
                              value={state.exkInput}
                              onChange={(value) => changeNameValue({ exkInput: value })}
                              placeholder="Type a keyword and press Enter"
                              autoComplete="off" />
                          </div>
                          <InlineStack gap={200}>
                            {widgetArr?.exkw?.length > 0 && widgetArr.exkw.map((keyword, index) =>
                              <Tag key={index} onRemove={() => handleRemoveKeyword(keyword, 'exkw')}>{keyword}</Tag>
                            )}
                          </InlineStack>
                        </BlockStack>

                        <BlockStack gap={100}>
                          <Text as='span'>Select tags</Text>
                          <Popover
                            fullWidth
                            active={state.isSelTag}
                            onClose={() => changeNameValue({ isSelTag: !state.isSelTag })}
                            activator={<Button textAlign="left" fullWidth disclosure
                              onClick={() => changeNameValue({ isSelTag: !state.isSelTag })}>
                              <Text variant='bodyMd' tone='subdued'>
                                {widgetArr?.arrtgid?.length > 0 && widgetArr.arrtgid.map(tgid => state.TagListArr?.length > 0 && state.TagListArr.find(({ value }) => value === tgid)?.label).filter(Boolean).join(', ') || 'Select'}
                              </Text>
                            </Button>}>
                            <Box padding={300}>
                              <ChoiceList
                                allowMultiple
                                choices={state.TagListArr || []}
                                selected={widgetArr?.arrtgid || []}
                                onChange={(e) => handleMainTextChange({ arrtgid: e })} />
                            </Box>
                          </Popover>
                        </BlockStack>

                      </BlockStack>
                    </Box>
                  </Box>
                }
              </Box>
            </Box>
          </Box>

        </Box>
        :
        state.sidebarActiveIndex === 1 ?
          <Box>

            <InlineGrid columns={2} gap={300}>
              {state.mainLayoutArr && state.mainLayoutArr.length > 0 &&
                state.mainLayoutArr.map((val, i) => {
                  return (
                    <div key={i} className={val._id === state.currLayoutId ? 'select_block active' : 'select_block'}
                      onClick={() => changeNameValue({ currLayoutId: val._id }, 'isEditable')}>
                      <InlineStack align='center' blockAlign='center'>
                        <Checkbox tone={val._id === state.currLayoutId ? 'magic' : ''}
                          checked={val._id === state.currLayoutId} />
                        <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                          {val.dn}</Text>
                      </InlineStack>
                      <img className='border_NoneImg' src={val.iu} alt="Skeleton" />
                    </div>
                  );
                })}
            </InlineGrid>

            {cnf && cnf.ibrnd !== undefined &&
              <Box paddingBlock={400}>
                <InlineStack gap={200} blockAlign='center'>
                  <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                    <Checkbox
                      disabled={state.isFreePln === true}
                      label={<Text fontWeight='medium' as='span'>Branding</Text>}
                      checked={cnf.ibrnd}
                      onChange={(e) => handleTextChange({ ibrnd: e })} />
                  </Tooltip>
                  <Box>
                    <Badge tone='warning'>Exclusive</Badge>
                  </Box>
                </InlineStack>
              </Box>
            }

          </Box>
          :
          state.sidebarActiveIndex === 2 ?
            <Box>

              <InlineGrid columns={2} gap={300}>
                {state.cardArr && state.cardArr.length > 0 && state.cardArr.map((val, i) => {
                  return (
                    <div key={i} className={val._id === state.currCardId ? 'select_block active' : 'select_block'}
                      onClick={() => changeNameValue({ currCardId: val._id }, 'isEditable')}>
                      <InlineStack align='center' blockAlign='center'>
                        <Checkbox tone={val._id === state.currCardId ? 'magic' : ''}
                          checked={val._id === state.currCardId} />
                        <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                          {val.dn}</Text>
                      </InlineStack>
                      <Thumbnail source={val.iu} size="large" alt="Layout" />
                    </div>
                  );
                })}
              </InlineGrid>

              {cnf && cnf.crdirvwnm !== undefined &&
                <Box paddingBlock={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show reviewer name</Text>}
                    checked={cnf.crdirvwnm}
                    onChange={(e) => handleTextChange({ crdirvwnm: e })}
                  />
                  <Box paddingBlockStart={200} paddingInlineStart={600}>
                    <Text as='span'>Select name format</Text>
                    <Box paddingBlockStart={100}>
                      {cnf.crdirvwnm !== true ?
                        <TextField value={nameOpt[cnf.crdrvwnmfrmt].label} disabled={!cnf.crdirvwnm} />
                        :
                        <Popover
                          fullWidth
                          active={state.isNmFrmt}
                          onClose={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                          activator={<Button textAlign="left" fullWidth disclosure
                            onClick={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}>
                            <Text variant='bodyMd' tone='subdued'>
                              {nameOpt.find(({ value }) => value === cnf.crdrvwnmfrmt)?.label || 'Select'}
                            </Text>
                          </Button>}>
                          <OptionList
                            options={nameOpt}
                            selected={[cnf.crdrvwnmfrmt]}
                            onChange={(e) => {
                              handleTextChange({ crdrvwnmfrmt: Number(e) });
                              changeNameValue({ isNmFrmt: !state.isNmFrmt });
                            }} />
                          {/* onChange={() => changeNameValue({ isNmFrmt: !state.isNmFrmt })}
                          onFocusOption={(e) => handleTextChange({ crdrvwnmfrmt: e })} /> */}
                        </Popover>
                      }
                    </Box>
                  </Box>
                </Box>
              }

              {cnf && cnf.crdittl !== undefined &&
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show review title</Text>}
                  checked={cnf.crdittl}
                  onChange={(e) => handleTextChange({ crdittl: e })}
                />
              }

              {cnf && cnf.crdidt !== undefined &&
                <Box paddingBlock={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show review date</Text>}
                    checked={cnf.crdidt}
                    onChange={(e) => handleTextChange({ crdidt: e })}
                  />
                  <Box paddingBlockStart={200} paddingInlineStart={600}>
                    <Text as='span'>Select date format</Text>
                    <Box paddingBlockStart={100}>
                      {cnf.crdidt !== true ?
                        <TextField value={cnf.crddtfrmt ? cnf.crddtfrmt : 'Select'} disabled={true} />
                        :
                        <Popover
                          fullWidth
                          active={state.isDtFrmt}
                          onClose={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}
                          activator={<Button textAlign="left" fullWidth disclosure
                            onClick={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}>
                            <Text variant='bodyMd' tone='subdued'>{cnf?.crddtfrmt || 'Select'}
                            </Text>
                          </Button>}>
                          <OptionList
                            options={dateOpt}
                            selected={cnf.crddtfrmt}
                            onChange={(e) => {
                              handleTextChange({ crddtfrmt: Number(e) });
                              changeNameValue({ isDtFrmt: !state.isDtFrmt });
                            }} />
                          {/* onChange={() => changeNameValue({ isDtFrmt: !state.isDtFrmt })}
                          onFocusOption={(e) => handleTextChange({ crddtfrmt: e })} /> */}
                        </Popover>
                      }
                    </Box>
                  </Box>
                </Box>
              }

              <Box paddingInlineStart={600}>
                <TextField
                  value={cnf.dsplshpnm ? cnf.dsplshpnm : ''}
                  label={<Text fontWeight='medium' as='span'>Shop name</Text>}
                  onChange={(e) => handleTextChange({ dsplshpnm: e })} />
              </Box>

              {cnf && cnf.ivrfd !== undefined &&
                <Box paddingBlock={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show verified badge</Text>}
                    checked={cnf.ivrfd}
                    onChange={(e) => handleTextChange({ ivrfd: e })}
                  />
                  <Box paddingBlockStart={400}>
                    <Checkbox
                      disabled={!cnf.ivrfd}
                      label={<Text fontWeight='medium' as='span'>Show verified text on hover</Text>}
                      checked={cnf.ivrfdtxthvr}
                      onChange={(e) => handleTextChange({ ivrfdtxthvr: e })}
                    />
                    <Box paddingBlockStart={100} paddingInlineStart={600}>
                      <TextField
                        disabled={!cnf.ivrfd}
                        onChange={(e) => handleTextChange({ vrfdtxt: e })}
                        value={cnf.vrfdtxt} />
                    </Box>
                  </Box>
                </Box>
              }

              {cnf && cnf.irvwvtng !== undefined &&
                <Checkbox
                  label={<Text fontWeight='medium' as='span'>Show review upvotes/downvotes</Text>}
                  checked={cnf.irvwvtng}
                  onChange={(e) => handleTextChange({ irvwvtng: e })}
                />
              }

              {cnf && cnf.crdilctn !== undefined &&
                <Box paddingBlockStart={400}>
                  <Checkbox
                    label={<Text fontWeight='medium' as='span'>Show location</Text>}
                    checked={cnf.crdilctn}
                    onChange={(e) => handleTextChange({ crdilctn: e })}
                  />
                  <Box paddingBlockStart={100} paddingInlineStart={600}>
                    <TextField
                      disabled={!cnf.crdilctn}
                      onChange={(e) => handleTextChange({ crdlctnstr: e })}
                      value={cnf.crdlctnstr}
                    />
                  </Box>
                </Box>
              }

            </Box>
            :
            state.sidebarActiveIndex === 3 ?
              <Box>

                {cnf && cnf.hdrittl !== undefined &&
                  <Box>
                    <Checkbox
                      label={<Text fontWeight='medium' as='span'>Show title text</Text>}
                      checked={cnf.hdrittl}
                      onChange={(e) => handleTextChange({ hdrittl: e })}
                    />
                    <Box paddingBlockStart={100} paddingInlineStart={600}>
                      <TextField
                        disabled={!cnf.hdrittl}
                        value={cnf.hdrttltxt}
                        onChange={(e) => handleTextChange({ hdrttltxt: e })}
                        autoComplete="off" />
                    </Box>
                  </Box>
                }

                {cnf && cnf.hdristrrtng !== undefined &&
                  <Box paddingBlock={400}>
                    <Checkbox
                      label={<Text fontWeight='medium' as='span'>Show average star rating</Text>}
                      checked={cnf.hdristrrtng}
                      onChange={(e) => handleTextChange({ hdristrrtng: e })}
                    />
                    <Box paddingInlineStart={600}>
                      <Box paddingBlockStart={100} paddingBlockEnd={200}>
                        <TextField
                          disabled={!cnf.hdristrrtng}
                          value={cnf.hdravgtxt1}
                          onChange={(e) => handleTextChange({ hdravgtxt1: e })}
                          autoComplete="off" />
                      </Box>
                      <TextField
                        disabled={!cnf.hdristrrtng}
                        value={cnf.hdravgtxt2}
                        onChange={(e) => handleTextChange({ hdravgtxt2: e })}
                        autoComplete="off" />
                    </Box>
                  </Box >
                }

              </Box >
              :
              state.sidebarActiveIndex === 4 ?
                <Box>

                  <Text variant="bodyMd" fontWeight='bold'>Star rating summary</Text>
                  {cnf &&
                    <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={800}>

                      <InlineStack direction={'column'} gap={500}>
                        {cnf.hdrstrclr !== undefined &&
                          <ColorPick
                            labelName='Star color'
                            colorKey="hdrstrclr"
                            colorValue={cnf.hdrstrclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.hdrfntclr !== undefined &&
                          <ColorPick
                            labelName='Font color'
                            colorKey="hdrfntclr"
                            colorValue={cnf.hdrfntclr}
                            changeNameValue={handleTextChange} />
                        }
                      </InlineStack>
                    </Box>
                  }

                  <Text variant="bodyMd" fontWeight='bold'>Slider arrow</Text>
                  {cnf &&
                    <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={800}>
                      <InlineStack direction={'column'} gap={500}>
                        {cnf.crslarwclr !== undefined &&
                          <ColorPick
                            labelName='Arrow color'
                            colorKey="crslarwclr"
                            colorValue={cnf.crslarwclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crslarwbgclr !== undefined &&
                          <ColorPick
                            labelName='Arrow background color'
                            colorKey="crslarwbgclr"
                            colorValue={cnf.crslarwbgclr}
                            changeNameValue={handleTextChange} />
                        }
                      </InlineStack>
                    </Box>
                  }

                  <Text variant="bodyMd" fontWeight='bold'>Reviewer card</Text>
                  {cnf &&
                    <Box paddingInlineStart={300} paddingBlockStart={300} paddingBlockEnd={2400}>
                      <InlineStack direction={'column'} gap={500}>
                        {cnf.crdbgclr !== undefined &&
                          <ColorPick
                            labelName='Background color'
                            colorKey="crdbgclr"
                            colorValue={cnf.crdbgclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crdstrclr !== undefined &&
                          <ColorPick
                            labelName='Star color'
                            colorKey="crdstrclr"
                            colorValue={cnf.crdstrclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crddtclr !== undefined &&
                          <ColorPick
                            labelName='Date color'
                            colorKey="crddtclr"
                            colorValue={cnf.crddtclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crdrvwnmclr !== undefined &&
                          <ColorPick
                            labelName='Name color'
                            colorKey="crdrvwnmclr"
                            colorValue={cnf.crdrvwnmclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.vrfdtxtclr !== undefined &&
                          <ColorPick
                            labelName='Verified text color'
                            colorKey="vrfdtxtclr"
                            colorValue={cnf.vrfdtxtclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.vrfdbgclr !== undefined &&
                          <ColorPick
                            labelName='Verified bg color'
                            colorKey="vrfdbgclr"
                            colorValue={cnf.vrfdbgclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crdlctnstrclr !== undefined &&
                          <ColorPick
                            labelName='Location label text color'
                            colorKey="crdlctnstrclr"
                            colorValue={cnf.crdlctnstrclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crdttlclr !== undefined &&
                          <ColorPick
                            labelName='Review title color'
                            colorKey="crdttlclr"
                            colorValue={cnf.crdttlclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crdtxtclr !== undefined &&
                          <ColorPick
                            labelName='Review text color'
                            colorKey="crdtxtclr"
                            colorValue={cnf.crdtxtclr}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.vtngclrfll !== undefined &&
                          <ColorPick
                            labelName='Vote fill color'
                            colorKey="vtngclrfll"
                            colorValue={cnf.vtngclrfll}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.vtngclrntfll !== undefined &&
                          <ColorPick
                            labelName='Vote not-fill color'
                            colorKey="vtngclrntfll"
                            colorValue={cnf.vtngclrntfll}
                            changeNameValue={handleTextChange} />
                        }
                        {cnf.crslpnclr !== undefined &&
                          <ColorPick
                            labelName='Product-name color'
                            colorKey="crslpnclr"
                            colorValue={cnf.crslpnclr}
                            changeNameValue={handleTextChange} />
                        }
                      </InlineStack>
                    </Box>
                  }

                </Box >
                :
                state.sidebarActiveIndex === 5 ?
                  <Box>

                    {cnf && cnf.icstcss !== undefined &&
                      <Box>
                        <Checkbox
                          label={<Text fontWeight='medium' as='span'>Is custom css enabled</Text>}
                          checked={cnf.icstcss}
                          onChange={(e) => handleTextChange({ icstcss: e })}
                        />
                        <Box paddingBlockStart={200} paddingInlineStart={600}>
                          <TextField
                            label={<Text tone='subdued' as='span'>Custom css block</Text>}
                            disabled={!cnf.icstcss}
                            multiline={4}
                            value={cnf.cstcss}
                            onChange={(e) => handleTextChange({ cstcss: e })}
                            autoComplete="off" />
                        </Box>
                      </Box>
                    }
                  </Box>
                  :
                  state.sidebarActiveIndex === 6 &&
                  <Box>
                    {cnf && cnf.lng !== undefined &&
                      <Card>
                        <Select
                          label="Select language"
                          options={state.lngOptions}
                          onChange={props.handleLanguageChange}
                          value={cnf.lng}
                        />
                      </Card>
                    }
                  </Box>
      }

    </Box>
  );
}

export default TextCardCarousel;