import { Text, Button, ButtonGroup, InlineStack, InlineGrid, Box, Link, BlockStack, Collapsible, Icon, Divider } from '@shopify/polaris';
import { ArrowLeftIcon, ExternalIcon, AppExtensionIcon, CodeIcon, ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import { onboard_step_2, copy_icon, onboard_step_Wx2, error, warning_icon } from '../../img';

const BoardingStep2 = ({ props }) => {
  let mainWdgtCode = `{% render 'wnreview_widgets', widget_type: 'wnreview_main_widget', product: product %}`;
  let _pvd = localStorage.getItem('pvd') || '';     //steps for shopify and wix

  return (
    _pvd === 'shopify' ?
      <Box>
        <Box paddingBlockEnd={800}>
          <InlineGrid gap="800" columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
            <div className='border-right'>
              <div className='onboard-image'>
                <img src={onboard_step_2} width='100%' height='100%' alt="onboard" />
              </div>
            </div>

            <div>
              <BlockStack gap={{ xl: 1000, lg: 600, md: 600, sm: 800, xs: 800 }}>
                <Text as="h6" variant='bodyLg' fontWeight='semibold'>Step 2 / 6</Text>
                <Text variant="headingLg" as="h5" fontWeight='medium'>Add the Product Reviews section to theme</Text>
              </BlockStack>

              <Box paddingBlockStart={600}>

                {props.state.isShwStepOne === true &&
                  <div className="collapse-active coll-cta">
                    <div onClick={() => props.changeNameValue({ tabEvent: 'firstTabCollapse' })}>
                      <InlineStack blockAlign='center' align='space-between'>
                        <Box paddingBlock={200}>
                          <InlineStack gap={200}>
                            <Box paddingInlineStart={400}>
                              <Icon source={AppExtensionIcon} tone="base" />
                            </Box>
                            <Text as="h5" variant="headingSm" fontWeight='medium'>Embed App via Drag & Drop</Text>
                            <div className='warning_banner'>
                              <InlineStack gap={200} wrap={false}>
                                <img src={warning_icon} alt='warning' />
                                <Text variant='headingSm' fontWeight='medium' as='p'>For Shopify 2 Themes Users only</Text>
                              </InlineStack>
                            </div>
                          </InlineStack>
                        </Box>
                        <Box paddingInlineEnd={400}>
                          {props.state.tabEvent === 'firstTabCollapse' ?
                            <Icon source={ChevronUpIcon} tone="base"></Icon> :
                            <Icon source={ChevronDownIcon} tone="base"></Icon>}
                        </Box>
                      </InlineStack>
                    </div>

                    <Collapsible
                      open={props.state.tabEvent === 'firstTabCollapse'}
                      id="firstTabCollapse"
                      transition={{ duration: '500ms', timingFunction: 'ease-in' }}
                      expandOnPrint>
                      <Box paddingInlineStart={400} paddingBlockEnd={200} paddingBlockStart={200}>
                        <BlockStack gap={200}>
                          <Text variant='headingSm' fontWeight='regular'>1. Tap 'Embed App' to open setting</Text>
                          <Text variant='headingSm' fontWeight='regular'>2. In the new window, place the review section where you like and save.</Text>
                        </BlockStack>
                        <Box paddingBlockStart={300}>
                          <Button id='primary_btn' variant="primary" icon={ExternalIcon} url={`https://${localStorage.getItem('shp')}/admin/themes/current/editor?template=product&addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/productReview&target=newAppsSection`} target='_blank'>Embed App</Button>
                        </Box>
                      </Box>
                    </Collapsible>
                  </div>
                }
                <div className="collapse-active coll-cta">
                  <div onClick={() => props.changeNameValue({ tabEvent: 'secondTabCollapse' })}>
                    <InlineStack blockAlign='center' align='space-between'>
                      <Box paddingBlock={200}>
                        <InlineStack gap={200}>
                          <Box paddingInlineStart={400}>
                            <Icon source={CodeIcon} tone="base" />
                          </Box>
                          <Text as="h5" variant="headingSm" fontWeight='medium'>Manual Setup via Code</Text>
                          <div className='warning_banner'>
                            <InlineStack gap={200} wrap={false}>
                              <img src={warning_icon} alt='warning' />
                              <Text variant='headingSm' fontWeight='medium' as='p'>Recommended for Vintage Themes Users</Text>
                            </InlineStack>
                          </div>
                        </InlineStack>
                      </Box>
                      <Box paddingInlineEnd={400}>
                        {props.state.tabEvent === 'secondTabCollapse' ?
                          <Icon source={ChevronUpIcon} tone="base"></Icon> :
                          <Icon source={ChevronDownIcon} tone="base"></Icon>}
                      </Box>
                    </InlineStack>
                  </div>
                  <Collapsible
                    open={props.state.tabEvent === 'secondTabCollapse'}
                    id="secondTabCollapse"
                    transition={{ duration: '500ms', timingFunction: 'ease-in' }}
                    expandOnPrint>
                    <Box paddingInline={400} paddingBlock={200}>
                      <Box>
                        <Text variant="headingSm" as="h6" fontWeight='regular'>
                          1. Open <Link target='_blank' url={`https://${localStorage.getItem('shp')}/admin/themes/current?key=templates/product.liquid`}> templates/product.liquid</Link> editor <br />

                          <Box paddingBlockStart={300} paddingBlockEnd={400}>
                            2. Under &#123;% section 'product-template' %&#125; add the following code <br />
                          </Box>

                          <div className="copycode-box">
                            <InlineStack wrap={false} blockAlign='start'>
                              <textarea id='copy_code' className="textarea-copycode" readOnly={true} onClick={(e) => props.handleCopyCode(e, mainWdgtCode)} value={mainWdgtCode} >
                              </textarea>
                              <Button variant='monochromePlain' onClick={() => props.handleCopyCode('', mainWdgtCode)}><img src={copy_icon} alt='copy'></img>
                              </Button>
                            </InlineStack>
                          </div>
                          <Box paddingBlockStart={200}>
                            3. When you're done, click Save.
                          </Box>
                        </Text>
                      </Box>
                    </Box>
                  </Collapsible>
                </div>

                <Box paddingBlockStart={200}>
                  <div className='info_banner'>
                    <InlineStack gap={200} wrap={false}>
                      <img src={error} alt='error' />
                      <Text variant='bodyMd' fontWeight='medium' as='p'>If you need any assistance, our support team is here to <Button variant='plain' onClick={() => props.handleDeskuShow()}>help!</Button></Text>
                    </InlineStack>
                  </div>
                </Box>
              </Box>

            </div>

          </InlineGrid>
        </Box>
        <Divider />
        <Box paddingBlockStart={400}>
          <InlineStack align="space-between">
            <Button id='plain_clr' variant={'plain'} icon={ArrowLeftIcon} onClick={() => props.handleNextStep('step1', 'Back_Step2')}>Back</Button>
            <ButtonGroup>
              <Button variant={'plain'} onClick={() => props.handleNextStep('step3', 'Skip_Step2')}>Skip</Button>
              <Button id='primry_clr' variant='primary' onClick={() => props.handleNextStep('step3', 'Step2')}>Continue</Button>
            </ButtonGroup>
          </InlineStack>
        </Box>
      </Box>
      :
      _pvd === 'wix' &&
      <Box>
        <Box paddingBlockEnd={{ xs: 6, sm: 3, md: 3, lg: 400, xl: 800 }}>
          <InlineGrid gap="800" columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
            <div className='border-right'>
              <div className='onboard-image onboard-image-border' style={{ display: 'flex', alignItems: 'center' }} >
                <img style={{ objectFit: 'cover' }} src={onboard_step_Wx2} width='429px' height='297px' alt="onboard" />
              </div>
            </div>

            <div>
              <Box paddingBlockEnd={600}>
                <BlockStack gap={{ xl: 1000, lg: 600, md: 600, sm: 800, xs: 800 }}>
                  <Text as="h6" variant='bodyLg' fontWeight='semibold'>Step 2 / 5</Text>
                  <Text variant="headingLg" as="h5" fontWeight='medium'>
                    Add the Product Reviews Section to Your theme. </Text>
                </BlockStack>
              </Box>

              <BlockStack gap={600}>
                <div className='ob_import_bg'>
                  <Text as="h3" variant='headingMd' fontWeight='regular'>Here’s how: </Text>
                  <Box paddingBlockStart={200} maxWidth='90%'>
                    <BlockStack gap={200}>
                      <Text as="h5" variant='headingMd' fontWeight='regular'>1. Open product page in theme editor</Text>
                      <Text as="h5" variant='headingMd' fontWeight='regular'>2. Find and Add plugin: “Product Review” by WisetNotify Product Reviews</Text>
                      <Text as="h5" variant='headingMd' fontWeight='regular'>3. When you're done, click Save & Publish.</Text>
                    </BlockStack>
                  </Box>
                </div>

                <div className='info_banner'>
                  <InlineStack gap={200} wrap={false}>
                    <img src={error} alt='error' />
                    <Text variant='bodyMd' fontWeight='medium' as='p'>If you need any assistance, our support team is here to  <Button variant='plain' onClick={() => props.handleDeskuShow()}>help!</Button></Text>
                  </InlineStack>
                </div>
              </BlockStack>

            </div>
          </InlineGrid>
        </Box>
        <Divider />
        <Box paddingBlockStart={400}>
          <InlineStack align="space-between">
            <Button variant={'monochromePlain'} icon={ArrowLeftIcon} onClick={() => props.handleNextStep('step1', 'Back_Step2')}><Text fontWeight='medium'>Back</Text></Button>
            <ButtonGroup>
              <Button variant={'plain'} onClick={() => props.handleNextStep('step3', 'Skip_Step2')}>Skip</Button>
              <Button id='primry_clr' variant='primary' onClick={() => props.handleNextStep('step3', 'Step2')}>Continue</Button>
            </ButtonGroup>
          </InlineStack>
        </Box>
      </Box>
  );
}

export default BoardingStep2;