import { InlineGrid, BlockStack, Link, Modal, Collapsible, Badge, Text, Card, Page, Button, ButtonGroup, Box, Icon, InlineStack } from '@shopify/polaris';
import { ExternalIcon, PaperCheckIcon, DnsSettingsIcon, AppExtensionIcon, CodeIcon, ChevronUpIcon, ChevronDownIcon, StarFilledIcon, StarIcon } from '@shopify/polaris-icons';
import { warning_icon, copy_icon } from '../../img';
// import MainTab from './MainTab';
import AllReviewTab from './AllReviewTab';
import PreviewCard from './Preview';
import React, { useEffect } from 'react';
import { toastify } from '../../ducks/toast';
import { useDispatch, useSelector } from 'react-redux';

let shpName = localStorage.getItem('shp') ? localStorage.getItem('shp') : '';

const EditPanel = ({ props }) => {
  const {
    state,
    changeNameValue,
    handleCopyCode,
    tabEvent,
    setTabEvent,
    handleBackToMain,
    openCloseModal,
    handleTabClick,
    movingClass,
    handlePreviewDiscrd,
    handleSaveChange,
    callResetApi,
    handleTextChange
  } = props;

  const dispatch = useDispatch();

  let tabsName = [{   //default show 'display' tab
    typ: 'displayReview',
    label: 'Display review',
    slctIcon: StarFilledIcon,
    disSlct: StarIcon
  }];

  //if 'wrtfrmlst' list is not empty, then display 'writeReview' tab
  if (state.writeArr?.length > 0) {
    const label = state.widgetTyp === 'que_ans' ? 'Write question' : 'Write review';
    tabsName.push({ typ: 'writeReview', label, slctIcon: PaperCheckIcon });
  }

  useEffect(() => { //on 1st time or refresh set to 1st default active 
    movingClass(0);
  }, []);

  useEffect(() => { //user complete the 1st step, hide that step in install widget modal.
    let obj = {};
    if (state?.profileState?.isvntgthm === true) {
      obj['isShwStepOne'] = false;
      obj['tabEvent'] = 'secondStepOpen';
    }
    changeNameValue(obj);

    if (state.profileState && state.profileState.pvd && state.profileState.pvd === 'shopify') {
      shpName = state.profileState.objshp.d;
    }
  }, [state.profileState, state.installOpen]);



  let wdgtId = state?.widgetArr?._id || '';

  let InstallStepsArr = [
    {
      typ: 'main',
      enableUrl: `https://${shpName}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/pixel&target=newAppsSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=templates/product.liquid`}><strong>sections/product-template.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code at the bottom of the file.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      ShopiCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_main_widget', product: product %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_main_widget', product: product %}`,
      copyCode: `<div id="wISpfRvwApp_main" data-id="wISpfRvwApp_main_widget" class="wISpfRvwApp_main_widget" data-category="wISpfRvwApp" data-type="main"></div><script src="https://storage.googleapis.com/shopify_review_app/bundle/${wdgtId}/widget.js"></script>`
    },
    {
      typ: 'star_rating',
      enableUrl: `https://${shpName}/admin/themes/current/editor?template=product&addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/starRating&target=mainSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=sections/product-template.liquid`}><strong>sections/product-template.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code under the <strong>&#123;&#123; product.title &#125;&#125; element.</strong></Text>
        </BlockStack>,
      ShopiCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_star_rating', product: product %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_star_rating', product: product %}`,
      copyCode: `<div id="wISpfRvwApp_star_rating" data-pid="0" data-category="wISpfRvwApp" data-type="star_rating" class="wISpfRvwApp_star_widget"></div><script src="https://storage.googleapis.com/shopify_review_app/bundle/${wdgtId}/widget.js"></script>`
    },
    {
      typ: 'carousel',
      enableUrl: `https://${shpName}/admin/themes/current/editor?addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/carousel&target=newAppsSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=templates/product.liquid`}><strong>templates/product.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code at the bottom of the file.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      ShopiCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_carousel' %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_carousel' %}`,
      copyCode: `<div id="wISpfRvwApp_carousel" data-category="wISpfRvwApp" data-type="carousel" class="wISpfRvwApp_carousel"></div>
<script src="https://storage.googleapis.com/shopify_review_app/bundle/${wdgtId}/widget.js"></script>`
    },
    {
      typ: 'wall',
      enableUrl: `https://${shpName}/admin/themes/current/editor?addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/reviewWall&target=newAppsSection`,
      listItem:
        <InlineStack gap={400} direction={'column'}>
          <BlockStack gap={300}>
            <Text variant='headingMd' fontWeight='regular'>1. From your Shopify admin, go to <strong>Online Store &gt; Themes.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>2. Click <strong>-&gt; Edit code.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>3. Click <strong>Add a new template.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>4. Select <strong>Page, Liquid,</strong> and name the template <strong>"wnreview_wall".</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>5. Click <strong>Done.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>6. Delete the existing code and paste the following code into the file.</Text>
            <Text variant='headingMd' fontWeight='regular'>7. When you're done, click <strong>Save.</strong></Text>
          </BlockStack>
          <span>Then</span>
          <BlockStack gap={300}>
            <Text variant='headingMd' fontWeight='regular'>1. From your Shopify admin, go to <strong>Online Store &gt; Pages.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>2. Click <strong>Add page.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>3. Give the page a title (e.g. "Happy Customers").</Text>
            <Text variant='headingMd' fontWeight='regular'>4. In the <strong>Online store section</strong> on the right, select <strong>wnreview_wall.</strong></Text>
            <Text variant='headingMd' fontWeight='regular'>5. When you're done, click <strong>Save.</strong></Text>
          </BlockStack>
        </InlineStack>,
      ShopiCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_wall_widget' %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_wall_widget' %}`,
      copyCode: `<div id="wISpfRvwApp_review_wall" data-type="wall" data-category="wISpfRvwApp" class="wISpfRvwApp_wall_widget"></div>
<script src="https://storage.googleapis.com/shopify_review_app/bundle/${wdgtId}/widget.js"></script>`
    },
    {
      typ: 'verified_badge',
      enableUrl: `https://${shpName}/admin/themes/current/editor?addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/verifiedBadge&target=newAppsSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=templates/product.liquid`}><strong>templates/product.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code at the bottom of the file.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      ShopiCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_verified_badge' %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_verified_badge' %}`,
      copyCode: `<div id="wISpfRvwApp_verified_badge" data-category="wISpfRvwApp" data-type="verified_badge" class="wISpfRvwApp_verified_badge"></div>
<script src="https://storage.googleapis.com/shopify_review_app/bundle/${wdgtId}/widget.js"></script>`
    },
    {
      typ: 'carousel_product',
      enableUrl: `https://${shpName}/admin/themes/current/editor?template=product&addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/carouselProduct&target=mainSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=sections/product-template.liquid`}><strong>sections/product-template.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code at the bottom of the file.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      ShopiCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_carousel_product' %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_carousel_product' %}`,
      copyCode: `<div id="wISpfRvwApp_carousel_product" data-category="wISpfRvwApp" data-type="carousel_product" class="wISpfRvwApp_carousel_product"></div>
<script src="https://storage.googleapis.com/shopify_review_app/bundle/${wdgtId}/widget.js"></script>`
    },
    {
      typ: 'ugc',
      enableUrl: `https://${shpName}/admin/themes/current/editor?addAppBlockId=c9dad0b1-2b42-42c3-b484-666f36432323/ugc&target=newAppsSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=templates/product.liquid`}><strong>templates/product.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code at the bottom of the file.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      ShopiCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_ugc_widget' %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_ugc_widget' %}`,
      copyCode: `<div id="wISpfRvwApp_ugc" data-id="wISpfRvwApp_ugc_widget" class="wISpfRvwApp_ugc_widget" data-category="wISpfRvwApp" data-type="ugc"></div>
<script src="https://storage.googleapis.com/shopify_review_app/bundle/${wdgtId}/widget.js"></script>`
    },
    {
      typ: 'sidebar_cta',
      enableUrl: `https://${shpName}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/sidebarCTA&target=newAppsSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=layout/theme.liquid`}><strong>sections/product-template.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code  immediately after the opening	&lt;body&gt; tag.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      ShopiCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_sidebar_cta' %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_sidebar_cta' %}`,
      copyCode: `<div id="wISpfRvwApp_sidebar_cta" data-id="wISpfRvwApp_sidebar_cta" class="wISpfRvwApp_sidebar_cta" data-category="wISpfRvwApp" data-type="sidebar_cta"></div>
<script src="https://storage.googleapis.com/shopify_review_app/bundle/${wdgtId}/widget.js"></script>`
    },
    {
      typ: 'popup_widget',
      enableUrl: `https://${shpName}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/floatingPopup&target=newAppsSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=layout/theme.liquid`}><strong>sections/product-template.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code immediately after the opening	&lt;body&gt; tag.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      ShopiCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_popup_widget' %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_popup_widget' %}`,
      copyCode: `<div id="wISpfRvwApp_popup_widget" data-id="wISpfRvwApp_popup_widget" class="wISpfRvwApp_popup_widget" data-category="wISpfRvwApp" data-type="popup_widget"></div>
<script src="https://storage.googleapis.com/shopify_review_app/bundle/${wdgtId}/widget.js"></script>`
    },
    {
      typ: 'avatar',
      enableUrl: `https://${shpName}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/floatingPopup&target=newAppsSection`,
      listItem:
        <BlockStack gap={300}>
          <Text variant='headingMd' fontWeight='regular'>1. Open <Link target='_blank' url={`https://${shpName}/admin/themes/current?key=layout/theme.liquid`}><strong>sections/product-template.liquid.</strong></Link></Text>
          <Text variant='headingMd' fontWeight='regular'>2. Add the following code immediately after the opening	&lt;body&gt; tag.</Text>
          <Text variant='headingMd' fontWeight='regular'>3. When you're done, click <strong>Save.</strong></Text>
        </BlockStack>,
      ShopiCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_popup_widget' %}`,
      wixCode: `{% render 'wnreview_widgets', widget_type: 'wnreview_popup_widget' %}`,
      copyCode: `<div id="wISpfRvwApp_avatar" data-category="wISpfRvwApp" data-type="avatar" class="wISpfRvwApp_avatar"></div>
<script src="https://storage.googleapis.com/shopify_review_app/bundle/${wdgtId}/widget.js"></script>`
    }
  ];

  const isDsplyInstlStp = localStorage.getItem('pvd') !== 'wix' ?
    InstallStepsArr?.length > 0 && InstallStepsArr.some(step => step.typ === state.widgetTyp) : false;


  const nWEditScreenBack = () => {
    changeNameValue({ currNwWdgtId: '' });

    if (state.isEdit === true) {
      dispatch(toastify({ type: 'error', msg: 'Please save or discard your changes before proceeding' }));
      return;
    }

    if (localStorage.getItem('pvd') === 'shopify') {
      handleBackToMain();
    } else {
      changeNameValue({ clkOnCustomize: false });
    }
  }

  return (
    <Page
      title={state.dn}
      subtitle={state.rm}
      backAction={{ onAction: () => nWEditScreenBack() }}
    >

      <Box paddingInlineStart={200}>
        <div className='widgets-preview-wrap-part'>
          <InlineGrid columns={2}>
            <div className='widgets-preview-left'>

              {/* Install steps section */}
              {isDsplyInstlStp &&
                <Card padding={'300'}>
                  <InlineStack align='space-between'>
                    <InlineStack gap={200} align='center' blockAlign='center'>
                      <Badge tone='info-strong'>
                        <Box paddingBlock={100}><Icon source={DnsSettingsIcon} /></Box>
                      </Badge>
                      <Text fontWeight='medium' tone='subdued'>Learn how to add the {state.dn} to your theme.</Text>
                    </InlineStack>
                    <Button onClick={() => {
                      openCloseModal('installOpen', state.installOpen, 'open');
                      changeNameValue({ firstStepOpen: true });
                    }}>Install</Button>
                  </InlineStack>
                </Card>
              }

              <Card padding={'100'}>
                <div className={isDsplyInstlStp ? 'wdgt-prvw-inpart' : 'wdgt-hght wdgt-prvw-inpart'}>
                  <InlineGrid columns={2}>
                    <div className="left_section">
                      <Box paddingBlock={300} paddingInlineStart={300}>

                        {/* display review tab && write review tab */}
                        {tabsName?.length > 1 &&
                          <Box paddingInlineEnd={1000}>
                            <Card padding={100} roundedAbove="lg">
                              <ButtonGroup fullWidth>
                                {tabsName && tabsName.length > 0 &&
                                  tabsName.map((data, i) => {
                                    return (
                                      <Button key={i} id={state.newActiveTab === data.typ ? 'violet_btn' : ''}
                                        onClick={() => handleTabClick('newActiveTab', data.typ)} variant=''
                                        icon={<Icon source={state.newActiveTab === data.typ ? data.slctIcon : data.disSlct ? data.disSlct : data.slctIcon}
                                          tone={state.newActiveTab === data.typ ? '' : 'subdued'} />}>
                                        <Text fontWeight='medium' tone={state.newActiveTab === data.typ ? '' : 'subdued'}>
                                          {data.label}</Text>
                                      </Button>
                                    );
                                  })}
                              </ButtonGroup>
                            </Card>
                          </Box>
                        }

                        {/* all fields sidebar (vertical tabs) */}
                        <div className='nf-sidebar'>
                          <div className='nf-sidebar-block'>
                            {state.verticalTabsArr && state.verticalTabsArr.length > 0 &&
                              state.verticalTabsArr.map((data, i) => {
                                return (
                                  <div key={i} id={`moveLink_${i}`} onClick={() => movingClass(i)}
                                    className={state.sidebarActiveIndex === i ? 'side-active move_div' : 'move_div'}>
                                    <div className='move_Inner'>
                                      <InlineStack gap={200} blockAlign='center'>
                                        <Icon source={data.img} tone={state.sidebarActiveIndex === i ? 'base' : 'subdued'} />
                                        <Text tone={state.sidebarActiveIndex === i ? '' : 'subdued'} fontWeight='medium'>
                                          {data.title}</Text>
                                      </InlineStack>
                                    </div>
                                  </div>
                                );
                              })
                            }
                          </div>
                          {/* moving class */}
                          <div className="sidebar-ani-block is-active" id='Wdgt_sidebar_Blk'></div>
                        </div>

                        {state.isEdit === true && //discard && save btn
                          <Box id="discrd_Svebtn" paddingInlineStart={500} paddingInlineEnd={800}>
                            <ButtonGroup fullWidth>
                              <Button variant='secondary' onClick={() => handlePreviewDiscrd()}>Discard</Button>
                              <Button id='primary_btn' onClick={() => handleSaveChange()}>Save</Button>
                            </ButtonGroup>
                          </Box>
                        }

                      </Box>
                    </div>

                    {/* tabs content */}
                    <AllReviewTab
                      props={props}
                      state={state}
                      changeNameValue={changeNameValue}
                      handleTextChange={handleTextChange} />

                  </InlineGrid>
                </div>
              </Card>
            </div>

            {/* preview card */}
            <PreviewCard
              state={state}
              changeNameValue={changeNameValue}
              openCloseModal={openCloseModal}
              callResetApi={callResetApi} />

          </InlineGrid>
        </div>
      </Box>




      {/* Install step modal */}
      {InstallStepsArr && InstallStepsArr.map((val, i) => {
        if (val.typ === localStorage.getItem('WS_PRVW_TYP')) {

          return (
            <Modal
              key={i}
              open={state.installOpen}
              onClose={(e) => openCloseModal('installOpen', state.installOpen, e)}
              title={<Text variant='headingMd' fontWeight='bold' as='h3'>
                {localStorage.getItem('pvd') === 'shopify' ? 'Follow the below mention steps to enable this widget'
                  : 'Place below code to your website'}</Text>}>
              <Modal.Section>

                {localStorage.getItem('pvd') === 'wix' ?
                  <Box id='custom_infoBG' padding={400}>
                    <div id='cursorPoint' onClick={() => setTabEvent('firstStepOpen')}>
                      <InlineStack align='space-between'>
                        <InlineStack align='center' blockAlign='center' gap={200}>
                          <Box><Icon source={CodeIcon} /></Box>
                          <Text variant='bodyLg' fontWeight='medium' as='h1'>Manual Setup via Code</Text>
                          <div className='warning_banner'>
                            <InlineStack gap={200} wrap={false}>
                              <img src={warning_icon} alt='warning' />
                              <Text variant='bodySm' fontWeight='medium' as='p'>Recommended for Vintage Themes Users</Text>
                            </InlineStack>
                          </div>
                        </InlineStack>
                        <Box>
                          <Icon source={tabEvent === 'firstStepOpen' === true ? ChevronUpIcon : ChevronDownIcon}></Icon>
                        </Box>
                      </InlineStack>
                    </div>

                    <Collapsible
                      open={tabEvent === 'firstStepOpen'}
                      id="db_collaspe"
                      transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                      <Box paddingInlineStart={100} paddingBlockStart={400}>
                        {/* copy code box */}
                        <div className="copycode-box">
                          <InlineStack align='start' blockAlign='start'>
                            <textarea id='copy_code' className="textarea-copycode" readOnly={true}
                              onClick={(e) => handleCopyCode(e, val.wixCode)} value={val.wixCode}>
                            </textarea>
                            <Button variant='monochromePlain'
                              onClick={() => handleCopyCode('', val.wixCode)}>
                              <img src={copy_icon} alt='copy'></img>
                            </Button>
                          </InlineStack>
                        </div>
                      </Box>
                    </Collapsible>

                  </Box>
                  :
                  localStorage.getItem('pvd') === 'shopify' ?
                    <BlockStack gap={400}>
                      {state.isShwStepOne === true &&
                        <Box id='custom_infoBG' padding={400}>
                          <div id='cursorPoint'
                            onClick={() => setTabEvent('firstStepOpen')} >
                            <InlineStack align='space-between'>
                              <InlineStack align='center' blockAlign='center' gap={200}>
                                <Box><Icon source={AppExtensionIcon} /></Box>
                                <Text variant='bodyLg' fontWeight='medium' as='h1'>Embed app via drag & Drop</Text>
                                <div className='warning_banner'>
                                  <InlineStack gap={200} wrap={false}>
                                    <img src={warning_icon} alt='warning' />
                                    <Text variant='bodySm' fontWeight='medium' as='p'>For shopify 2 themes users only</Text>
                                  </InlineStack>
                                </div>
                              </InlineStack>
                              <Box>
                                <Icon source={tabEvent === 'firstStepOpen' ? ChevronUpIcon : ChevronDownIcon}></Icon>
                              </Box>
                            </InlineStack>
                          </div>

                          <Collapsible
                            open={tabEvent === 'firstStepOpen'}
                            id="db_collaspe"
                            transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                            <Box paddingInlineStart={100} paddingBlockStart={400}>
                              <BlockStack gap={300}>
                                <Text variant='headingSm' fontWeight='regular' tone='subdued'>
                                  1. Click on Enable the app & click on save button (top-right)</Text>
                                <Text variant='headingSm' fontWeight='regular' tone='subdued'>
                                  <InlineStack gap={100}>
                                    <Box maxWidth='10%'>2.</Box>
                                    <Box maxWidth='90%'>Then click on {state.dn} & place you want to show the review section & save the change</Box>
                                  </InlineStack>
                                </Text>
                              </BlockStack>
                              <Box paddingBlockStart={400}>
                                <InlineStack gap={400} direction={'column'} align='start' blockAlign='start'>
                                  <Button id='primary_btn' target='_blank' url={`https://${shpName}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/pixel&target=newAppsSection`} icon={ExternalIcon}>Enable app</Button>
                                  <Button id='primary_btn' target='_blank' url={val.enableUrl} icon={ExternalIcon}>Embed {state.dn}</Button>
                                </InlineStack>
                              </Box>
                            </Box>
                          </Collapsible>

                        </Box>
                      }
                      <Box id='custom_infoBG' padding={400}>
                        <div id='cursorPoint' onClick={() => setTabEvent('secondStepOpen')}>
                          <InlineStack align='space-between'>
                            <InlineStack align='center' blockAlign='center' gap={200}>
                              <Box><Icon source={CodeIcon} /></Box>
                              <Text fontWeight='medium' variant='bodyLg' as='h1'>Manual setup via code </Text>
                              <div className='warning_banner'>
                                <InlineStack gap={200} wrap={false}>
                                  <img src={warning_icon} alt='warning' />
                                  <Text fontWeight='medium' as='p'>Recommended for vintage themes users</Text>
                                </InlineStack>
                              </div>
                            </InlineStack>
                            <Box>
                              <Icon source={tabEvent === 'secondStepOpen' === true ? ChevronUpIcon : ChevronDownIcon}></Icon>
                            </Box>
                          </InlineStack>
                        </div>

                        <Collapsible
                          open={tabEvent === 'secondStepOpen'}
                          id="db_collaspe"
                          transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                          <Box paddingInlineStart={100} paddingBlockStart={400}>
                            <Text as='p' variant='bodySm' tone='subdued'>{val.listItem}</Text>
                            <Box paddingBlockStart={300}>
                              {/* copy code box */}
                              <div className="copycode-box">
                                <InlineStack align='start' blockAlign='start'>
                                  <textarea id='copy_code' className="textarea-copycode" readOnly={true}
                                    onClick={(e) => handleCopyCode(e, val.ShopiCode)} value={val.ShopiCode}>
                                  </textarea>
                                  <Button variant='monochromePlain'
                                    onClick={() => handleCopyCode('', val.ShopiCode)}>
                                    <img src={copy_icon} alt='copy'></img>
                                  </Button>
                                </InlineStack>
                              </div>
                            </Box>
                          </Box>
                        </Collapsible>

                      </Box>
                    </BlockStack>
                    :                // except wix and shopify    
                    <div className="copycode-box othrCode_box" style={{ background: '#f0f2f5' }}>
                      <div className='code_box'>
                        <InlineStack align='start' blockAlign='start'>
                          {/* <Scrollable
                            horizontal
                            style={{ width: '1000px' }}
                            focusable
                            scrollbarGutter="stable"
                            scrollbarWidth="thin"
                          > */}
                          <textarea id='copy_code' className="textarea-copycode" readOnly={true}
                            onClick={(e) => handleCopyCode(e, val.copyCode)}>
                            {val.copyCode}
                          </textarea>

                          {/* <pre class="sc-58d58b25-2 dPpiHm">
                            <code class="sc-58d58b25-3 klSiDc">
                              {val.copyCode}
                            </code>
                          </pre> */}

                          {/* </Scrollable> */}
                        </InlineStack>
                      </div>
                      <Button variant='monochromePlain'
                        onClick={() => handleCopyCode('', val.copyCode)}>
                        <img src={copy_icon} alt='copy'></img>
                      </Button>
                    </div>

                }

              </Modal.Section>
            </Modal>
          );
        }
      })}

    </Page >
  );
}
export default EditPanel;