import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/auth';
import { toastify } from '../ducks/toast';

//function for date to second 
function dateToSeconds(dateString) {
  // Parse the date string into milliseconds
  let milliseconds = Date.parse(dateString);

  // Convert milliseconds to seconds
  let seconds = milliseconds / 1000;

  return seconds;
}

function* getProfile(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/auth/getProfile', action.data);

    if (res.status === 'success') {
      yield put(actions.getProfileSuccess(res.data));

      if (res.data.e) localStorage.setItem('e', res.data.e);
      if (res.data.fn) localStorage.setItem('fn', res.data.fn);
      if (res.data.ti) localStorage.setItem('ti', res.data.ti);
      if (res.data.csub) localStorage.setItem('csub', res.data.csub);
      if (res.data.pvd) localStorage.setItem('pvd', res.data.pvd);
      if (res.data.objshp && res.data.objshp.d) localStorage.setItem('shp', res.data.objshp.d);

      window._cio.identify({
        // Required attributes
        id: res.data.ti,    // Use either cio_id, id, or email.

        // attributes
        name: res.data.fn,
        email: res.data.e,
        registered: res.data.cdt,
        created_at: dateToSeconds(res.data.cdt),
        quota_reset_date: res.data.nxtb,
        store_URL: res.data.objshp.d,
        product_reviews: res.data.iinstlwdgt === true ? 'Yes' : 'No',
        wall_love: res.data.iinstlwall === true ? 'Yes' : 'No',
        nudges: res.data.iinstlstrrtng === true ? 'Yes' : 'No',
        carousel: res.data.iinstlcrsl === true ? 'Yes' : 'No',
        PlanName: res.data.csub
      });

      //is complete onboard flow
      let _isCmpbord = localStorage.getItem('icmpltonbrdng');
      let _pvd = localStorage.getItem('pvd');

      if (_pvd === 'shopify' && (_isCmpbord === undefined || _isCmpbord === null || _isCmpbord === 'false')) {
        // setTimeout(() => window.open('/', "_self"), 10);
        if (res.data.iskponbrd) {
          localStorage.setItem('icmpltonbrdng', true);
        }
        else if (!res.data.iinstlstrrtng || !res.data.iinstlwdgt || !res.data.ienblpxlapp) {
          localStorage.setItem('icmpltonbrdng', false);
        }
        else {
          localStorage.setItem('icmpltonbrdng', true);
        }
      } else if (_pvd === 'wix' && (_isCmpbord === undefined || _isCmpbord === null || _isCmpbord === 'false')) {
        localStorage.setItem('icmpltonbrdng', false);
      } else {
        localStorage.setItem('icmpltonbrdng', true);
      }

      // onboarding steps flow
      if (!res.data.ienblpxlapp) { localStorage.setItem('ienblpxlapp', false); }
      else { localStorage.setItem('ienblpxlapp', res.data.ienblpxlapp); }

      if (!res.data.iinstlwdgt) { localStorage.setItem('iinstlwdgt', false); }
      else { localStorage.setItem('iinstlwdgt', res.data.iinstlwdgt); }

      if (!res.data.iinstlstrrtng) { localStorage.setItem('iinstlstrrtng', false); }
      else { localStorage.setItem('iinstlstrrtng', res.data.iinstlstrrtng); }
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
    }
    yield put(loaded());
  } catch (error) {
    console.log(error, '====error');
  }
}

function* updSetupFlow(action) {
  try {
    const res = yield call(api.POST, '/auth/updSetupFlow', action.data);

    if (res.status === 'success') {
      // yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please refresh page.' }));
    }
  } catch (error) {
    console.log(error, '====error');
  }
}

function* getDataCount(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getDataCount', action.payload);

    if (res.status === 'success')
      yield put(actions.getDataCountSuccess(res.data));
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    console.log(error, '====error');
  }
}

function* loginWithWix(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/loginWithWix', action.payload);
    if (res.status === 'success') {
      localStorage.removeItem('cd');//remove appsumo code from localstorage
      // if (window.fcWidget) {
      //   window.fcWidget.user.clear().then(function () {
      //     console.log('User cleared');
      //   }, function () {
      //     console.log('User Not cleared');
      //   });
      // }
      localStorage.setItem('authToken', res.data.t);
      localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.un);
      localStorage.setItem('e', res.data.e);
      localStorage.setItem('ti', res.data.ti);
      localStorage.setItem('istm', res.data.istm);
      localStorage.setItem('isMaster', res.data.isMaster);
      localStorage.setItem('csub', res.data.csub);
      if (res.data.vstp && res.data.vstp.plx && res.data.vstp.plx.st !== true) {
        localStorage.setItem('isPixelIns', false);
      }
      if (res.data.isbusr === true) {
        //white_label
        localStorage.setItem('isbusr', true);
      }
      else {
        //white_label
        localStorage.setItem('isbusr', false);
      }
      if (res.data.pvd === 'shopify' || res.data.pvd === 'wix' || res.data.pvd === 'thinkific') {
        localStorage.setItem('pvd', res.data.pvd);
      } else {
        localStorage.setItem('pvd', 'local');
      }
      if (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') {
        localStorage.setItem('utyp', res.data.utyp);
      }
      if (action.payload.newUser && action.payload.newUser === true) {
        localStorage.setItem('isNewUser', true);
        try {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', window.location.hostname, 'sign_up_website', 'create_account_wix');
          //for GA-4
          window.gtag('event', window.location.hostname, { 'event_action': 'sign_up_website', 'event_label': 'create_account_wix' });
        } catch (error1) {
          console.log(error1, '=====error1');
        }
        //linkdin tracking 
        if (window.lintrk) {
          window.lintrk('track', { conversion_id: 14211337 });
        }
      }
      // if (res.data.role) {
      //   localStorage.setItem('role', res.data.role);
      // }
      //mixpanel start
      // window.mixpanel.identify(res.data.e);
      // window.mixpanel.people.set({
      //   '$email': res.data.e,
      //   '$last_login': new Date()
      // });
      //mixpanel end

      //froged 
      setTimeout(() => {
        if (window.Froged) {
          window.Froged('logout');
        }
      }, 1000);

      //if user not login 
      // let rdt_url = localStorage.getItem('rdt_url');
      setTimeout(() => {
        // (localStorage.getItem('csub') === '' || localStorage.getItem('csub') === null || localStorage.getItem('csub') === undefined) ?
        //   (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') ?
        //     window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard'
        //     :
        //     window.location.href = '/onboarding'
        //   :
        //   window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard';
        window.location.href = '/socialproof';
      }, 2000);
      localStorage.removeItem('rdt_url');
    }
    else if (res.status === 'not_found') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'not_active') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  }
  catch (error) {
    console.log(error, '====error');
  }
}

function* skipOnBoard() {
  try {
    yield put(load());
    const res = yield call(api.POST, '/skipOnBoard');

    if (res.status === 'success') {
      window.location.reload();
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    console.log(error, '====error');
  }
}

export function* watchGetProfile() {
  yield takeLatest(actions.getProfile.type, getProfile);
  yield takeLatest(actions.loginWithWix.type, loginWithWix);
}

export function* watchUpdSetupFlow() {
  yield takeLatest(actions.updSetupFlow.type, updSetupFlow);
}

export function* watchGetDataCount() {
  yield takeLatest(actions.getDataCount.type, getDataCount);
}

export function* watchSkipOnBoard() {
  yield takeLatest(actions.skipOnBoard.type, skipOnBoard);
}

export default function* rootSaga() {
  yield fork(watchGetProfile);
  yield fork(watchUpdSetupFlow);
  yield fork(watchGetDataCount);
  yield fork(watchSkipOnBoard);
}