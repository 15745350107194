import { Text, Card, Page, Button, Box, Icon, ButtonGroup } from '@shopify/polaris';
import { FileIcon, ProductIcon, TeamIcon, QuestionCircleIcon } from '@shopify/polaris-icons';
import ModerateReview from './ModerateReview';
import QueAns from '../../containers/QueAns/QueAns';
import EmlTempSidebar from '../Manual/EmlTempSidebar';
import ProductReview from '../Product/List';
import GroupReview from '../ReviewGroupPR/List';
import React, { memo, useMemo } from 'react';
import './List.css';

function List(props) {
  const { state, changeNameValue, openCloseModal, handleTextChange } = props;

  const tabs = useMemo(() => [
    { tabName: 'manageReview', icon: FileIcon, label: 'Manage reviews' },
    { tabName: 'productReview', icon: ProductIcon, label: 'Products' },
    { tabName: 'groupReview', icon: TeamIcon, label: 'Grouping' },
    { tabName: 'quetionAns', icon: QuestionCircleIcon, label: 'Question answer' }
  ], []);

  return (
    <div className='containerSM-custom-mediascr'>

      {/* {props.state.isNewEditPanel !== true && props.state.isConfiguration !== true ? */}
      <div>
        {localStorage.getItem('pvd') !== 'local' &&  //4 tabs
          <Page>
            <Box paddingBlockStart={0}>
              <div className='subdubd_bg cutsm_Poltab'>
                <Card padding={100} roundedAbove='md'>

                  <ButtonGroup>
                    {tabs.map((d, i) => (
                      <Button key={i} variant={state.mainTabIndex === d.tabName ? 'tertiary' : ''}
                        onClick={() => changeNameValue({ mainTabIndex: d.tabName })}
                        icon={<Icon source={d.icon} tone={state.mainTabIndex === d.tabName ? '' : 'subdued'} />}>
                        <Text fontWeight='medium' tone={state.mainTabIndex === d.tabName ? '' : 'subdued'}>{d.label}</Text>
                      </Button>
                    ))}
                  </ButtonGroup>

                </Card>
              </div>
            </Box>
          </Page>
        }

        <Box paddingInlineStart={200}>
          {state.mainTabIndex === 'groupReview' ?
            <GroupReview
              props={props} />
            :
            state.mainTabIndex === 'productReview' ?
              <ProductReview
                props={props} />
              :
              state.mainTabIndex === 'quetionAns' ?
                <QueAns />
                :
                <ModerateReview  //'manageReview' show default
                  props={props} />
          }
        </Box>

      </div>
      {/* : */}

      {/* //email template edit panel | preview */}
      {state.tempSidebarOpen === true &&
        <EmlTempSidebar
          state={state}
          isShwTestEml={true}
          SaveClkCallApi={true}
          changeNameValue={changeNameValue}
          openCloseModal={openCloseModal}
          handleTextChange={handleTextChange}
        />
      }


    </div >
  );
}

export default memo(List);