import React, { useState, useCallback, useEffect, memo, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text, Page, IndexFilters, IndexTable, InlineGrid, Thumbnail, Divider, Button, Box, Card, TextField, InlineStack, useSetIndexFiltersMode, BlockStack, Spinner } from '@shopify/polaris';
import { CodeIcon, ImageIcon, ImportIcon } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as groupDucks from '../../ducks/groupProduct';
import { toastify } from '../../ducks/toast';
import domtoimage from 'dom-to-image';
import copy from 'copy-to-clipboard';
import { changePathForShopifyAppEmbed } from "../../utils/utils";
import '../../containers/Forms/Forms.css';
const QRcode = React.lazy(() => import('./QRcode'));


let _ti = localStorage.getItem('ti') ? window.btoa(localStorage.getItem('ti')) : '';
let _shp = window.btoa(localStorage.getItem('shp') || '');
let _hrefLink = `https://wisernotify.com/form/writereview?ti=${_ti}&shp=${_shp}&rwtyp=qr_code&typ=ord`;

const _embedhtml = `<iframe style="overflow:hidden;border:0" height=44 width=200 srcdoc="<html><style>body{display:flex;align-items:center;justify-content:center;overflow:hidden;margin:0;height:100vh;font-family:system-ui}.reviewBttn{display:inline-block;width:100%;color:#fff;background-color:#645CFC;font-weight:600;border-radius:4px;letter-spacing:1px;font-size:16px;padding:16px 18px;text-align:center;box-sizing:border-box;text-decoration:none;border-radius:30px}</style><body><a target='_top' class=reviewBttn href='${_hrefLink}'>Write a Review</a></body></html>"></iframe>`;

const _embedhtmedit = `<iframe style="overflow:hidden;border:0" height=44 width=200 srcdoc="<html><style>body{display:flex;align-items:center;justify-content:center;overflow:hidden;margin:0;height:100vh;font-family:system-ui}.reviewBttn{display:inline-block;width:100%;text-transform:uppercase;color:#212121;font-weight:600;border-radius:4px;letter-spacing:1px;font-size:16px;padding:14px 18px;text-align:center;box-sizing:border-box;text-decoration:none;border : 1px solid #212121; border-radius:30px;}</style><body><a target='_top' class=reviewBttn href='${_hrefLink}'>edit my reviews</a></body></html>"></iframe>`;


function Main(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [reviewLink, setReviewLink] = useState('');
  const [searchParam, setSearchParam] = useState('');
  const [proPageNo, setProPageNo] = useState(0);
  const [prodOptions, setProdOptions] = useState([]);
  const [startCursor, setStartCursor] = useState('');
  const [endCursor, setEndCursor] = useState('');
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [proQuery, setProQuery] = useState('');
  const [reLoading, setReloading] = useState(false)

  const { mode, setMode } = useSetIndexFiltersMode();

  let Loading = useSelector((state) => state.groupProduct.loading)

  let loading = useSelector((state) => state.loading.isLoading)

  useEffect(() => {
    if (loading || prodOptions.length <= 0) {
      setReloading(true)
    }
    else {
      setReloading(false)
    }
  }, [loading, prodOptions])

  // api data 
  const groupState = useSelector(state => state.groupProduct);
  const productInfo = groupState.ProductName?.pageInfo || {}; //product page info

  useEffect(() => {
    setReviewLink(_hrefLink);
    setSearchParam(queryParams.get("p"));
  }, [_hrefLink, queryParams]);

  useEffect(() => {
    const { startCursor, endCursor, hasPreviousPage, hasNextPage } = productInfo;

    if (startCursor !== undefined) setStartCursor(startCursor);
    if (endCursor !== undefined) setEndCursor(endCursor);
    if (hasPreviousPage !== undefined) setHasPreviousPage(hasPreviousPage);
    if (hasNextPage !== undefined) setHasNextPage(hasNextPage);
  }, [productInfo]);

  useEffect(() => {
    if (groupState.ProductName && groupState.ProductName.data) {
      const newProOpt = groupState.ProductName.data.map(ele => ({
        value: ele.pid.toString(),
        label: ele.pnm,
        img: ele.pimg,
      }));
      setProdOptions(newProOpt);
    }
  }, [groupState.ProductName]);

  useEffect(() => {
    if (proQuery && proQuery.length > 2) {
      let proQuery = setTimeout(() => handleSubmit(), 700);
      return () => clearTimeout(proQuery);
    }
    else handleSubmit();
  }, [proQuery]);

  const handleSubmit = useCallback(() => {
    let obj = {
      title: proQuery,
      pageCount: 10,
      pageNo: proPageNo
    };
    dispatch(groupDucks.getProductName(obj));
    setProdOptions(prodOptions);
  }, [prodOptions, proQuery, proPageNo]);

  const handleCopyCode = useCallback((value, type) => {
    copy(value, { debug: true });
    dispatch(toastify({ type: 'success', msg: `Your ${type ? type : 'code'} is copied.` }));
  }, []);

  const handleDownloadImage = useCallback(() => {
    var _html = document.getElementById('qr-box');

    domtoimage.toPng(_html)
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'QR.png';
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error('Oops, something went wrong!', error);
      });
  }, []);

  // paggination
  const groupPagiCall = useCallback((i) => {
    let productNum = proPageNo;
    if (i === '+1') productNum = proPageNo + 1;
    else productNum = proPageNo - 1;

    setProPageNo(productNum);

    let obj = {
      pageNo: productNum,
      pageCount: 10,
      title: proQuery,
      ...(i === '+1' && { after: endCursor }),
      ...(i === '-1' && { before: startCursor })
    };

    dispatch(groupDucks.getProductName(obj));
    setReloading(true)
    // dispatch(groupDucks.setLoading({ payload: true }))
  }, [proPageNo, endCursor, startCursor, proQuery]);

  const handleBackToMain = () => {
    if (localStorage.getItem('is_shopify_embed')) {
      if (window.location.search !== '') {
        changePathForShopifyAppEmbed('/shopifyAuth');
      } else {
        changePathForShopifyAppEmbed('/collectReview');
      }
    } else {
      navigate(-1);
    }
  };

  const findProdLink = (pid) => {
    let _pid = window.btoa(pid);
    return `https://wisernotify.com/form/writereview?pid=${_pid}&ti=${_ti}&shp=${_shp}&rwtyp=qr_code`;
  }

  return (
    <Page
      title={"Forms"}
      subtitle='Get links for dedicated review forms to ask existing customers to update their previous reviews or to invite new buyers to write new ones manually'
      backAction={(searchParam === 'frm' || props.isShwBack === true) ? { onAction: () => handleBackToMain() } : false}>

      <Box padding={{ xs: 400, sm: 400, md: 0 }} paddingBlockEnd={2000} paddingInline={(searchParam === 'frm' || props.isShwBack === true) ? 800 : 0}>

        <BlockStack gap={200}>
          <BlockStack gap={100}>
            <Text id="storeDetails" fontWeight='bold' variant="headingMd" as="h5">Leave review</Text>
            <Text variant='bodyMd' tone="subdued" as="p">
              Add review links to chat, support emails, account pages, and packaging.</Text>
          </BlockStack>

          <Card>
            <InlineGrid gap={600}>
              <Box>
                <InlineStack gap={100} direction={'column'}>
                  <Text variant="headingXs" as="h6">Link</Text>
                  <Text variant='bodySm' as='p' tone="subdued">
                    Copy the review form link & share it with any existing customer to ask review</Text>
                </InlineStack>
                <Box paddingBlockStart={300}>
                  <InlineGrid gap={200}>
                    <TextField
                      readOnly
                      value={reviewLink}
                      onFocus={(e) => e.target.select()}
                      connectedRight={<Button id='primary_btn' onClick={() => handleCopyCode(reviewLink, 'link')}>Copy link</Button>} />
                  </InlineGrid>
                </Box>
              </Box>

              <InlineGrid gap="500" columns={{ xs: 1, sm: 2, md: 2 }} alignItems='center'>
                <Box>
                  <InlineStack gap={100} direction={'column'}>
                    <Text variant="headingXs" as="h6">Embed button</Text>
                    <Text variant='bodySm' tone='subdued'>Collect reviews on your support or thank you page</Text>
                  </InlineStack>
                  <div className='forms_canvas'>
                    <div dangerouslySetInnerHTML={{ __html: _embedhtml }} />
                  </div>
                  <Button icon={CodeIcon} onClick={() => handleCopyCode(_embedhtml, 'code')}>Copy embed code</Button>
                </Box>

                <Box>
                  <InlineStack gap={100} direction={'column'}>
                    <Text variant="headingXs" as="h6">QR code</Text>
                    <Text variant='bodySm' tone='subdued'>Put QR code at products & packaging (use stickers)</Text>
                  </InlineStack>
                  <Suspense fallback={<div>Loading...</div>}>
                    <QRcode _hrefLink={_hrefLink} />
                  </Suspense>
                  <Button icon={ImportIcon} onClick={() => handleDownloadImage()}>Download image</Button>
                </Box>
              </InlineGrid>
            </InlineGrid>
          </Card>
        </BlockStack>

        <Box paddingBlock={400}><Divider borderColor='border-tertiary' /></Box>

        <BlockStack gap={200}>
          <BlockStack gap={100}>
            <Text id="storeDetails" fontWeight='bold' variant="headingMd" as="h5">Update review</Text>
            <Text variant='bodySm' tone="subdued" as="p">
              This link lets authors manage their reviews on your store, including viewing, editing, or deleting them.
            </Text>
          </BlockStack>

          <Card>
            <InlineGrid gap={500}>
              <Box>
                <InlineStack gap={100} direction={'column'}>
                  <Text variant="headingXs" as="h6">Link</Text>
                  <Text variant='bodySm' tone='subdued'>Use links and an embed button to ask reviewer to update & delete reviews</Text>
                </InlineStack>
                <Box paddingBlockStart={400}>
                  <InlineGrid >
                    <TextField
                      readOnly
                      value={reviewLink}
                      onFocus={(e) => e.target.select()}
                      connectedRight={<Button id='primary_btn' onClick={() => handleCopyCode(reviewLink, 'link')}>Copy link</Button>} />
                  </InlineGrid>
                </Box>
              </Box>

              <InlineGrid gap="500" columns={{ xs: 1, sm: 2, md: 2 }} alignItems='center'>
                <Box>
                  <InlineStack gap={100} direction={'column'}>
                    <Text variant="headingXs" as="h6">Embed button</Text>
                    <Text variant='bodySm' tone='subdued'>Update reviews with embed button</Text>
                  </InlineStack>
                  <div className='forms_canvas'>
                    <div dangerouslySetInnerHTML={{ __html: _embedhtmedit }} />
                  </div>
                  <Button icon={CodeIcon} onClick={() => handleCopyCode(_embedhtmedit, 'code')}>Copy embed code</Button>
                </Box>

                <Box>
                  <InlineStack gap={100} direction={'column'}>
                    <Text variant="headingXs" as="h6">QR code</Text>
                    <Text variant='bodySm' tone='subdued'>Ask to update review using QR code</Text>
                  </InlineStack>
                  <QRcode _hrefLink={_hrefLink} />
                  <Button icon={ImportIcon} onClick={() => handleDownloadImage()}>Download image</Button>
                </Box>
              </InlineGrid>

            </InlineGrid>
          </Card>
        </BlockStack>

        {localStorage.getItem('pvd') !== 'local' &&
          <div>
            <Box paddingBlock={400}><Divider borderColor='border-tertiary' /></Box>

            <Card>
              <Box>
                <InlineStack gap={100} direction={'column'}>
                  <Text variant="headingXs" as="h6">Product specific review</Text>
                  <Text variant='bodySm' tone='subdued'>Get a specific link to request reviews for any product</Text>
                </InlineStack>

                <Box paddingBlockStart={500}>
                  <div className='loading-background' style={{ display: reLoading ? 'block' : 'none' }}>
                    <div className='spinner_div' >
                      {reLoading ? <Spinner className="loading" accessibilityLabel="Spinner example" size="large" /> : ""}
                    </div>
                  </div>
                  <Card padding="0">
                    <IndexFilters
                      queryValue={proQuery}
                      queryPlaceholder="Search product name..."
                      onQueryChange={(e) => setProQuery(e)}
                      onQueryClear={() => setProQuery('')}
                      cancelAction={{
                        onAction: () => setProQuery(''),
                      }}
                      tabs={[]}
                      filters={[]}
                      hideFilters
                      onClearAll={() => setProQuery('')}
                      mode={mode}
                      setMode={setMode}
                    />

                    <div id='product_tbl'>
                      <IndexTable
                        itemCount={prodOptions ? prodOptions.length : 0}
                        selectable={false}
                        headings={[
                          { title: '', alignment: 'start' },
                          { title: "Product name", alignment: 'start' },
                          { title: "Reference link", alignment: 'start' }
                        ]}
                        pagination={{
                          hasPrevious: (hasPreviousPage === true) ? true : false,
                          hasNext: (hasNextPage === true) ? true : false,
                          onNext: () => groupPagiCall('+1'),
                          onPrevious: () => groupPagiCall('-1')
                        }}>
                        {
                          prodOptions && prodOptions.length > 0 &&
                          prodOptions.map((data, index) => (
                            <IndexTable.Row
                              id={data.value}
                              key={index}
                              position={index}
                            >
                              <IndexTable.Cell>
                                <Thumbnail
                                  size='small'
                                  source={data.img ? data.img : ImageIcon}
                                  alt="product" />
                              </IndexTable.Cell>
                              <IndexTable.Cell>
                                <Text id='txt_limit' variant="bodySm" fontWeight="regular" as="span">{data.label ? data.label : '-'}</Text>
                              </IndexTable.Cell>
                              <IndexTable.Cell>
                                <Button id='primary_btn'
                                  onClick={() => handleCopyCode(findProdLink(data.value), 'link')}>Copy Link</Button>
                              </IndexTable.Cell>
                            </IndexTable.Row>
                          ))}
                      </IndexTable>
                    </div>
                  </Card>
                </Box>

              </Box>
            </Card>
          </div>
        }

      </Box>
    </Page>
  );
}

export default memo(Main);