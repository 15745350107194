import React, { useEffect, useState } from 'react'
import { MyprofileCard } from '../../components';
import './Myprofile.css';
import { useDispatch, useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import * as myprofile from '../../ducks/myprofile';
import { is } from 'immutable';
import copy from 'copy-to-clipboard';
import { toastify } from '../../ducks/toast';
import { encrypt } from '../../utils/passwordService';
import { useNavigate } from 'react-router-dom';

function Myprofile(props) {
    const [state, setState] = useState({
        e: '', // email
        fn: '', // full name
        istm: false, // is team member
        errMessage: null, // error message
        ak: '', // api key
        pd: '', // password
        npd: '', // new password
        isCurnPassVisible: false, // is current password visible
        isNewPassVisible: false, // is new password visible
    });
    const validator = new SimpleReactValidator({ locale: 'en' });

    let dispatch = useDispatch();
     const navigate = useNavigate();

    const profile = useSelector(state => state.auth.profile)

    const changeNameValue = (obj) => {
        setState((prevState) => ({ ...prevState, ...obj }));
    }

    useEffect(() => {
        if (profile) {
            changeNameValue(profile)
        }

        if (profile) {
            if (localStorage.getItem('istm') === 'true') {
                let userImg = profile.iu || '';
                changeNameValue({ iu: userImg })
            }
            else {
                let userImg = profile.iu || '';
                changeNameValue({ iu: userImg })
            };
        }

    }, [profile])

    // Update full name 
    let updFullname = (type, value) => {
        if (validator.errorMessages.fn !== null && validator.errorMessages.fn !== undefined) {
            validator.showMessages();
            validator.helpers.forceUpdateIfNeeded();
            changeNameValue({ errMessage: validator.errorMessages });
        }
        else {
            changeNameValue({ errMessage: '' });
            const obj = {
                [type]: value,
            }
            if (localStorage.getItem('istm') === 'true') {
                obj.istm = true;
            }
            dispatch(myprofile.updateProfile(obj));
            localStorage.setItem('username', state.fn);
        }
    }

    // change password 
    let handleChangePassword = () => {
        if (!validator.allValid()) {
            validator.showMessages();
            validator.helpers.forceUpdateIfNeeded();
            changeNameValue({ 'errMessage': validator.errorMessages });
            validator.errorMessages?.pd == null ? changeNameValue({ errMessage: { ...state.errMessage, pd: null, npd: 'The new password field is required.' } }) : validator.errorMessages?.npd == null ? changeNameValue({ errMessage: { ...state.errMessage, pd: 'The current password field is required.', npd: null } }) : changeNameValue({ errMessage: { ...state.errMessage, pd: 'The current password field is required.', npd: 'The new password field is required.' } })
        }
        else {
            let obj = {
                opd: encrypt(state.pd),
                pd: encrypt(state.npd)
            };
            dispatch(myprofile.cPwd(obj))
            if (localStorage.getItem('istm') === 'true') {
                obj.istm = true;
            }
            changeNameValue({ errMessage: { ...state.errMessage, pd: null, npd: null } })
        }
    }

    let handleCopyApiKey = () => {
        copy(state.ak);
        dispatch(toastify({ type: 'success', msg: 'Your API key is copied' }));
    }

    const userProfileImg = (file) => {
        changeNameValue({ iu: file.base64 })
        let obj = {
            iu: file.base64
        };
        if (localStorage.getItem('istm') === 'true') {
            obj.istm = true;
        }
        dispatch(myprofile.updProfileImg(obj));
    }

    const handleBackToMain = () => {
        navigate('/setting');
      }

    return (
        <MyprofileCard
            state={state}
            profile={profile}
            changeNameValue={changeNameValue}
            userProfileImg={userProfileImg}
            updFullname={updFullname}
            validator={validator}
            handleCopyApiKey={handleCopyApiKey}
            handleChangePassword={handleChangePassword}
            handleBackToMain={handleBackToMain}
            isShwBack={props.isShwBack}
        />
    )
}

export default Myprofile;