import {
    Badge, Page, Button, Box, Thumbnail, Popover, InlineError, InlineStack, Autocomplete, Banner, Modal, PageActions, IndexTable, Text, Card, Divider, Icon,
    Collapsible, List, BlockStack,
} from '@shopify/polaris';
import { DeleteIcon, ChevronUpIcon, ChevronDownIcon, SearchIcon } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import * as integrationDucks from '../../ducks/integration';
import { facebook_icon } from '../../img';
import { createApp } from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';
import moment from 'moment';

function Facebook({ props }) {
    const { state, changeNameValue, handleBackToMain } = props;
    const dispatch = useDispatch();
    let _ia = false;

    const [info, setInfo] = useState({
        FacebookCard: [],//facebook data
        activePopover: null,
        searchFBPG: '',//serach
        dataId: '',//delete data id
        subId: '',
        apiD: false,//api condition
        deleteDataModal: false,//delete modal
    });

    //get data from state
    const profile = useSelector(state => state.auth?.profile);
    const getFacebookCardData = useSelector(state => state.integration?.facebookData);

    //set data.
    const changeNameValueCard = useCallback((obj) => {
        setInfo((prevState) => ({ ...prevState, ...obj }))
    }, []);

    //openCloseModal 
    const openCloseModal = useCallback((name, value, type) => {
        if (type !== undefined) {
            changeNameValueCard({ [name]: !value });
        }
    }, []);

    //set data in state 
    useEffect(() => {
        changeNameValueCard({ FacebookCard: getFacebookCardData });
    }, [getFacebookCardData]);

    //api call for facebook data
    useEffect(() => {
        dispatch(integrationDucks.facebookIntegration());
    }, []);

    //redirect to facebook
    const redirectFB = () => {
        const url = window.location.href.replace(/&/gi, '@');
        const cleanUrl = url.split('facebook')[0] + 'facebook';
        let objStateFB = {
            uk: profile._id,
            ti: profile.ti,
            path: window.location.pathname,
            uri: cleanUrl
        };
        //      uri: window.location.href.replace(/&/gi, '@'),//window.location.href,
        let authURL = 'https://www.facebook.com/v18.0/dialog/oauth?client_id=' +
            process.env.REACT_APP_FB_APP_ID +
            '&redirect_uri=' +
            process.env.REACT_APP_INT_API_URL +
            '/facebook/oauth&scope=business_management,email,pages_show_list,pages_read_engagement,pages_manage_metadata,pages_read_user_content,public_profile,read_insights,pages_manage_posts,pages_manage_engagement&response_type=code&state=' + JSON.stringify(objStateFB);
        //check if shopify embed, then redirect to shopify app, else open in new tab
        if (localStorage.getItem('is_shopify_embed')) {
            // ref: https://shopify.dev/docs/api/app-bridge/previous-versions/actions/navigation/redirect-navigate
            const strObjConfig = localStorage.getItem('store_config');
            const appShopify = createApp(JSON.parse(strObjConfig));
            const redirect = Redirect.create(appShopify);
            redirect.dispatch(Redirect.Action.REMOTE, authURL);
        }
        else {
            window.open(authURL, '_self');
        }
    };

    //update toggle button
    const handleIsActive = (item) => {
        let obj = {
            id: item._id,
            ia: !item.ia
        };
        dispatch(integrationDucks.updateIntegrationStatus(obj));
        const updatedList = info.FacebookCard.map((elm) =>
            elm._id === item._id ? { ...elm, ia: !elm.ia } : elm
        );
        changeNameValueCard({ FacebookCard: updatedList })
    };

    //search field autocomplete
    const textField = (
        <Autocomplete.TextField
            onChange={(e) => changeNameValueCard({ searchFBPG: e })}
            value={info.searchFBPG}
            prefix={<Icon source={SearchIcon} tone="base" />}
            placeholder={"Search by page name"}
            autoComplete="off"
            label={"Page list"}
            id='fblist'
        />
    );

    //handle toggle
    const handleToggle = (id) => {
        changeNameValueCard({ activePopover: info.activePopover === id ? null : id, searchFBPG: "" },);
        setTimeout(() => {
            const input = document.getElementById('fblist');
            if (input) {
                input.focus(); // Focus the input field
            }
            else {
                console.warn('input not found');
            }
        }, 10);
    };

    //create fb page data
    const handleData = (object, val, id) => {
        let filterData = object.find(x => x.name === val[0]);
        const obj = {
            _id: id
        }
        const combined = { ...filterData, ...obj };
        dispatch(integrationDucks.createFBPage(combined));
        changeNameValueCard({ activePopover: null });
    }

    //open delete modal for sub data
    const handleSubdeleteModal = (id, subId) => {
        openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
        changeNameValueCard({ dataId: id, subId: subId, apiD: true })
    }

    //delete sub data api call
    const handleDeleteSubData = () => {
        dispatch(integrationDucks.deleteFBList({ _id: info.dataId, id: info.subId }));
        openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
    }

    //open delete modal
    const handledeleteModal = (id) => {
        openCloseModal('deleteDataModal', info.deleteDataModal, 'open');
        changeNameValueCard({ dataId: id, apiD: false })
    }

    //delete api call 
    const handleDeleteCall = (id) => {
        dispatch(integrationDucks.deleteFB(id));
        openCloseModal('deleteDataModal', info.deleteDataModal, 'close');
    }

    let queArr = [
        {
            field: 'firstTabCollapse',
            question: 'How does this integration work?',
            answer: 'Connect your Facebook profile and select the pages. The system will start fetching reviews automatically from the linked pages.'
        },
        {
            field: 'secondTabCollapse',
            question: 'What type of data do we fetch?',
            answer: 'The integration fetches old reviews, new reviews & ratings.'
        }
    ];

    return (
        <div>
            <Page
                title={(state.currMasterObj && state.currMasterObj.dn) && state.currMasterObj.dn}
                titleMetadata={_ia === true ?
                    <Badge tone='success'>
                        <Text variant="bodySm" fontWeight="medium" as="span">Active</Text>
                    </Badge>
                    : <Badge>
                        <Text variant="bodySm" fontWeight="medium" as="span">Disabled</Text>
                    </Badge>
                }
                subtitle={
                    <Text tone='base' >
                        {state.currMasterObj.dsc ? state.currMasterObj.dsc : '-'}
                    </Text>}
                backAction={{ onAction: () => handleBackToMain() }}>

                <Box paddingInline={800}>
                    <BlockStack gap={400}>
                        <Card>
                            <Box paddingBlockEnd={200}>
                                <Text variant='headingMd' as='p' fontWeight='semibold'>Webhook setup</Text>
                            </Box>
                            <BlockStack gap={400}>
                                <Text variant='headingSm' as='p' fontWeight='semibold'>Connect your app:</Text>
                                <Box>
                                    <List type="bullet">
                                        <List.Item>Click the "Login with Facebook" button to connect your Facebook profile.</List.Item>
                                        <List.Item>Once your profile is connected, select the Facebook pages you wish to sync for reviews.</List.Item>
                                        <List.Item>After completing the connection, the system will automatically pull and display reviews from the selected pages.</List.Item>
                                    </List>
                                </Box>

                                <Box padding={0}>
                                    <InlineStack align='space-between'>
                                        <Box paddingBlockEnd={200} paddingBlockStart={100}>
                                            <Text variant="headingMd" fontWeight='bold' as="h6">{"Account details"}</Text>
                                        </Box>
                                        <Box paddingBlockEnd={200}>
                                            <div id='insta-btn' onClick={() => redirectFB()}>
                                                <Button>
                                                    <InlineStack align='space-between'>
                                                        <Thumbnail
                                                            source={facebook_icon}
                                                            size='extraSmall'
                                                            alt="facebook"
                                                        />
                                                        <span className='login-text'>{"Login with facebook"}</span>
                                                    </InlineStack>
                                                </Button>
                                            </div>
                                        </Box>
                                    </InlineStack>
                                    <Card padding={0}>
                                        <IndexTable
                                            itemCount={info.FacebookCard?.length || 0}
                                            headings={[
                                                { title: '#' },
                                                { title: 'User name' },
                                                { title: 'Created date' },
                                                { title: 'Active' },
                                                { title: '' },
                                                { title: '' },
                                                { title: 'Action' },
                                            ]}
                                            selectable={false}
                                        >
                                            {
                                                info.FacebookCard && info.FacebookCard.length > 0 && info.FacebookCard.map((i, index) => {
                                                    return (
                                                        <>
                                                            <IndexTable.Row key={index}>
                                                                <IndexTable.Cell>
                                                                    <Text variant="bodyMd" fontWeight="bold" as="span">
                                                                        {index + 1}
                                                                    </Text>
                                                                </IndexTable.Cell>
                                                                <IndexTable.Cell><span className='text-break amazon-url'>{i?.d?.name}</span></IndexTable.Cell>
                                                                <IndexTable.Cell>{moment(new Date(i.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>

                                                                <IndexTable.Cell>
                                                                    <label className="switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={i.ia}
                                                                            onChange={() => handleIsActive(i)}
                                                                        />
                                                                        <span className="switch-label"></span>
                                                                        <span className="switch-handle"></span>
                                                                    </label>
                                                                </IndexTable.Cell>
                                                                <IndexTable.Cell></IndexTable.Cell>
                                                                <IndexTable.Cell></IndexTable.Cell>
                                                                <IndexTable.Cell>
                                                                    <Box id='actionfb'>
                                                                        <InlineStack>
                                                                            {i.d?.fbTokenError === '' || i.d?.fbTokenError === undefined ? (
                                                                                <>
                                                                                    {
                                                                                        <div id="delete" onClick={() => handledeleteModal(i._id)}>
                                                                                            <Banner hideIcon tone='critical'>
                                                                                                <Icon
                                                                                                    source={DeleteIcon}
                                                                                                    tone="critical"
                                                                                                />
                                                                                            </Banner>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        <Box paddingInline={200}>
                                                                                            < Popover
                                                                                                active={info.activePopover === index}
                                                                                                activator={
                                                                                                    <Button
                                                                                                        onClick={() => {
                                                                                                            handleToggle(index);
                                                                                                        }}
                                                                                                    >
                                                                                                        <InlineStack>
                                                                                                            <div className='add-events'>{"Add List"}</div>
                                                                                                            <Icon
                                                                                                                source={ChevronDownIcon}
                                                                                                                tone="base"
                                                                                                            />
                                                                                                        </InlineStack>

                                                                                                    </Button>
                                                                                                }
                                                                                                onClose={() => changeNameValueCard({ activePopover: null })}
                                                                                            >
                                                                                                {i && i.d && i.d.fb_page && i.d.fb_page.length > 0 && i.d.fb_page.filter(x => !x.ia).length > 0 ? (
                                                                                                    <Card>
                                                                                                        <Autocomplete
                                                                                                            options={i.d.fb_page.filter(x => !x.ia).filter(obj => {
                                                                                                                let regexStr = new RegExp(info.searchFBPG, 'i');
                                                                                                                return !info.searchFBPG || obj.name.search(regexStr) !== -1;
                                                                                                            }).map(obj => ({
                                                                                                                value: obj.name, label: (
                                                                                                                    <div>
                                                                                                                        <Text variant="bodyMd">{obj.name}</Text>
                                                                                                                        {obj.id && <Text variant="bodyXs"> ({obj.id})</Text>}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }))}
                                                                                                            selected={info.searchFBPG}
                                                                                                            onSelect={(e) => handleData(i.d.fb_page.filter(x => !x.ia).map(obj => (obj)), e, i._id)}
                                                                                                            textField={textField}
                                                                                                        />
                                                                                                    </Card>
                                                                                                ) : (
                                                                                                    <Box padding={400}>
                                                                                                        {"No record found"}
                                                                                                    </Box>
                                                                                                )
                                                                                                }
                                                                                            </Popover>
                                                                                        </Box>
                                                                                    }
                                                                                </>
                                                                            ) : (
                                                                                <Box paddingBlockEnd={200}>
                                                                                    <div id='insta-btn' onClick={() => redirectFB()}>
                                                                                        <Button>
                                                                                            <InlineStack align='space-between'>
                                                                                                <Thumbnail
                                                                                                    source={facebook_icon}
                                                                                                    size='extraSmall'
                                                                                                    alt="facebook"
                                                                                                />
                                                                                                <span className='login-text'> {"Login with facebook"}</span>
                                                                                            </InlineStack>
                                                                                        </Button>
                                                                                    </div>
                                                                                </Box>
                                                                            )
                                                                            }
                                                                        </InlineStack>
                                                                    </Box>
                                                                </IndexTable.Cell>
                                                            </IndexTable.Row >
                                                            {
                                                                i && i.d && i.d.fb_page?.length > 0 && i.d.fb_page.filter(x => x.ia === true).length > 0 &&
                                                                <IndexTable.Row >
                                                                    <IndexTable.Cell>
                                                                        <Text variant="bodyMd" fontWeight="bold" as="span">
                                                                            #
                                                                        </Text>
                                                                    </IndexTable.Cell>
                                                                    <IndexTable.Cell>
                                                                        <Text variant="bodyMd" fontWeight="bold" as="span">
                                                                            Page Name
                                                                        </Text>
                                                                    </IndexTable.Cell>
                                                                    <IndexTable.Cell>
                                                                        <Text variant="bodyMd" fontWeight="bold" as="span">
                                                                            Page category
                                                                        </Text>
                                                                    </IndexTable.Cell>
                                                                    <IndexTable.Cell>
                                                                        <Text variant="bodyMd" fontWeight="bold" as="span">
                                                                            Average rating
                                                                        </Text>
                                                                    </IndexTable.Cell>
                                                                    <IndexTable.Cell>
                                                                        <Text variant="bodyMd" fontWeight="bold" as="span">
                                                                            Rating count
                                                                        </Text>
                                                                    </IndexTable.Cell>
                                                                    <IndexTable.Cell>
                                                                        <Text variant="bodyMd" fontWeight="bold" as="span">
                                                                            Like count
                                                                        </Text>
                                                                    </IndexTable.Cell>
                                                                    <IndexTable.Cell></IndexTable.Cell>

                                                                </IndexTable.Row>
                                                            }

                                                            {
                                                                i.d?.fbTokenError && (
                                                                    <IndexTable.Row>
                                                                        <IndexTable.Cell colSpan={8}>
                                                                            <InlineError message={i.d?.fbTokenError} />
                                                                        </IndexTable.Cell>
                                                                    </IndexTable.Row>
                                                                )
                                                            }

                                                            {i && i.d && i.d.fb_page?.length > 0 &&
                                                                i.d.fb_page.filter(x => x.ia === true).length > 0 ? i.d.fb_page.filter(x => x.ia === true).map((LIObj, index) => (
                                                                    <IndexTable.Row key={index}>
                                                                        <IndexTable.Cell>{index + 1}</IndexTable.Cell>
                                                                        <IndexTable.Cell>
                                                                            <Text variant="bodyMd">
                                                                                {LIObj.name}
                                                                            </Text>
                                                                            <Text variant="bodyMd">
                                                                                ({LIObj.id})
                                                                            </Text>
                                                                        </IndexTable.Cell>
                                                                        <IndexTable.Cell>
                                                                            <Text variant="bodyMd">{LIObj.category}</Text>
                                                                        </IndexTable.Cell>
                                                                        <IndexTable.Cell>
                                                                            <Text variant="bodyMd">{LIObj.avgRating ? LIObj.avgRating : 0}</Text>
                                                                        </IndexTable.Cell>
                                                                        <IndexTable.Cell>
                                                                            <Text variant="bodyMd">{LIObj.ratingCount ? LIObj.ratingCount : 0}</Text>
                                                                        </IndexTable.Cell>
                                                                        <IndexTable.Cell>
                                                                            <Text variant="bodyMd">{LIObj.count ? LIObj.count : 0}</Text>
                                                                        </IndexTable.Cell>
                                                                        {
                                                                            <IndexTable.Cell>
                                                                                <div id="delete"
                                                                                    onClick={() => handleSubdeleteModal(i._id, LIObj.id)}
                                                                                >
                                                                                    <Banner hideIcon tone="critical">
                                                                                        <Icon source={DeleteIcon} tone="critical" />
                                                                                    </Banner>
                                                                                </div>
                                                                            </IndexTable.Cell>
                                                                        }
                                                                    </IndexTable.Row>
                                                                ))
                                                                : null
                                                            }
                                                        </>
                                                    )
                                                })}
                                        </IndexTable>
                                    </Card>
                                </Box>
                            </BlockStack>
                        </Card>

                        {/* General questions */}
                        <Card padding={0}>
                            <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
                                <Text variant='headingMd' fontWeight='semibold' as='h1'>General questions</Text>
                            </Box>
                            <Divider borderColor='border-tertiary' />
                            <Box paddingInline={400} paddingBlockEnd={300}>

                                {queArr && queArr.map((data, i) => {
                                    return (
                                        <div key={i} id='cursorPoint' onClick={() => changeNameValue({ tabEvent: data.field })}>
                                            <Box paddingBlock={300}>
                                                <InlineStack wrap={false} blockAlign='center' align='space-between'>
                                                    <Text variant='headingSm' as='p' fontWeight='medium'>{data.question}</Text>
                                                    <Box><Icon source={data.field === state.tabEvent ? ChevronUpIcon : ChevronDownIcon}></Icon></Box>
                                                </InlineStack>
                                                <Collapsible open={data.field === state.tabEvent} id="db_collaspe"
                                                    transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                                                    <Box paddingBlockStart={100}>
                                                        <Text variant='headingSm' as='p' fontWeight='regular' tone="subdued">{data.answer}</Text>
                                                    </Box>
                                                </Collapsible>
                                            </Box>
                                            {i !== queArr.length - 1 && <Divider />}
                                        </div>
                                    )
                                })}

                            </Box>
                        </Card>

                    </BlockStack>
                </Box>

                {/* delete data modal  */}
                <Modal
                    open={info.deleteDataModal}
                    title={<Text variant='headingMd' fontWeight='bold'>{"Are you sure want to delete this record?"}</Text>}
                    onClose={(e) => { openCloseModal('deleteDataModal', info.deleteDataModal, e); changeNameValueCard({ apiD: false }); }}>
                    <Modal.Section>
                        <Box paddingBlockEnd={400}>
                            <Text variant="bodyMd" as="p">{"By clicking yes, you will lose the current record."}</Text>
                        </Box>
                        <Divider />
                        <PageActions
                            primaryAction={{
                                icon: DeleteIcon, content: "Yes, delete record", tone: 'critical',
                                onAction: () => {
                                    if (info.apiD) {
                                        handleDeleteSubData();
                                    } else {
                                        handleDeleteCall(info.dataId);
                                    }
                                }
                            }}
                            secondaryActions={[
                                {
                                    content: "Cancel",
                                    onAction: () => { openCloseModal('deleteDataModal', info.deleteDataModal, 'close'); changeNameValueCard({ apiD: false }); }
                                }
                            ]}
                        />
                    </Modal.Section>
                </Modal>
            </Page >
        </div>
    )
}

export default Facebook