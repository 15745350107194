import { Grid, Button, Popover, Card, Checkbox, Badge, Divider, RadioButton, InlineGrid, Collapsible, Icon, InlineStack, OptionList, Box, IndexTable, Label, Text, TextField } from '@shopify/polaris';
import { ExternalIcon, EditIcon } from '@shopify/polaris-icons';
import { wisernotify, error } from '../../img';
import { Link } from 'react-router-dom';

const FullfillCard = ({ props }) => {
  let { state, changeNameValue, getValbyTyp } = props;

  const getTitle = (typ) => {
    let ttl = 'Single product review template';
    if (typ === 'multi')
      ttl = 'Multiple product review template';
    return ttl;
  }

  const handleClkTempEdit = (data) => {
    changeNameValue({ isNewEditPanel: true });
    props.editTemplateClk(data);
  }

  let fullFill = getValbyTyp('on_order_fulfill') || {};

  return (
    <Box paddingBlockStart={600}>

      <Card padding={'0'}>
        <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
          <Text variant='bodyLg' fontWeight='medium' as='h1'>Start collecting reviews via Email & SMS</Text>
        </Box>
        <Divider borderColor='border-tertiary' />
        <Box padding={400}>
          <InlineStack gap='200' align='start' blockAlign='start' direction={'row'}>
            <Box paddingBlockStart={100}>
              <Text variant='headingSm' fontWeight='regular' as='h6'>
                Send the review request after {fullFill.dd || ''} days. </Text>
            </Box>
            <TextField
              min={1}
              max={60}
              suffix='days'
              type='number'
              id='afterdayinput'
              value={fullFill.dd}
              onChange={(e) => props.updDaysField(Number(e), 'on_order_fulfill')}
            />
          </InlineStack>

          <Box paddingBlock={400}>
            <Text variant='headingSm' fontWeight='medium' as='h6'>
              Choose templates for requesting reviews via email, SMS, and whatsApp.</Text>

            {localStorage.getItem('pvd') !== 'local' &&
              <Box paddingBlockStart={400}>
                <div className='info_banner' style={{ width: '100%' }}>
                  <InlineStack gap={200} wrap={false}>
                    <img src={error} alt='error' />
                    <Text variant='bodyMd' fontWeight='medium' as='p'>If you select multiple templates, then we will randomly pick a template.</Text>
                  </InlineStack>
                </div>
              </Box>
            }

            <Box paddingBlockStart={600}>
              <InlineStack gap={200} blockAlign='start'>
                <Box paddingBlockStart={'050'}>
                  <img src={wisernotify} alt="wisernotify" />
                </Box>
                <InlineStack direction={'column'}>
                  <Text variant='headingSm' fontWeight='medium' as='h6'>Default templates</Text>
                  <Box paddingBlockStart={100}>
                    <Text variant='bodyMd' fontWeight='regular' tone='subdued'>Choose email template to collect reviews</Text>
                  </Box>
                </InlineStack>
              </InlineStack>

              <Box paddingBlockEnd={100} paddingInlineStart={{ xs: '0', sm: '0', md: '800', lg: '800', xl: '800' }}>

                {/* create template section */}
                {state?.createMstArr?.length > 0 && state.createMstArr.map((data, i) => {
                  if (data.typ === 'on_order_fulfill')
                    return (
                      <Box key={i} paddingBlockStart={400}>
                        <InlineStack align="space-between" blockAlign='center' gap={200}>
                          <Checkbox disabled label={<InlineStack gap={200}>
                            <Text id='clrsecondary' as='span' fontWeight='regular' variant='bodySm' >
                              {getTitle(data.sbtyp)}</Text>
                            <Badge tone="attention"><Text fontWeight='medium'>Email</Text></Badge>
                          </InlineStack>}
                          />
                          <Button icon={EditIcon} variant='primary' onClick={() => props.createSeqTemplate(data)}>Create Template</Button>
                        </InlineStack>
                      </Box>
                    )
                })}

                {console.log(state?.createMstArr, state.newSeqData, '........;;;;;')}

                {/* edit template section */}
                {state.newSeqData?.length > 0 && state.newSeqData.map((data, i) => {
                  if (data.typ === 'on_order_fulfill')
                    return (
                      <Box key={i} paddingBlockStart={400}>
                        <InlineStack align="space-between" blockAlign='center' gap={'50'}>
                          <Checkbox checked={data.ia} onChange={(e) => props.handleStatusUpdate(data._id, e)}
                            label={<InlineStack gap={200}>
                              <Text id='clrsecondary' as='span' fontWeight='regular' variant='bodyMd'>
                                {getTitle(data.sbtyp)} &nbsp;
                                <Badge tone="attention" fontWeight='medium'>Email</Badge>
                              </Text>
                            </InlineStack>
                            } />
                          <Button icon={EditIcon} onClick={() => handleClkTempEdit(data)}>Edit</Button>
                        </InlineStack>
                      </Box>
                    );
                })}

              </Box>
            </Box>

            {/* integration parts */}
            {state?.stateIntegration?.length > 0 && state.stateIntegration.map((mainData, j) => {
              return (
                <Box key={j}>
                  <Box paddingBlock={400}><Divider /></Box>

                  <InlineStack align='space-between' gap={300}>
                    <InlineStack gap={200} blockAlign='start'>
                      <Box paddingBlockStart={'050'}>
                        <img src={mainData.img ? mainData.img : wisernotify} alt="Integration" />
                      </Box>
                      <InlineStack direction={'column'}>
                        <Text variant='headingSm' fontWeight='medium' as='h6'>{mainData.dn}</Text>
                        <Box paddingBlockStart={100}>
                          <Text variant='bodySm' as='span' tone='subdued'>Ideal for requesting reviews for individual product</Text>
                        </Box>
                      </InlineStack>
                    </InlineStack>
                    <Link to={`/integration?name=${mainData.inm}`}><Button icon={ExternalIcon} variant='plain'>Integration</Button></Link>
                  </InlineStack>

                  <Box paddingBlockStart={400} paddingInlineStart={800}>
                    {mainData?.d?.arr?.length > 0 && mainData.d.arr.map((data, i) => {
                      if (data.ia === true) {
                        return (
                          <Box key={i} paddingBlockEnd={i !== mainData.d.arr.length - 1 ? 400 : 0}>
                            <InlineStack align="space-between" blockAlign='center'>
                              <InlineStack align='center'>

                                <Checkbox checked={data.iordfulfil}
                                  onChange={(e) => props.updateTmpStatus(mainData._id, data._id, 'iordfulfil', e)} />

                                <div id='cursorPoint'
                                  onClick={() => props.updateTmpStatus(mainData._id, data._id, 'iordfulfil', !data.iordfulfil)} >

                                  <InlineStack direction={'column'} align='start' blockAlign='start' gap={100}>
                                    <InlineStack gap={200} align='start'>
                                      <Text id='clrsecondary' as='p' fontWeight='regular' variant='bodymd'>
                                        {data.rmk ? data.rmk : '-'}</Text>
                                      {mainData?.ctg &&
                                        <Badge tone={mainData.ctg === 'Whatsapp' ? 'success' : 'info'}>
                                          <Text fontWeight='medium'>{mainData.ctg}</Text>
                                        </Badge>
                                      }
                                    </InlineStack>
                                  </InlineStack>

                                </div>

                              </InlineStack>
                            </InlineStack>
                          </Box>
                        );
                      }
                    })}
                  </Box>

                </Box>
              );
            })}

          </Box>

        </Box>
      </Card>

    </Box >
  );
}

export default FullfillCard;