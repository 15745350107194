import { Box, Checkbox, Text, TextField, InlineStack, InlineGrid, Tooltip, BlockStack } from '@shopify/polaris';
import { ImageWithTextOverlayIcon } from '@shopify/polaris-icons';
import ColorPick from '../ColorPick/ColorPick';
import { useEffect } from 'react';

const TextStarRating = ({ props }) => {
  let { state, handleTextChange, changeNameValue, } = props;
  let cnf = state.cnf;
  let verticalTabsArr = [
    { title: 'Reviewer cards', img: ImageWithTextOverlayIcon }
  ];

  useEffect(() => {
    changeNameValue({ verticalTabsArr: verticalTabsArr });
  }, [state.newActiveTab]);

  return (
    <Box padding={200}>

      {state.sidebarActiveIndex === 0 &&
        <Box>

          <InlineGrid columns={2} gap={300}>
            {state.cardArr && state.cardArr.length > 0 && state.cardArr.map((val, i) => {
              return (
                <div key={i} className={val._id === state.currCardId ? 'select_block active' : 'select_block'}
                  onClick={() => { changeNameValue({ currCardId: val._id }, 'isEditable') }}>
                  <InlineStack align='center' blockAlign='center'>
                    <Checkbox tone={val._id === state.currCardId ? 'magic' : ''}
                      checked={val._id === state.currCardId} />
                    <Text id='text_capitalize' fontWeight='medium' alignment='center'>
                      {val.dn}</Text>
                  </InlineStack>
                  {/* <Thumbnail source={val.iu} size="large" alt="Layout" /> */}
                  <img className='border_NoneImg' src={val.iu} alt="Skeleton" />
                </div>
              );
            })}
          </InlineGrid>

          <Box paddingBlock={400} paddingBlockEnd={600}>
            {cnf && cnf.strclr !== undefined &&
              <ColorPick
                labelName='Star color'
                colorKey="strclr"
                colorValue={cnf.strclr}
                changeNameValue={handleTextChange} />
            }
          </Box>

          <Tooltip content="Select where to hide star ratings when no reviews are available" width='wide' padding="400" dismissOnMouseOut>
            <Text fontWeight='semibold'>Hide star if no reviews:</Text>
          </Tooltip>
          <Box paddingInlineStart={300} paddingBlockStart={150} paddingBlockEnd={400}>
            <div>
              <Checkbox
                checked={cnf.ihdzrstrctlg}
                onChange={(e) => handleTextChange({ ihdzrstrctlg: e })}
                label={<Text fontWeight='medium' as='span'>Collection page</Text>} />
            </div>
            <div>
              <Checkbox
                checked={cnf.ihdzrstrpdp}
                onChange={(e) => handleTextChange({ ihdzrstrpdp: e })}
                label={<Text fontWeight='medium' as='span'>Product page</Text>} />
            </div>
          </Box>
          {/* <Checkbox
                  label={<Text fontWeight='medium' as='span'>Hide star if no reviews (Collection page only)</Text>}
                  checked={cnf.ihdzrstr}
                  onChange={(e) => handleTextChange({ ihdzrstr: e })}
                /> */}


          {cnf && cnf.icstcss !== undefined &&
            <Box>
              <Checkbox
                label={<Text fontWeight='medium' as='span'>Is custom css enabled</Text>}
                checked={cnf.icstcss}
                onChange={(e) => handleTextChange({ icstcss: e })}
              />
              <Box paddingBlockStart={100} paddingInlineStart={600}>
                <TextField
                  label={<Text tone='subdued' as='span'>Custom css block</Text>}
                  disabled={!cnf.icstcss}
                  multiline={4}
                  value={cnf.cstcss}
                  onChange={(e) => handleTextChange({ cstcss: e })}
                  autoComplete="off" />
              </Box>
            </Box>
          }

        </Box>
      }

    </Box>
  );
}

export default TextStarRating;