import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import Routes from './Routes';
import 'react-toastify/dist/ReactToastify.css';

const rootEl = document.getElementById('root');

const root = createRoot(rootEl);

root.render(
  <App Component={Routes} />
);