import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/branding';
import { toastify } from '../ducks/toast';

function* getBranding(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getBranding', action.data);

    if (res.status === 'success') {
      yield put(actions.getBrandingSuccess(res.data));

      if (res?.data?.lg) localStorage.setItem('lg', res.data.lg);
      if (res?.data?.nm) localStorage.setItem('bn', res.data.nm);
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updBranding(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updBranding', action.data);
    if (res.status === 'success') {
      yield put(actions.getBranding());
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(actions.getBranding());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* removeBrndImg(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/removeBrndImg', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* verifyEmail(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/verifyEmail', action.data);
    if (res.status === 'success') {
      yield put(actions.verifyEmailSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetBranding() {
  yield takeLatest("GET_BRANDING", getBranding);
}

export function* watchUpdBranding() {
  yield takeLatest("UPD_BRANDING", updBranding);
}

export function* watchRemoveBrndImg() {
  yield takeLatest("REMOVE_BRND_IMG", removeBrndImg);
}

export function* watchVerifyEmail() {
  yield takeLatest("VERIFY_EMAIL", verifyEmail);
}

export default function* rootSaga() {
  yield fork(watchGetBranding);
  yield fork(watchUpdBranding);
  yield fork(watchRemoveBrndImg);
  yield fork(watchVerifyEmail);
}


