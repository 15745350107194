import { InlineGrid, BlockStack, Link, Modal, Collapsible, Badge, Spinner, Banner, Text, Card, Tooltip, Page, Button, ButtonGroup, Box, Icon, InlineStack } from '@shopify/polaris';
import { ExternalIcon, PaperCheckIcon, DnsSettingsIcon, AppExtensionIcon, CodeIcon, ChevronUpIcon, ChevronDownIcon, StarFilledIcon, StarIcon } from '@shopify/polaris-icons';
import { warning_icon, copy_icon } from '../../img';
import ConfirmationAlert from '../ConfirmationModal/ConfirmationAlert';
import { ArrowDiagonalIcon } from '@shopify/polaris-icons';
// import MainTab from './MainTab'; 
import AddEditWidgetScreen from './AddEditWidgetScreen';
import EditPanel from './EditPanel';
import React, { memo, useEffect } from 'react';


let disableWdgtArr = ['carousel']; // disable widgets for free plan.
let hideMsterWdgt = ['checkout_review'];  // hide these widget, when localStorage's is_master has no value
const isMaster = localStorage.getItem("is_master");
const isShwMstWdgt = isMaster !== null && isMaster !== undefined && isMaster !== '';


function List(props) {
  const { state, changeNameValue, handleUpdateAction } = props;

  // const arr2Types2 = state.WidgetList && state.WidgetList.length > 0 ? state.WidgetList.map(data => data.wdtyp) : [];

  // const addWidgets = (state.lstLayout || [])
  //   .filter(data => isShwMstWdgt || (Array.isArray(hideMsterWdgt) && !hideMsterWdgt.includes(data.typ)))
  //   .filter(data => !arr2Types2.includes(data.typ))
  //   .map((data, i) => (
  //     <Box key={i} paddingBlockEnd={200}>
  //       <Card padding={0}>
  //         <div className='theme_div'>
  //           <div className='theme_img'>
  //             <img className='w-100' src={data.iu} alt="ThemeImage" />
  //           </div>
  //           <Box padding={400} paddingBlockStart={300}>
  //             <InlineStack gap={200}>
  //               <InlineStack gap={200}>
  //                 <Text variant="headingMd" fontWeight="semibold" as="h5">{data.dn ? data.dn : ''}</Text>
  //                 {state.isFreePln === true && disableWdgtArr.includes(data.typ) &&
  //                   <Box><Badge tone='warning'>Exclusive</Badge></Box>
  //                 }
  //               </InlineStack>
  //               <Text variant="bodySm" as="p" id='fix_hght' fontWeight='regular' tone='subdued'>{data.rm ? data.rm : ''}</Text>
  //             </InlineStack>
  //             <ButtonGroup>
  //               {state.isFreePln === true && disableWdgtArr.includes(data.typ) ?
  //                 <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
  //                   <Button variant="secondary" disabled>Add widget</Button>
  //                 </Tooltip>
  //                 :
  //                 <Button variant="secondary"
  //                   onClick={() => props.handleUpdateAction(data, 'create')}>Add widget</Button>
  //               }
  //               <Button variant="primary" disabled>Manage</Button>
  //             </ButtonGroup>
  //           </Box>
  //         </div>
  //       </Card>
  //     </Box >
  //   ));

  // const editWidgets = (state.lstLayout || [])
  //   .filter(data => isShwMstWdgt || (Array.isArray(hideMsterWdgt) && !hideMsterWdgt.includes(data.typ)))
  //   .filter(data => arr2Types2.includes(data.typ))
  //   .map((data, i) => (
  //     <Box key={i} paddingBlockEnd={200}>
  //       <Card padding={0}>
  //         <div className='theme_div' id='cursorPoint'
  //           onClick={state.isFreePln === true && disableWdgtArr.includes(data.typ) ?
  //             () => { } :
  //             () => props.handleUpdateAction(data)}
  //         >
  //           <div className='theme_img'><img className='w-100' src={data.iu} alt="ThemeImage" /></div>
  //           <Box padding={400} paddingBlockStart={300}>
  //             <InlineStack gap={200}>
  //               <Text variant="headingMd" fontWeight="semibold" as="h5">{data.dn ? data.dn : ''}</Text>
  //               {data.installWidget &&
  //                 <Badge tone="success" progress="complete">Installed</Badge>
  //               }
  //               <Text variant="bodySm" as="p" id='fix_hght' fontWeight='regular' tone='subdued'>{data.rm ? data.rm : ''}</Text>
  //             </InlineStack>

  //             {state.isFreePln === true && disableWdgtArr.includes(data.typ) ?
  //               <InlineStack gap={200} blockAlign='center'>
  //                 <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
  //                   <Button variant="primary" disabled={state.isFreePln === true && disableWdgtArr.includes(data.typ)}>Manage</Button>
  //                 </Tooltip>
  //                 <Box>
  //                   <Badge tone='warning'>Exclusive</Badge>
  //                 </Box>
  //               </InlineStack>
  //               :
  //               <Button variant="primary">Manage</Button>
  //             }
  //           </Box>
  //         </div>
  //       </Card>
  //     </Box >
  //   ));

  // const DsplyWidgets2 = [...addWidgets, ...editWidgets];  //showing "not created widgets" at top




























  const arr2Types = state.WidgetList?.map(data => data.wdtyp) || [];

  const DsplyWidgets = (state.lstLayout || [])
    .filter((data) => { //shw que_ans & main widget only for shopify, hide for others
      if (localStorage.getItem('pvd') !== 'shopify' && (data.typ === 'main' || data.typ === 'que_ans')) {
        return false;
      }
      return true;
    })
    .map((data, i) => {
      const isWidgetCreated = arr2Types.includes(data.typ);
      const isExclusive = state.isFreePln === true && disableWdgtArr.includes(data.typ);

      return (
        <Box key={i} paddingBlockEnd={200}>
          <Card padding={0}>
            <div
              className='theme_div'
              id={'cursorPoint'}
              onClick={isWidgetCreated && !isExclusive ?
                () => handleUpdateAction(data)
                :
                !isWidgetCreated && !isExclusive ?
                  () => handleUpdateAction(data, 'create')
                  :
                  () => changeNameValue({})
              }>
              <div className='theme_img'>
                <img className='w-100' src={data.iu} alt="ThemeImage" />
              </div>
              <Box padding={400} paddingBlockStart={300}>
                <InlineStack gap={200}>
                  <Text variant="headingMd" fontWeight="semibold" as="h5">{data.dn || ''}</Text>
                  {isWidgetCreated && data.installWidget &&
                    <Badge tone="success" progress="complete">Installed</Badge>
                  }
                  <Text variant="bodySm" as="p" id='fix_hght' fontWeight='regular' tone='subdued'> {data.rm || ''} </Text>
                </InlineStack>
                <ButtonGroup>

                  {isWidgetCreated ?
                    isExclusive ?
                      <InlineStack gap={200} blockAlign='center'>
                        <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                          <Button variant="primary" disabled>Manage</Button>
                        </Tooltip>
                        <Box><Badge tone='warning'>Exclusive</Badge></Box>
                      </InlineStack>
                      :
                      <Button variant="primary">Manage</Button>
                    :
                    isExclusive ?
                      <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                        <Button variant="secondary" disabled>Add widget</Button>
                      </Tooltip>
                      :
                      <Button variant="secondary">Add widget</Button>
                  }
                </ButtonGroup>
              </Box>
            </div>
          </Card>
        </Box>
      );
    });


  return (
    <Box paddingBlockEnd={600}>

      {state.isShwEditPanel !== true ?
        <Page
          title={'Widgets'}
          subtitle={'Multiple widgets that allow you to show reviews more effective way on your store.'}>

          {props.isShwBanner === true && localStorage.getItem('pvd') === 'shopify' &&
            <Box paddingBlockStart={400} paddingBlockEnd={800}>
              <Card padding={0}>
                <Box background='bg-surface-critical' padding={200}>
                  <Banner tone='critical' onDismiss={() => props.setIsShwBanner(false)}>
                    <Box paddingBlockEnd={300}>
                      <InlineStack gap={200} direction={'column'}>
                        <Text variant='bodyLg' fontWeight='medium' as='h5'>App embeds missing from live theme</Text>
                        <Text variant='bodyMd' fontWeight='regular' as='span'>This is required for all widgets to show correctly. Click the button below and then Save.</Text>
                      </InlineStack>
                    </Box>
                    <Button variant='plain' tone='critical' target='_blank' url={`https://${localStorage.getItem('shp')}/admin/themes/current/editor?template=product&context=apps&activateAppId=c9dad0b1-2b42-42c3-b484-666f36432323/pixel&target=newAppsSection`}>
                      <InlineStack gap={100}>
                        <Text fontWeight='medium' variant='bodyMd'>Enable app embed</Text>
                        <Icon source={ArrowDiagonalIcon} />
                      </InlineStack>
                    </Button>
                  </Banner>
                </Box>
              </Card>
            </Box>
          }

          {state.lstLayout && state.lstLayout.length > 0 ?
            <InlineGrid gap={400} columns={{ xl: 3, lg: 3, md: 2 }}>
              {/* {DsplyWidgets2} */}

              {DsplyWidgets}
            </InlineGrid>
            :
            <Box paddingBlockStart={600}>
              <InlineStack align='center'>
                <Spinner accessibilityLabel="Spinner example" size="large" />
              </InlineStack>
            </Box>
          }
        </Page>
        :
        (localStorage.getItem('pvd') === 'shopify' || state.clkOnCustomize === true) ?
          <div className='container-custom'>
            <EditPanel props={props} />
          </div>
          :
          <div className='container-custom'>
            <AddEditWidgetScreen props={props} />
          </div>
      }












      <ConfirmationAlert
        state={state}
        openCloseModal={props.openCloseModal}
        saveChanges={props.saveChanges}
        discardChanges={props.discardChanges}
      />
    </Box >
  );
}

export default memo(List);