import { BlockStack, Box, Button, Icon, InlineGrid, InlineStack, Page, Text, TextField, Tooltip } from '@shopify/polaris'
import { ClipboardIcon, EditIcon, HideIcon, ImageAddIcon, ViewIcon } from '@shopify/polaris-icons'
import FileBase64 from 'react-file-base64';

import React from 'react'

function MyProfile(props) {

  const { state, changeNameValue, userProfileImg, updFullname, validator, handleCopyApiKey, handleChangePassword, handleBackToMain,
    isShwBack, updateProfile } = props;

  return (
    <Page title="Profile" backAction={isShwBack === true ? { onAction: () => handleBackToMain() } : false}>
      <Box background='bg-surface' padding={400} id='profileBox-bottom-space' borderRadius='200'>
        <BlockStack gap={500}>
          <Box padding={400} id='customebg-color' borderRadius='100'>
            <BlockStack gap={300}>
              <Text as='p' variant='headingMd' fontWeight='semibold'>Profile details</Text>
              <BlockStack gap="200">
                <div style={{ width: '50%' }}>
                  <Box>
                    <InlineStack align='space-between'>
                      <Box><Text tone="subdued">{'Profile image'}</Text></Box>
                      <Tooltip content={'Upload profile image'} width='wide' padding="400" dismissOnMouseOut >
                        <Box> <span className='edit-icon' style={{ position: 'relative' }}> <Icon source={EditIcon} />
                          <div className='Profile_Img_Icon'>
                            <FileBase64
                              multiple={false}
                              onDone={(file) => userProfileImg(file)}
                            />
                          </div>
                        </span>
                        </Box>
                      </Tooltip>
                    </InlineStack>
                    <div style={{ width: '40px', height: '40px', position: 'relative', marginTop: '10px' }} >
                      {state.iu ? <img src={state.iu} width={40} />
                        :
                        <Icon
                          source={ImageAddIcon}
                          tone="base"
                        />}
                      <div className='Profile_Img'>
                        <FileBase64
                          multiple={false}
                          onDone={(file) => userProfileImg(file)}
                        />
                      </div>
                    </div>
                  </Box>
                </div>
              </BlockStack>

              <Box>
                <InlineGrid gap="300" columns={{ xs: 1, sm: 2, md: 2 }}>
                  <BlockStack gap={100}>
                    <TextField value={state.e} disabled label={<Text tone="subdued">Email</Text>} />
                  </BlockStack>
                  <TextField
                    value={state.fn}
                    onChange={(e) => changeNameValue({ fn: e })}
                    label={<Text tone="subdued">Full name</Text>}
                    error={state.errMessage?.fn ? 'The name field is required.' : false} />
                  {validator.message('fn', state.fn, 'required')}
                </InlineGrid>
              </Box>

              <Box>
                <Button variant="primary" tone='success' onClick={() => updFullname('fn', state.fn)}>Save</Button>
              </Box>
            </BlockStack>
          </Box>
          <Box padding={400} id='customebg-color' borderRadius='100'>
            <BlockStack gap={200}>
              <Box>
                <Text variant='headingMd' as='p' fontWeight='semibold'>Change password</Text>
              </Box>

              <InlineGrid gap="300" columns={{ xs: 1, sm: 2, md: 2 }}>
                <div className='divCpass'>
                  <TextField
                    label={<Text tone="subdued">Current Password</Text>}
                    placeholder='Enter current password'
                    type={state.isCurnPassVisible ? 'text' : 'password'}
                    onChange={(e) => changeNameValue({ pd: e })}
                    value={state.pd}
                    error={state.errMessage?.pd ? 'The current password field is required.' : false}
                  />
                  {validator.message('pd', state.pd, 'required')}
                  <Button
                    icon={state.isCurnPassVisible ? HideIcon : ViewIcon}
                    onClick={() => changeNameValue({ isCurnPassVisible: !state.isCurnPassVisible })}
                    plain
                    variant='monochromePlain'
                  />
                </div>

                <div className='divCpass'>
                  <TextField
                    label={<Text tone="subdued">New Password</Text>}
                    placeholder='New Password'
                    type={state.isNewPassVisible ? 'text' : 'password'}
                    onChange={(e) => changeNameValue({ npd: e })}
                    value={state.npd}
                    error={state.errMessage?.npd ? 'The new password field is required.' : false}
                  />
                  {validator.message('npd', state.npd, 'required')}
                  <Button
                    icon={state.isNewPassVisible ? HideIcon : ViewIcon}
                    onClick={() => changeNameValue({ isNewPassVisible: !state.isNewPassVisible })}
                    plain
                    variant='monochromePlain'
                  />
                </div>
              </InlineGrid>
              <Box>
                <Button variant="primary" tone='success' onClick={() => handleChangePassword()}>Save</Button>
              </Box>
            </BlockStack>
          </Box>

          {/* {state?.utyp === 'appsumo' || state?.pvd === 'shopify' || state?.pvd === 'wix' || state?.pvd === 'shopline' && */}
          <Box id='customebg-color' borderRadius='100'>
            <Box padding="400">
              <div className='apiOuterBox'>
                <pre className="apiBack">
                  &lt;!-- {'API BEGIN'} --&gt;
                  <br />
                  <span>{state.ak}</span>
                  <br />
                  &lt;!-- {'API END'}--&gt;

                </pre>
                <div className='apiKeyBtn'>
                  <BlockStack gap={200}>
                    <Box><Text variant='headingMd' as='p' fontWeight='semibold'>Api Key</Text></Box>
                    <Button onClick={() => handleCopyApiKey()}><Box padding={200}><BlockStack gap={100}><Icon source={ClipboardIcon} /><Text tone="subdued">Copy api</Text> </BlockStack></Box></Button>
                  </BlockStack>
                </div>
              </div>
            </Box>
          </Box>
        </BlockStack>
      </Box>
    </Page>
  )
}

export default MyProfile