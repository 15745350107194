import { Button, Popover, Card, Checkbox, Badge, Divider, InlineStack, OptionList, Box, Text, TextField } from '@shopify/polaris';
import { ExternalIcon, EditIcon } from '@shopify/polaris-icons';
import { wisernotify, error } from '../../img';
import { Link } from 'react-router-dom';

const MediaCard = ({ props }) => {
  let { state, changeNameValue, saveMediaConfig, getValbyTyp, updConfigData } = props;

  let starRtOpt = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' }
  ];

  const getTitle = (typ) => {
    let ttl = 'Single product review template';
    if (typ === 'multi')
      ttl = 'Multiple product review template';
    return ttl;
  }

  const handleClkTempEdit = (data) => {
    changeNameValue({ isNewEditPanel: true });
    props.editTemplateClk(data);
  }

  let media = getValbyTyp('media_request') || {};

  return (
    <Box paddingBlockStart={600}>

      <Card padding={'0'}>
        <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
          <InlineStack gap={200}>
            <Text variant='bodyLg' fontWeight='medium' as='h1'>Media follow up</Text>

            {media.ia ? <Badge tone='success'><Text variant="bodySm" fontWeight="medium" as="span">On</Text></Badge> : <Badge tone='critical'><Text variant="bodySm" fontWeight="medium" as="span">Off</Text></Badge>}

          </InlineStack>
        </Box>
        <Divider borderColor='border-tertiary' />
        <Box padding={400}>

          <Checkbox
            checked={media.ia}
            onChange={() => updConfigData(!media.ia, 'media_request')}
            label={<Text as='span' id='fw500' fontWeight='medium' variant='bodySm'>Want to follow up to collect the video testimonial?</Text>} />

          {media.ia &&
            <Box paddingBlockStart={200} paddingInlineStart={600}>
              {/* save days */}
              <InlineStack gap='200' align='start' blockAlign='start' direction={'row'}>
                <Box paddingBlockStart={100}>
                  <Text variant='headingSm' fontWeight='regular' as='h6'>
                    Follow-up requests and reminders for media upload are sent after {media.dd || ''} days.
                  </Text>
                </Box>
                <TextField
                  suffix='days'
                  type='number'
                  id='afterdayinput'
                  value={media.dd || ''}
                  onChange={(e) => props.updDaysField(Number(e), 'media_request')}
                />
              </InlineStack>

              {/* media minimum rating */}
              <Box paddingBlockStart={400} paddingBlockEnd={200}>
                <InlineStack gap={200} blockAlign='center'>
                  <Text fontWeight='medium' as='span'>Minimum star rating:</Text>
                  <Box width='13.5%'>
                    <Popover
                      fullWidth
                      active={state.isMinStar}
                      onClose={() => changeNameValue({ isMinStar: !state.isMinStar })}
                      activator={<Button textAlign="left" fullWidth disclosure
                        onClick={() => changeNameValue({ isMinStar: !state.isMinStar })}>
                        <Text tone='subdued' fontWeight='bold'>
                          {starRtOpt.find(({ value }) => value == media.mnrtng)?.label || 'Select'}
                        </Text>
                      </Button>}>
                      <OptionList
                        options={starRtOpt}
                        selected={[media.mnrtng.toString()]}
                        onChange={(e) => { saveMediaConfig(e.toString(), 'media_request'); changeNameValue({ isMinStar: !state.isMinStar }) }} />
                    </Popover>
                  </Box>
                </InlineStack>
              </Box>

              {/* email template */}
              <Box paddingBlock={400}>
                <Text variant='headingSm' fontWeight='medium' as='h6'>
                  Choose templates for requesting reviews via email, SMS, and whatsApp.</Text>

                {localStorage.getItem('pvd') !== 'local' &&
                  <Box paddingBlockStart={400}>
                    <div className='info_banner' style={{ width: '100%' }}>
                      <InlineStack gap={200} wrap={false}>
                        <img src={error} alt='error' />
                        <Text variant='bodyMd' fontWeight='medium' as='p'>If you select multiple templates, then we will randomly pick a template.</Text>
                      </InlineStack>
                    </div>
                  </Box>
                }

                <Box paddingBlockStart={600}>
                  <InlineStack gap={200} blockAlign='start'>
                    <Box paddingBlockStart={'050'}>
                      <img src={wisernotify} alt="wisernotify" />
                    </Box>
                    <InlineStack direction={'column'}>
                      <Text variant='headingSm' fontWeight='medium' as='h6'>Default templates</Text>
                      <Box paddingBlockStart={100}>
                        <Text variant='bodyMd' fontWeight='regular' tone='subdued'>Choose email template to collect reviews</Text>
                      </Box>
                    </InlineStack>
                  </InlineStack>

                  <Box paddingBlockEnd={100} paddingInlineStart={{ xs: '0', sm: '0', md: '800', lg: '800', xl: '800' }}>

                    {/* create template section */}
                    {state.createMstArr?.length > 0 && state.createMstArr.map((data, i) => {
                      if (data.typ === 'media_request')
                        return (
                          <Box key={i} paddingBlockStart={400}>
                            <InlineStack align="space-between" blockAlign='center' gap={200}>
                              <Checkbox disabled label={<InlineStack gap={200}>
                                <Text id='clrsecondary' as='span' fontWeight='regular' variant='bodySm' >
                                  {getTitle(data.sbtyp)}</Text>
                                <Badge tone="attention"><Text fontWeight='medium'>Email</Text></Badge>
                              </InlineStack>}
                              />
                              <Button icon={EditIcon} variant='primary' onClick={() => props.createSeqTemplate(data)}>Create Template</Button>
                            </InlineStack>
                          </Box>
                        )
                    })}

                    {/* edit template section */}
                    {/* {state.emailSeqArr?.length > 0 && state.emailSeqArr.map((data, i) => { */}
                    {state.newSeqData?.length > 0 && state.newSeqData.map((data, i) => {
                      if (data.typ === 'media_request')
                        return (
                          <Box key={i} paddingBlockStart={400}>
                            <InlineStack align="space-between" blockAlign='center' gap={'50'}>
                              <Checkbox checked={data.ia} onChange={(e) => props.handleStatusUpdate(data._id, e)}
                                label={<InlineStack gap={200}>
                                  <Text id='clrsecondary' as='span' fontWeight='regular' variant='bodyMd'>
                                    {getTitle(data.sbtyp)} &nbsp;
                                    <Badge tone="attention" fontWeight='medium'>Email</Badge>
                                  </Text>
                                </InlineStack>
                                } />
                              <Button icon={EditIcon} onClick={() => handleClkTempEdit(data)}>Edit</Button>
                            </InlineStack>
                          </Box>
                        );
                    })}

                  </Box>
                </Box>

                {/* integration parts */}
                {state?.stateIntegration?.length > 0 && state.stateIntegration.map((mainData, j) => {
                  return (
                    <Box key={j}>
                      <Box paddingBlock={400}><Divider /></Box>

                      <InlineStack align='space-between' gap={300}>
                        <InlineStack gap={200} blockAlign='start'>
                          <Box paddingBlockStart={'050'}>
                            <img src={mainData.img ? mainData.img : wisernotify} alt="Integration" />
                          </Box>
                          <InlineStack direction={'column'}>
                            <Text variant='headingSm' fontWeight='medium' as='h6'>{mainData.dn}</Text>
                            <Box paddingBlockStart={100}>
                              <Text variant='bodySm' as='span' tone='subdued'>Ideal for requesting reviews for individual product</Text>
                            </Box>
                          </InlineStack>
                        </InlineStack>
                        <Link to={`/integration?name=${mainData.inm}`}><Button icon={ExternalIcon} variant='plain'>Integration</Button></Link>
                      </InlineStack>

                      <Box paddingBlockStart={400} paddingInlineStart={800}>
                        {mainData?.d?.arr?.length > 0 && mainData.d.arr.map((data, i) => {
                          if (data.ia === true) {
                            return (
                              <Box key={i} paddingBlockEnd={i !== mainData.d.arr.length - 1 ? 400 : 0}>
                                <InlineStack align="space-between" blockAlign='center'>
                                  <InlineStack align='center'>

                                    <Checkbox checked={data.imda}
                                      onChange={(e) => props.updateTmpStatus(mainData._id, data._id, 'imda', e)} />

                                    <div id='cursorPoint'
                                      onClick={() => props.updateTmpStatus(mainData._id, data._id, 'imda', !data.imda)} >

                                      <InlineStack direction={'column'} align='start' blockAlign='start' gap={100}>
                                        <InlineStack gap={200} align='start'>
                                          <Text id='clrsecondary' as='p' fontWeight='regular' variant='bodymd'>
                                            {data.rmk ? data.rmk : '-'}</Text>
                                          {mainData?.ctg &&
                                            <Badge tone={mainData.ctg === 'Whatsapp' ? 'success' : 'info'}>
                                              <Text fontWeight='medium'>{mainData.ctg}</Text>
                                            </Badge>
                                          }
                                        </InlineStack>
                                      </InlineStack>

                                    </div>

                                  </InlineStack>
                                </InlineStack>
                              </Box>
                            );
                          }
                        })}
                      </Box>

                    </Box>
                  );
                })}

              </Box>
            </Box>
          }

        </Box>
      </Card>
    </Box >
  );
}

export default MediaCard;