import { IndexTable, IndexFilters, IndexFiltersMode, Text, Modal, Badge, Tooltip, InlineStack, Card, Page, Button, Box, PageActions, useSetIndexFiltersMode } from '@shopify/polaris';
import { DeleteIcon, RefreshIcon, MegaphoneIcon, StarIcon, ClockIcon } from '@shopify/polaris-icons';
import { useIndexResourceState } from '@shopify/polaris';
import * as queueDucks from '../../ducks/currentQueue';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useCallback, useEffect, memo } from 'react';
import { toastify } from '../../ducks/toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { changePathForShopifyAppEmbed } from "../../utils/utils";

const opt = { day: 'numeric', month: 'numeric', year: 'numeric' };

const timeStamp = (dt) => {
  const d = new Date(dt);
  return d.toLocaleDateString("es-CL", opt) + ' | ' + d.toLocaleTimeString();
}

const statusTabs = ['Queue', 'Sent', 'Incomplete'];


function List(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState({
    deleteOpen: false,
    retryOpen: false
  });
  const [sortSelected, setSortSelected] = useState(['cdt desc']);
  const [actionState, setActionState] = useState('');
  const [proIdDel, setProIdDel] = useState('');
  const [queryValue, setQueryValue] = useState('');
  const [tabSelected, setTabSelected] = useState(0);
  const [seqno, setSeqno] = useState('');
  const [msgid, setMsgid] = useState('');
  const [paggiActive, setPaggiActive] = useState(1);
  const [sts, setSts] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortOptions, setSortOptions] = useState([]);


  useEffect(() => {
    let sortOptions = [];
    if (tabSelected === 1) { //in 'sent' tab timestamp field name is-'udt'
      sortOptions = [
        { label: 'Sequence name', value: 'typ asc', directionLabel: 'A-Z' },
        { label: 'Sequence name', value: 'typ desc', directionLabel: 'Z-A' },
        { label: 'Email id', value: 'eml asc', directionLabel: 'A-Z' },
        { label: 'Email id', value: 'eml desc', directionLabel: 'Z-A' },
        { label: 'Order date', value: 'cdt asc', directionLabel: 'A-Z' },
        { label: 'Order date', value: 'cdt desc', directionLabel: 'Z-A' },
        { label: 'Email to be sent', value: 'udt asc', directionLabel: 'Ascending' },
        { label: 'Email to be sent', value: 'udt desc', directionLabel: 'Descending' }
      ];

      let sortLbl = sortSelected.toString().split(' ');
      if (sortLbl[0] === 'schdldt') {
        setSortSelected(`udt ${sortLbl[1]}`.split());
      }
    }
    else {
      sortOptions = [
        { label: 'Sequence name', value: 'typ asc', directionLabel: 'A-Z' },
        { label: 'Sequence name', value: 'typ desc', directionLabel: 'Z-A' },
        { label: 'Email ID', value: 'eml asc', directionLabel: 'A-Z' },
        { label: 'Email ID', value: 'eml desc', directionLabel: 'Z-A' },
        { label: 'Order date', value: 'cdt asc', directionLabel: 'A-Z' },
        { label: 'Order date', value: 'cdt desc', directionLabel: 'Z-A' },
        { label: 'Email to be sent', value: 'schdldt asc', directionLabel: 'Ascending' },
        { label: 'Email to be sent', value: 'schdldt desc', directionLabel: 'Descending' }
      ];
      let sortLbl = sortSelected.toString().split(' ');
      if (sortLbl[0] === 'udt') {
        setSortSelected(`schdldt ${sortLbl[1]}`.split());
      }
    }
    setSortOptions(sortOptions);
  }, [tabSelected]);


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let cid = queryParams.get("cid");

  const [mode, setMode] = useState(IndexFiltersMode.Default);
  const { setMode: setDefaultMode } = useSetIndexFiltersMode();
  const { setMode: setFilteringMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);

  useEffect(() => {
    if (cid) {
      setSts(1);
      setQueryValue(cid);
      setMode(IndexFiltersMode.Filtering);
      setFilteringMode();
    } else {
      setMode(IndexFiltersMode.Default);
      setDefaultMode();
    }
  }, [cid, setFilteringMode, setDefaultMode]);

  const promotedBulkActions = [
    { content: 'Delete record', onAction: () => actionListClick('delete') },
  ];

  function getType(typ) {
    if (typ === 'on_order_fulfill')
      return 'Ask for reviews';
    else if (typ === 'on_followup')
      return 'Reminder';
    else if (typ === 'one_time_campaign')
      return 'One time One review campaign';
  }

  const callEmlQueueApi = useCallback(() => {
    let srt = sortSelected.toString().replace('asc', '1').replace('desc', '-1');
    let obj = {
      num: paggiActive,
      eml: queryValue,
      srt: srt,
      sts: sts
    };
    dispatch(queueDucks.getEmailQueue(obj));
  }, [sortSelected, queryValue, paggiActive, sts]);


  useEffect(() => {
    setPaggiActive(1);
  }, [tabSelected]);

  // api data 
  const { QueueList } = useSelector(state => state.currentQueue);
  let QueueData = QueueList?.data.map(x => ({ ...x, id: x._id })) || [];
  let TotalData = QueueList?.total || 0;

  // paggination
  const onPaggiCall = useCallback((i) => {
    let num = 1;
    if (i === '+1') {
      num = paggiActive + 1;
    } else if (i === '-1') {
      num = paggiActive - 1;
    } else num = i;

    setPaggiActive(num);
  }, [paggiActive]);

  useEffect(() => {
    setPageNumber(parseInt(Math.ceil(TotalData / 20)));
  }, [TotalData]);

  const { selectedResources, handleSelectionChange } = useIndexResourceState(QueueData);

  const changeNameValue = useCallback((obj) => {
    setModal((prevState) => ({ ...prevState, ...obj }));
  }, []);

  useEffect(() => {
    if (queryValue) {
      let queryTimer = setTimeout(() => {
        callEmlQueueApi();
      }, 1000);
      return () => clearTimeout(queryTimer);
    }
    else callEmlQueueApi();
  }, [queryValue, sortSelected, paggiActive, sts]);

  const actionListClick = useCallback((typ) => {
    if (selectedResources.length > 0) {
      setActionState(typ);

      let arr_id = [];
      let msgidAry = [];
      arr_id = selectedResources;

      for (let i = 0; i < selectedResources.length; i++) {
        let data = QueueData && QueueData.filter(x => x._id === selectedResources[i]);
        if (data.length > 0) {
          msgidAry.push(data[0].seqno ? data[0].seqno : '');
        }
      }

      let obj = {
        _id: Array.from(new Set(arr_id)),
        msgidAry: Array.from(new Set(msgidAry))
      }
      dispatch(queueDucks.deleteEmailQueue(obj));

      setTimeout(() => {
        setActionState('');
        callEmlQueueApi();
        selectedResources.splice(0, selectedResources.length);
      }, 10);
    }
    else {
      dispatch(toastify({ type: 'error', msg: 'Select atleast one record' }));
      setActionState('');
    }
  }, [selectedResources, actionState]);

  const handleDeleteAction = useCallback((_id, seqno) => {
    setProIdDel(_id);
    setSeqno(seqno);
    openCloseModal('deleteOpen', modal.deleteOpen, 'open');
  }, [modal.deleteOpen, seqno]);

  const handleDeleteCall = useCallback((_id, seqno) => {
    let obj = {
      _id: _id ? String(_id).split() : [],
      msgidAry: seqno ? String(seqno).split() : []
    }
    dispatch(queueDucks.deleteEmailQueue(obj));

    setTimeout(() => {
      callEmlQueueApi();
      openCloseModal('deleteOpen', modal.deleteOpen, 'close');
    }, 100);
  }, [modal.deleteOpen]);

  const tabs = statusTabs.map((item, i) => ({
    key: i,
    id: `${item}-${i}`,
    content: item,
    onAction: () => handleTabsClick(item),
  }));

  const handleTabsClick = useCallback((typ) => {
    let sts = 0;
    if (typ === 'Sent') sts = 1;
    else if (typ === 'Incomplete') sts = 2;

    setSts(sts);
  }, [sts]);

  const handleRetryEml = (msgid) => {
    dispatch(queueDucks.retryEmailQueue({ msgid }));
    setTimeout(() => callEmlQueueApi(), 100);
    openCloseModal('retryOpen', modal.retryOpen, 'close');
  }

  const handleRetryAction = (msgid) => {
    setMsgid(msgid);
    openCloseModal('retryOpen', modal.retryOpen, 'open');
  }

  const openCloseModal = useCallback((name, value, type) => { //use of type is don't close modal on click of outside
    if (type !== undefined) changeNameValue({ [name]: !value });
  }, []);

  const handleBackToMain = useCallback(() => {
    if (localStorage.getItem('is_shopify_embed'))
      changePathForShopifyAppEmbed('/collectReview');
    else navigate(-1);
  }, []);

  let headingName = [];
  if (tabSelected === 1) { //sent tab
    headingName = [
      { title: "Email ID" },
      { title: "Phone number" },
      { title: "Type" },
      { title: "Sequence name" },
      { title: "Campaign ID" },
      { title: "Trigger type" },
      { title: "Order date" },
      { title: "Email to be sent" },
    ]
  }
  else if (tabSelected === 2) { //incomplete tab
    headingName = [
      { title: "Email ID" },
      { title: "Phone number" },
      { title: "Type" },
      { title: "Sequence name" },
      { title: "Status" },
      { title: "Order date" },
      { title: "Email to be sent" },
      { title: "Action" }
    ]
  }
  else {  //default showing queue tab
    headingName = [
      { title: "Email ID" },
      { title: "Phone number" },
      { title: "Type" },
      { title: "Sequence name" },
      { title: "Order date" },
      { title: "Email to be sent" },
      { title: "Action" }
    ]
  }

  return (
    <div className='container-custom'>
      <Page
        fullWidth
        title={"Outbox"}
        subtitle={'Monitor the status of review request emails, including those pending, sent, and failed'}
        backAction={props.isShwBack === true ? { onAction: () => handleBackToMain() } : false}
      >

        <Box paddingBlockEnd={400} paddingInline={props.isShwBack === true ? 800 : 0}>
          <Card padding={0}>

            <IndexFilters
              sortOptions={sortOptions}
              sortSelected={sortSelected}
              onSort={(e) => setSortSelected(e)}
              queryValue={queryValue}
              queryPlaceholder="Search email ID..."
              onQueryChange={(e) => setQueryValue(e)}
              onQueryClear={() => setQueryValue('')}
              selected={tabSelected}
              onSelect={(e) => setTabSelected(e)}
              onClearAll={() => setQueryValue('')}
              cancelAction={{ onAction: () => setQueryValue('') }}
              tabs={tabs}
              canCreateNewView={false}
              filters={[]}
              mode={mode}
              setMode={setMode} />

            <IndexTable
              itemCount={QueueData ? QueueData.length : 0}
              selectedItemsCount={selectedResources.length}
              onSelectionChange={handleSelectionChange}
              promotedBulkActions={promotedBulkActions}
              selectable={true}
              headings={headingName}
              pagination={{
                hasPrevious: paggiActive > 1 ? true : false,
                hasNext: (paggiActive < pageNumber || 0) ? true : false,
                onPrevious: () => onPaggiCall('-1'),
                onNext: () => onPaggiCall('+1')
              }}>

              {QueueData && QueueData.length > 0 && QueueData.map((data, index) => (
                <IndexTable.Row
                  key={index}
                  id={data._id}
                  position={index}
                  onClick={handleSelectionChange}
                  selected={selectedResources.includes(data._id)}>
                  <IndexTable.Cell>
                    <Text variant="bodySm" fontWeight="medium" as="span">{data.eml ? data.eml : '-'}</Text>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <Text variant="bodySm" fontWeight="medium" as="span">{data.phn ? data.phn : '-'}</Text>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    {data.intObj && data.intObj.nm && data.intObj.nm === 'wp_interakt' ?
                      <img src={`https://storage.googleapis.com/shopify_review_app/dash/rvw_src_whatsapp.svg`} alt="Source" />
                      :
                      data.intObj && data.intObj.nm && data.intObj.nm === 'webhook_review_request_export' ?
                        <img src={`https://storage.googleapis.com/shopify_review_app/dash/synd_webhhok.svg`} alt="Source" />
                        :
                        <img src={`https://storage.googleapis.com/shopify_review_app/dash/rvw_src_email.svg`} alt="Source" />
                    }
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    {data.typ && getType(data.typ) ?
                      <Tooltip width='wide' padding="400" dismissOnMouseOut content={data.typ ? getType(data.typ) : '-'}>
                        <Badge size='large'
                          icon={data.typ === 'on_order_fulfill' ? StarIcon
                            : data.typ === 'on_followup' ? ClockIcon
                              : data.typ === 'one_time_campaign' ? MegaphoneIcon
                                : null}>
                          <Text id='txt_limit2' variant="bodySm" fontWeight="medium" as="span">{getType(data.typ)}</Text>
                        </Badge>
                      </Tooltip>
                      :
                      '-'
                    }
                  </IndexTable.Cell>
                  {tabSelected === 1 &&  // campaign id
                    <IndexTable.Cell>
                      <Text variant="bodySm" fontWeight="medium" as="span">{data.cmpgnid ? data.cmpgnid : '-'}</Text>
                    </IndexTable.Cell>
                  }
                  {tabSelected === 1 &&  // Triggertyp
                    <IndexTable.Cell>
                      <Text variant="bodySm" fontWeight="medium" as="span">
                        {data.trgtyp &&
                          data.trgtyp === 2 ? 'Retry'
                          : data.trgtyp === 3 ? 'OneTime'
                            : 'Auto'}
                      </Text>
                    </IndexTable.Cell>
                  }
                  {tabSelected === 2 &&  // Status
                    <IndexTable.Cell>
                      <Text variant="bodySm" fontWeight="medium" as="span">
                        <Badge size='large'>
                          <Text variant='headingXs' fontWeight='medium' as='span'>
                            {data.sts && data.sts === 0 ?
                              'Pending'
                              : data.sts === 1 ?
                                'Send'
                                : data.sts === 2 ?
                                  'Pause'
                                  : data.sts === 3 ?
                                    'Preference'
                                    : data.sts === 4 ?
                                      'Limit exceed'
                                      : data.sts === 5 ?
                                        'Service stop'
                                        : data.sts === 6 ?
                                          'Phone not found'
                                          : data.sts === 7 ?
                                            'Email not found'
                                            : data.sts === 8 ?
                                              'Disable product'
                                              : data.sts === 9 ?
                                                'Template not selected'
                                                : data.sts === 10 ?
                                                  'Automation off'
                                                  : data.sts === 11 &&
                                                  'Service provider issue'
                            }
                          </Text>
                        </Badge>
                      </Text>
                    </IndexTable.Cell>
                  }
                  <IndexTable.Cell>
                    <Text variant="bodySm" fontWeight="medium" as="span">{data.cdt ? timeStamp(data.cdt) : '-'}</Text>
                  </IndexTable.Cell>
                  {tabSelected === 1 ? // 'sent' tab
                    <IndexTable.Cell>
                      <Text variant="bodySm" fontWeight="medium" as="span">{data.udt ? timeStamp(data.udt) : '-'}</Text>
                    </IndexTable.Cell>
                    :
                    <IndexTable.Cell>
                      <Text variant="bodySm" fontWeight="medium" as="span">{data.schdldt ? timeStamp(data.schdldt) : '-'}</Text>
                    </IndexTable.Cell>
                  }
                  {(tabSelected === 0 || tabSelected === 2) &&
                    <IndexTable.Cell>
                      <InlineStack gap={100}>
                        <Tooltip width='wide' padding="400" dismissOnMouseOut content="Delete">
                          <Button icon={DeleteIcon} variant='monochromePlain' onClick={() => handleDeleteAction(data._id, data.seqno)}>
                          </Button>
                        </Tooltip>
                        {tabSelected === 2 &&
                          <Tooltip width='wide' padding="400" dismissOnMouseOut content="Retry">
                            <Button variant='monochromePlain' icon={RefreshIcon} onClick={() => handleRetryAction(data.msgid)}>
                            </Button>
                          </Tooltip>
                        }
                      </InlineStack>
                    </IndexTable.Cell>
                  }

                </IndexTable.Row>
              ))}
            </IndexTable>

          </Card>
        </Box>

        <Modal
          size='small'
          open={modal.deleteOpen}
          title={<Text variant='headingMd' as='h3' fontWeight='bold'>Confirmation</Text>}
          onClose={(e) => openCloseModal('deleteOpen', modal.deleteOpen, e)}>
          <Modal.Section>
            <div className="pageAction_paDDing">
              <Text fontWeight='medium' variant='headingMd' as='h5'>Are you sure to delete this?</Text>
              <PageActions
                primaryAction={<Button icon={DeleteIcon} variant='primary' tone='critical'
                  onClick={() => handleDeleteCall(proIdDel, seqno)}>Yes</Button>}
                secondaryActions={[{
                  content: 'No',
                  onAction: () => openCloseModal('deleteOpen', modal.deleteOpen, 'close')
                }]} />
            </div>
          </Modal.Section>
        </Modal>

        <Modal
          size='small'
          open={modal.retryOpen}
          title={<Text variant='headingMd' fontWeight='bold'>Retry action?</Text>}
          onClose={(e) => openCloseModal('retryOpen', modal.retryOpen, e)}>
          <Modal.Section>
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure you want to retry sending this action?</Text>
            <PageActions
              primaryAction={<Button id='primary_btn' onClick={() => handleRetryEml(msgid)}>Yes</Button>}
              secondaryActions={[{
                content: 'Cancel',
                onAction: () => openCloseModal('retryOpen', modal.retryOpen, 'close')
              }]} />
          </Modal.Section>
        </Modal>

      </Page >
    </div>
  );
}

export default memo(List);