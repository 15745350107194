import React, { Suspense, lazy } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import * as authDucks from './ducks/auth';

let authToken = localStorage.getItem('authToken');

const LazyDashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const LazyUsers = lazy(() => import('./containers/Users/Users'));
const LazyCustomer = lazy(() => import('./containers/Customer/Customer'));
const LazyReviewModerate = lazy(() => import('./containers/ReviewModerate/ReviewModerate'));
const LazyAnalytic = lazy(() => import('./containers/Analytic/Analytic'));
const LazyImport = lazy(() => import('./containers/Import/Import'));
const LazySequence = lazy(() => import('./containers/Sequence/Sequence'));
const LazyManual = lazy(() => import('./containers/Manual/Manual'));
const LazyCurrentQueue = lazy(() => import('./containers/CurrentQueue/CurrentQueue'));
const LazyIntegration = lazy(() => import('./containers/Integration/Integration'));
const LazyIncentives = lazy(() => import('./containers/Incentives/Incentives'));
const LazyCheckReview = lazy(() => import('./containers/CheckReview/CheckReview'));
const LazyMarketing = lazy(() => import('./containers/Marketing/Marketing'));
const LazySeo = lazy(() => import('./containers/Seo/Seo'));
const LazyUgc = lazy(() => import('./containers/Ugc/Ugc'));
const LazyReviewWidget = lazy(() => import('./containers/ReviewWidget/ReviewWidget'));
const LazyBranding = lazy(() => import('./containers/Branding/Branding'));
const LazyPlan = lazy(() => import('./containers/Plan/Plan'));
const LazyWixPlan = lazy(() => import('./containers/WixPlan/WixPlan'));
const LazyForms = lazy(() => import('./containers/Forms/Forms'));
const LazySetting = lazy(() => import('./containers/Setting/Setting'));
const LazyStoreInformation = lazy(() => import('./containers/StoreInformation/StoreInformation'));
const LazyReviewCollection = lazy(() => import('./containers/ReviewCollection/ReviewCollection'));
const LazyCustomQuestion = lazy(() => import('./containers/CustomQuestion/CustomQuestion'));
const LazyUpsell = lazy(() => import('./containers/Upsell/Upsell'));
const LazyOnBoarding = lazy(() => import('./containers/OnBoarding/OnBoarding'));
const LazyLogin = lazy(() => import('./containers/Login/Login'));
const LazySignUp = lazy(() => import('./containers/SignUp/SignUp'));
const LazyResetPwd = lazy(() => import('./containers/ResetPwd/ResetPwd'));
const LazyForgotPwd = lazy(() => import('./containers/ForgotPwd/ForgotPwd'));

//get value from querystring
let params = new URLSearchParams(window.location.search);
const params_instance = params.get('instance');
const params_instanceId = params.get('instanceId');
const params_newUser = params.get('newUser');

const MyRoutes = () => {
  const dispatch = useDispatch();

  const provider = localStorage.getItem('pvd');

  if (params_instanceId) {
    let obj = {
      instanceId: params_instanceId
    };
    if (params_newUser === 'true') {
      obj.newUser = true;
    }
    dispatch(authDucks.loginWithWix(obj));
  } else if (params_instance) {
    let obj = {
      instanceEncode: params_instance,
      path: '/plan'
    };
    if (params_newUser === 'true') {
      obj.newUser = true;
    }
    dispatch(authDucks.loginWithWix(obj));
  }

  return (
    <Suspense fallback={<></>}>
      {
        <Routes>
          {
            !authToken && !localStorage.getItem('is_shopify_embed') ?
              <>
                <Route path='*' element={<LazySignUp />} />
                <Route path="/login" element={<LazyLogin />} />
                <Route path="/signup" element={<LazySignUp />} />
                <Route path="/forgotPwd" element={<LazyForgotPwd />} />
                <Route path="/reset" element={<LazyResetPwd />} />
              </>
              :
              localStorage.getItem('icmpltonbrdng') === 'true' ?
                <Route>
                  <Route path='/' element={<LazyDashboard />} />
                  <Route path='/dashboard' element={<LazyDashboard />} />
                  <Route path='/users' element={<LazyUsers />} />
                  <Route path='/customer' element={<LazyCustomer />} />
                  <Route path='/reviewmoderate' element={<LazyReviewModerate />} />
                  <Route path='/analytic' element={<LazyAnalytic />} />
                  <Route path='/import' element={<LazyImport />} />
                  <Route path='/sequence' element={<LazySequence />} />
                  <Route path='/manual' element={<LazyManual />} />
                  <Route path='/currentQueue' element={<LazyCurrentQueue />} />
                  <Route path='/integration/:type?' element={<LazyIntegration />} />
                  <Route path='/incentives' element={<LazyIncentives />} />
                  <Route path='/checkoutReview' element={<LazyCheckReview />} />
                  <Route path='/marketing' element={<LazyMarketing />} />
                  <Route path='/seo' element={<LazySeo />} />
                  <Route path='/ugc' element={<LazyUgc />} />
                  <Route path='/reviewWidget/:type?' element={<LazyReviewWidget />} />
                  <Route path='/branding' element={<LazyBranding />} />
                  {/* <Route path='/plan' element={<LazyPlan />} /> */}
                  {
                    provider === 'shopify' ?
                      <Route path='/plan' element={<LazyPlan />} />
                      :
                      provider === 'wix' ?
                        <Route path='/plan' element={<LazyWixPlan />} />
                        :
                        <Route path='/plan' element={<LazyPlan />} />

                  }
                  <Route path='/forms' element={<LazyForms />} />
                  <Route path='/setting/:type?' element={<LazySetting />} />
                  <Route path='/storeInformation' element={<LazyStoreInformation />} />
                  <Route path='/collectReview/:type?' element={<LazyReviewCollection />} />
                  <Route path='/customQuestion' element={<LazyCustomQuestion />} />
                  <Route path='/upsell' element={<LazyUpsell />} />
                  <Route path='*' element={<LazyDashboard />} />
                </Route>
                :
                <Route>
                  <Route path='*' element={<LazyOnBoarding />} />
                  <Route path='/onboarding' element={<LazyOnBoarding />} />
                </Route>
          }
        </Routes>
      }
    </Suspense>
  )
}



export default MyRoutes;
