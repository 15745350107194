import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/moderate';
import { toastify } from '../ducks/toast';

function* getModerateData(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/getReviewData', action.payload);

    if (res.status === 'success') {
      yield put(actions.getModerateSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getProDetail(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getProductDetail?pid=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.getProDetailSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateRevStatus(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateReviewDataStutas', action.payload);
    if (res.status === 'success') {
      yield put(actions.getModerateData(global.modeObj));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updReviewReply(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateReviewReply', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delReviewReply(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/deleteReviewReply', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addTestiMoniClk(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/addUpdTestimonial', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getModerateData(global.modeObj));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getModerateSetting(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getReviewModerate', action.payload);

    if (res.status === 'success') {
      yield put(actions.getModerateSettingSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updModerateSetting(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updReviewModerate', action.payload);

    if (res.status === 'success') {
      yield put(actions.getModerateSetting());
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* exportReview(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/exportReview', action.payload);

    if (res.status === 'success') {
      if (res.url) window.open(res.url);
      else yield put(toastify({ type: 'success', msg: res.m }));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* generateReviewReplyAi(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/generateReviewReplyAi', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.generateReviewReplyAiSuccess(res.reviewReplayAiData));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* generateReviewAi(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/generateReviewAi', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.generateReviewAiSuccess(res.reviewAiData));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateReview(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/updateReview', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      // yield put(actions.getModerateData(global.modeObj));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetModerateData() {
  yield takeLatest(actions.getModerateData.type, getModerateData);
}
export function* watchGetProDetail() {
  yield takeLatest(actions.getProDetail.type, getProDetail);
}
export function* watchUpdateRevStatus() {
  yield takeLatest(actions.updateRevStatus.type, updateRevStatus);
}
export function* watchUpdReviewReply() {
  yield takeLatest(actions.updReviewReply.type, updReviewReply);
}
export function* watchDelReviewReply() {
  yield takeLatest(actions.delReviewReply.type, delReviewReply);
}
export function* watchAddTestiMoniClk() {
  yield takeLatest(actions.addTestiMoniClk.type, addTestiMoniClk);
}
export function* watchGetModerateSetting() {
  yield takeLatest(actions.getModerateSetting.type, getModerateSetting);
}
export function* watchUpdModerateSetting() {
  yield takeLatest(actions.updModerateSetting.type, updModerateSetting);
}
export function* watchExportReview() {
  yield takeLatest(actions.exportReview.type, exportReview);
}
export function* watchGenerateReviewReplyAi() {
  yield takeLatest(actions.generateReviewReplyAi.type, generateReviewReplyAi);
}
export function* watchGenerateReviewAi() {
  yield takeLatest(actions.generateReviewAi.type, generateReviewAi);
}
export function* watchUpdReview() {
  yield takeLatest(actions.updateReview.type, updateReview);
}

export default function* rootSaga() {
  yield fork(watchGetModerateData);
  yield fork(watchGetProDetail);
  yield fork(watchUpdateRevStatus);
  yield fork(watchUpdReviewReply);
  yield fork(watchDelReviewReply);
  yield fork(watchAddTestiMoniClk);
  yield fork(watchGetModerateSetting);
  yield fork(watchUpdModerateSetting);
  yield fork(watchExportReview);
  yield fork(watchGenerateReviewReplyAi);
  yield fork(watchGenerateReviewAi);
  yield fork(watchUpdReview);
}


