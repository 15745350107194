import { Text, Modal, Tooltip, Page, SkeletonThumbnail, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Thumbnail, Badge, Box, TextField, IndexTable, BlockStack, PageActions, Button, InlineStack, Card } from '@shopify/polaris';
import { PlusIcon, DeleteIcon, } from '@shopify/polaris-icons';
import moment from 'moment';
import { useEffect } from 'react';

const AddEditWidgetScreen = ({ props }) => {
  const { state, changeNameValue, openCloseModal, handleAddNewWdgt, handleDelWdgt, handleUpdStatus } = props;

  const handleEditNotification = (data) => {
    changeNameValue({ currNwWdgtId: data._id, clkOnCustomize: true });
  }

  useEffect(() => {
    if (state.DulWdgtList?.length > 0) {
      changeNameValue({ isLoadWidget: false });
    }
  }, [state.DulWdgtList]);

  return (
    <div>

      {state.isLoadWidget ?
        <div className='widgetSklton'>
          <SkeletonPage backAction primaryAction>
            <div className='sticky-preview'>
              <Card padding={0}>
                <IndexTable
                  itemCount={1}
                  selectable={false}
                  headings={[
                    { title: 'Preview' },
                    { title: 'Name' },
                    { title: 'Status' },
                    { title: 'Change' },
                    { title: 'Actions' }
                  ]}
                >
                  <IndexTable.Row position={1}>
                    <IndexTable.Cell>
                      <SkeletonThumbnail size="medium" />
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <SkeletonBodyText lines={2} />
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <SkeletonBodyText lines={1} />
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <SkeletonDisplayText size="small" />
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <SkeletonDisplayText size="small" />
                    </IndexTable.Cell>
                  </IndexTable.Row>

                </IndexTable>
              </Card>
            </div>
          </SkeletonPage>
        </div>
        :
        <Page
          title={state.dn}
          backAction={{ onAction: () => props.handleBackToMain() }}
          primaryAction={<Button variant="primary" icon={PlusIcon}
            onClick={() => openCloseModal('createOpen', state.createOpen, 'open')}>Add </Button>}
        >
          <div className='sticky-preview'>
            <Card padding={0}>
              <IndexTable
                itemCount={state?.DulWdgtList?.length || 0}
                selectable={false}
                headings={[
                  { title: 'Preview' },
                  { title: 'Name' },
                  { title: 'Status' },
                  { title: 'Change' },
                  { title: 'Actions' }
                ]}>

                {state?.DulWdgtList?.length > 0 && state.DulWdgtList.map((data, index) => {
                  const _thumbnail = state?.mainLayoutArr?.length > 0 &&
                    state.mainLayoutArr.find(item => item._id === data.htn)?.iu || '';
                  return (
                    <IndexTable.Row
                      id={data._id}
                      key={index}
                      position={index}
                    >
                      <IndexTable.Cell id='nTf_wrap'>
                        {!_thumbnail ? <SkeletonThumbnail size="medium" /> :
                          <Thumbnail source={_thumbnail} alt="Skeleton" />
                        }
                      </IndexTable.Cell>

                      <IndexTable.Cell>
                        <Text id='txt_limit' variant="headingXs" fontWeight="semibold" as="p">{data.rttl}</Text>
                        <Box paddingBlockStart={200} paddingBlockEnd={200}>
                          <BlockStack gap="200" align="start">
                            <Text variant="headingMd" as="h6" fontWeight="medium">
                              {data.dn}
                            </Text>
                            <div style={{ padding: '0 120px 0 0' }}>
                              <Tooltip dismissOnMouseOut content={<BlockStack><Text>CreatedOn: {moment(new Date(data.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</Text><Text>LastModified: {moment(data.udt ? new Date(data.udt) : new Date()).format('MMM-DD-YYYY hh:mm:ss a')}</Text></BlockStack>} width='wide'>
                                <Text variant="bodyXs" tone='subdued' fontWeight="medium" as="p">
                                  {moment(data.udt ? new Date(data.udt) : new Date()).format('MMM-DD-YYYY')}
                                </Text>
                              </Tooltip>
                            </div>
                          </BlockStack>
                        </Box>
                      </IndexTable.Cell>

                      <IndexTable.Cell>
                        <InlineStack gap={200} blockAlign='center'>
                          <Badge size='medium' tone={data.ia ? 'success' : 'critical'}>
                            <Text variant='headingXs' fontWeight='medium' as='span'>
                              {data.ia ? 'On' : 'Off'}
                            </Text>
                          </Badge>

                          <label className="switch">
                            <input type="checkbox" checked={data.ia}
                              onChange={() => handleUpdStatus(data)} />
                            <span className="switch-label"></span>
                            <span className="switch-handle"></span>
                          </label>
                        </InlineStack>
                      </IndexTable.Cell>

                      <IndexTable.Cell>
                        <Button variant='primary' tone='success' onClick={() => handleEditNotification(data)}>Customize</Button>
                      </IndexTable.Cell>

                      <IndexTable.Cell>
                        <InlineStack gap={200}>
                          <Button variant='primary' tone='critical' icon={DeleteIcon}
                            onClick={() => {
                              openCloseModal('deleteOpen', state.deleteOpen, 'open');
                              changeNameValue({ delWdgtId: data._id });
                            }} />
                        </InlineStack>
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  );
                })}
              </IndexTable>
            </Card>
          </div>
        </Page>
      }




      <Modal // create widget
        size='small'
        open={state.createOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Add New Widget</Text>}
        onClose={(e) => openCloseModal('createOpen', state.createOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <TextField
              label={<Text>Widget Name</Text>}
              onChange={(e) => changeNameValue({ wdgtDnField: e })}
              value={state?.wdgtDnField} />

            <PageActions
              primaryAction={<Button variant='primary' tone='success' disabled={!state.wdgtDnField}
                onClick={() => handleAddNewWdgt()}> Create </Button>}
              secondaryActions={[{
                content: 'Not now',
                onAction: () => openCloseModal('createOpen', state.createOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>

      <Modal // delete widget
        size='small'
        open={state.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('deleteOpen', state.deleteOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this widget?</Text>
            <PageActions
              primaryAction={<Button variant='primary' tone='critical'
                onClick={() => handleDelWdgt(state.delWdgtId)}> Yes</Button>}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('deleteOpen', state.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>


    </div>
  );
}
export default AddEditWidgetScreen;