import { Text, Box, Grid, Button, Modal, Divider, Page, TextField, Card } from '@shopify/polaris';
import EditFields from './EditFields';
import PreviewCard from './Preview';
import { useEffect } from 'react';
import * as emailDucks from '../../ducks/email';
import { useDispatch } from 'react-redux';

const MainEditPanel = (props) => {

  const dispatch = useDispatch();
  let { state, changeNameValue, openCloseModal, handleTextChange, backTempList } = props;

  useEffect(() => {
    changeNameValue({ emailTest: localStorage.getItem('e') });
  }, []);

  const sendTestEmailModal = () => {
    openCloseModal('testEmailOpen', state.testEmailOpen, 'open');
    changeNameValue({ emailErr: false });
  }

  const handleTestEmail = () => {
    if (state.emailTest) {
      changeNameValue({ emailErr: false });
      let obj = {
        _id: state.proIdUpd,
        e: state.emailTest,
        typ: state.emailTyp
      }
      dispatch(emailDucks.testEmail(obj));
      openCloseModal('testEmailOpen', state.testEmailOpen, 'close');
    }
    else changeNameValue({ emailErr: true });
  }

  const callResetApi = () => {
    changeNameValue({ isEdit: true });

    if (state?.emailMSTArr?.length > 0) {
      state.emailMSTArr.filter(data => {
        if (data.typ === state.emailTyp) {
          if (data.sbtyp === state.sbtyp) {
            let stateObj = {};

            let config = {
              dd: data.dd,
              sbjct: data.sbjct,
              msg: data.msg
            }

            if (state.emailObj) config['lgurl'] = state.emailObj.lgurl; // for not reseting logo field
            if (data.irttl) config['irttl'] = data.irttl;
            if (data.rtngtxt) config['rtngtxt'] = data.rtngtxt;
            if (data.rttltxt) config['rttltxt'] = data.rttltxt;
            if (data.irdsc) config['irdsc'] = data.irdsc;
            if (data.rdsctxt) config['rdsctxt'] = data.rdsctxt;
            if (data.rbtntxt) config['rbtntxt'] = data.rbtntxt;
            if (data.clstmnt) config['clstmnt'] = data.clstmnt;

            if (data.htmltmpl) stateObj['htmltmpl'] = data.htmltmpl;
            if (data.htmlcrd) stateObj['htmlcrd'] = data.htmlcrd;

            stateObj['dn'] = data.dn;
            stateObj['emailObj'] = config;
            changeNameValue(stateObj);
          }
        }
      });
    }
    openCloseModal('resetOpen', state.resetOpen, 'close');
  }

  const handleDiscrdChange = () => {
    if (state?.emailSeqArr?.length > 0) {
      state.emailSeqArr.filter((data) => {
        if (data.typ === state.emailTyp) {
          if (data.sbtyp === state.sbtyp) {
            let obj = {
              dd: data.dd,
              sbjct: data.sbjct,
              msg: data.msg,
              lgurl: data.lgurl,
              irttl: data.irttl,
              rtngtxt: data.rtngtxt,
              rttltxt: data.rttltxt,
              irdsc: data.irdsc,
              rdsctxt: data.rdsctxt,
              rbtntxt: data.rbtntxt,
              clstmnt: data.clstmnt
            }
            changeNameValue({ emailObj: obj });
          }
        }
      });
    }
    changeNameValue({ isEdit: false });
  }

  const saveSequnceTab = () => {
    let obj = state.emailObj;
    obj['_id'] = state.proIdUpd;
    dispatch(emailDucks.updateSequence(obj));
    changeNameValue({ isEdit: false });
  }

  return (
    <div className='Seq_Edit container-custom'>

      <Page
        fullWidth
        title={state.isSettingPage === true ? 'Go back to notification' : 'Update email template'}
        secondaryActions={<Button onClick={() => sendTestEmailModal()}>Send test email</Button>}
        backAction={{ onAction: () => backTempList() }}>

        <Box paddingInlineStart={200} paddingBlockEnd={200}>
          <Grid>
            <Grid.Cell columnSpan={{ xl: 5, lg: 5, md: 6, sm: 6, xs: 6 }}>
              <Card>
                <Text variant='headingMd' as='h3' fontWeight='medium'>Template type</Text>
                <Box paddingBlockStart={200} paddingBlockEnd={400}>
                  <Text id='text_capitalize'>{state.sbtyp} Product Review Email
                    {state.emlTypName && <span> ({state.emlTypName})</span>}</Text>
                </Box> <Divider borderColor='border-tertiary' />

                <EditFields
                  state={state}
                  saveSequnceTab={saveSequnceTab}
                  handleTextChange={handleTextChange}
                  handleDiscrdChange={handleDiscrdChange} />
              </Card>
            </Grid.Cell>

            <Grid.Cell columnSpan={{ xl: 7, lg: 7, md: 6, sm: 6, xs: 6 }}> {/* Preview */}
              <PreviewCard
                state={state}
                changeNameValue={changeNameValue}
                openCloseModal={openCloseModal}
                callResetApi={callResetApi} />
            </Grid.Cell>

          </Grid>
        </Box>
      </Page>

      <Modal  //send test email model
        size='small'
        open={state.testEmailOpen}
        onClose={(e) => openCloseModal('testEmailOpen', state.testEmailOpen, e)}
        title={<Text variant='headingMd' as='h5' fontWeight='bold'>Send test email</Text>}>
        <Modal.Section>
          <Box paddingBlockEnd={600}>
            <TextField
              label="Enter email address where you want to receive email"
              value={state.emailTest}
              onChange={(e) => changeNameValue({ emailTest: e })}
              error={state.emailErr === true ? "Email is required" : false}
              autoComplete="off"
              placeholder='Enter your Email' />
          </Box>
          <Button id='primary_btn' fullWidth onClick={() => handleTestEmail()}>Send</Button>
        </Modal.Section>
      </Modal>

    </div >
  );
}

export default MainEditPanel;