import { Page, Card, RadioButton, Label, Select, Tooltip, TextField, Modal, Box, Text, Badge, BlockStack, Button, InlineStack, ButtonGroup, PageActions, Divider, InlineGrid } from '@shopify/polaris';
import ConfirmationAlert from '../ConfirmationModal/ConfirmationAlert';
import IncentiveTemplateCard from './IncentiveTemplateCard';
import * as integrationDucks from '../../ducks/integration';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { useState, useCallback, useEffect, memo } from 'react';
import * as incentiveDucks from '../../ducks/incentive';
import { useDispatch, useSelector } from 'react-redux';
import MainEditPanel from '../Sequence/MainEditPanel';
import * as settingDucks from '../../ducks/setting';
import * as emailDucks from '../../ducks/email';
import * as authDucks from '../../ducks/auth';
import { changePathForShopifyAppEmbed } from "../../utils/utils";


function Incentives(props) {

  const templatesArr = ['incentive'];  //list of typ coming in this page

  const options = [
    { label: 'Fixed amount', value: 1 },
    { label: 'Percentage (%)', value: 2 },
    { label: 'Free shipping', value: 3 }
  ];

  const couponOptions = [
    { label: 'All (1 to 5 stars)', value: 1 },
    { label: '2 stars', value: 2 },
    { label: '3 stars', value: 3 },
    { label: '4 stars', value: 4 },
    { label: '5 stars', value: 5 }
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validator = new SimpleReactValidator({ locale: 'en' });

  const [state, setState] = useState({
    createOpen: false, //modal open/close
    editOpen: false,
    deleteOpen: false,
    cstcd: '',
    cdtyp: 1,
    cdamnt: '',
    cdprfx: '',
    iunqcd: true,
    rvwrtyp: 1,
    rvwtyp: 1,
    mnrtng: 1,
    incIdUpd: '',
    incIdDel: '',
    errMessage: {},
    UserObjshp: {},
    discSetting: false,
    currUserInc: '',
    currIncId: '',
    emailObj: {},  //obj same as cnf (lgurl,sbjct,msg,rtngtxt,irttl,rttltxt,irdsc,rdsctxt,rbtntxt,clstmnt)
    emailMSTArr: [],  //latest new master data
    htmltmpl: '',  //html 
    htmlcrd: '',
    emailTyp: 'incentive',
    sbtyp: '',
    userIntegration: [],
    integrationList: [],
    campTyp: 'automation',
    isConfiguration: false, //is show configuration screen
    isNewEditPanel: false,
    resetOpen: false,
    testEmailOpen: false,
    searchParam: '',
    isShowMessage: false,
    IncentiveList: []
  });

  useEffect(() => {
    dispatch(emailDucks.getSeqData());
    dispatch(incentiveDucks.getIncentive());
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  state.searchParam = queryParams.get("p");

  // get api data
  const ProfileData = useSelector(state => state.auth.profile);
  const IncentiveList = useSelector(state => state.incentive.IncentiveList);
  const masterState = useSelector(state => state.master.MasterData);
  const seqState = useSelector(state => state.email);
  const UserIntegration = useSelector(state => state.integration.UserIntegration);

  useEffect(() => {
    if (IncentiveList !== state.IncentiveList) {
      changeNameValue({ IncentiveList });
    }
  }, [IncentiveList]);

  useEffect(() => {
    let obj = { shp: localStorage.getItem('shp'), typ: 'automation' };
    dispatch(integrationDucks.getUserIntegration(obj));
  }, []);

  useEffect(() => {
    if (seqState.SeqList && seqState.SeqList.data) {
      let newSeqData = seqState.SeqList.data.filter(data => templatesArr.includes(data.typ));

      let SeqData = newSeqData.filter((obj, i) => i === newSeqData.findIndex(o => obj.typ === o.typ)); //unique arr
      changeNameValue({ newSeqData, SeqData });
    }
  }, [seqState]);

  // store emlMaster values in arr
  useEffect(() => {
    let _arrEml = [];
    let stateObj = {};

    if (masterState && masterState.emailSequenceData && masterState.emailSequenceData.length > 0) {
      masterState.emailSequenceData.filter(y => {
        if (templatesArr.includes(y.typ)) {
          _arrEml.push(y);
          if (y.typ === state.emailTyp) {
            if (y.sbtyp === state.sbtyp) {
              stateObj['htmltmpl'] = y.htmltmpl;
              if (y.htmlcrd)
                stateObj['htmlcrd'] = y.htmlcrd;
            }
          }
        }
      });
      stateObj['emailMSTArr'] = _arrEml;
    }
    changeNameValue(stateObj);
  }, [masterState, state.emailTyp, state.emlsqmstid, state.sbtyp]);

  useEffect(() => {
    let _seqEml = [];
    if (state.newSeqData && state.newSeqData.length > 0) {
      state.newSeqData.filter(y => {
        if (y.typ === state.emailTyp) {
          _seqEml.push(y);
          if (y.sbtyp && y.sbtyp === state.sbtyp) {
            changeNameValue({ temp_id: y._id });
          }
        }
      });
      changeNameValue({ emailSeqArr: _seqEml });
    }
  }, [seqState, state.sbtyp, state.emailTyp, state.newSeqData]);

  useEffect(() => {
    let data = UserIntegration;

    if (masterState && masterState?.integrationData.length > 0)
      changeNameValue({ integrationList: masterState?.integrationData });

    if (data && data.length > 0 && masterState && masterState.integrationData && masterState.integrationData.length > 0) {
      data = data.map(dataItem => {
        const matchingIntegration = masterState.integrationData.find(integration => integration.in === dataItem.inm);
        if (matchingIntegration) {
          return {
            ...dataItem,
            img: matchingIntegration.img,
            dn: matchingIntegration.dn,
            dsc: matchingIntegration.dsc,
            ctg: matchingIntegration.ctg
          };
        }
        return dataItem;
      });
    }

    if (data && data !== state.userIntegration)
      changeNameValue({ userIntegration: data });
  }, [masterState, masterState?.integrationData, UserIntegration, state.userIntegration]);

  useEffect(() => { //set Current Incentive data 
    if (state.IncentiveList && state.IncentiveList.length > 0) {
      state.IncentiveList.map(function (data) {
        let obj = {
          currUserInc: data,
          currIncId: data._id && data._id,
          iunqcd: data.iunqcd && data.iunqcd,
          cdprfx: data.cdprfx && data.cdprfx,
          cdtyp: data.cdtyp && data.cdtyp,
          cdamnt: data.cdamnt && data.cdamnt,
          cstcd: data.cstcd && data.cstcd,
          rvwrtyp: data.rvwrtyp && data.rvwrtyp,
          rvwtyp: data.rvwtyp && data.rvwtyp,
          mnrtng: data.mnrtng && data.mnrtng
        }
        changeNameValue(obj);
      });
    }
  }, [state.IncentiveList]);

  useEffect(() => { //get objshp from userData
    if (ProfileData !== null && ProfileData !== undefined && ProfileData.objshp)
      changeNameValue({ UserObjshp: ProfileData.objshp });
    else
      dispatch(authDucks.getProfile());
  }, [ProfileData]);

  const changeNameValue = useCallback((obj, isShowMessage) => {
    //show message on page refresh (incentive page)
    if (isShowMessage === 'isShowMessage')
      changeNameValue({ isShowMessage: true });

    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  const clearState = () => {
    let obj = {
      cstcd: '',
      cdtyp: 1,
      cdamnt: null,
      cdprfx: '',
      rvwrtyp: 1,
      rvwtyp: 1,
      mnrtng: 1
    }
    changeNameValue(obj);
  }

  // only for template edit field
  const handleTextChange = (obj, isEditable) => {
    let currentState = { ...state.emailObj, ...obj };

    changeNameValue({
      emailObj: currentState,
      isEdit: isEditable ? true : false
    });
  }

  const handleIncTxtChange = (obj) => {
    changeNameValue({
      ...obj,
      isShowMessage: true
    });
  }

  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) { //stop closing on outside click 
      changeNameValue({ [name]: !value });
    }
    if (name === 'createOpen' && state.createOpen === false)
      clearState();
  }, [state.createOpen, state.editOpen, state.deleteOpen]);

  const handleSaveIncentive = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    }
    else {
      const obj = {
        shp: localStorage.getItem('shp') || '',
        iunqcd: state.iunqcd,
        cstcd: state.cstcd,
        cdtyp: state.cdtyp,
        cdamnt: state.cdamnt,
        cdprfx: state.cdprfx,
        rvwrtyp: state.rvwrtyp,
        rvwtyp: state.rvwtyp,
        mnrtng: state.mnrtng,
      };

      if (state.currIncId) {
        obj._id = state.currIncId;
        dispatch(incentiveDucks.updateIncentive(obj));
      } else {
        dispatch(incentiveDucks.createIncentive(obj));
      }

      changeNameValue({ isShowMessage: false, errMessage: {} });
    }
  };

  const handleDiscrdIncentive = () => {
    let stateObj = { isShowMessage: false };

    if (state.currUserInc) {
      stateObj['iunqcd'] = state.currUserInc.iunqcd;
      stateObj['cdprfx'] = state.currUserInc.cdprfx;
      stateObj['cdtyp'] = state.currUserInc.cdtyp;
      stateObj['cdamnt'] = state.currUserInc.cdamnt;
      stateObj['cstcd'] = state.currUserInc.cstcd;
      stateObj['rvwrtyp'] = state.currUserInc.rvwrtyp;
      stateObj['rvwtyp'] = state.currUserInc.rvwtyp;
      stateObj['mnrtng'] = state.currUserInc.mnrtng;
    }
    changeNameValue(stateObj);
  }

  const handleUpdateAction = (data, id) => {
    let obj = {
      incIdUpd: id,
      iunqcd: data.iunqcd,
      cstcd: data.cstcd,
      cdtyp: data.cdtyp,
      cdamnt: data.cdamnt,
      cdprfx: data.cdprfx,
      rvwrtyp: data.rvwrtyp,
      rvwtyp: data.rvwtyp,
      mnrtng: data.mnrtng
    };
    changeNameValue(obj);
    openCloseModal('editOpen', state.editOpen, 'open');
  }

  const handleUpdateIncentive = (id) => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    }
    else {
      changeNameValue({ errMessage: {} });
      let obj = {
        _id: id,
        shp: localStorage.getItem('shp') || '',
        iunqcd: state.iunqcd,
        cstcd: state.cstcd,
        cdtyp: state.cdtyp,
        cdamnt: state.cdamnt,
        cdprfx: state.cdprfx,
        rvwrtyp: state.rvwrtyp,
        rvwtyp: state.rvwtyp,
        mnrtng: state.mnrtng
      };

      dispatch(incentiveDucks.updateIncentive(obj));
      openCloseModal('editOpen', state.editOpen, 'close');
    }
  }

  const handleStatusUpdateIncentive = (id, ia) => {
    if (id) {
      let obj = { _id: id, ia: ia };
      dispatch(incentiveDucks.updateIncentive(obj));
    }
  }

  const handleDeleteAction = (id) => {
    openCloseModal('deleteOpen', state.deleteOpen, 'open');
    changeNameValue({ incIdDel: id });
  }

  const handleDeleteIncentive = (id) => {
    dispatch(incentiveDucks.deleteIncentive(id));
    openCloseModal('deleteOpen', state.deleteOpen, 'close');
  };

  const editTemplateClk = (data) => {
    if (data) {
      let obj = {
        isConfiguration: true,
        isNewEditPanel: true,
        proIdUpd: data._id,
        emailTyp: data.typ,
        emlTypName: data.nm,
        emlsqmstid: data.emlsqmstid,
        sbtyp: data.sbtyp
      }
      changeNameValue(obj);

      let { lgurl, sbjct, msg, rtngtxt, irttl, rttltxt, irdsc, rdsctxt, rbtntxt, clstmnt } = data;

      let stateObj = {};
      if (lgurl) stateObj['lgurl'] = lgurl;
      if (sbjct) stateObj['sbjct'] = sbjct;
      if (msg) stateObj['msg'] = msg;
      if (rtngtxt) stateObj['rtngtxt'] = rtngtxt;
      if (irttl) stateObj['irttl'] = irttl;
      if (rttltxt) stateObj['rttltxt'] = rttltxt;
      if (irdsc) stateObj['irdsc'] = irdsc;
      if (rdsctxt) stateObj['rdsctxt'] = rdsctxt;
      if (rbtntxt) stateObj['rbtntxt'] = rbtntxt;
      if (clstmnt) stateObj['clstmnt'] = clstmnt;
      handleTextChange(stateObj);

      // global.SeqEmailTyp = data.typ;

      setTimeout(() => {
        changeNameValue({
          isNewEditPanel: true,
          isConfiguration: true
        });
      }, 200);
    }
  }

  const handleStatusUpdate = (_id, ia) => {
    if (_id) {
      let obj = { _id: _id, ia: ia };
      dispatch(emailDucks.updateSequence(obj));
    }
  }

  const updateTmpStatus = (mainData, data, ia) => {
    let obj = {
      id: mainData._id,
      tid: data._id
    };

    if (state.emailTyp === 'incentive')
      obj['iincntv'] = ia;

    dispatch(emailDucks.updTmpTypeStatus(obj));
  }

  const backTempList = (isEdit) => {
    if (state.isEdit !== true || isEdit) {
      changeNameValue({ isNewEditPanel: false, isConfiguration: false });
    }
    else {
      changeNameValue({ eventName: 'backToTempList' });
      openCloseModal('confirmOpen', state.confirmOpen, 'open');
    }
  }

  const createTemplate = (data) => {
    if (data) {
      let obj = {
        selSeqName: data.dspttl,
        subTtl: data.dspdsc,
        emailTyp: data.typ,
        sbtyp: data.sbtyp,
        emlTypName: data.nm
      }
      changeNameValue(obj);


      let { lgurl, sbjct, msg, rtngtxt, irttl, rttltxt, irdsc, rdsctxt, rbtntxt, clstmnt } = data;

      let stateObj = {};
      if (lgurl) stateObj['lgurl'] = lgurl;
      if (sbjct) stateObj['sbjct'] = sbjct;
      if (msg) stateObj['msg'] = msg;
      if (rtngtxt) stateObj['rtngtxt'] = rtngtxt;
      if (irttl) stateObj['irttl'] = irttl;
      if (rttltxt) stateObj['rttltxt'] = rttltxt;
      if (irdsc) stateObj['irdsc'] = irdsc;
      if (rdsctxt) stateObj['rdsctxt'] = rdsctxt;
      if (rbtntxt) stateObj['rbtntxt'] = rbtntxt;
      if (clstmnt) stateObj['clstmnt'] = clstmnt;
      handleTextChange(stateObj);

      // global.SeqEmailTyp = data.typ;

      setTimeout(() => {
        let newObj = {
          isNewEditPanel: true,
          isConfiguration: true
        }
        changeNameValue(newObj);
      }, 200);

      dispatch(settingDucks.createTemplate({ id: data._id }));
    }
  }

  const handleBackToMain = (isShowMessage) => {
    if (state.isShowMessage !== true || isShowMessage) {
      const isShopifyEmbed = localStorage.getItem('is_shopify_embed');
      const path = window.location.search !== '' ? '/shopifyAuth' : '/collectReview';

      if (isShopifyEmbed) {
        changePathForShopifyAppEmbed(path);
      } else {
        navigate(-1);
      }
    }
    else {
      changeNameValue({ eventName: 'backToMain' });
      openCloseModal('confirmOpen', state.confirmOpen, 'open');
    }
  }

  // on unsaved change do not redirect
  useEffect(() => { //on refresh
    if (state.isShowMessage === true) //on sidebar click alert
      localStorage.setItem('isShowLert', true)
    else
      localStorage.removeItem('isShowLert');

    const handleBeforeUnload = (e) => {
      if (localStorage.getItem('isShowLert')) {
        // Display a confirmation dialog
        const confirmationMessage = 'Are you sure you want to leave this page?';
        e.preventDefault();
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [state.isShowMessage]);

  const saveChanges = () => {
    openCloseModal('confirmOpen', state.confirmOpen, 'close');
  }

  const discardChanges = () => {
    changeNameValue({ isShowMessage: false });
    openCloseModal('confirmOpen', state.confirmOpen, 'close');
    backTempList('isEdit');

    if (state.eventName === 'backToTempList') {
      backTempList('isEdit');
    }
    if (state.eventName === 'backToMain') {
      handleBackToMain('isEdit');
    }
  }

  return (
    <div>

      {state.isNewEditPanel !== true && state.isConfiguration !== true ?
        <Page
          title={<>Incentives
            {state.IncentiveList && state.IncentiveList.length > 0 &&
              <Box paddingInlineStart={200}>
                {state.currUserInc && state.currUserInc.ia === true ?
                  <Badge tone="success"><Text variant="bodySm" fontWeight="medium" as="span">On</Text></Badge>
                  : <Badge><Text variant="bodySm" fontWeight="medium" as="span">Off</Text></Badge>
                }
              </Box>
            }
          </>}
          subtitle={'Encourage reviews by offering rewards like discounts or free shipping.'}
          primaryAction={
            state.IncentiveList && state.IncentiveList.length > 0 &&
            <Box paddingBlockStart={300}>
              <Button
                variant={state.currUserInc && state.currUserInc.ia === true ? 'primary' : ''}
                tone={state.currUserInc && state.currUserInc.ia === true ? 'critical' : ''}
                id={state.currUserInc && state.currUserInc.ia === true ? '' : 'primary_btn'}
                onClick={() => handleStatusUpdateIncentive(state.currIncId, !state.currUserInc.ia)}>
                {state.currUserInc && state.currUserInc.ia === true ? 'Turn OFF' : 'Turn ON'}
              </Button>
            </Box>
          }
          backAction={(state.searchParam === 'inc' || props.isShwBack === true) ? { onAction: () => handleBackToMain() } : false}>

          {/* {(state.IncentiveList && state.IncentiveList.length === 0) && (state.discSetting === false) &&
            <Card>
              <EmptyState>
                <Box paddingBlockStart={800}>
                  <BlockStack gap={400}>
                    <img src={"https://storage.googleapis.com/shopify_review_app/dash/incentive_create.svg"} alt='incentive_create' height="165px" />
                    <Text variant='bodyMd' fontWeight='semibold'>Mange discounts and promotions</Text>
                    <Text variant='bodySm' fontWeight='regular'>Create discount codes and automatic discounts that apply at checkout. You can also use discounts with <a href=''>compare at prices.</a></Text>
                    <Box>
                      <Button variant='primary' onClick={() => changeNameValue({discSetting: true})}>Create discount</Button>
                    </Box>
                  </BlockStack>
                </Box>
              </EmptyState>
            </Card>
          } */}

          {(state.IncentiveList && state.IncentiveList.length > 0) || state.discSetting === true ?
            <Box padding={{ xs: 400, sm: 400, md: 0 }} paddingBlockEnd={500} paddingInlineStart={(state.searchParam === 'inc' || props.isShwBack === true) ? 800 : 0}>
              <BlockStack gap={600}>
                <Card padding={0}>
                  <Box background='bg-surface-secondary' paddingInline={400} paddingBlock={300}>
                    <InlineStack align='space-between' blockAlign='center'>
                      <Text variant='bodyLg' fontWeight='bold' as='h1'>Discounts</Text>
                      {state.isShowMessage === true ?
                        <ButtonGroup>
                          <Button onClick={() => handleDiscrdIncentive()}>Cancel</Button>
                          <Button id='primary_btn' onClick={() => handleSaveIncentive()}>Save</Button>
                        </ButtonGroup>
                        :
                        <Box paddingBlockStart={400} paddingBlockEnd={300}></Box>
                      }
                    </InlineStack>
                  </Box>

                  <Divider borderColor='border-tertiary' />

                  <Box padding={400}>
                    <BlockStack gap={600}>
                      <Box>
                        <Box paddingBlockEnd={600}>
                          <Text variant='headingSm' as='p' fontWeight='medium'>Discount code options</Text>
                          <Box paddingBlockStart={200}>
                            <BlockStack>
                              <RadioButton
                                label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Generate a unique discount code for each review</Text>}
                                id="createCode"
                                checked={state.iunqcd}
                                onChange={() => handleIncTxtChange({ iunqcd: true })}
                              />
                              <RadioButton
                                label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Use a common discount code for all reviewers</Text>}
                                id="manualCode"
                                checked={!state.iunqcd}
                                onChange={() => handleIncTxtChange({ iunqcd: false })}
                              />
                            </BlockStack>
                          </Box>
                        </Box>
                        {state.iunqcd === true ?
                          <div className='incet-desc-bg'>
                            <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={400}>
                              <Select
                                label="Discount type"
                                options={options}
                                value={state.cdtyp}
                                onChange={(e) => handleIncTxtChange({ cdtyp: Number(e) })}
                              />
                              {state.cdtyp === 3 ? null :
                                <div>
                                  <TextField
                                    label="Amount"
                                    type="number"
                                    value={state.cdamnt}
                                    onChange={(e) => handleIncTxtChange({ cdamnt: Number(e) })}
                                    autoComplete="off"
                                    error={state.errMessage.amount ? state.errMessage.amount : false}
                                  />
                                  {validator.message('amount', state.cdamnt, 'required')}
                                </div>
                              }
                              <TextField
                                label="Code prefix"
                                type="text"
                                value={state.cdprfx}
                                onChange={(e) => handleIncTxtChange({ cdprfx: e })}
                                autoComplete="off"
                              />
                            </InlineGrid>
                          </div>
                          :
                          <div className='incet-desc-bg'>
                            <TextField
                              label="Discount manual code"
                              type="text"
                              value={state.cstcd}
                              onChange={(e) => handleIncTxtChange({ cstcd: e })}
                              autoComplete="off"
                              error={state.errMessage.manualCode ? state.errMessage.manualCode : false}
                            />
                            {validator.message('manualCode', state.cstcd, 'required')}
                          </div>
                        }
                      </Box>
                      <Box>
                        <Text variant='headingSm' as='p' fontWeight='medium'>Reward eligible reviewers</Text>
                        <Box paddingBlockStart={200}>
                          <BlockStack>
                            <RadioButton
                              label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Any reviewer</Text>}
                              value='1'
                              checked={state.rvwrtyp === 1}
                              onChange={() => handleIncTxtChange({ rvwrtyp: 1 })}
                            />
                            <RadioButton
                              label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Only reviewers who have received a review request</Text>}
                              value='2'
                              checked={state.rvwrtyp === 2}
                              onChange={() => handleIncTxtChange({ rvwrtyp: 2 })}
                            />
                            {/* <RadioButton
                    label="Only verified buyers of the product they reviewed"
                    value='3'
                    checked={state.rvwrtyp === 3}
                    onChange={() => handleIncTxtChange({rvwrtyp: 3})}
                  />
                  <RadioButton
                    label="Only reviewers who have received a review request"
                    value='4'
                    checked={state.rvwrtyp === 4}
                    onChange={() => handleIncTxtChange({rvwrtyp: 4})}
                  /> */}
                          </BlockStack>
                        </Box>
                      </Box>
                      <Box>
                        <Text variant='headingSm' as='p' fontWeight='medium'>Eligibility by rating</Text>
                        <Box paddingBlockStart={200}>
                          <InlineStack gap={600}>
                            <RadioButton
                              label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>All (1 to 5 stars)</Text>}
                              value='1'
                              checked={state.mnrtng === 1}
                              onChange={() => handleIncTxtChange({ mnrtng: 1 })}
                            />
                            <RadioButton
                              label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>2 stars</Text>}
                              value='2'
                              checked={state.mnrtng === 2}
                              onChange={() => handleIncTxtChange({ mnrtng: 2 })}
                            />
                            <RadioButton
                              label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>3 stars</Text>}
                              value='3'
                              checked={state.mnrtng === 3}
                              onChange={() => handleIncTxtChange({ mnrtng: 3 })}
                            />
                            <RadioButton
                              label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>4 stars</Text>}
                              value='4'
                              checked={state.mnrtng === 4}
                              onChange={() => handleIncTxtChange({ mnrtng: 4 })}
                            />
                            <RadioButton
                              label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>5 stars</Text>}
                              value='5'
                              checked={state.mnrtng === 5}
                              onChange={() => handleIncTxtChange({ mnrtng: 5 })}
                            />
                          </InlineStack>
                        </Box>
                      </Box>
                      <Box>
                        <Text variant='headingSm' as='p' fontWeight='medium'>Send coupons for reviews with</Text>
                        <Box paddingBlockStart={200}>
                          <BlockStack>
                            <RadioButton
                              label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Any review</Text>}
                              value='1'
                              checked={state.rvwtyp === 1}
                              onChange={() => handleIncTxtChange({ rvwtyp: 1 })}
                            />
                            <RadioButton
                              label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Reviews with title and body</Text>}
                              value='2'
                              checked={state.rvwtyp === 2}
                              onChange={() => handleIncTxtChange({ rvwtyp: 2 })}
                            />
                            <RadioButton
                              label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Reviews containing at least one photo or video</Text>}
                              value='3'
                              checked={state.rvwtyp === 3}
                              onChange={() => handleIncTxtChange({ rvwtyp: 3 })}
                            />
                            <RadioButton
                              label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Reviews containing at least one video</Text>}
                              value='4'
                              checked={state.rvwtyp === 4}
                              onChange={() => handleIncTxtChange({ rvwtyp: 4 })}
                            />
                          </BlockStack>
                        </Box>
                      </Box>
                    </BlockStack>
                  </Box>
                </Card>
                <IncentiveTemplateCard
                  state={state}
                  createTemplate={createTemplate}
                  editTemplateClk={editTemplateClk}
                  updateTmpStatus={updateTmpStatus}
                  handleStatusUpdate={handleStatusUpdate}
                />
              </BlockStack>
            </Box>
            :
            <Box paddingInlineStart={(state.searchParam === 'inc' || props.isShwBack === true) ? 300 : 0}>
              <Card>
                <Box paddingBlock={1600}>
                  <BlockStack gap={400} inlineAlign='center'>
                    <img src={"https://storage.googleapis.com/shopify_review_app/dash/incentive_create.svg"} alt='incentive_create' height="165px" />
                    <Text variant='headingMd' as='h3' fontWeight='semibold'>Boost Reviews with Rewards</Text>
                    <Text variant='headingXs' as='p' fontWeight='regular'>Motivate customers to leave reviews with perks like discounts or free delivery</Text>
                    <Box>
                      {localStorage.getItem('csub') === 'free' ?
                        <InlineStack gap={200} blockAlign='center'>
                          <Box>
                            <Badge tone='warning'>Exclusive</Badge>
                          </Box>
                          <Tooltip width='wide' padding="400" dismissOnMouseOut content='Feature available on paid plan'>
                            <Button id='primary_btn' variant='primary' disabled >Create Discount Now</Button>
                          </Tooltip>
                        </InlineStack>
                        :
                        <Button id='primary_btn' variant='primary'
                          onClick={() => changeNameValue({ discSetting: true })}>Create Discount Now</Button>
                      }
                    </Box>
                  </BlockStack>
                </Box>
              </Card>
            </Box>
          }


          {/* {state.IncentiveList && state.IncentiveList.length > 0 &&
        state.IncentiveList.map((data, index) => (

          <Card key={index} id={data._id}>
            <Box padding='400'>
              <Text variant='headingMd' as='h2'>Standard incentive</Text>
            </Box>

            <Box padding={500} paddingBlockStart={200}>

              <InlineStack align='space-between'> 
                <InlineStack> 
                  <Box paddingBlockEnd={100} paddingInlineEnd={400}>
                    <Thumbnail size={'small'} alt="Image" source={
                      data.cdtyp === 1 ? (state.UserObjshp && state.UserObjshp.crncy === '$') ? CashDollarIcon : CashRupeeIcon
                        : data.cdtyp === 2 ? DiscountIcon
                          : DeliveryIcon
                    } />
                  </Box>

                  <Box paddingBlockEnd={100}>
                    <Text as='p' fontWeight='medium' variant='bodyMd'>
                      {data.iunqcd === true ? (
                        data.cdtyp === 1 ? `${state.UserObjshp && state.UserObjshp.crncy ? state.UserObjshp.crncy : '₹'}${data.cdamnt} off`
                          : data.cdtyp === 2 ? `${data.cdamnt}% off`
                            : data.cdtyp === 3 ? 'Free shipping'
                              : null
                      ) : data.cstcd
                      } {data.ia ? <Badge tone="success">Active</Badge> : <Badge>Disabled</Badge>}
                    </Text>
                    <Text variant='bodyMd' as='p' tone='subdued'>{reviewTypeLabels[data.rvwrtyp]}</Text>
                  </Box> 
                  </InlineStack>

                <ButtonGroup>
                  <Button variant={data.ia ? 'tertiary' : 'primary'} onClick={() => handleStatusUpdate(data._id, !data.ia)}>{data.ia ? 'Turn off' : 'Turn on'}</Button>
                  <Button icon={EditIcon} onClick={() => handleUpdateAction(data, data._id)} >Edit</Button>
                  <Button variant="primary" tone="critical" icon={DeleteIcon} onClick={() => handleDeleteAction(data._id)}></Button>
                </ButtonGroup>

              </InlineStack>

            </Box>
          </Card>
        ))
      } */}

          {/* {state.IncentiveList && state.IncentiveList.length === 0 &&
        <Card>
          <EmptyState
            heading="Add a standard incentive"
            action={{
              content: 'Add standard incentive',
              onAction: () => openCloseModal('createOpen', state.createOpen, 'open')
            }}
            image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
          >
            <p>Standard incentives are given to any customer who leaves a review, regardless of content.</p>
          </EmptyState>
        </Card>
      } */}

        </Page>
        :
        //email template edit panel | preview
        <MainEditPanel
          state={state}
          changeNameValue={changeNameValue}
          handleTextChange={handleTextChange}
          openCloseModal={openCloseModal}
          backTempList={backTempList}
        />
      }

      {/* confirmation message on change page without save changes */}
      <ConfirmationAlert
        state={state}
        openCloseModal={openCloseModal}
        saveChanges={saveChanges}
        discardChanges={discardChanges}
      />

      <Modal
        open={state.createOpen}
        onClose={(e) => openCloseModal('createOpen', state.createOpen, e)}
        title={<Text variant='headingMd' fontWeight='bold'>Discount Settings</Text>}
        primaryAction={{ content: 'Save', id: 'primary_btn', onAction: () => handleSaveIncentive() }}
        secondaryActions={[{ content: 'Cancel', onAction: () => openCloseModal('createOpen', state.createOpen, 'close') }]}
      >
        <Modal.Section>
          <BlockStack gap={300}>
            <Box>
              <Box paddingBlockEnd={300}>
                <Label>Code</Label>
                <RadioButton
                  label="Creates a unique one-time discount code for each review"
                  id="createCode"
                  checked={state.iunqcd}
                  onChange={() => handleIncTxtChange({ iunqcd: true })}
                />
                <RadioButton
                  label="Manual discount code for all customers"
                  id="manualCode"
                  checked={!state.iunqcd}
                  onChange={() => handleIncTxtChange({ iunqcd: false })}
                />
              </Box>
              {state.iunqcd === true ?
                <div>
                  <Box paddingBlockEnd={200}>
                    <Select
                      label="Discount type"
                      options={options}
                      value={state.cdtyp}
                      onChange={(e) => handleIncTxtChange({ cdtyp: Number(e) })} />
                  </Box>
                  {state.cdtyp === 3 ? null : (
                    <Box paddingBlockEnd={200}>
                      <TextField
                        label="Amount"
                        type="number"
                        value={state.cdamnt}
                        onChange={(e) => handleIncTxtChange({ cdamnt: Number(e) })}
                        autoComplete="off"
                        error={state.errMessage.amount ? state.errMessage.amount : false}
                      />
                      {validator.message('amount', state.cdamnt, 'required')}
                    </Box>
                  )}
                  <TextField
                    label="Code prefix"
                    type="text"
                    value={state.cdprfx}
                    onChange={(e) => handleIncTxtChange({ cdprfx: e })}
                    autoComplete="off" />
                </div>
                :
                <Box>
                  <TextField
                    label="Discount manual code"
                    type="text"
                    value={state.cstcd}
                    onChange={(e) => handleIncTxtChange({ cstcd: e })}
                    autoComplete="off"
                    error={state.errMessage.manualCode ? state.errMessage.manualCode : false}
                  />
                  {validator.message('manualCode', state.cstcd, 'required')}
                </Box>
              }

            </Box>
            <Box>
              <Box paddingBlockStart={200}>
                <Label>Reward this to:</Label>
                <Box paddingBlockStart={100}>
                  <BlockStack>
                    <RadioButton
                      label="Any reviewer"
                      value='1'
                      checked={state.rvwrtyp === 1}
                      onChange={() => handleIncTxtChange({ rvwrtyp: 1 })}
                    />
                    <RadioButton
                      label="Only reviewers who have received a review request"
                      value='2'
                      checked={state.rvwrtyp === 2}
                      onChange={() => handleIncTxtChange({ rvwrtyp: 2 })}
                    />
                    {/* <RadioButton
                    label="Only verified buyers of the product they reviewed"
                    value='3'
                    checked={state.rvwrtyp === 3}
                    onChange={() => handleIncTxtChange({rvwrtyp: 3})}
                  />
                  <RadioButton
                    label="Only reviewers who have received a review request"
                    value='4'
                    checked={state.rvwrtyp === 4}
                    onChange={() => handleIncTxtChange({rvwrtyp: 4})}
                  /> */}
                  </BlockStack>
                </Box>
              </Box>
            </Box>
            <Box>
              <Label>Type of review that triggers coupon emails</Label>
              <Box paddingBlockStart={100}>
                <BlockStack>
                  <RadioButton
                    label="Any review"
                    value='1'
                    checked={state.rvwtyp === 1}
                    onChange={() => handleIncTxtChange({ rvwtyp: 1 })}
                  />
                  <RadioButton
                    label="Reviews with title and body"
                    value='2'
                    checked={state.rvwtyp === 2}
                    onChange={() => handleIncTxtChange({ rvwtyp: 2 })}
                  />
                  <RadioButton
                    label="Reviews containing at least one photo or video"
                    value='3'
                    checked={state.rvwtyp === 3}
                    onChange={() => handleIncTxtChange({ rvwtyp: 3 })}
                  />
                  <RadioButton
                    label="Reviews containing at least one video"
                    value='4'
                    checked={state.rvwtyp === 4}
                    onChange={() => handleIncTxtChange({ rvwtyp: 4 })}
                  />
                </BlockStack>
              </Box>
            </Box>
            <Box>
              <Select
                label="Who can receive coupons"
                options={couponOptions}
                value={state.mnrtng}
                onChange={(e) => handleIncTxtChange({ mnrtng: Number(e) })}
              />
            </Box>
          </BlockStack>
        </Modal.Section>
      </Modal>

      <Modal
        open={state.editOpen}
        onClose={(e) => openCloseModal('editOpen', state.editOpen, e)}
        title={<Text variant='headingMd' fontWeight='bold'>Update Discount Settings</Text>}
        primaryAction={{ content: 'Update', id: 'primary_btn', onAction: () => handleUpdateIncentive(state.incIdUpd) }}
        secondaryActions={[{ content: 'Cancel', onAction: () => openCloseModal('editOpen', state.editOpen, 'close') }]}
      >
        <Modal.Section>
          <BlockStack gap={400}>
            <Box>
              <Box paddingBlockEnd={300}>
                <Label>Code</Label>
                <RadioButton
                  label="Creates a unique one-time discount code for each review"
                  id="createCode"
                  checked={state.iunqcd}
                  onChange={() => handleIncTxtChange({ iunqcd: true })}
                  disabled
                />
                <RadioButton
                  label="Manual discount code for all customers"
                  id="manualCode"
                  checked={!state.iunqcd}
                  onChange={() => handleIncTxtChange({ iunqcd: false })}
                  disabled
                />
              </Box>
              {state.iunqcd === true ? (
                <>
                  <Box paddingBlockEnd={100}>
                    <Select
                      label="Discount type"
                      options={options}
                      value={state.cdtyp}
                      onChange={(e) => handleIncTxtChange({ cdtyp: Number }(e))}
                      disabled
                    />
                  </Box>
                  {state.cdtyp === 3 ? null : (
                    <Box paddingBlockEnd={100}>
                      <TextField
                        label="Amount"
                        type="number"
                        value={state.cdamnt}
                        onChange={(e) => handleIncTxtChange({ cdamnt: Number }(e))}
                        autoComplete="off"
                        error={state.errMessage.amount ? state.errMessage.amount : false}
                        disabled
                      />
                      {validator.message('amount', state.cdamnt, 'required')}
                    </Box>
                  )}
                  <TextField
                    label="Code prefix"
                    type="text"
                    value={state.cdprfx}
                    onChange={(e) => handleIncTxtChange({ cdprfx: e })}
                    autoComplete="off"
                    disabled
                  />
                </>
              ) : (
                <Box>
                  <TextField
                    label="Discount manual code"
                    type="text"
                    value={state.cstcd}
                    onChange={(e) => handleIncTxtChange({ cstcd: e })}
                    autoComplete="off"
                    error={state.errMessage.manualCode ? state.errMessage.manualCode : false}
                    disabled
                  />
                  {validator.message('manualCode', state.cstcd, 'required')}
                </Box>
              )}

            </Box>
            <Box>
              <Label>Reward this to:</Label>
              <Box paddingBlockStart={100}>
                <BlockStack>
                  <RadioButton
                    label="Any reviewer"
                    value='1'
                    checked={state.rvwrtyp === 1}
                    onChange={() => handleIncTxtChange({ rvwrtyp: 1 })}
                  />
                  <RadioButton
                    label="Only reviewers who have received a review request"
                    value='2'
                    checked={state.rvwrtyp === 2}
                    onChange={() => handleIncTxtChange({ rvwrtyp: 2 })}
                  />
                  {/* <RadioButton
                    label="Only verified buyers of the product they reviewed"
                    value='3'
                    checked={state.rvwrtyp === 3}
                    onChange={() => handleIncTxtChange({rvwrtyp: 3})}
                  />
                  <RadioButton
                    label="Only reviewers who have received a review request"
                    value='4'
                    checked={state.rvwrtyp === 4}
                    onChange={() => handleIncTxtChange({rvwrtyp: 4})}
                  /> */}
                </BlockStack>
              </Box>
            </Box>
            <Box>
              <Label>Type of review that triggers coupon emails</Label>
              <Box paddingBlockStart={100}>
                <BlockStack>
                  <RadioButton
                    label="Any review"
                    value='1'
                    checked={state.rvwtyp === 1}
                    onChange={() => handleIncTxtChange({ rvwtyp: 1 })}
                  />
                  <RadioButton
                    label="Reviews with title and body"
                    value='2'
                    checked={state.rvwtyp === 2}
                    onChange={() => handleIncTxtChange({ rvwtyp: 2 })}
                  />
                  <RadioButton
                    label="Reviews containing at least one photo or video"
                    value='3'
                    checked={state.rvwtyp === 3}
                    onChange={() => handleIncTxtChange({ rvwtyp: 3 })}
                  />
                  <RadioButton
                    label="Reviews containing at least one video"
                    value='4'
                    checked={state.rvwtyp === 4}
                    onChange={() => handleIncTxtChange({ rvwtyp: 4 })}
                  />
                </BlockStack>
              </Box>
            </Box>
            <Box>
              <Select
                label="Who can receive coupons"
                options={couponOptions}
                value={state.mnrtng}
                onChange={(e) => handleIncTxtChange({ mnrtng: Number(e) })}
              />
            </Box>
          </BlockStack>
        </Modal.Section>
      </Modal>

      <Modal
        size='small'
        open={state.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('deleteOpen', state.deleteOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this?</Text>
            <PageActions
              primaryAction={<Button id='primary_btn' onClick={() => handleDeleteIncentive(state.incIdDel)}>Yes</Button>}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('deleteOpen', state.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>

    </div >
  );
}

export default memo(Incentives);