import { Text, Page, Layout, Card, InlineGrid, Popover, OptionList, Button, Box, Banner, InlineStack, Badge, BlockStack, Collapsible, Icon, Divider, IndexTable } from '@shopify/polaris';
import { CheckIcon, XIcon, ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons';
import { wisernotify } from '../../img';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as planDucks from '../../ducks/plan';
import * as authDucks from '../../ducks/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { changePathForShopifyAppEmbed } from "../../utils/utils";
import '../../containers/Plan/Plan.css';
const moment = require('moment');

function Main(props) {

  // const convertUaq = (data) => {
  //   if (data < 1e3) return data;
  //   if (data >= 1e6 && data < 1e9) return +(data / 1e6).toFixed(1) + 'M';
  //   if (data >= 1e3 && data < 1e6) return +(data / 1e3).toFixed(1) + 'K';
  //   if (data >= 1e9 && data < 1e12) return +(data / 1e9).toFixed(1) + 'B';
  //   if (data >= 1e12) return +(data / 1e12).toFixed(1) + 'T';
  // };

  // let planUse = profileState && profileState.uaq * 100 || 0;

  // let _width;
  // if (planUse > 100) _width = 100;
  // else _width = planUse;

  // let toggleLabel =
  //   <div className='plan_wraper'>
  //     <span className={state.pt === 'month' ? 'plan_active' : ''}>Pay Monthly</span>
  //     <label className="switch">
  //       <input type="checkbox" checked={state.pt === 'month' ? false : true}
  //         onChange={(e) => handlePlanChange(state.pt)} />
  //       <span className="switch-label"></span>
  //       <span className="switch-handle"></span>
  //     </label>
  //     <span className={state.pt === 'year' ? 'plan_active' : ''}>Pay yearly</span>
  //   </div>;

  // let mainPobj;
  // if (profileState && profileState.billing && profileState.billing.pobj)
  //   mainPobj = profileState.billing.pobj;

  const collectReviewsList = [
    {
      _id: 1,
      label: 'Photo reviews',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 2,
      label: 'Video reviews',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 3,
      label: 'Discount code',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 4,
      label: 'Past orders',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 5,
      label: 'Reminder email',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 6,
      label: 'Customize email',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 7,
      label: 'Review form',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
  ]

  const displayReviewsList = [
    {
      _id: 1,
      label: 'Product reviews',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 2,
      label: 'Rating widget',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 3,
      label: 'Trust badge',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 4,
      label: 'Carousel',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 5,
      label: 'Wall of love',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 6,
      label: 'Review popups',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 7,
      label: 'Branding removal',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 8,
      label: 'Product grouping',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 9,
      label: 'Reply publicly to reviews',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 10,
      label: 'Question answer widget',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
  ]

  const integrationsList = [
    {
      _id: 1,
      label: 'Google shopping reviews (PLAs)',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 2,
      label: 'Google review snippets',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 3,
      label: 'Integrations(Klaviyo)',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 4,
      label: 'Multi store sync',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
  ]

  const supportList = [
    {
      _id: 1,
      label: 'Live chat',
      freeVal: CheckIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 2,
      label: 'Implementation',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 3,
      label: 'Custom HTML/CSS by our team',
      freeVal: XIcon,
      essentialsVal: XIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
    {
      _id: 4,
      label: 'Building integration based on request',
      freeVal: XIcon,
      essentialsVal: CheckIcon,
      proVal: CheckIcon,
      growthVal: CheckIcon,
      exclusiveVal: CheckIcon,
    },
  ]

  const exclusivePlanList = [
    {
      id: 1,
      label: 'All from free plan',
    },
    {
      id: 2,
      label: 'Video reviews',
    },
    {
      id: 3,
      label: 'Past orders',
    },
    {
      id: 4,
      label: 'Branding removal',
    },
    {
      id: 5,
      label: 'Google shopping reviews',
    },
    {
      id: 6,
      label: 'Multi store sync',
    },
    {
      id: 7,
      label: 'Building integration based on request',
    },
    {
      id: 8,
      label: 'UGC Grid',
    },
    {
      id: 9,
      label: 'Questions & answers',
    },
  ]

  const freePlanList = [
    {
      id: 1,
      label: '50 orders a month',
    },
    {
      id: 2,
      label: 'Photo reviews',
    },
    {
      id: 3,
      label: 'Reminder email',
    },
    {
      id: 4,
      label: 'Review form',
    },
    {
      id: 5,
      label: 'Google review snippets',
    },
    {
      id: 6,
      label: 'Live chat',
    }
  ]

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    planList: [],
    selectedPlan: '',
    pn: '',
    pt: '',
    billingPrc: '',
    billingDate: '',
    uaq: '',
    uuq: '',
    pid: '',
    cid: '',
    billingPi: '',
    resetDate: '',
    planOpen: false,
    firstTabCollapse: true,
    secondTabCollapse: false,
    thirdTabCollapse: false,
    fourthTabCollapse: false,
    fifthTabCollapse: false,
    selEssOption: '',
    selProOption: '',
    selGrowthOption: '',
    selexclusiveOption: '',
    essentialOptions: [],
    proOptions: [],
    growthOptions: [],
    exclusiveOptions: [],
    searchParam: '',
    tabEvent: 'firstTabCollapse'
  });

  useEffect(() => {
    dispatch(authDucks.getProfile());
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  state.searchParam = queryParams.get("p");

  // api data
  const planState = useSelector(state => state.plan);
  const profileState = useSelector(state => state.auth.profile);

  let planList = planState && planState.shopifyPlan || [];

  useEffect(() => {
    // let _offset = new Date().getTimezoneOffset();

    if (profileState) {
      // if (profileState.billing && profileState.billing.cdt) {
      //   if (Math.sign(_offset) === -1 && _offset > -330) {
      //     let reset = new Date(profileState.billing.cdt);
      //     reset = new Date(reset.setMonth(reset.getMonth() + 1));
      //     reset = new Date(reset);
      //     reset.setDate(reset.getDate() + 1);
      //     changeNameValue('resetDate', moment(new Date(reset)).format('MMM DD YYYY'));
      //   }
      //   else {
      //     let newDate = new Date(profileState.billing.cdt);
      //     newDate = new Date(newDate.setMonth(newDate.getMonth() + 1));
      //     changeNameValue('resetDate', moment(new Date(newDate)).format('MMM DD YYYY'));
      //   }
      // }
      var nxtbDate = new Date(profileState.nxtb);
      let obj = {
        billingDate: nxtbDate ? moment(nxtbDate).format('MMM DD, YYYY') : '-',
        uaq: profileState.uaq ? profileState.uaq : 0,
        uuq: profileState.uuq ? profileState.uuq : 0,
        cid: profileState.billing ? profileState.billing.cid : '-'
      }
      changeNameValue(obj);

      if (profileState.billing && profileState.billing.pobj) {
        let obj = {
          pn: profileState.billing.pobj.pn ? profileState.billing.pobj.pn : '-',
          pt: profileState.billing.pobj.pt ? profileState.billing.pobj.pt : '-',
          billingPrc: profileState.billing.pobj.prc ? profileState.billing.pobj.prc : 0,
        }
        changeNameValue(obj);
      }
    }
  }, [profileState, profileState?.billing, profileState?.billing?.pobj]);

  useEffect(() => {
    if (planList && planList.length > 0) {
      planList = [...planList].sort((a, b) => a.tprc - b.tprc);

      const EssOptiondata = [];
      const ProOptiondata = [];
      const GrowthOptiondata = [];
      const exclusiveOptiondata = [];

      planList.forEach((data) => {
        if (data.ctg.includes('essential')) {
          EssOptiondata.push(data);
        } else if (data.ctg.includes('pro')) {
          ProOptiondata.push(data);
        } else if (data.ctg.includes('growth')) {
          GrowthOptiondata.push(data);
        } else if (data.ctg.includes('exclusive')) {
          exclusiveOptiondata.push(data);
        }
      });

      let obj = {
        planList,
        essentialOptions: EssOptiondata,
        proOptions: ProOptiondata,
        growthOptions: GrowthOptiondata,
        exclusiveOptions: exclusiveOptiondata
      }
      changeNameValue(obj);
    }
  }, [planList]);

  // useEffect(() => {
  //   if (planList && planList.length > 0) {
  //     let planListNew = [];
  //     for (let i = 0; i < planList.length; i++) {
  //       const ele = planList[i];
  //       if (ele.pt === state.pt) {
  //         planListNew.push(ele);
  //       }
  //       if (state.pt === 'year' && ele.pid === 'free_plan') {
  //         planListNew.push(ele);
  //       }
  //     }
  //     changeNameValue('planList', planListNew);
  //   }
  // }, [planList, state.pt]);

  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  useEffect(() => {
    dispatch(planDucks.getShopifyPlan());
  }, []);

  const handlePlanUpgrade = (type) => {
    let _selectedPlanId = state.pid;
    if (type === 'ess' && (!_selectedPlanId || _selectedPlanId.indexOf('essential') === -1)) {
      _selectedPlanId = state.essentialOptions[0].pid;
    } else if (type === 'pro' && (!_selectedPlanId || _selectedPlanId.indexOf('pro') === -1)) {
      _selectedPlanId = state.proOptions[0].pid;
    } else if (type === 'exu' && (!_selectedPlanId || _selectedPlanId.indexOf('exclusive') === -1)) {
      _selectedPlanId = state.exclusiveOptions[0].pid;
    }
    let obj = {
      shop: localStorage.getItem('shp'),
      planId: _selectedPlanId
    }
    dispatch(planDucks.shopifyPayment(obj));
    dispatch(authDucks.getProfile());
  }

  const handleCancelShopifyPlan = () => {
    let obj = {
      shp: localStorage.getItem('shp'),
      cid: state.cid
    }
    dispatch(planDucks.cancelShopifyPlan(obj));
    dispatch(authDucks.getProfile());
  }

  const handlePlanChange = (value) => {
    changeNameValue({ pt: value === 'month' ? 'year' : 'month' });
  }

  const handleBackToMain = () => {
    if (localStorage.getItem('is_shopify_embed')) {
      changePathForShopifyAppEmbed('/setting');
    }
    else navigate('/setting');
  }

  useEffect(() => {
    // default value for the plan options

    const defaultPlanOptions = [
      { option: 'selEssOption', optionsArray: state.essentialOptions, orderVal: 'duaq', prcVal: 'essentialPrc', pidVal: 'pid' },
      { option: 'selProOption', optionsArray: state.proOptions, orderVal: 'duaq', prcVal: 'proPrc', pidVal: 'pid' },
      { option: 'selGrowthOption', optionsArray: state.growthOptions, orderVal: 'duaq', prcVal: 'growthPrc' },
      { option: 'selexclusiveOption', optionsArray: state.exclusiveOptions, orderVal: 'duaq', prcVal: 'exclusivePrc' }
    ];

    defaultPlanOptions.forEach(({ option, optionsArray, orderVal, prcVal, pidVal }) => {
      if (!state[option] && optionsArray.length > 0) {
        let obj = {
          [option]: optionsArray[0][orderVal],
          [prcVal]: optionsArray[0].prc
        }
        changeNameValue(obj);
        // if (option === 'selEssOption' && pidVal) {
        //   changeNameValue({ 'pid': optionsArray[0].pid });
        // }
      }
    });
  }, [state.selEssOption, state.essentialOptions, state.essentialPrc, state.selProOption, state.proOptions, state.proPrc, state, changeNameValue]);

  const handleSelectChange = (name, value) => {
    changeNameValue({ [name]: value });
    if (value) {
      const optionTypes = [
        { type: 'essentialOptions', property: 'essentialPrc' },
        { type: 'proOptions', property: 'proPrc' },
        { type: 'growthOptions', property: 'growthPrc' }
      ];

      optionTypes.forEach(({ type, property }) => {
        const prcData = state[type].find(data => data.duaq === value.replace(/\s*orders\s*/, ""));

        if (prcData && prcData.prc) {
          let obj = {
            [property]: prcData.prc,
            pid: prcData.pid
          }
          changeNameValue(obj);
        }
      });
    }
  };

  useEffect(() => { //monthly usage dropdwon options
    let essentialOpt = [], proOpt = [], growthOpt = [];
    state.essentialOptions && state.essentialOptions.length > 0 && state.essentialOptions.map((data) => {
      essentialOpt.push({ label: data.duaq + ' orders', value: data.duaq + ' orders' });
    });

    state.proOptions && state.proOptions.length > 0 && state.proOptions.map((data) => {
      proOpt.push({ label: data.duaq + ' orders', value: data.duaq + ' orders' });
    });

    state.growthOptions && state.growthOptions.length > 0 && state.growthOptions.map((data) => {
      growthOpt.push({ label: data.duaq + ' orders', value: data.duaq + ' orders' });
    });

    let stateObj = {
      essentialOpt: essentialOpt,
      proOpt: proOpt,
      growthOpt: growthOpt
    }
    changeNameValue(stateObj);
  }, [state.essentialOptions, state.proOptions, state.growthOptions, changeNameValue]);

  const handleDeskuShow = () => {
    window.Desku('show');
  }

  let queArr = [
    {
      field: 'firstTabCollapse',
      question: 'Can I request additional integrations?',
      answer: 'Yes, we build new integrations based on user demand and prioritize the most requested ones.'
    }, {
      field: 'secondTabCollapse',
      question: 'Is it possible to change my plan later?',
      answer: 'Absolutely, you can upgrade or downgrade your plan at any time based on your needs.'
    }, {
      field: 'thirdTabCollapse',
      question: "What happens if I exceed my plan's order limit?",
      answer: "We'll send you an email alert as you near your limit, so you can upgrade and avoid any disruptions."
    }, {
      field: 'fourthTabCollapse',
      question: 'Can I be billed annually instead of monthly?',
      answer: 'At the moment, we only offer monthly billing.'
    }, {
      field: 'fifthTabCollapse',
      question: 'Can I transfer my existing reviews to your platform?',
      answer: 'You can easily import your reviews from other platforms with just a few clicks or by reaching out to the live chat support team.'
    }
  ];

  return (
    <Page
      title={"Billing settings"}
      backAction={props.isShwBack === true ? { onAction: () => handleBackToMain() } : false} >

      <Layout>

        {/* {profileState && profileState.billing &&
          <Layout.Section>
            <InlineGrid gap={200} columns={1}>
              <Card title="Current plan">
                <Box>
                  <div className='media-current-plan'>
                    <BlockStack gap={200}>
                      <Text variant="headingMd" as="h2">
                        {profileState && profileState.csub === 'free' ?
                          <Text as='p' variant='bodyMd' fontWeight='medium'>Free</Text>
                          :
                          <Text as='p' variant='bodyMd' fontWeight='medium'>
                            {mainPobj &&
                              <>
                                <span>{mainPobj.pn && mainPobj.pn} </span>
                                {mainPobj.prc && mainPobj.prc !== 0 &&
                                  <span>${mainPobj.prc}.00</span>
                                }
                              </>
                            }</Text>
                        }

                      </Text>
                      <p>Your plan will be renewed on {state.resetDate}</p>
                    </BlockStack>
                  </div>
                </Box>
                <Box title={`${mainPobj ? mainPobj.duaq ? mainPobj.duaq : 0 : 0} Orders/month`}>
                  <p>Your Order quota reset date: {state.resetDate}.</p>
                </Box>
              </Card>
            </InlineGrid>
          </Layout.Section>} */}

        {/* <Layout.Section>
          <InlineGrid gap={200} columns={1}>
            <div className='box-current-plan'>
              <Card className="my-custom-card">
                <BlockStack>
                  <div className='box-current-plan-left'>
                    <Icon source={ClockIcon} />
                  </div>
                  <div className='box-current-plan-right'>
                    <Box>
                      <Text as="p" fontWeight="bold">Current plan & visitor quota</Text>

                      {profileState &&
                        profileState.csub === 'free' ?
                        <p>Free Trial</p>
                        :
                        <p>
                          {mainPobj &&
                            <>
                              <span>{profileState.billing.pobj.name && profileState.billing.pobj.name}</span>
                              {profileState.billing.pobj.price && profileState.billing.pobj.price !== 0 &&
                                <span>${profileState.billing.price}.00</span>
                              }
                            </>
                          }
                        </p>
                      }

                      <p className='plan-renewed-text'>Your plan will be renewed on Feb-06-2022</p>
                      <BlockStack>
                        <BlockStack.Item fill>

                          <Text as="p" fontWeight="bold" tone="success">
                            {mainPobj && profileState.billing.pobj.duaq ? profileState.billing.pobj.duaq : 0} Orders/month</Text>

                          <div style={{ width: 320 }}>
                            <ProgressBar progress={_width} size="small" tone="primary" />
                          </div>
                          <Text variant="bodyMd" as="p">Your Visitor quota reset date: {state.resetDate}.</Text>
                        </BlockStack.Item>
                        <BlockStack.Item>
                          <div className='btn-plan-refresh'>
                            <Button variant="plain" icon={RefreshIcon} onClick={() => getUserPlanData()}> Refresh</Button>
                          </div>
                        </BlockStack.Item>
                      </BlockStack>
                    </Box>
                  </div>
                </BlockStack>

              </Card>
            </div>
          </InlineGrid>
        </Layout.Section> */}

        {/* <Layout.Section>
          <InlineGrid gap={200} columns={3}>

            {state.planList && state.planList.length > 0 && state.planList.map((data, i) => {
              return (
                <Card key={i}>
                  <Box padding={100}>
                    <Box paddingBlockEnd={200}><Text as='h3' variant='headingSm'>{data.pn}</Text></Box>
                    <Text as='span' variant='headingLg'>{`$${data.prc}`}</Text>
                    <Box paddingBlockStart={300} paddingBlockEnd={100}>
                      <Text variant='headingMd' as='h5'>{`${convertUaq(data.uaq ? data.uaq : 0)} Orders/month`}</Text>
                    </Box>
                    <Box paddingBlockStart={400}>
                      <List>
                        <List.Item>All the basic features</List.Item>
                        <List.Item>LiveChat & Email Support</List.Item>
                        <List.Item>All review widgets</List.Item>
                        <List.Item>Up to {data.duaq ? data.duaq : 0} Orders/month</List.Item>
                      </List>
                    </Box>
                    <Box paddingBlockStart={400}>
                      <Button variant='primary' fullWidth onClick={() => handlePlanUpgrade(data)}
                        disabled={profileState && profileState.billing && profileState.billing.pi && profileState.billing.pi === data.pid}>
                        {(profileState && profileState.billing) ?
                          (profileState.billing.pi === data.pid) ?
                            'Selected'
                            : 'Upgrade'
                          : 'Select'}
                      </Button>
                    </Box>
                  </Box>

                </Card>
              );
            })}

          </InlineGrid>
        </Layout.Section> */}

      </Layout>

      <Box paddingInlineStart={props.isShwBack === true ? 200 : 0}
        padding={{ xs: 400, sm: 400, md: 0 }} paddingBlockEnd={600}>
        <BlockStack gap={600}>
          {/* <Box paddingBlockStart={300}>
            <Banner
              title="You're eligible for a free upgrade"
              tone="success"
              icon={SmileyHappyIcon}
            >
              <Text variant='bodyMd' as='p' fontWeight='regular'>Partners and development stores can upgrade to any paid plan for free.</Text>
            </Banner>
          </Box> */}

          <Box paddingBlockStart={300}>
            <InlineGrid gap={500} columns={{ lg: 2, md: 2, sm: 1, xs: 1 }}>
              <Card>
                <BlockStack gap={600}>
                  <Text variant='headingMd' as='p' fontWeight='semibold'>Forever free</Text>
                  <Box>
                    <Text variant='headingXl' as='p' fontWeight='semibold'>$ 0</Text>
                    <Text variant='headingSm' as='p' fontWeight='regular'>/month</Text>
                  </Box>
                  <Box>
                    {freePlanList && freePlanList.length > 0 && freePlanList.map((data, i) => (
                      <Box key={i} paddingBlock={100}>
                        <InlineStack gap={200} wrap={false} blockAlign='center'>
                          <Box>
                            <Icon source={CheckIcon} />
                          </Box>
                          <Text variant='headingSm' fontWeight='regular' as='span'>{data.label}</Text>
                        </InlineStack>
                      </Box>
                    ))}
                  </Box>
                </BlockStack>
              </Card>
              <Card>
                <BlockStack gap={600}>
                  <InlineStack gap={200} wrap={false} blockAlign='center'>
                    <Text variant='headingMd' as='p' fontWeight='semibold'>Exclusive</Text>
                    <Box>
                      <Badge tone='info'>Recommended</Badge>
                    </Box>
                  </InlineStack>
                  <Box>
                    <Text variant='headingXl' as='p' fontWeight='semibold'>$ {state.exclusivePrc ? state.exclusivePrc : '-'}</Text>
                    <Text variant='headingSm' as='p' fontWeight='regular'>/month</Text>
                  </Box>
                  <Box>
                    {exclusivePlanList && exclusivePlanList.length > 0 && exclusivePlanList.map((data, i) => (
                      <Box key={i} paddingBlock={100}>
                        <InlineStack gap={200} wrap={false} blockAlign='center'>
                          <Box>
                            <Icon source={CheckIcon} />
                          </Box>
                          <Text variant='headingSm' fontWeight='regular' as='span'>{data.label}</Text>
                        </InlineStack>
                      </Box>
                    ))}
                  </Box>
                  <InlineStack align='center'>
                    {/* <Box>
                      <Button id='primary_btn' variant='primary'>Upgrade now</Button>
                    </Box> */}
                    {(state.exclusivePrc < state.billingPrc) ?
                      <Button id='primary_btn' variant='primary' onClick={() => handlePlanUpgrade('exu')} fullWidth>
                        Downgrade
                      </Button>
                      :
                      (state.exclusivePrc === state.billingPrc) ?
                        <Button fullWidth disabled>
                          Selected
                        </Button>
                        :
                        <Button id='primary_btn' variant='primary' onClick={() => handlePlanUpgrade('exu')} fullWidth>
                          Upgrade now
                        </Button>
                    }
                  </InlineStack>
                </BlockStack>
              </Card>
            </InlineGrid>
          </Box>

          {/* <Box paddingBlockStart={300}>
            <InlineGrid gap={500} columns={{ xs: 1, sm: 3, md: 2, lg: 3 }}>
              <Card>
                <Box padding={100}>
                  <BlockStack gap={300}>
                    <Box maxWidth='95%'>
                      <Badge tone='warning'><Text variant='headingXs' fontWeight='medium' as='span'>
                        Essentials</Text></Badge>
                    </Box>
                    <InlineStack gap={100} blockAlign='center'>
                      <Text as='span' variant='headingLg' fontWeight='semibold'>${state.essentialPrc ? state.essentialPrc : '-'}</Text>
                      <Text variant='headingSm' as='span' fontWeight='regular'>per month</Text>
                    </InlineStack>
                  </BlockStack>
                  <Box paddingBlockStart={400}>
                    <Divider />
                  </Box>
                  <Box paddingBlockStart={400}>

                    <Text fontWeight='medium' as='span'>Monthly usage</Text>
                    <Box paddingBlockStart={100}>
                      <Popover
                        fullWidth
                        active={state.isEssFrmt}
                        onClose={() => changeNameValue({ isEssFrmt: !state.isEssFrmt })}
                        activator={<Button textAlign="left" fullWidth disclosure='select'
                          onClick={() => changeNameValue({ isEssFrmt: !state.isEssFrmt })}>
                          {state.selEssOption ? !state.selEssOption.includes('orders') ?
                            state.selEssOption + ' orders' : state.selEssOption
                            : 'Select'
                          }
                        </Button>}>
                        <OptionList
                          options={state.essentialOpt}
                          selected={!state.selEssOption.includes('orders') ?
                            state.selEssOption + ' orders' : state.selEssOption}
                          onChange={() => changeNameValue({ isEssFrmt: !state.isEssFrmt })}
                          onFocusOption={(e) => handleSelectChange('selEssOption', e)} />
                      </Popover>
                    </Box>

                  </Box>
                  <Box paddingBlockStart={400}>
                    {(state.essentialPrc < state.billingPrc) ?
                      <Button id='primary_btn' variant='primary' onClick={() => handlePlanUpgrade('ess')} fullWidth>
                        Downgrade
                      </Button>
                      :
                      (state.essentialPrc === state.billingPrc) ?
                        <Button fullWidth disabled>
                          Selected
                        </Button>
                        :
                        <Button id='primary_btn' variant='primary' onClick={() => handlePlanUpgrade('ess')} fullWidth>
                          Upgrade
                        </Button>
                    }
                  </Box>
                </Box>
              </Card>
              <Card>
                <Box padding={100}>
                  <BlockStack gap={300}>
                    <Box>
                      <Badge tone='info'><Text variant='headingXs' fontWeight='medium' as='span'>Pro</Text></Badge>
                    </Box>
                    <InlineStack gap={100} blockAlign='center'>
                      <Text as='span' variant='headingLg' fontWeight='semibold'>${state.proPrc ? state.proPrc : '-'}</Text>
                      <Text variant='headingSm' as='span' fontWeight='regular'>per month</Text>
                    </InlineStack>
                  </BlockStack>
                  <Box paddingBlockStart={400}>
                    <Divider />
                  </Box>
                  <Box paddingBlockStart={400}>
                    <Text fontWeight='medium' as='span'>Monthly usage</Text>
                    <Box paddingBlockStart={100}>
                      <Popover
                        fullWidth
                        active={state.isProFrmt}
                        onClose={() => changeNameValue({ isProFrmt: !state.isProFrmt })}
                        activator={<Button textAlign="left" fullWidth disclosure='select'
                          onClick={() => changeNameValue({ isProFrmt: !state.isProFrmt })}>
                          {state.selProOption ? !state.selProOption.includes('orders') ?
                            state.selProOption + ' orders' : state.selProOption
                            : 'Select'
                          }
                        </Button>}>
                        <OptionList
                          options={state.proOpt}
                          selected={!state.selProOption.includes('orders') ?
                            state.selProOption + ' orders' : state.selProOption}
                          onChange={() => changeNameValue({ isProFrmt: !state.isProFrmt })}
                          onFocusOption={(e) => handleSelectChange('selProOption', e)} />
                      </Popover>
                    </Box>

                  </Box>
                  <Box paddingBlockStart={400}>
                    {(state.proPrc < state.billingPrc) ?
                      <Button id='primary_btn' variant='primary' onClick={() => handlePlanUpgrade('pro')} fullWidth>
                        Downgrade
                      </Button>
                      :
                      (state.proPrc === state.billingPrc) ?
                        <Button fullWidth disabled>
                          Selected
                        </Button>
                        :
                        <Button id='primary_btn' variant='primary' onClick={() => handlePlanUpgrade('pro')} fullWidth>
                          Upgrade
                        </Button>
                    }
                  </Box>
                </Box>
              </Card>
              <Card>
                <Box padding={100}>
                  <BlockStack gap={300}>
                    <Box>
                      <Badge tone='attention'><Text variant='headingXs' fontWeight='medium' as='span'>Growth</Text></Badge>
                    </Box>
                    <InlineStack gap={100} blockAlign='center'>
                      <Text as='span' variant='headingLg' fontWeight='semibold'>Let's discuss</Text>
                    </InlineStack>
                  </BlockStack>
                  <Box paddingBlockStart={400}>
                    <Divider />
                  </Box>
                  <Box paddingBlockStart={400}>

                    <Text fontWeight='medium' as='span'>Monthly usage</Text>
                    <Box paddingBlockStart={100}>
                      <Popover
                        fullWidth
                        active={state.isGwFrmt}
                        onClose={() => changeNameValue({ isGwFrmt: !state.isGwFrmt })}
                        activator={<Button textAlign="left" fullWidth disclosure='select'
                          onClick={() => changeNameValue({ isGwFrmt: !state.isGwFrmt })}>
                          {state.selGrowthOption ? !state.selGrowthOption.includes('orders') ?
                            state.selGrowthOption + ' orders' : state.selGrowthOption
                            : 'Select'
                          }
                        </Button>}>
                        <OptionList
                          options={state.growthOpt}
                          selected={!state.selGrowthOption.includes('orders') ?
                            state.selGrowthOption + ' orders' : state.selGrowthOption}
                          onChange={() => changeNameValue({ isGwFrmt: !state.isGwFrmt })}
                          onFocusOption={(e) => handleSelectChange('selGrowthOption', e)} />
                      </Popover>
                    </Box>
                  </Box>
                  <Box paddingBlockStart={400}>
                    <Button fullWidth onClick={() => handleDeskuShow()}>
                      Talk with us
                    </Button>
                  </Box>
                </Box>
              </Card>
            </InlineGrid>
          </Box> */}

          {/* <Box>
            <Card>
              <InlineStack align='space-between' blockAlign='center'>
                <InlineStack gap={100} direction={'column'} blockAlign='start'>
                  <Text variant='headingSm' as='p' fontWeight='semibold'>Free plan</Text>
                  <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>Basic features to kickstart your review management, up to 50 orders a month</Text>
                </InlineStack>
                <Box>
                  {state.cid !== 'free' && <Button onClick={() => handleCancelShopifyPlan()}>Downgrade to free</Button>}
                </Box>
              </InlineStack>
            </Card>
          </Box> */}

          <Box>
            <Card>
              <BlockStack gap={400}>
                <Box>
                  <InlineStack gap={200}>
                    <Text variant='headingMd' as='p' fontWeight='semibold'>Current plan</Text>
                    <Badge tone="attention"><Text variant='headingXs' fontWeight='medium' as='span'>
                      {state.pn}</Text></Badge>
                  </InlineStack>
                </Box>
                <Box>
                  <InlineStack gap={300}>
                    <img src={wisernotify} alt='wisernotify' width="24px" />
                    <Text variant='headingSm' as='p' fontWeight='regular'>${state.billingPrc} USD billed {state.pt}. Renews on {state.billingDate}</Text>
                  </InlineStack>
                </Box>
                <Box>
                  <Banner>
                    <Text variant='headingSm' fontWeight='medium' as='span'>
                      You have used {state.uuq} of your {state.uaq} order monthly usage limit. It will reset at the end of your billing cycle.
                    </Text>
                  </Banner>
                </Box>
              </BlockStack>
            </Card>
          </Box>

          <Box>
            <Card padding={0}>
              <div id='cursorPoint' onClick={() => changeNameValue({ 'planOpen': !state.planOpen })}>
                <Box background="bg-fill-active" padding={400}>
                  <InlineStack align='space-between'>
                    <Text variant='bodyLg' fontWeight='semibold' as='h1'>Plan comparison</Text>
                    <Box>
                      {state.planOpen ?
                        <Icon source={ChevronUpIcon} tone="base"></Icon> :
                        <Icon source={ChevronDownIcon} tone="base"></Icon>}
                    </Box>
                  </InlineStack>
                </Box>
              </div>
              <Divider />
              <Box paddingInline={400}>
                <Collapsible
                  open={state.planOpen}
                  id="db_collaspe"
                  transition={{ duration: '300ms', timingFunction: 'ease-in' }}>

                  <Box paddingBlockStart={400} paddingBlockEnd={400}>
                    <div id='plan_tbl'>
                      <IndexTable
                        itemCount={collectReviewsList ? collectReviewsList.length : 0}
                        selectable={false}
                        headings={[
                          { title: '' },
                          { title: <Text variant='headingXs' fontWeight='semibold' as='span'>Free</Text> },
                          { title: <Text variant='headingXs' fontWeight='semibold' as='span'>Exclusive</Text> },
                          // { title: <Text variant='headingXs' fontWeight='semibold' as='span'>Essentials</Text> },
                          // { title: <Text variant='headingXs' fontWeight='semibold' as='span'>Pro</Text> },
                          // { title: <Text variant='headingXs' fontWeight='semibold' as='span'>Growth</Text> }, 
                        ]}
                      // pagination={{
                      //   // label: paggiLabel,
                      //   hasPrevious: state.paggiActive > 1 ? true : false,
                      //   hasNext: (state.paggiActive < pageNumber) ? true : false,
                      //   onPrevious: () => onPaggiCall('-1'),
                      //   onNext: () => onPaggiCall('+1')
                      // }}
                      >
                        <Box paddingBlockStart={600} paddingBlockEnd={200}>
                          <Text variant='headingSm' as='h2' fontWeight='bold'>Collect reviews</Text>
                        </Box>

                        {collectReviewsList && collectReviewsList.length > 0 && collectReviewsList.map((data, index) => (
                          <IndexTable.Row
                            id={data._id + 'clctRev'}
                            selected={false}
                            key={'clctRev' + index}
                            position={'clctRev' + index}>
                            <IndexTable.Cell>
                              <Text variant="bodyMd" fontWeight="medium" as="span">
                                {data.label}
                              </Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.freeVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.exclusiveVal} />
                            </IndexTable.Cell>
                            {/* <IndexTable.Cell>
                              <Icon source={data.essentialsVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.proVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.growthVal} />
                            </IndexTable.Cell> */}
                          </IndexTable.Row>
                        ))}

                        <Box paddingBlockStart={600} paddingBlockEnd={200}>
                          <Text variant='headingSm' as='h2' fontWeight='bold'>Display reviews</Text>
                        </Box>

                        {displayReviewsList && displayReviewsList.length > 0 && displayReviewsList.map((data, index) => (
                          <IndexTable.Row
                            id={data._id + 'dsplyRev'}
                            selected={false}
                            key={'dsplyRev' + index}
                            position={'dsplyRev' + index}>
                            <IndexTable.Cell>
                              <Text variant="bodyMd" fontWeight="medium" as="span">
                                {data.label}
                              </Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.freeVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.exclusiveVal} />
                            </IndexTable.Cell>
                            {/* <IndexTable.Cell>
                              <Icon source={data.essentialsVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.proVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.growthVal} />
                            </IndexTable.Cell> */}
                          </IndexTable.Row>
                        ))}

                        <Box paddingBlockStart={600} paddingBlockEnd={200}>
                          <Text variant='headingSm' as='h2' fontWeight='bold'>Integrations</Text>
                        </Box>

                        {integrationsList && integrationsList.length > 0 && integrationsList.map((data, index) => (
                          <IndexTable.Row
                            id={data._id + 'integrate'}
                            selected={false}
                            key={'integrate' + index}
                            position={'integrate' + index}>
                            <IndexTable.Cell>
                              <Text variant="bodyMd" fontWeight="medium" as="span">
                                {data.label}
                              </Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.freeVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.exclusiveVal} />
                            </IndexTable.Cell>
                            {/* <IndexTable.Cell>
                              <Icon source={data.essentialsVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.proVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.growthVal} />
                            </IndexTable.Cell> */}
                          </IndexTable.Row>
                        ))}

                        <Box paddingBlockStart={600} paddingBlockEnd={200}>
                          <Text variant='headingSm' as='h2' fontWeight='bold'>Support</Text>
                        </Box>

                        {supportList && supportList.length > 0 && supportList.map((data, index) => (
                          <IndexTable.Row
                            id={data._id + 'support'}
                            selected={false}
                            key={'support' + index}
                            position={'support' + index}>
                            <IndexTable.Cell>
                              <Text variant="bodyMd" fontWeight="medium" as="span">
                                {data.label}
                              </Text>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.freeVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.exclusiveVal} />
                            </IndexTable.Cell>
                            {/* <IndexTable.Cell>
                              <Icon source={data.essentialsVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.proVal} />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <Icon source={data.growthVal} />
                            </IndexTable.Cell> */}
                          </IndexTable.Row>
                        ))}
                      </IndexTable>
                    </div>
                  </Box>

                </Collapsible>
              </Box>
            </Card>
          </Box>

          <Box>
            <Card padding={0}>
              <Box paddingBlock={300} paddingInline={400} background="bg-fill-active">
                <Text variant='headingMd' fontWeight='semibold' as='h1'>General questions</Text>
              </Box>
              <Divider borderColor='border-tertiary' />

              <Box paddingInline={400}>

                {queArr && queArr.map((data, i) => {
                  return (
                    <div key={i} id='cursorPoint' onClick={() => changeNameValue({ tabEvent: data.field })}>
                      <Box paddingBlock={300}>
                        <InlineStack wrap={false} blockAlign='center' align='space-between'>
                          <Text variant='headingSm' as='p' fontWeight='medium'>{data.question}</Text>
                          <Box><Icon source={data.field === state.tabEvent ? ChevronUpIcon : ChevronDownIcon}></Icon></Box>
                        </InlineStack>
                        <Collapsible open={data.field === state.tabEvent} id="db_collaspe"
                          transition={{ duration: '300ms', timingFunction: 'ease-in' }}>
                          <Box paddingBlockStart={100}>
                            <Text variant='headingSm' as='p' fontWeight='regular'  tone='subdued'>{data.answer}</Text>
                          </Box>
                        </Collapsible>
                      </Box>
                      {i !== queArr.length - 1 && <Divider />}
                    </div>
                  )
                })}

              </Box>
            </Card>
          </Box>
        </BlockStack>
      </Box>

    </Page>
  );
}

export default Main;