import { Text, Button, ButtonGroup, InlineStack, Card, Badge, Select, Icon, InlineGrid, Box, BlockStack, Modal, List, Divider } from '@shopify/polaris';
import { ArrowLeftIcon, CheckIcon } from '@shopify/polaris-icons';
import { onboard_step_6 } from '../../img';
import Import from '../../containers/Import/Import';

const BoardingStep6 = ({ props }) => {

  return (
    <Box>
      <Box paddingBlockEnd={800}>
        <InlineGrid gap="800" columns={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
          <div className='border-right'>
            <div className='onboard-image'>
              <div>
                <img src={onboard_step_6} width='100%' height='100%' alt="onboard" />
              </div>
            </div>
          </div>
          <div>
            <BlockStack gap={{ xl: 1000, lg: 600, md: 600, sm: 800, xs: 800 }}>
              <Text as="h6" variant='bodyLg' fontWeight='semibold'>Step 6 / 6</Text>
              <Box paddingBlockEnd={400}>
                <Text variant="headingLg" as="h5" fontWeight='medium'>Collect new reviews & import existing reviews</Text>
              </Box>
            </BlockStack>
            <div className='ob_import_bg'>
              <BlockStack gap={300}>
                <Text as="h3" variant="headingSm" fontWeight="medium">
                  Got existing reviews?
                </Text>
                <List type="bullet">
                  <List.Item>Good news! You can move these reviews over by importing a CSV file.</List.Item>
                </List>
                <Box paddingBlockEnd={200}>
                  <Button id='primary_btn' variant="primary" onClick={() => props.openCloseModal('importCsvOpen', props.state.importCsvOpen, 'open')}>Import CSV</Button>
                </Box>
              </BlockStack>
            </div>
          </div>
        </InlineGrid>
      </Box>
      <Divider />
      <Box paddingBlockStart={500}>
        <InlineStack align="space-between">
          <Button id='plain_clr' variant={'plain'} icon={ArrowLeftIcon} onClick={() => props.handleNextStep('step5', 'Back_Step6')}>Back</Button>
          <ButtonGroup>
            <Button variant={'plain'} onClick={() => props.handleNextStep('dashboard', 'Skip_Step6')}>Skip</Button>
            <Button id='primry_clr' variant='primary' onClick={() => props.handleNextStep('dashboard', 'Step6')}>Save & Continue</Button>
          </ButtonGroup>
        </InlineStack>
      </Box>

      {/* import csv modal */}
      <Modal
        open={props.state.importCsvOpen}
        onClose={(e) => props.openCloseModal('importCsvOpen', props.state.importCsvOpen, e)}
        title={<Text variant='headingMd' fontWeight='bold'>Import Review</Text>}>
        <Modal.Section>
          <Import />
        </Modal.Section>
      </Modal>
    </Box>
  );
}

export default BoardingStep6;