import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const tagList = createSlice({
  name: 'tagList',
  initialState,
  reducers: {
    getTagList: (state, action) => { },
    getTagListSuccess: (state, action) => {
      return { ...state, TagList: action.payload };
    },
    createTagList: (state, action) => { },
    updateTagList: (state, action) => { }
  }
});


export const {
  getTagList,
  getTagListSuccess,
  createTagList,
  updateTagList
} = tagList.actions;



export default tagList.reducer;