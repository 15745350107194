import { combineReducers } from 'redux';
import loading from './loading';
import auth from './auth';
import product from './product';
import customer from './customer';
import groupProduct from './groupProduct';
import integration from './integration';
import incentive from './incentive';
import toast from './toast';
import reviewWidget from './reviewWidget';
import master from './master';
import moderate from './moderate';
import importPage from './import';
import email from './email';
import analytics from './analytics';
import branding from './branding';
import plan from './plan';
import setting from './setting';
import ugc from './ugc';
import customQuestion from './customQuestion';
import upsell from './upsell';
import queAns from './queAns';
import currentQueue from './currentQueue';
import login from './login';
import tagList from './tagList';
import myprofile from './myprofile';

const reducer = combineReducers({
  loading,
  auth,
  product,
  customer,
  groupProduct,
  integration,
  incentive,
  toast,
  reviewWidget,
  master,
  moderate,
  importPage,
  email,
  analytics,
  branding,
  plan,
  setting,
  ugc,
  customQuestion,
  upsell,
  queAns,
  currentQueue,
  login,
  tagList,
  myprofile,
});

export default reducer;
