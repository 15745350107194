import { useCallback, useEffect, useState } from "react";
import { WixPlanCard } from "../../components";
import { useDispatch, useSelector } from 'react-redux';
import * as planDucks from '../../ducks/plan';
import * as authDucks from '../../ducks/auth';
const moment = require('moment');

const WixPlan = () => {

  const dispatch = useDispatch();

  const [state, setState] = useState({
    planOpen: false,
    planList: [],
    pn: '',
    pt: '',
    billingPrc: '',
    billingDate: '',
    uaq: '',
    uuq: '',
    pid: '',
    cid: '',
    billingPi: '',
    resetDate: '',
    firstTabCollapse: true,
    secondTabCollapse: false,
    thirdTabCollapse: false,
    fourthTabCollapse: false,
    fifthTabCollapse: false,
    selEssOption: '',
    selProOption: '',
    selGrowthOption: '',
    selexclusiveOption: '',
    essentialOptions: [],
    proOptions: [],
    growthOptions: [],
    exclusiveOptions: [],
    searchParam: '',
    tabEvent: 'firstTabCollapse'
  });

  // useEffect(() => {
  //   dispatch(authDucks.getProfile());
  // }, []);

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, []);

  const planState = useSelector(state => state.plan);
  const profileState = useSelector(state => state.auth.profile);

  let planList = planState && planState.wixPlan || [];

  useEffect(() => {
    dispatch(planDucks.getWixPlan());
  }, [])

  useEffect(() => {

    if (profileState) {

      var nxtbDate = new Date(profileState.nxtb);
      let obj = {
        billingDate: nxtbDate ? moment(nxtbDate).format('MMM DD, YYYY') : '-',
        uaq: profileState.uaq ? profileState.uaq : 0,
        uuq: profileState.uuq ? profileState.uuq : 0,
        cid: profileState.billing ? profileState.billing.cid : '-'
      }
      changeNameValue(obj);

      if (profileState.billing && profileState.billing.pobj) {
        let obj = {
          pn: profileState.billing.pobj.pn ? profileState.billing.pobj.pn : '-',
          pt: profileState.billing.pobj.pt ? profileState.billing.pobj.pt : '-',
          billingPrc: profileState.billing.pobj.prc ? profileState.billing.pobj.prc : 0,
        }
        changeNameValue(obj);
      }
    }
  }, [profileState, profileState?.billing, profileState?.billing?.pobj]);

  useEffect(() => {
    if (planList && planList.length > 0) {
      planList = [...planList].sort((a, b) => a.tprc - b.tprc);

      const EssOptiondata = [];
      const ProOptiondata = [];
      const GrowthOptiondata = [];
      const exclusiveOptiondata = [];

      planList.forEach((data) => {
        if (data.ctg.includes('essentials')) {
          EssOptiondata.push(data);
        } else if (data.ctg.includes('pro')) {
          ProOptiondata.push(data);
        } else if (data.ctg.includes('growth')) {
          GrowthOptiondata.push(data);
        } else if (data.ctg.includes('exclusive')) {
          exclusiveOptiondata.push(data);
        }
      });

      let obj = {
        planList,
        essentialOptions: EssOptiondata,
        proOptions: ProOptiondata,
        growthOptions: GrowthOptiondata,
        exclusiveOptions: exclusiveOptiondata
      }
      changeNameValue(obj);
    }
  }, [planList]);

  useEffect(() => {
    const defaultPlanOptions = [
      { option: 'selEssOption', optionsArray: state.essentialOptions, orderVal: 'duaq', prcVal: 'essentialPrc', pidVal: 'pid' },
      { option: 'selProOption', optionsArray: state.proOptions, orderVal: 'duaq', prcVal: 'proPrc', pidVal: 'pid' },
      { option: 'selGrowthOption', optionsArray: state.growthOptions, orderVal: 'duaq', prcVal: 'growthPrc' },
      { option: 'selexclusiveOption', optionsArray: state.exclusiveOptions, orderVal: 'duaq', prcVal: 'exclusivePrc' }
    ];

    defaultPlanOptions.forEach(({ option, optionsArray, orderVal, prcVal, pidVal }) => {
      if (!state[option] && optionsArray && optionsArray.length > 0) {
        let obj = {
          [option]: optionsArray[0][orderVal],
          [prcVal]: optionsArray[0].prc
        }
        changeNameValue(obj);
        // if (option === 'selEssOption' && pidVal) {
        //   changeNameValue({ 'pid': optionsArray[0].pid });
        // }
      }
    });
  }, [state.selEssOption, state.essentialOptions, state.essentialPrc, state.selProOption, state.proOptions, state.proPrc]);

  const handlePlanUpgrade = (type) => {
    changeNameValue({ planOpen: true })

    let _selectedPlanId = state.pid;
    if (type === 'ess' && (!_selectedPlanId || _selectedPlanId.indexOf('essentials') === -1)) {
      _selectedPlanId = state.essentialOptions[0].pid;
    } else if (type === 'pro' && (!_selectedPlanId || _selectedPlanId.indexOf('pro') === -1)) {
      _selectedPlanId = state.proOptions[0].pid;
    } else if (type === 'exu' && (!_selectedPlanId || _selectedPlanId.indexOf('exclusive') === -1)) {
      _selectedPlanId = state.exclusiveOptions[0].pid;
    }
    let obj = {
      // shop: localStorage.getItem('shp'),
      planId: _selectedPlanId
    }

    dispatch(planDucks.wixCheckOut(obj));
    dispatch(authDucks.getProfile());
  }

  const handleCloseModal = () => {
    changeNameValue({ planOpen: false });
  }

  return (
    <WixPlanCard
      state={state}
      changeNameValue={changeNameValue}
      handlePlanUpgrade={handlePlanUpgrade}
      handleCloseModal={handleCloseModal}
    />
  );
}

export default WixPlan;