import React, { useEffect, useState } from 'react'
import { TagListCard } from "../../components";
import { useDispatch, useSelector } from 'react-redux';
import * as tagListDucks from "../../ducks/tagList";
import SimpleReactValidator from "simple-react-validator";
import { useIndexResourceState } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';


function TagList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validator = new SimpleReactValidator({ locale: 'en' });

  let num = 1,
    pageNumber = 0,
    getTagList = [];

  const [state, setState] = useState({
    statusTabs: [ // tabs
      { label: 'All', value: 'All' },
      { label: 'Publish', value: true },
      { label: 'Unpublish', value: false },
    ],
    selected: 0,
    searchText: '',
    paggiActive: 1,
    tagStatus: 'All',
    getTagList: [],
    selectedTagId: '',
    tagName: '',
    tagListAdd: false,
    tagListDelete: false,
    tagListUpdate: false,
    tagListDeleteOpen: false,
    errMessage: {}
  });

  const changeNameValue = (e) => {
    setState((prevState) => ({
      ...prevState, ...e
    }));
  };

  const openCloseModal = (name, value, action) => {
    setState({ ...state, [name]: action === 'open' ? true : false });
  };

  const callGetTagApi = () => {
    const obj = {
      status: state.tagStatus === 'All' ? '' : state.tagStatus,
      num: state.paggiActive,
      stxt: state.searchText,
    }
    dispatch(tagListDucks.getTagList(obj));
    global.modeObj = obj;
  };

  //Get Tag Api Call
  useEffect(() => {
    if (state.searchText) {
      let queryTimer = setTimeout(() => { callGetTagApi(); }, 1000);
      return () => clearTimeout(queryTimer);
    }
    else {
      callGetTagApi();
    }
  }, [state.selected, state.paggiActive, state.searchText]);

  //Get Tag List Data
  getTagList = useSelector((state) => state.tagList.TagList);

  //selected resources all selected
  let Tdata = [];
  Tdata = state.getTagList && state.getTagList.length > 0 && state.getTagList.map(x => ({ ...x, id: x._id }));
  const { selectedResources, handleSelectionChange } = useIndexResourceState(Tdata);

  //Tab Change
  const tabs = state.statusTabs.map((item, i) => ({
    key: i,
    id: `${item.label}-${i}`,
    content: item.label,
    onAction: () => tagStatusClick(item.value)
  }));

  const tagStatusClick = (typ) => {
    let stateObj = {
      tagStatus: typ,
      paggiActive: 1
    }
    setState((prevState) => ({ ...prevState, ...stateObj }));
  }

  //Paggination
  const onPaggiCall = (i) => {
    if (i === '+1') {
      num = state.paggiActive + 1;
    } else if (i === '-1') {
      num = state.paggiActive - 1;
    } else
      num = i;
    changeNameValue({ 'paggiActive': num });
  }

  if (getTagList && getTagList.data.length > 0) {
    pageNumber = getTagList.total / 20;
    pageNumber = parseInt(Math.ceil(pageNumber));
  }

  //Get Tag List Data Set
  useEffect(() => {
    if (getTagList) {
      changeNameValue({ getTagList: getTagList.data });
      getTagList = getTagList.data;
    }
  }, [getTagList]);

  //handle add tag button
  const handleAddTagBtn = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ 'errMessage': validator.errorMessages });
    } else {
      const obj = {
        tg: state.tagName,
        ia: state.tagStatus,
        shp: localStorage.getItem('shp')
      }
      dispatch(tagListDucks.createTagList(obj));
      changeNameValue({ tagListAdd: false });
    }
  }

  //handle delete button
  const handleDeleteBtn = (id, isDeleteOpen) => {
    if (isDeleteOpen) {
      const obj = {
        arr_id: selectedResources,
        typ: "delete"
      }
      dispatch(tagListDucks.updateTagList(obj));
    }
    else {
      const obj = {
        arr_id: [id],
        typ: "delete"
      }
      dispatch(tagListDucks.updateTagList(obj));
    }
    changeNameValue({ tagListDelete: false });
    selectedResources.splice(0, selectedResources.length);
  }

  //handle update button
  const handleUpdateBtn = (id) => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ 'errMessage': validator.errorMessages });
    } else {
      const obj = {
        tg: state.tagName,
        ia: state.tagStatus,
        _id: id,
        shp: localStorage.getItem('shp')
      }
      dispatch(tagListDucks.updateTagList(obj));
      changeNameValue({ tagListUpdate: false });
      selectedResources.splice(0, selectedResources.length);

    }
  }

  //handle Selected Tag Id
  const actionListClick = (value, typ) => {
    if (typ === 'update') {
      const obj = {
        arr_id: selectedResources,
        ia: value,
        shp: localStorage.getItem('shp'),
        typ: "update"
      }
      dispatch(tagListDucks.updateTagList(obj));
      changeNameValue({ tagListUpdate: false });
    };
    selectedResources.splice(0, selectedResources.length);
  }

  //handle back to main
  const handleBackToMain = () => {
    navigate('/setting');
  }

  return (
    <TagListCard
      state={state}
      tabs={tabs}
      validator={validator}
      isShwBack={props.isShwBack}
      pageNumber={pageNumber}
      onPaggiCall={onPaggiCall}
      selectedResources={selectedResources}
      handleSelectionChange={handleSelectionChange}
      handleAddTagBtn={handleAddTagBtn}
      handleDeleteBtn={handleDeleteBtn}
      handleUpdateBtn={handleUpdateBtn}
      openCloseModal={openCloseModal}
      changeNameValue={changeNameValue}
      actionListClick={actionListClick}
      handleBackToMain={handleBackToMain}
    />
  )
}

export default TagList