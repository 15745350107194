import { Button, Divider, Card, Modal, TextField, Sheet, Grid, Scrollable, InlineStack, Box, Text } from '@shopify/polaris';
import { XIcon } from '@shopify/polaris-icons';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import EditFields from './EditFields';
import * as emailDucks from '../../ducks/email';
import PreviewCard from './Preview';
// import * as brandingDucks from '../../ducks/branding';

const EmlTempSidebar = (props) => {
  let { state, changeNameValue, openCloseModal, handleTextChange, handleTestEmail, isShwTestEml, SaveClkCallApi } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(brandingDucks.getBranding());
    changeNameValue({ emailTest: localStorage.getItem('e') });
  }, []);

  const callResetApi = () => {
    changeNameValue({ isEdit: true });

    if (state.emailMSTArr && state.emailMSTArr.length > 0) {
      state.emailMSTArr && state.emailMSTArr.length > 0 &&
        state.emailMSTArr.filter(data => {
          if (data.typ === 'one_time_campaign') {
            if (data.sbtyp === state.sbtyp) {

              let config = {
                dd: data.dd,
                sbjct: data.sbjct,
                msg: data.msg,
                ...(state?.emailObj?.lgurl && { lgurl: state.emailObj.lgurl }), // for not reseting logo field
                ...(data.irttl && { irttl: data.irttl }),
                ...(data.rtngtxt && { rtngtxt: data.rtngtxt }),
                ...(data.rttltxt && { rttltxt: data.rttltxt }),
                ...(data.irdsc && { irdsc: data.irdsc }),
                ...(data.rdsctxt && { rdsctxt: data.rdsctxt }),
                ...(data.rbtntxt && { rbtntxt: data.rbtntxt }),
                ...(data.clstmnt && { clstmnt: data.clstmnt }),
              }

              let stateObj = {
                emailObj: config,
                dn: data.dn,
                ...(data.htmltmpl && { htmltmpl: data.htmltmpl }),
                ...(data.htmlcrd && { htmlcrd: data.htmlcrd })
              };
              changeNameValue(stateObj);
            }
          }
        });
    }
    openCloseModal('resetOpen', state.resetOpen, 'close');
  }

  const handleDiscrdChange = () => {
    state?.emailSeqArr?.length > 0 && state.emailSeqArr.filter((data) => {
      if (data.typ === 'one_time_campaign') {
        if (data.sbtyp === state.sbtyp) {
          let obj = {
            dd: data.dd,
            sbjct: data.sbjct,
            msg: data.msg,
            lgurl: data.lgurl,
            irttl: data.irttl,
            rtngtxt: data.rtngtxt,
            rttltxt: data.rttltxt,
            irdsc: data.irdsc,
            rdsctxt: data.rdsctxt,
            rbtntxt: data.rbtntxt,
            clstmnt: data.clstmnt
          }
          changeNameValue({ emailObj: obj });
        }
      }
    });
    changeNameValue({ isEdit: false });
  }

  const sendTestEmailModal = () => {
    openCloseModal('testEmailOpen', state.testEmailOpen, 'open');
    changeNameValue({ emailErr: false });
  }

  const saveSequnceTab = () => {
    changeNameValue({ isEdit: false, tempSidebarOpen: false, isSaveMnualTemp: true });

    if (SaveClkCallApi) {
      let obj = state.emailObj;
      obj['_id'] = state.proIdUpd;
      dispatch(emailDucks.updateSequence(obj));
    }
  }

  return (
    <div>
      <Sheet
        open={state.tempSidebarOpen}
        onClose={() => changeNameValue({ tempSidebarOpen: false })}>

        <div style={{ height: '100%' }}>
          <Box padding={400}>
            <InlineStack align='space-between'>
              <Text variant="headingMd" as="h2"> Update email template </Text>

              <InlineStack gap={200}>
                {isShwTestEml &&
                  <Button variant='primary' tone='success' onClick={() => sendTestEmailModal()}>Send test email</Button>}
                <Button accessibilityLabel="Cancel" icon={XIcon} onClick={() => changeNameValue({ tempSidebarOpen: false })} variant="plain" />
              </InlineStack>
            </InlineStack>
          </Box>
          <Divider borderColor='bg-fill-tertiary' />
          <Scrollable style={{ padding: '1rem', height: '100%', background: '#f1f1f1' }}>

            <div className='Seq_Edit container-custom'>
              <Box paddingInlineStart={200} paddingBlockEnd={200}>
                <Grid>
                  <Grid.Cell columnSpan={{ xl: 5, lg: 5, md: 6, sm: 6, xs: 6 }}>
                    <Card>
                      <Text variant='headingMd' as='h3' fontWeight='medium'>Template type</Text>
                      <Box paddingBlockStart={200} paddingBlockEnd={400}>
                        <Text id='text_capitalize'>{state.sbtyp} Product Review Email
                          {state.emlTypName && <span> ({state.emlTypName})</span>}</Text>
                      </Box> <Divider borderColor='border-tertiary' />

                      <EditFields
                        state={state}
                        saveSequnceTab={saveSequnceTab}
                        handleTextChange={handleTextChange}
                        handleDiscrdChange={handleDiscrdChange} />
                    </Card>
                  </Grid.Cell>

                  <Grid.Cell columnSpan={{ xl: 7, lg: 7, md: 6, sm: 6, xs: 6 }}> {/* Preview */}
                    <PreviewCard
                      state={state}
                      changeNameValue={changeNameValue}
                      openCloseModal={openCloseModal}
                      callResetApi={callResetApi} />
                  </Grid.Cell>

                </Grid>
              </Box>
            </div>
          </Scrollable>
        </div>
      </Sheet>

      <Modal  //send test email model
        size='small'
        open={state.testEmailOpen}
        onClose={(e) => openCloseModal('testEmailOpen', state.testEmailOpen, e)}
        title={<Text variant='headingMd' as='h5' fontWeight='bold'>Send test email</Text>}>
        <Modal.Section>
          <Box paddingBlockEnd={600}>
            <TextField
              label="Enter email address where you want to receive email"
              value={state.emailTest}
              onChange={(e) => changeNameValue({ emailTest: e })}
              error={state.emailErr === true ? "Email is required" : false}
              autoComplete="off"
              placeholder='Enter your Email' />
          </Box>
          <Button id='primary_btn' fullWidth onClick={() => handleTestEmail()}>Send</Button>
        </Modal.Section>
      </Modal>
    </div>
  );
}

export default EmlTempSidebar;