// import { fromJS } from 'immutable';

// export const getWidgetList = (data) => ({ type: "GET_WIDGET_LIST", data });
// export const getWidgetListSuccess = (data) => ({ type: "GET_WIDGET_LIST_SUCCESS", data });

// export const createWidget = (data) => ({ type: "CREATE_WIDGET", data });

// export const updateWidget = (data) => ({ type: "UPD_WIDGET", data });

// const initialState = fromJS({

// });

// function reviewWidget(state = initialState, action) {
//   switch (action.type) {

//     case "GET_WIDGET_LIST_SUCCESS":
//       return { ...state, WidgetList: action.data };

//     default:
//       return { ...state };
//   }
// }

// export default reviewWidget;


import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const reviewWidget = createSlice({
  name: 'reviewWidget',
  initialState,
  reducers: {
    getWidgetList: (state, action) => { },
    getWidgetListSuccess: (state, action) => {
      return { ...state, WidgetList: action.payload };
    },
    createWidget: (state, action) => { },
    updateWidget: (state, action) => { },
    deleteWidget: (state, action) => { },
    updWdgtStatus: (state, action) => { },
    getIntgList: (state, action) => { },
    getIntgListSuccess: (state, action) => {
      return { ...state, InteList: action.payload };
    },
    addDataSrc: (state, action) => { },
    delDataSrc: (state, action) => { },
  }
});

export const {
  getWidgetList,
  getWidgetListSuccess,
  createWidget,
  updateWidget,
  deleteWidget,
  updWdgtStatus,
  getIntgList,
  getIntgListSuccess,
  addDataSrc,
  delDataSrc
} = reviewWidget.actions;

export default reviewWidget.reducer;