import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const moderate = createSlice({
  name: 'moderate',
  initialState,
  reducers: {
    getModerateData: (state, action) => { },
    getModerateSuccess: (state, action) => {
      return { ...state, ModerateList: action.payload };
    },
    getProDetail: (state, action) => { },
    getProDetailSuccess: (state, action) => {
      return { ...state, ModeReviewList: action.payload };
    },
    updateReview: (state, action) => { },
    updateRevStatus: (state, action) => { },
    updReviewReply: (state, action) => { },
    delReviewReply: (state, action) => { },

    addTestiMoniClk: (state, action) => { },
    getModerateSetting: (state, action) => { },
    getModerateSettingSuccess: (state, action) => {
      return { ...state, ModeSettingList: action.payload };
    },
    updModerateSetting: (state, action) => { },
    exportReview: (state, action) => { },
    generateReviewReplyAi: (state, action) => { },
    generateReviewReplyAiSuccess: (state, action) => {
      return { ...state, generateReviewReplyAi: action.payload };
    },
    generateReviewAi: (state, action) => { },
    generateReviewAiSuccess: (state, action) => {
      return { ...state, generateReviewAi: action.payload };
    }
  }
});

export const {
  getModerateData,
  getModerateSuccess,
  getProDetail,
  getProDetailSuccess,
  updateRevStatus,
  updateReview,
  updReviewReply,
  delReviewReply,
  addTestiMoniClk,
  getModerateSetting,
  getModerateSettingSuccess,
  updModerateSetting,
  exportReview,
  generateReviewReplyAi,
  generateReviewReplyAiSuccess,
  generateReviewAi,
  generateReviewAiSuccess
} = moderate.actions;

export default moderate.reducer;