import React, { useState, useEffect, useCallback } from 'react';
import { BlockStack, Box, Button, Card, IndexTable, InlineStack, Page, Text, Autocomplete, Tooltip, Banner, Icon, Modal, Divider, PageActions, Popover, SkeletonTabs } from '@shopify/polaris';
import { ArrowDiagonalIcon, DeleteIcon, SearchIcon, ChevronDownIcon, RefreshIcon } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as integrationDucks from '../../ducks/integration';

import moment from 'moment';
// import IntegrationHeader from './IntegrationHeader';
import { useNavigate } from 'react-router-dom';
import { Redirect } from '@shopify/app-bridge/actions';
import { createApp } from '@shopify/app-bridge';
// import { skeleton } from '../../utils/skeleton';
// import { useLanguage } from '../../LanguageContext';

function GoogleMyBusiness({ props }) {
  const dispatch = useDispatch();
  const { state, handleBackToMain } = props;
  const navigate = useNavigate();
  // const { translate } = useLanguage();

  const [value, setValue] = useState({
    intDataList: [],
    ac: '',
    rmk: '',
    errMessage: {},
    deleteModal: false,
    dataId: '',
    selected: [],
    activePopover: null,
    delSubTyp: false,
    delSubTypId: '',
    notifListData: [],
    searchGB: []
  });

  const changeValue = ((obj) => {
    setValue((prevValue) => ({ ...prevValue, ...obj }))
  })

  let intDataList = useSelector(state => state.integration?.gmbData);
  let isLoading = useSelector(state => state?.loading?.isLoading);//loading(skleton)

  useEffect(() => {
    if (Array.isArray(intDataList)) {
      changeValue({ intDataList: intDataList })
    }
    else {
      changeValue({ intDataList: [intDataList] })
    }
  }, [intDataList])

  useEffect(() => {
    dispatch(integrationDucks.getGMB());
  }, [])

  const handleupdActive = (_id, active) => {
    const obj = {
      id: _id,
      ia: active
    }
    dispatch(integrationDucks.updateGmbStatus(obj))
    const filterData = value.intDataList?.map((elm) => {
      if (elm._id === _id) {
        return { ...elm, ia: active };
      }
      return elm;
    });
    changeValue({ intDataList: filterData })
    // intDataList = filterData;
  }

  //openCloseModal 
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeValue({ [name]: !value });
    }
  }, []);

  const handleDeleteData = (id, delSub, delSubId) => {
    openCloseModal('deleteModal', value.deleteModal, 'open');
    changeValue({ dataId: id, delSubTyp: delSub, delSubTypId: delSubId });
  }

  //delete api call 
  const handleDeleteCall = (id) => {
    if (value.delSubTyp === false) {
      dispatch(integrationDucks.deleteGMB(id));
    }
    else {
      const obj = { ...value.delSubTypId, _id: value.dataId }
      dispatch(integrationDucks.deletePage(obj));
    }
    openCloseModal('deleteModal', value.deleteModal, 'close');
  }

  const handleHowToConn = () => {
    // if (props.isWhite === false) {
    //   state.hpul ? fnRedirect(state?.hpul) : fnRedirect(state?.hpulpxl);
    // }
  }

  //Sync latest google page
  const syncGooglePage = (id) => {
    dispatch(integrationDucks.syncGooglePage(id));
  }

  //handle toggle
  const handleToggle = (id) => {
    changeValue({ activePopover: value.activePopover === id ? null : id });
    setTimeout(() => {
      const input = document.getElementById('gblist');
      if (input) {
        input.focus(); // Focus the input field
      }
      else {
        console.warn('input not found');
      }
    }, 10);
  };

  // login with google
  const redirectGGL = () => {
    const url = window.location.href.replace(/&/gi, '@');
    const cleanUrl = url.split('googlemybusiness')[0] + 'googlemybusiness';
    let objStateGGL = {
      uk: props.profile._id,
      ti: props.profile.ti,
      uri: cleanUrl,
      path: window.location.pathname,
    };
    //  uri: window.location.href.replace(/&/gi, '@'),//window.location.href,
    let authURL = 'https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/business.manage&access_type=offline&prompt=consent&include_granted_scopes=true&response_type=code&state=' + JSON.stringify(objStateGGL) + '&redirect_uri=' + process.env.REACT_APP_API_URL + '/gmb/oauth&client_id=587152220735-k6b4ir39gvk5ji7pmnedntil7slnr56f.apps.googleusercontent.com';

    //check if shopify embed, then redirect to shopify app, else open in new tab
    if (localStorage.getItem('is_shopify_embed')) {
      // ref: https://shopify.dev/docs/api/app-bridge/previous-versions/actions/navigation/redirect-navigate
      const strObjConfig = localStorage.getItem('store_config');
      const appShopify = createApp(JSON.parse(strObjConfig));
      const redirect = Redirect.create(appShopify);
      redirect.dispatch(Redirect.Action.REMOTE, authURL);
    }
    else {
      window.open(authURL, '_self');
    }
  };

  //handle select list 
  const handleAddSelect = (obj, id, e) => {
    const objData = obj.filter(x => x.locationName === e[0]);
    let data = objData[0];
    const valObj = { ...data, _id: id };
    dispatch(integrationDucks.createGMBPage(valObj));
  }

  //search field autocomplete
  const textField = (
    <Autocomplete.TextField
      onChange={(e) => changeValue({ searchGB: e })}
      value={value.searchGB}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder="Search Page ToolTip"
      autoComplete="off"
      label="Page List"
      id='gblist'
    />
  );
  const rowMarkup =
    isLoading ?
      <>{
        Array.from(Array(4)).map((_, i) => (<IndexTable.Row>
          <IndexTable.Cell><SkeletonTabs count={1} /></IndexTable.Cell>
          <IndexTable.Cell><SkeletonTabs count={1} /></IndexTable.Cell>
          <IndexTable.Cell><SkeletonTabs count={1} /></IndexTable.Cell>
          <IndexTable.Cell></IndexTable.Cell>
          <IndexTable.Cell></IndexTable.Cell>
          <IndexTable.Cell><SkeletonTabs count={1} /></IndexTable.Cell>
          <IndexTable.Cell><SkeletonTabs count={1} /></IndexTable.Cell>
        </IndexTable.Row>))
      }
      </>
      :
      value.intDataList && value.intDataList.length > 0 && value.intDataList?.map(
        (
          usInt,
          index,
        ) => {

          return (<>
            <IndexTable.Row
              id={usInt?._id}
              key={index}
              position={index}
            >
              <IndexTable.Cell>
                <Text variant="bodyMd" fontWeight="bold" as="span">
                  {index + 1}
                </Text>
              </IndexTable.Cell>
              <IndexTable.Cell>{usInt?.d?.acn}</IndexTable.Cell>
              <IndexTable.Cell>{moment(new Date(usInt?.cdt)).format('MMM-DD-YYYY hh:mm:ss a')}</IndexTable.Cell>
              <IndexTable.Cell></IndexTable.Cell>
              <IndexTable.Cell></IndexTable.Cell>

              <IndexTable.Cell> <label className="switch">
                <input type="checkbox" checked={usInt?.ia}
                  onChange={() => handleupdActive(usInt._id, !usInt.ia)} />
                <span className="switch-label"></span>
                <span className="switch-handle"></span>
              </label></IndexTable.Cell>

              <IndexTable.Cell>
                <div className='google_mainDiv'>
                  {/* syncAweber btn */}
                  <Box paddingInline={100}>
                    <Tooltip content="SyncList" padding='400' width='wide' dismissOnMouseOut>
                      <Button icon={RefreshIcon} variant='monochromePlain' onClick={() => syncGooglePage(usInt._id)}></Button>
                    </Tooltip>
                  </Box>

                  {/* Add list */}
                  {
                    // props.checkPermission('integration', 'isadd', props.profile) &&
                    <Box paddingInline={200}>
                      <Popover
                        active={value.activePopover === index}
                        activator={
                          <Button
                            onClick={() => {
                              handleToggle(index);
                            }}
                          >
                            <InlineStack>
                              <div className='add-events'>Add List</div>
                              <Icon
                                source={ChevronDownIcon}
                                tone="base"
                              />
                            </InlineStack>
                          </Button>
                        }
                        onClose={() => changeValue({ activePopover: null })}
                      >
                        {usInt && usInt.d && usInt.d.lc?.length > 0 && usInt.d.lc.filter(x => !x.ia)?.length > 0 ? (
                          <Card>
                            <div className='addlist-modal'>
                              <Autocomplete
                                options={usInt.d.lc.filter(x => !x.ia).filter(obj => {
                                  let regexStr = new RegExp(value.searchGB, 'i');
                                  return !value.searchGB || obj.locationName.search(regexStr) !== -1;
                                })?.map(obj => ({
                                  value: obj.locationName, label: (
                                    <div className='addlist-item-block'>
                                      <div>
                                        <Text variant="bodyMd">{obj.locationName}</Text>
                                        {obj.adrs && <Text variant="bodyXs"> ({obj.adrs})</Text>}
                                      </div>
                                    </div>
                                  )
                                }))}
                                selected={value.searchGB}
                                onSelect={(e) => handleAddSelect(usInt.d.lc.filter(x => !x.ia)?.map(obj => (obj)), usInt._id, e)}
                                textField={textField}
                              />
                            </div>
                          </Card>
                        ) : (
                          <Box padding={400}>
                            NoRecord
                          </Box>
                        )
                        }
                      </Popover>
                    </Box>
                  }

                  {/* delete btn */}
                  {
                    // props.checkPermission('integration', 'isdelete', props.profile) &&
                    <div id="delete" onClick={() => handleDeleteData(usInt._id, false)}>
                      <Banner hideIcon tone='critical'>
                        <Icon
                          source={DeleteIcon}
                          tone="critical"
                        />
                      </Banner>
                    </div>
                  }
                </div>
              </IndexTable.Cell>
            </IndexTable.Row>
            {usInt && usInt?.d && usInt?.d?.lc?.length > 0 &&
              usInt.d.lc.filter(x => x.ia === true)?.length > 0 &&
              <IndexTable.Row >
                <IndexTable.Cell></IndexTable.Cell>
                <IndexTable.Cell>
                  <Text variant="bodyMd" fontWeight="bold" as="span">
                    #
                  </Text>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Text variant="bodyMd" fontWeight="bold" as="span">PageId</Text>
                </IndexTable.Cell>
                <IndexTable.Cell><Text variant="bodyMd" fontWeight="bold" as="span">Page Name</Text></IndexTable.Cell>
                <IndexTable.Cell><Text variant="bodyMd" fontWeight="bold" as="span"> Average rating </Text></IndexTable.Cell>
                <IndexTable.Cell><Text variant="bodyMd" fontWeight="bold" as="span">Rating count</Text></IndexTable.Cell>
                <IndexTable.Cell>
                </IndexTable.Cell>
              </IndexTable.Row>}

            {usInt && usInt?.d && usInt.d?.lc?.length > 0 &&
              usInt.d?.lc.filter(x => x.ia === true)?.length > 0 ? usInt?.d?.lc.filter(x => x.ia === true)?.map((GMBObj, i) => (
                <IndexTable.Row >
                  <IndexTable.Cell></IndexTable.Cell>
                  <IndexTable.Cell>
                    <Text>
                      {i + 1}
                    </Text>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <Tooltip content={GMBObj?.name} padding='400' width='wide' dismissOnMouseOut>
                      <Text as="h6">{GMBObj?.name ? GMBObj?.name.length > 15 ? GMBObj?.name.slice(0, 15) + '...' : GMBObj?.name : '-'}</Text>
                    </Tooltip>
                  </IndexTable.Cell>
                  <IndexTable.Cell>{GMBObj.locationName ? GMBObj.locationName.length > 13 ? GMBObj.locationName.slice(0, 13) + '...' : GMBObj.locationName : '-'}</IndexTable.Cell>
                  <IndexTable.Cell>{GMBObj.avgRating ? GMBObj.avgRating.toFixed(1) : 0}</IndexTable.Cell>
                  <IndexTable.Cell>{GMBObj.ratingCount ? GMBObj.ratingCount : 0}</IndexTable.Cell>
                  <IndexTable.Cell>
                    {
                      // props.checkPermission('integration', 'isdelete', props.profile) &&
                      <div id="delete" onClick={() => handleDeleteData(usInt._id, true, GMBObj)}>
                        <Banner hideIcon tone="critical">
                          <Icon source={DeleteIcon} tone="critical" />
                        </Banner>
                      </div>
                    }
                  </IndexTable.Cell>
                </IndexTable.Row >
              )) :
              ''
            }
          </>
          )
        },
      );

  const fnRedirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Page title={
      <div className='app-inner-box'>
        {/* <img src={state.img} alt='' className='app-inner-img' /> */}
        <span>
          Google My Business
        </span>
      </div>
    }
      backAction={{ onAction: () => handleBackToMain() }}
      primaryAction={props.isWhite === false ? <Button icon={ArrowDiagonalIcon} onClick={() => handleHowToConn()}>How to connect</Button> : <></>}>
      <BlockStack gap={200}>
        {/* <IntegrationHeader
          fnRedirect={fnRedirect}
          isWhite={props.isWhite}
          data={state}
        /> */}
        <Card>
          <Box padding={400}>
            <InlineStack align='space-between'>
              <Box paddingBlockEnd={200} paddingBlockStart={100}><Text variant="headingMd" fontWeight='bold' as="h6">Account details</Text></Box>
              {
                // props.checkPermission('integration', 'isadd', props.profile) &&
                <Box align='end' paddingInlineEnd={100} paddingBlockEnd={200}>
                  <Button id='login-btn-bg' variant='primary' onClick={() => { redirectGGL() }}>Login with google</Button>
                </Box>
              }
            </InlineStack>

            <Card padding={0}>
              <IndexTable
                itemCount={value.intDataList?.length || 0}
                selectable={false}
                headings={[
                  { title: "Table No" },
                  { title: "Account Name" },
                  { title: "Created Date" },
                  { title: '' },
                  { title: '' },
                  { title: "Active" },
                  { title: "Action" }
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </Card>
          </Box>
        </Card>
      </BlockStack>

      {/* delete data modal */}
      <Modal
        open={value.deleteModal}
        title={<Text variant='headingMd' fontWeight='bold'>Delete Record</Text>}
        onClose={(e) => { openCloseModal('deleteModal', value.deleteModal, e); }}>
        <Modal.Section>
          <Box paddingBlockEnd={400}>
            <Text variant="bodyMd" as="p">By clicking yes, you will lose the current record.</Text>
          </Box>
          <Divider />
          <PageActions
            primaryAction={{
              icon: DeleteIcon, content: "Delete", tone: 'critical',
              onAction: () => {
                handleDeleteCall(value.dataId);
              }
            }}
            secondaryActions={[
              {
                content: "Cancel",
                onAction: () => { openCloseModal('deleteModal', value.deleteModal, 'close'); }
              }
            ]}
          />
        </Modal.Section>
      </Modal>
    </Page>
  )
}

export default GoogleMyBusiness;