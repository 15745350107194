import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/plan';
import * as actionProfile from '../ducks/auth';
import { toastify } from '../ducks/toast';

function* getShopifyPlan(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getShopifyPlan', action.data);
    if (res.status === 'success') {
      yield put(actions.getShopifyPlanSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* shopifyPayment(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/shopifyPayment', action.data);
    if (res.status === 'success') {
      if (res.url) window.parent.location.href = res.url;
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* cancelShopifyPlan(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/cancelShopifyPlan', action.data);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actionProfile.getProfile())
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getWixPlan(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, '/getWixPlan', action.data);
    if (res.status === 'success') {
      yield put(actions.getWixPlanSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


function* wixCheckOut(action) {
  try {
    yield put(load());
        
    const res = yield call(api.GET, '/wixCheckOut?planID=' + action.data.planId);
    if (res.status === 'success') {
      window.open(res.data, '_blank');
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetShopifyPlan() {
  yield takeLatest("GET_SHOPIFY_PLAN", getShopifyPlan);
}
export function* watchShopifyPayment() {
  yield takeLatest("SHOPIFY_PAYMENT", shopifyPayment);
}
export function* watchCancelShopifyPlan() {
  yield takeLatest("CANCEL_SHOPIFY_PLAN", cancelShopifyPlan);
}
export function* watchGetWixPlan() {
  yield takeLatest("GET_WIX_PLAN", getWixPlan);
}

export function* watchWixCheckout() {
  yield takeLatest("WIX_CHEKOUT", wixCheckOut);
}
export default function* rootSaga() {
  yield fork(watchGetShopifyPlan);
  yield fork(watchShopifyPayment);
  yield fork(watchCancelShopifyPlan);
  yield fork(watchGetWixPlan);
  yield fork(watchWixCheckout);
}