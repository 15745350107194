import { Badge, Page, Box, Button, Text, BlockStack, Divider, InlineStack, Card, Checkbox, Modal, TextField, Select, Icon, Collapsible, Label, Popover, IndexTable, RadioButton, ButtonGroup, PageActions, ResourceList, Thumbnail, Filters } from '@shopify/polaris';
import { ChevronDownIcon, ChevronUpIcon, DeleteIcon, EditIcon, ImageIcon, PlusCircleIcon, PlusIcon } from '@shopify/polaris-icons';
import { custom_que } from '../../img/index';
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { toastify } from "../../ducks/toast";
import * as CustmQueDucks from '../../ducks/customQuestion';
import SimpleReactValidator from "simple-react-validator";
import { useNavigate } from 'react-router-dom';
import { changePathForShopifyAppEmbed } from "../../utils/utils";
import * as groupDucks from '../../ducks/groupProduct';
import '../../containers/CustomQuestion/CustomQuestion.css';

const CustomQuestion = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validator = new SimpleReactValidator({ locale: 'en' });

  let productNameState = [], newProOpt = [];

  const [state, setState] = useState({
    queList: [],
    queOpen: false, //modal open/close
    editQueOpen: false,
    deleteOpen: false,
    filterCollapseOpen: false,
    iarrcnd: false,
    errMessage: {},
    que: '',
    rmk: '',
    quetyp: 'radio',
    queHide: false,
    dataId: '',
    dataTxt: '',   // condition data
    ctyp: '',  // condition typ
    cnd: '',   // condition
    cndtyp: 2,
    arrcnd: [],
    arrans: [],
    queId: '',
    prodOptions: newProOpt,
    popActive: false,
    productSearchQuery: '',
  });

  const [options, setOptions] = useState([]);

  const queOptions = [
    { label: 'Single choice', value: 'radio' },
    { label: 'Multiple choice', value: 'checkbox' },
    // { label: 'Rating scale', value: 'linear_scale' },
    { label: 'Text', value: 'text' },
    { label: 'Slider', value: 'slider' },
  ];

  const popOpt1 = [
    { label: 'Product', value: 'product_id' },
    { label: 'Title', value: 'product_title' },
    { label: 'Type', value: 'product_type' }
  ];

  const allOptions = [
    { label: 'Is', value: 'is' },
    { label: 'Is not', value: 'isnot' },
    { label: 'Contains', value: 'contains' },
    { label: 'Equals', value: 'equals' },
    { label: 'Does not contain', value: 'notcontain' },
    { label: 'Does not equal', value: 'notequal' }
  ];

  const productOptions = [
    { label: 'Is', value: 'is' },
    { label: 'Is not', value: 'isnot' }
  ];

  const popOpt2 = state.ctyp === 'product_id' ? productOptions : allOptions;

  const getLabel = (val) => {
    let _lbl = <Text fontWeight='medium' tone='subdued'>Select</Text>;
    if (val) {
      popOpt1.map((x) => { if (x.value === val) _lbl = x.label; });
      popOpt2.map((x) => { if (x.value === val) _lbl = x.label; });
    }
    return _lbl;
  }

  const getBadge = (val) => {
    const option = val && queOptions.find(x => x.value === val);
    return option?.label || '';
  }

  useEffect(() => {
    dispatch(CustmQueDucks.getCustmQue());
  }, []);

  //api data
  const questionData = useSelector(state => state.customQuestion.QuestionList);
  const groupState = useSelector(state => state.groupProduct);

  if (groupState.ProductName && groupState.ProductName.data) //product List
    productNameState = groupState.ProductName.data;

  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  useEffect(() => {
    if (questionData) {
      changeNameValue({ 'queList': questionData });
    }
  }, [dispatch, questionData, changeNameValue]);

  const handleAddOption = () => {
    const newOpt = { id: options.length + 1 };
    setOptions([...options, newOpt]);
  };

  const handleRemoveOption = (id) => {
    setOptions(prevOptions => {
      const updatedOptions = [];
      const updatedArrans = [];

      prevOptions.forEach((opt, index) => {
        if (opt.id !== id || opt.id === 1) {
          updatedOptions.push({ ...opt, id: updatedOptions.length + 1 });
          updatedArrans.push(state.arrans[index]);
        }
      });

      changeNameValue({ 'arrans': updatedArrans });
      return updatedOptions;
    });
  };

  const handleChangeQAns = (e, index) => {
    const updatedArrans = [...state.arrans];
    updatedArrans[index] = e;
    const filteredArrans = updatedArrans.filter(value => value !== null && value !== undefined && value !== '');
    changeNameValue({ 'arrans': filteredArrans });
  };

  useEffect(() => {
    if (state.quetyp === 'text' || state.quetyp === 'slider') {
      changeNameValue({ 'queHide': true });
    } else {
      changeNameValue({ 'queHide': false });
    }
  }, [state.quetyp, changeNameValue]);

  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) { //stop closing on outside click 
      changeNameValue({ [name]: !value });
    }
    if (type === 'close') {
      let StateObj = {
        que: '',
        rmk: '',
        quetyp: 'radio',
        arrans: [],
        cndtyp: 2,
        arrcnd: [],
        ireq: false,
        ihdwdgt: false
      }
      changeNameValue(StateObj);
      setOptions([]);
    }
    if (type === 'open') {
      if (state.quetyp === 'radio' || state.quetyp === 'checkbox') {
        setOptions([{ id: 1 }]);
      } else {
        setOptions([]);
      }
    }
  }, [changeNameValue, state.quetyp]);

  const handleConditionAdd = () => {
    if (state.ctyp && state.cnd && state.dataTxt) {
      let obj;
      if (state.ctyp === 'product_id') {
        obj = {
          ctyp: state.ctyp,
          cnd: state.cnd,
          data: state.dataId,
          dataTxt: state.dataTxt
        };
      } else {
        obj = {
          ctyp: state.ctyp,
          cnd: state.cnd,
          data: state.dataTxt
        };
      }
      state.arrcnd.push(obj);
      let stateObj = {
        ctyp: '',
        cnd: '',
        dataId: '',
        dataTxt: '',
      }
      changeNameValue(stateObj);
    }
    else
      dispatch(toastify({ type: 'error', msg: 'Please fill out all the fields' }));
  }

  const handleDelCondition = (data) => {
    const filteredArray = state.arrcnd.filter(item => item !== data);
    changeNameValue({ 'arrcnd': filteredArray });
  }

  const handleCreateCustmQue = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    }
    else {
      changeNameValue({ errMessage: {} });
      let obj = {
        ti: localStorage.getItem('ti'),
        shp: localStorage.getItem('shp'),
        que: state.que,
        rmk: state.rmk,
        quetyp: state.quetyp,
        ireq: state.ireq,
        ihdwdgt: state.ihdwdgt,
        arrans: state.arrans,
        cndtyp: state.cndtyp,
        arrcnd: state.arrcnd
      }
      dispatch(CustmQueDucks.createCustmQue(obj));
      openCloseModal('queOpen', state.queOpen, 'close');
      let StateObj = {
        que: '',
        rmk: '',
        quetyp: 'radio',
        arrans: [],
        cndtyp: '',
        arrcnd: []
      }
      changeNameValue(StateObj);
    }
  }

  const handleUpdateAction = (data) => {
    let obj = {
      que: data.que,
      rmk: data.rmk,
      ireq: data.ireq,
      ihdwdgt: data.ihdwdgt,
      arrans: data.arrans,
      quetyp: data.quetyp,
      cndtyp: data.cndtyp,
      arrcnd: data.arrcnd,
      queId: data._id
    }
    changeNameValue(obj);
    data.arrans && data.arrans.forEach((val, index) => {
      const newOpt = { id: index + 1 };
      setOptions(prevOptions => [...prevOptions, newOpt]);
    });
    if (data.cndtyp === 3) {
      changeNameValue({ 'filterCollapseOpen': true });
    }
    openCloseModal('editQueOpen', state.editQueOpen, 'open');
  }

  const handleUpdateCustmQue = () => {
    let obj = {
      _id: state.queId,
      que: state.que,
      rmk: state.rmk,
      quetyp: state.quetyp,
      ireq: state.ireq,
      ihdwdgt: state.ihdwdgt,
      arrans: state.arrans,
      cndtyp: state.cndtyp,
      arrcnd: state.arrcnd,
    }
    dispatch(CustmQueDucks.updateCustmQue(obj));
    openCloseModal('editQueOpen', state.editQueOpen, 'close');
  }

  const handleUpdateCustmQueStatus = (id, ia) => {
    let obj = {
      _id: id,
      ia: ia,
    }
    dispatch(CustmQueDucks.updateCustmQue(obj));
  }

  const handleDeleteCustmQue = (id) => {
    let obj = {
      _id: id,
    }
    dispatch(CustmQueDucks.deleteCustmQue(obj));
    openCloseModal('deleteOpen', state.deleteOpen, 'close')
  };

  const handleBackToMain = () => {
    if (localStorage.getItem('is_shopify_embed')) {
      let params = new URLSearchParams(window.location.search);
      const _frm = params && params.get('frm') || '';
      let shopiPage = '';

      if (_frm && _frm === "prdct") shopiPage = '/reviewmoderate';
      else shopiPage = '/collectReview';

      changePathForShopifyAppEmbed(shopiPage);
    }
    else navigate(-1);
  }

  useEffect(() => {
    for (let i = 0; i < productNameState.length; i++) {
      const ele = productNameState[i];
      let optArr = {};
      optArr['value'] = ele.pid.toString();
      optArr['label'] = ele.pnm;
      optArr['img'] = ele.pimg;
      newProOpt.push(optArr);
    }
    changeNameValue({ 'prodOptions': newProOpt });
  }, [groupState.ProductName, changeNameValue]);

  const handleSubmit = () => {
    dispatch(groupDucks.getProductName({ title: state.productSearchQuery }));
    changeNameValue({ 'prodOptions': state.prodOptions });
  };

  useEffect(() => {
    if (state.productSearchQuery && state.productSearchQuery.length > 2) {
      let productSearchQuery = setTimeout(() => handleSubmit(), 700);
      return () => clearTimeout(productSearchQuery);
    }
    else
      handleSubmit();
  }, [state.productSearchQuery]);

  const handleSelectProduct = (item) => {
    let obj = {
      dataTxt: item.label,
      dataId: item.value,
      popActive: false
    }
    changeNameValue(obj);
  }

  return (
    <Page
      title={'Custom questions'}
      subtitle={`Add custom questions to your review form to gather more detailed feedback and learn more about your customers, tailoring them to your organization's needs.`}
      secondaryActions={
        state.queList && state.queList.length > 0 ?
          <Button id='primary_btn' icon={PlusCircleIcon} variant='primary' onClick={() => openCloseModal('queOpen', state.queOpen, 'open')}>New Question</Button> : false
      }
      backAction={(props.isShwBack === true) ? { onAction: () => handleBackToMain() } : false}>

      <Box paddingInline={150}>
        {state.queList && state.queList.length > 0 ?
          <Card padding={0}>
            <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
              <Text variant='headingMd' fontWeight='semibold' as='h1'>Question</Text>
            </Box>

            <Divider borderColor='border-tertiary' />
            <Box paddingInline={400} paddingBlockEnd={200} paddingBlockStart={200}>
              {state.queList && state.queList.map((data, i) => {
                return (
                  <div key={i}>
                    <Box paddingBlock={400} paddingInline={100}
                      paddingBlockEnd={i === state.queList.length - 1 && 200}>
                      <InlineStack align="space-between" blockAlign='center'>
                        <InlineStack blockAlign='center'>
                          <Box paddingInlineEnd={400}>
                            <Checkbox
                              checked={data.ia}
                              onChange={() => handleUpdateCustmQueStatus(data._id, !data.ia)}
                            />
                          </Box>
                          <Box paddingBlockEnd={100}>
                            <InlineStack align='start' gap={200}>
                              <Text variant='headingXs' as='span' fontWeight='semibold'>{data.que ? data.que : '-'}</Text>
                              <Badge tone='info'>{getBadge(data.quetyp)}</Badge>
                            </InlineStack>
                            <Box paddingBlockStart={100}>
                              <Text variant='headingXs' as='p' fontWeight='regular' tone='subdued'>{data.rmk ? data.rmk : '-'}</Text>
                            </Box>
                          </Box>
                        </InlineStack>
                        <ButtonGroup noWrap>
                          <Button icon={EditIcon} onClick={() => handleUpdateAction(data)}>Edit</Button>
                          <Button icon={DeleteIcon} variant='primary' tone='critical' onClick={() => { openCloseModal('deleteOpen', state.deleteOpen, 'open'); changeNameValue({ 'queId': data._id }) }}>Delete</Button>
                        </ButtonGroup>
                      </InlineStack>
                    </Box>
                    {i !== state.queList.length - 1 && <Divider />}
                  </div>
                );
              })}
            </Box>
          </Card>
          :
          <Card>
            <Box paddingBlock={1600}>
              <BlockStack gap={400} inlineAlign='center'>
                <img src={custom_que} alt='custom_que' height="224px" />
                <Text variant='headingLg' as='p' fontWeight='semibold'>Add custom questions to your product reviews</Text>
                <Text variant='headingSm' as='p' fontWeight='regular'>Use custom questions to get feedback on specific attributes or learn more your customers.</Text>
                <Box>
                  <Button id='primary_btn' variant='primary' icon={PlusCircleIcon} onClick={() => openCloseModal('queOpen', state.queOpen, 'open')}>Create question</Button>
                </Box>
              </BlockStack>
            </Box>
          </Card>
        }
      </Box>

      <Modal //create custom question modal
        open={state.queOpen}
        onClose={(e) => openCloseModal('queOpen', state.queOpen, e)}>
        <div className="cusm_que_modal">
          <div style={{ backgroundColor: "white", padding: "12px 16px" }}>
            <InlineStack align="space-between" blockAlign='center' gap={400}>
              <Text variant="headingMd" as="h5" fontWeight='bold'>New Question</Text>
            </InlineStack>
          </div>
          <Box padding={400}>
            <div className='queList'>
              <BlockStack gap={400}>
                <Card>
                  <BlockStack gap={400}>
                    <div>
                      <TextField
                        label="Question"
                        value={state.que}
                        onChange={(e) => changeNameValue({ 'que': e })}
                        autoComplete="off"
                        error={state.errMessage.question ? state.errMessage.question : false}
                      />
                      {validator.message('question', state.que, 'required')}
                    </div>
                    {/* <TextField
                      label="Description"
                      value={state.rmk}
                      onChange={(e) => changeNameValue({ 'rmk': e })}
                      autoComplete="off"
                    /> */}
                    <Select
                      label="Question type"
                      options={queOptions}
                      value={state.quetyp}
                      onChange={(e) => {
                        changeNameValue({ 'quetyp': e })
                      }}
                    />
                    <BlockStack>
                      <Checkbox
                        label="Required question"
                        checked={state.ireq}
                        onChange={(e) => changeNameValue({ 'ireq': e })}
                      />
                      <Checkbox
                        label="Hide answers from widgets"
                        checked={state.ihdwdgt}
                        onChange={(e) => changeNameValue({ 'ihdwdgt': e })}
                      />
                    </BlockStack>
                    {!state.queHide ?
                      <BlockStack>
                        <Box paddingBlockEnd={options.length > 0 ? 400 : 100}>
                          <Text variant='bodyMd' as='p' fontWeight='regular'>Options</Text>
                        </Box>
                        {options.map((data, index) => (
                          <Box key={index} paddingBlockEnd={400}>
                            <InlineStack blockAlign='center' wrap={false}>
                              {state.quetyp === 'radio' && <RadioButton checked={false} />}
                              {state.quetyp === 'checkbox' && <Checkbox />}
                              {state.quetyp === 'linear_scale' && <Box paddingInlineEnd={200}><Text variant='bodyMd' as='p' fontWeight='regular'>{data.id}</Text></Box>}

                              <Box width='93%'>
                                <TextField
                                  value={state.arrans[index]}
                                  onChange={(e) => handleChangeQAns(e, index)}
                                  autoComplete="off"
                                  autoFocus
                                  error={state.errMessage.ans ? state.errMessage.ans : false}
                                />
                                {validator.message('ans', state.arrans[index], 'required')}
                              </Box>
                              <Box paddingInlineStart={200}>
                                <Button icon={DeleteIcon} variant='monochromePlain' onClick={() => handleRemoveOption(data.id)}></Button>
                              </Box>
                            </InlineStack>
                          </Box>
                        ))}
                        <Box>
                          <Button icon={PlusIcon} onClick={() => handleAddOption()}>Add option</Button>
                        </Box>
                      </BlockStack>
                      : null}
                    {state.quetyp === 'slider' &&
                      <Box>
                        <BlockStack gap={400}>
                          <TextField
                            label="Lower value"
                            value={state.arrans[0]}
                            onChange={(e) => handleChangeQAns(e, 0)}
                            autoComplete="off"
                          />
                          <TextField
                            label="Top value"
                            value={state.arrans[1]}
                            onChange={(e) => handleChangeQAns(e, 1)}
                            autoComplete="off"
                          />
                        </BlockStack>
                      </Box>
                    }
                  </BlockStack>
                </Card>
                <Card padding={'0'}>
                  <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
                    <Text variant='bodyLg' fontWeight='medium' as='h1'>Select where to show this question(choose any one)</Text>
                  </Box>
                  <Divider borderColor='border-tertiary' />

                  <Box padding={400}>

                    <InlineStack direction={'column'} gap={100}>
                      <RadioButton
                        label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Store Reviews</Text>}
                        checked={state.cndtyp === 1}
                        name="Store Reviews"
                        onChange={() => changeNameValue({ 'cndtyp': 1 })}
                      />
                      <RadioButton
                        label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>All products</Text>}
                        name="All products"
                        checked={state.cndtyp === 2}
                        onChange={() => changeNameValue({ 'cndtyp': 2 })}
                      />
                      <RadioButton
                        label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Selected product</Text>}
                        name="All products"
                        checked={state.cndtyp === 3}
                        onChange={() => changeNameValue({ 'cndtyp': 3 })}
                      />
                    </InlineStack>

                    {state.cndtyp === 3 &&
                      <Box paddingBlockStart={300}>
                        <Box id='custom_infoBG' padding={400} paddingBlock={300}>
                          <div id='cursorPoint' onClick={() => changeNameValue({ filterCollapseOpen: !state.filterCollapseOpen })}>
                            <InlineStack align='space-between'>
                              <Text variant='bodyLg' fontWeight='regular' as='h1'>Add conditions</Text>
                              <Box>
                                <Icon source={state.filterCollapseOpen ? ChevronUpIcon : ChevronDownIcon} tone="base"></Icon>
                              </Box>
                            </InlineStack>
                          </div>

                          <Collapsible
                            open={state.filterCollapseOpen}
                            id="db_collaspe"
                            transition={{ duration: '300ms', timingFunction: 'ease-in' }}
                          >
                            <Box paddingBlockStart={400}>
                              <InlineStack gap={300} blockAlign='end' wrap={false}>
                                <Box width='28%'>
                                  <Label><Text fontWeight='regular'>Select type</Text></Label>
                                  <div className='mt-2'>
                                    <Select
                                      options={popOpt1}
                                      placeholder='Select'
                                      value={state.ctyp}
                                      onChange={(e) => changeNameValue({ ctyp: e }, 'isShowMessage')}
                                    />
                                  </div>
                                </Box>
                                <Box width='28%'>
                                  <Label><Text fontWeight='regular'>Select condition</Text></Label>
                                  <div className='mt-2'>
                                    <Select
                                      options={popOpt2}
                                      placeholder='Select'
                                      value={state.cnd}
                                      onChange={(e) => changeNameValue({ cnd: e }, 'isShowMessage')}
                                    />
                                  </div>
                                </Box>
                                <div className='newquestion-dd-selectpdt'>
                                  <Box>
                                    {state.ctyp === 'product_id' ?
                                      <Label><Text fontWeight='regular'>Select Product</Text>
                                        <div className='mt-2'>
                                          <Popover
                                            fullWidth
                                            active={state.popActive}
                                            onClose={() => changeNameValue({ 'popActive': !state.popActive })}
                                            activator={
                                              <Button textAlign="left" fullWidth disclosure="select"
                                                onClick={() => changeNameValue({ 'popActive': true })}>
                                                <div style={{ textWrap: 'nowrap' }}>
                                                  {state.dataTxt ? state.dataTxt : 'Select a Product'}
                                                </div>
                                              </Button>}>

                                            <div className='form_productList newquestion-dd-selectpdt'>
                                              <div>
                                                <Box padding={300}>
                                                  <Filters
                                                    queryValue={state.productSearchQuery}
                                                    queryPlaceholder='Search product'
                                                    onQueryChange={(e) => changeNameValue({ 'productSearchQuery': e })}
                                                    filters={[]}
                                                    appliedFilters={[]}
                                                    onQueryClear={() => changeNameValue({ 'productSearchQuery': '' })}
                                                  />
                                                </Box>
                                              </div>
                                              {state.prodOptions && state.prodOptions.length > 0 ?
                                                <ResourceList
                                                  items={state.prodOptions}
                                                  renderItem={(item) => {
                                                    const { id, img } = item;
                                                    return (
                                                      <ResourceList.Item
                                                        id={id}
                                                        verticalAlignment="center"
                                                        media={img ? <Thumbnail source={img} size='small' /> : <Thumbnail source={ImageIcon} size='small' />}
                                                        onClick={() => handleSelectProduct(item)}>
                                                        <Text variant="bodyMd" fontWeight="bold" as="h3">{item.label}</Text>
                                                      </ResourceList.Item>
                                                    );
                                                  }} />
                                                :
                                                <Box paddingBlockStart={300} paddingBlockEnd={300}>
                                                  <InlineStack align='center' blockAlign='center'>
                                                    No data found
                                                  </InlineStack>
                                                </Box>
                                              }
                                            </div>
                                          </Popover>
                                        </div>
                                      </Label>
                                      :
                                      <TextField
                                        autoComplete='off'
                                        label={<Text fontWeight='regular'>Enter data</Text>}
                                        placeholder='Enter data'
                                        value={state.dataTxt}
                                        onChange={(e) => changeNameValue({ dataTxt: e }, 'isShowMessage')} />
                                    }
                                  </Box>
                                </div>
                                <Box width='10%'>
                                  <Button id='primary_btn' size='large' onClick={() => handleConditionAdd()}>Add</Button>
                                </Box>
                              </InlineStack>

                              <Box paddingBlockStart={400}>
                                <Card padding={0}>

                                  <IndexTable
                                    itemCount={state.arrcnd ? state.arrcnd.length : 0}
                                    selectable={false}
                                    headings={[
                                      { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Type</Text> },
                                      { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Condition</Text> },
                                      { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Data</Text> },
                                      { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Action</Text> }
                                    ]}>
                                    {state.arrcnd && state.arrcnd.map((val, i) => {
                                      return (
                                        <IndexTable.Row
                                          id={i}
                                          key={i}
                                          position={i}>
                                          <IndexTable.Cell>
                                            <Text variant="bodyMd" fontWeight="regular" as="span">
                                              {val.ctyp ? getLabel(val.ctyp) : '-'}</Text>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Text variant="bodyMd" fontWeight="regular" as="span">
                                              {val.cnd ? getLabel(val.cnd) : '-'}</Text>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Text variant="bodyMd" fontWeight="regular" as="span">{val.ctyp === 'product_id' ? val.dataTxt : val.data ? val.data : '-'}</Text>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Button icon={DeleteIcon} variant='monochromePlain' onClick={() => handleDelCondition(val)}></Button>
                                          </IndexTable.Cell>
                                        </IndexTable.Row>
                                      )
                                    })}
                                  </IndexTable>

                                </Card>
                              </Box>
                            </Box>
                          </Collapsible>

                        </Box>
                      </Box>
                    }

                  </Box >
                </Card>
              </BlockStack>
            </div>
            <Box paddingBlockStart={400}>
              <InlineStack align='end'>
                <Button id='primary_btn' variant='primary' onClick={() => handleCreateCustmQue()}>Save</Button>
              </InlineStack>
            </Box>
          </Box>
        </div>
      </Modal>

      <Modal //Edit custom question modal
        open={state.editQueOpen}
        onClose={() => openCloseModal('editQueOpen', state.editQueOpen, 'close')}>
        <div className="cusm_que_modal">
          <div style={{ backgroundColor: "white", padding: "12px 16px" }}>
            <InlineStack align="space-between" blockAlign='center' gap={400}>
              <Text variant="headingMd" as="h5" fontWeight='bold'>Edit Question</Text>
            </InlineStack>
          </div>
          <Box padding={400}>
            <div className='queList'>
              <BlockStack gap={400}>
                <Card>
                  <BlockStack gap={400}>
                    <TextField
                      label="Question"
                      value={state.que}
                      onChange={(e) => changeNameValue({ 'que': e })}
                      autoComplete="off"
                    />
                    {/* <TextField
                      label="Description"
                      value={state.rmk}
                      onChange={(e) => changeNameValue({ 'rmk': e })}
                      autoComplete="off"
                    /> */}
                    <Select
                      label="Question type"
                      options={queOptions}
                      value={state.quetyp}
                      onChange={(e) => {
                        changeNameValue({ 'quetyp': e })
                      }}
                    />
                    <BlockStack>
                      <Checkbox
                        label="Required question"
                        checked={state.ireq}
                        onChange={(e) => changeNameValue({ 'ireq': e })}
                      />
                      <Checkbox
                        label="Hide answers from widgets"
                        checked={state.ihdwdgt}
                        onChange={(e) => changeNameValue({ 'ihdwdgt': e })}
                      />
                    </BlockStack>
                    {!state.queHide ?
                      <BlockStack>
                        <Box paddingBlockEnd={options.length > 0 ? 400 : 100}>
                          <Text variant='bodyMd' as='p' fontWeight='regular'>Options</Text>
                        </Box>
                        {options.map((data, index) => (
                          <Box key={index} paddingBlockEnd={400}>
                            <InlineStack blockAlign='center' wrap={false}>
                              {state.quetyp === 'radio' && <RadioButton checked={false} />}
                              {state.quetyp === 'checkbox' && <Checkbox />}
                              {state.quetyp === 'linear_scale' && <Box paddingInlineEnd={200}><Text variant='bodyMd' as='p' fontWeight='regular'>{data.id}</Text></Box>}

                              <Box width='93%'>
                                <TextField
                                  value={state.arrans[index]}
                                  onChange={(e) => handleChangeQAns(e, index)}
                                  autoComplete="off"
                                  autoFocus
                                />
                              </Box>
                              <Box paddingInlineStart={200}>
                                <Button icon={DeleteIcon} variant='monochromePlain' onClick={() => handleRemoveOption(data.id)}></Button>
                              </Box>
                            </InlineStack>
                          </Box>
                        ))}
                        <Box>
                          <Button icon={PlusIcon} onClick={() => handleAddOption()}>Add option</Button>
                        </Box>
                      </BlockStack>
                      : null}
                    {state.quetyp === 'slider' &&
                      <Box>
                        <BlockStack gap={400}>
                          <TextField
                            label="Lower value"
                            value={state.arrans[0]}
                            onChange={(e) => handleChangeQAns(e, 0)}
                            autoComplete="off"
                          />
                          <TextField
                            label="Top value"
                            value={state.arrans[1]}
                            onChange={(e) => handleChangeQAns(e, 1)}
                            autoComplete="off"
                          />
                        </BlockStack>
                      </Box>
                    }
                  </BlockStack>
                </Card>
                <Card padding={'0'}>
                  <Box paddingBlock={300} paddingInline={400} background="bg-surface-secondary">
                    <Text variant='bodyLg' fontWeight='medium' as='h1'>Select where to show this question(choose any one)</Text>
                  </Box>
                  <Divider borderColor='border-tertiary' />

                  <Box padding={400}>

                    <InlineStack direction={'column'} gap={100}>
                      <RadioButton
                        label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Store Reviews</Text>}
                        checked={state.cndtyp === 1}
                        name="Store Reviews"
                        onChange={() => changeNameValue({ 'cndtyp': 1 })}
                      />
                      <RadioButton
                        label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>All products</Text>}
                        name="All products"
                        checked={state.cndtyp === 2}
                        onChange={() => changeNameValue({ 'cndtyp': 2 })}
                      />
                      <RadioButton
                        label={<Text id='clrsecondary' tone='base' fontWeight='regular' as='span'>Selected product</Text>}
                        name="All products"
                        checked={state.cndtyp === 3}
                        onChange={() => changeNameValue({ 'cndtyp': 3 })}
                      />
                    </InlineStack>

                    {state.cndtyp === 3 &&
                      <Box paddingBlockStart={300}>
                        <Box id='custom_infoBG' padding={400} paddingBlock={300}>
                          <div id='cursorPoint' onClick={() => changeNameValue({ filterCollapseOpen: !state.filterCollapseOpen })}>
                            <InlineStack align='space-between'>
                              <Text variant='bodyLg' fontWeight='regular' as='h1'>Add conditions</Text>
                              <Box>
                                <Icon source={state.filterCollapseOpen ? ChevronUpIcon : ChevronDownIcon} tone="base"></Icon>
                              </Box>
                            </InlineStack>
                          </div>

                          <Collapsible
                            open={state.filterCollapseOpen}
                            id="db_collaspe"
                            transition={{ duration: '300ms', timingFunction: 'ease-in' }}
                          >
                            <Box paddingBlockStart={400}>
                              <InlineStack gap={300} blockAlign='end' wrap={false}>
                                <Box width='28%'>
                                  <Label><Text fontWeight='regular'>Select Type</Text></Label>
                                  <div className='mt-2'>
                                    <Select
                                      options={popOpt1}
                                      placeholder='Select'
                                      value={state.ctyp}
                                      onChange={(e) => changeNameValue({ ctyp: e }, 'isShowMessage')}
                                    />
                                  </div>
                                </Box>
                                <Box width='28%'>
                                  <Label><Text fontWeight='regular'>Select Condition</Text></Label>
                                  <div className='mt-2'>
                                    <Select
                                      options={popOpt2}
                                      placeholder='Select'
                                      value={state.cnd}
                                      onChange={(e) => changeNameValue({ cnd: e }, 'isShowMessage')}
                                    />
                                  </div>
                                </Box>
                                <Box>
                                  {state.ctyp === 'product_id' ?
                                    <Label><Text fontWeight='regular'>Select Product</Text>
                                      <div className='mt-2'>
                                        <Popover
                                          fullWidth
                                          active={state.popActive}
                                          onClose={() => changeNameValue({ 'popActive': !state.popActive })}
                                          activator={
                                            <Button textAlign="left" fullWidth disclosure="select"
                                              onClick={() => changeNameValue({ 'popActive': true })}>
                                              <div style={{ textWrap: 'nowrap' }}>
                                                {state.dataTxt ? state.dataTxt : 'Select a Product'}
                                              </div>
                                            </Button>}>

                                          <div className='form_productList newquestion-dd-selectpdt'>
                                            <div>
                                              <Box padding={300}>
                                                <Filters
                                                  queryValue={state.productSearchQuery}
                                                  queryPlaceholder='Search product'
                                                  onQueryChange={(e) => changeNameValue({ 'productSearchQuery': e })}
                                                  filters={[]}
                                                  appliedFilters={[]}
                                                  onQueryClear={() => changeNameValue({ 'productSearchQuery': '' })}
                                                />
                                              </Box>
                                            </div>
                                            {state.prodOptions && state.prodOptions.length > 0 ?
                                              <ResourceList
                                                items={state.prodOptions}
                                                renderItem={(item) => {
                                                  const { id, img } = item;
                                                  return (
                                                    <ResourceList.Item
                                                      id={id}
                                                      verticalAlignment="center"
                                                      media={img ? <Thumbnail source={img} size='small' /> : <Thumbnail source={ImageIcon} size='small' />}
                                                      onClick={() => handleSelectProduct(item)}>
                                                      <Text variant="bodyMd" fontWeight="bold" as="h3">{item.label}</Text>
                                                    </ResourceList.Item>
                                                  );
                                                }} />
                                              :
                                              <Box paddingBlockStart={300} paddingBlockEnd={300}>
                                                <InlineStack align='center' blockAlign='center'>
                                                  No data found
                                                </InlineStack>
                                              </Box>
                                            }
                                          </div>
                                        </Popover>
                                      </div>
                                    </Label>
                                    :
                                    <TextField
                                      autoComplete='off'
                                      label={<Text fontWeight='regular'>Enter data</Text>}
                                      placeholder='Enter data'
                                      value={state.dataTxt}
                                      onChange={(e) => changeNameValue({ dataTxt: e }, 'isShowMessage')} />
                                  }
                                </Box>
                                <Box width='10%'>
                                  <Button id='primary_btn' size='large' onClick={() => handleConditionAdd()}>Add</Button>
                                </Box>
                              </InlineStack>

                              <Box paddingBlockStart={400}>
                                <Card padding={0}>

                                  <IndexTable
                                    itemCount={state.arrcnd ? state.arrcnd.length : 0}
                                    selectable={false}
                                    headings={[
                                      { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Type</Text> },
                                      { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Condition</Text> },
                                      { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Data</Text> },
                                      { title: <Text as='p' fontWeight='medium' tone='subdued' variant='bodyMd'>Action</Text> }
                                    ]}>
                                    {state.arrcnd && state.arrcnd.map((val, i) => {
                                      return (
                                        <IndexTable.Row
                                          id={i}
                                          key={i}
                                          position={i}>
                                          <IndexTable.Cell>
                                            <Text variant="bodySm" fontWeight="regular" as="span">
                                              {val.ctyp ? getLabel(val.ctyp) : '-'}</Text>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Text variant="bodySm" fontWeight="regular" as="span">
                                              {val.cnd ? getLabel(val.cnd) : '-'}</Text>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Text variant="bodySm" fontWeight="regular" as="span">{val.ctyp === 'product_id' ? val.dataTxt : val.data ? val.data : '-'}</Text>
                                          </IndexTable.Cell>
                                          <IndexTable.Cell>
                                            <Button icon={DeleteIcon} variant='monochromePlain' onClick={() => handleDelCondition(val)}></Button>
                                          </IndexTable.Cell>
                                        </IndexTable.Row>
                                      )
                                    })}
                                  </IndexTable>

                                </Card>
                              </Box>
                            </Box>
                          </Collapsible>

                        </Box>
                      </Box>
                    }

                  </Box >
                </Card>
              </BlockStack>
            </div>
            <Box paddingBlockStart={400}>
              <InlineStack align='end'>
                <Button id='primary_btn' variant='primary' onClick={() => handleUpdateCustmQue()}>Update</Button>
              </InlineStack>
            </Box>
          </Box>
        </div>
      </Modal>

      <Modal
        size='small'
        open={state.deleteOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Confirmation</Text>}
        onClose={(e) => openCloseModal('deleteOpen', state.deleteOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Text fontWeight='medium' variant='headingMd' as='h6'>Are you sure to delete this?</Text>
            <PageActions
              primaryAction={<Button id='primary_btn' onClick={() => handleDeleteCustmQue(state.queId)}>Yes</Button>}
              secondaryActions={[{
                content: 'No',
                onAction: () => openCloseModal('deleteOpen', state.deleteOpen, 'close')
              }]} />
          </div>
        </Modal.Section>
      </Modal>
    </Page>
  );
}

export default CustomQuestion;