import { Text, Button, Card, Box } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import BoardingStep from './BoardingStep';
import BoardingStep1 from './BoardingStep1';
import BoardingStep2 from './BoardingStep2';
import BoardingStep3 from './BoardingStep3';
import BoardingStep4 from './BoardingStep4';
import BoardingStep5 from './BoardingStep5';
import BoardingStep6 from './BoardingStep6';

function Page(props) {
  const shop_name = localStorage.getItem('fn');
  let _pvd = localStorage.getItem('pvd') || '';   //steps for shopify and wix

  useEffect(() => {
    let title = `Welcome to WiserReview, ${shop_name}!`;

    if (props.state.stepName === 'step1')
      title = (_pvd === 'shopify') ?
        `👋 Welcome, ${shop_name}! Let's quickly set up your review system`
        : `👋 Hi  ${shop_name}!, Let’s get live with reviews`;
    else if (props.state.stepName === 'step2')
      title = (_pvd === 'shopify') ?
        "Set Up Product Reviews"
        : `👋 Hi  ${shop_name}!, Let’s get live with reviews`;
    else if (props.state.stepName === 'step3')
      title = (_pvd === 'shopify') ?
        "Let’s highlight your products with star ratings!"
        : `👋 Hi  ${shop_name}!, Let’s get live with reviews`;
    else if (props.state.stepName === 'step4')
      title = (_pvd === 'shopify') ?
        "Let’s setup branding"
        : `👋 Hi  ${shop_name}!, Let’s get live with reviews`;
    else if (props.state.stepName === 'step5')
      title = (_pvd === 'shopify') ?
        "Let’s Streamline Review Requests"
        : `👋 Hi  ${shop_name}!, Let’s get live with reviews`;
    else if (props.state.stepName === 'step6')
      title = (_pvd === 'shopify') ?
        "Let's jumpstart your showcase of reviews"
        : `👋 Hi  ${shop_name}!, Let’s get live with reviews`;

    props.changeNameValue({ onBoardTtl: title });

    let key = props.state.stepName.replace('step', '') * 1145;
    let isNum = Number.isInteger(key) === true ? key : 0;
    props.changeNameValue({ key: isNum === 0 ? 0 : '-' + isNum });
  }, [props.state.stepName, shop_name]);

  //if user already completed onboarding then redirect to dashboard. 
  if (localStorage.getItem('icmpltonbrdng') === 'true') {
    window.location.href = '/';
  }

  console.log(localStorage.getItem('icmpltonbrdng'), shop_name);


  return (
    <div className='onBord_container'>

      {localStorage.getItem('icmpltonbrdng') && localStorage.getItem('icmpltonbrdng') !== 'true' &&
        <>
          <Box paddingBlockEnd={600}> {/* all steps title */}
            <Text variant="headingXl" as="h5" fontWeight='medium'>{props.state.onBoardTtl}</Text>
          </Box>

          <Card padding={800}>
            <div className='animie_Clss' style={{ transform: `translateX(${props.state.key}px)` }}>
              <BoardingStep
                props={props}
              />
              <BoardingStep1
                props={props}
              />
              <BoardingStep2
                props={props}
              />
              <BoardingStep3
                props={props}
              />
              <BoardingStep4
                props={props}
              />
              <BoardingStep5
                props={props}
              />
              <BoardingStep6
                props={props}
              />
            </div>
          </Card>
          {props.state.stepName === 'stepGuide' &&
            <Box paddingBlockStart={800}>
              <Button variant='plain' onClick={() => props.handleNextStep('dashboard', 'Skip_Start')}>Skip start guide</Button>
            </Box>
          }
        </>
      }

    </div >
  );
}

export default Page;